import { groupClient } from '@community-group/api';
import { GroupMainProfileCreateForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupMainProfileCreateForm: GroupMainProfileCreateForm;
};
type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePostGroupProfile = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const PostGroupProfile = groupClient.api.GroupProfileApi.apiV1GroupsProfilePost({
    axios: fetchInstance,
  });

  const fetchPostGroupProfile = ({ groupMainProfileCreateForm }: Params) => {
    return PostGroupProfile(groupMainProfileCreateForm);
  };

  return useThrottleMutation(fetchPostGroupProfile, {
    onError,
    onSuccess,
  });
};
