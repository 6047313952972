import { Category } from '@community-group/api/lib/group/models';
import { Checkbox, Spacing, Typography, useBottomSheet } from '@community-group/components';
import { useState } from 'react';

import { trackEvent } from '@/utils/analytics';

import * as s from './CategoryFilterBottomSheet.css';
import FilterBottomSheetButtonGroup from './FilterBottomSheetButtonGroup';

type Props = {
  categoryList: Category[];
  selectedCategoryList: Category[];
  setSelectedCategoryList: (categoryList: Category[]) => void;
};

const CategoryFilterBottomSheet = ({
  categoryList,
  selectedCategoryList,
  setSelectedCategoryList: handleSelectedCategoryList,
}: Props) => {
  const [currentSelectedList, setCurrentSelectedList] = useState(selectedCategoryList);
  const { closeAsync } = useBottomSheet();

  const isSelectedCategory = (id: number) =>
    currentSelectedList.some((category) => category.id === id);

  const handleClick = (category: Category) => {
    if (isSelectedCategory(category.id)) {
      return setCurrentSelectedList(
        currentSelectedList.filter((selectedCategory) => selectedCategory.id !== category.id)
      );
    }

    return setCurrentSelectedList([...currentSelectedList, category]);
  };

  const handleReset = () => {
    setCurrentSelectedList([]);
  };

  const handleSubmit = () => {
    trackEvent({
      event: 'click_search_apply_age_filter',
      params: {
        categoryList: currentSelectedList.map((category) => category.name).join(', '),
        categoryCount: currentSelectedList.length,
      },
    });

    closeAsync();
    handleSelectedCategoryList(currentSelectedList);
  };

  return (
    <div className={s.BottomSheetContainer}>
      <Typography typography="title2Bold" color="gray900">
        카테고리
      </Typography>
      <Spacing size={16} />
      <ul className={s.CategoryList}>
        {categoryList.map((category) => (
          <li className={s.CategoryItem} key={category.id} onClick={() => handleClick(category)}>
            <div className={s.Checkbox}>
              <Checkbox isSelected={isSelectedCategory(category.id)} size={'large'} />
            </div>
            {category.name}
          </li>
        ))}
        <div className={s.Gradient} />
      </ul>
      <FilterBottomSheetButtonGroup onReset={handleReset} onApply={handleSubmit} />
    </div>
  );
};

export default CategoryFilterBottomSheet;
