import { Dialog, useDialog, withAsyncBoundary } from '@community-group/components';
import { useEffect, useState } from 'react';

import { useGetParentingGroupKids } from '@/api/hooks/useGetParentingGroupKids';
import { useFlow } from '@/stackflow';
import { useBack } from '@/stackflow/hooks/useBack';

type Props = {
  groupId: string;
  isFlowFromChatRoom: boolean;
};

const ParentingGroupKidProfileCreateHooks = ({ groupId, isFlowFromChatRoom }: Props) => {
  const { data: parentingGroup } = useGetParentingGroupKids(groupId, !isFlowFromChatRoom);

  const back = useBack();
  const { replace } = useFlow();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { open: openDialog, close: closeDialog } = useDialog();

  useEffect(() => {
    const isKidsEmpty = (parentingGroup?.kids?.length ?? 0) === 0;
    if (!isFlowFromChatRoom || isKidsEmpty || isDialogOpen) return;

    setIsDialogOpen(true);
    openDialog({
      element: (
        <Dialog
          description="이미 아이 정보를 입력했어요"
          primaryActionLabel="아이 정보 보기"
          secondaryActionLabel="닫기"
          secondaryActionIntent="nonpreferred"
          onPrimaryAction={async () => {
            await closeDialog();
            replace('ParentingGroupKidProfileListPage', { groupId }, { animate: false });
          }}
          onSecondaryAction={async () => {
            await closeDialog();
            back();
          }}
        />
      ),
    });
  }, [
    groupId,
    isFlowFromChatRoom,
    parentingGroup,
    openDialog,
    closeDialog,
    back,
    replace,
    isDialogOpen,
  ]);

  return null;
};

export default withAsyncBoundary(ParentingGroupKidProfileCreateHooks, {});
