import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';

import { useFetchInstance } from './instance/useFetchInstance';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type RequestParams = {
  joinGrowth?: model.GroupGrowthEnum;
  joinExerciseGrowth?: ActivityQueryParams['joinExerciseGrowth'];
};

type Props = UseMutationOptions<
  AxiosResponse<model.GroupCreationResponse>,
  Error,
  RequestParams & model.GroupCreateForm
>;

export const usePostNewGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postGroup = groupClient.api.GroupApi.apiV1GroupsPost({ axios: fetchInstance });

  const fetchPostGroup = async (params: RequestParams & model.GroupCreateForm) => {
    const { joinGrowth, joinExerciseGrowth, ...groupCreateForm } = params;

    // NOTE: isGroupV2에 해당하는 첫번째 인자는 서버에서 제거해야 해요
    return await postGroup(
      false,
      joinGrowth,
      undefined, // joinRunningGrowth 인데 OAS 업데이트 해서 제거 하면 됨. joinExerciseGrowth로 변경됨
      joinExerciseGrowth,
      groupCreateForm
    );
  };

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  return useThrottleMutation(fetchPostGroup, {
    onError,
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);

      mutateEventLogLocation({
        eventName: 'create_group_detail',
        groupId: data.data.group?.id,
      });
    },
  });
};
