import { groupClient } from '@community-group/api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  userId: string;
};

type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const usePostRestrictedMemberUnblock = ({
  onError,
  onSuccess,
  onMutate,
  onSettled,
}: Props) => {
  const fetchInstance = useFetchInstance();
  const postRestrict =
    groupClient.api.GroupRestrictedMemberApi.apiV1GroupsIdRestrictedMembersUserIdUnblockPost({
      axios: fetchInstance,
    });

  const postRestrictWrapperFp = ({ groupId, userId }: Params) => {
    return postRestrict(parseInt(groupId), parseInt(userId));
  };

  return useMutation({
    mutationFn: postRestrictWrapperFp,
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
