import { ChipToggleButton, Spacing, Typography, useBottomSheet } from '@community-group/components';
import { Dispatch, SetStateAction, useState } from 'react';

import { trackEvent } from '@/utils/analytics';

import { AgeRangeType } from '../../model';
import * as s from './AgeFilterBottomSheet.css';
import FilterBottomSheetButtonGroup from './FilterBottomSheetButtonGroup';

type Props = {
  selectedAgeRange: AgeRangeType;
  setSelectedAgeRange: Dispatch<SetStateAction<AgeRangeType>>;
};

const ageRangeFilterOptions: Exclude<AgeRangeType, undefined>[] = ['20', '30', '40', '50', '60'];

const AgeFilterBottomSheet = ({ selectedAgeRange, setSelectedAgeRange }: Props) => {
  const [ageRange, setAgeRange] = useState(selectedAgeRange);

  const handleChipClick = (value: AgeRangeType) => () => {
    setAgeRange(value);
  };

  const handleReset = () => {
    setAgeRange(undefined);
  };

  const { closeAsync: closeBottomSheet } = useBottomSheet();
  const handleSubmit = () => {
    trackEvent({
      event: 'click_search_apply_age_filter',
      params: { ageRange },
    });

    closeBottomSheet();
    setSelectedAgeRange(ageRange);
  };

  return (
    <div className={s.BottomSheetContainer}>
      <Typography typography="title2Bold" color="gray900">
        모집 연령대
      </Typography>
      <Spacing size={16} />
      <div className={s.ChipContainer}>
        <ChipToggleButton isSelected={ageRange === undefined} onClick={handleChipClick(undefined)}>
          전체
        </ChipToggleButton>
        {ageRangeFilterOptions
          .map((option) => ({ key: option, label: `${option}대` }))
          .map((option) => (
            <ChipToggleButton
              key={option.key}
              isSelected={ageRange === option.key}
              onClick={handleChipClick(option.key)}
            >
              {option.label}
            </ChipToggleButton>
          ))}
      </div>
      <Spacing size={24} />
      <FilterBottomSheetButtonGroup onReset={handleReset} onApply={handleSubmit} />
    </div>
  );
};

export default AgeFilterBottomSheet;
