import '@daangn/karrot-clothes/lib/pullToRefresh.css';

import { ClientOnly } from '@community-group/components';
import { PullToRefresh } from '@daangn/karrot-clothes/lib/pullToRefresh';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { AppScreen as BasicAppScreen } from '@stackflow/plugin-basic-ui';
import { Ref, useCallback } from 'react';

import { useBack } from '@/stackflow/hooks/useBack';

import { Layout } from '../Layout';
import { LayoutRefProps } from '../Layout/components/ContentsLayout';

export type AppScreenProps = Parameters<typeof BasicAppScreen>[0] & {
  children: React.ReactNode;
  accessoryBar?: React.ReactNode;
  onPull?: () => void;
  layoutRef?: Ref<LayoutRefProps>;
  hasAppBar?: boolean;
  fallbackLoading?: React.ReactNode;
  disabledClientOnly?: boolean;
};

export const AppScreen = ({
  children,
  appBar,
  hasAppBar = true,
  accessoryBar,
  onPull,
  layoutRef,
  fallbackLoading,
  disabledClientOnly = false,
  ...props
}: AppScreenProps) => {
  const pop = useBack();

  const handleBackButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    pop();
  };

  const render = useCallback(
    () => (
      <Layout ref={layoutRef} accessoryBar={accessoryBar}>
        {onPull ? (
          <PullToRefresh onPullToRefresh={() => onPull()}>{children}</PullToRefresh>
        ) : (
          children
        )}
      </Layout>
    ),
    [accessoryBar, children, layoutRef, onPull]
  );

  return (
    <BasicAppScreen
      {...props}
      backgroundColor={vars.$semantic.color.paperDefault}
      appBar={
        hasAppBar
          ? {
              borderSize: '0',
              textColor: vars.$scale.color.gray900,
              iconColor: vars.$scale.color.gray900,

              ...appBar,

              closeButton: {
                renderIcon: () => <IconChevronLeftLine />,
                onClick: handleBackButtonClick,
                ...appBar?.closeButton,
              },
              backButton: {
                renderIcon: () => <IconChevronLeftLine />,
                onClick: handleBackButtonClick,
                ...appBar?.backButton,
              },

              title: appBar?.title && (
                <div
                  style={{
                    display: 'flex',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: '192px',
                  }}
                >
                  {appBar.title}
                </div>
              ),
            }
          : undefined
      }
    >
      {disabledClientOnly ? render() : <ClientOnly render={render} fallback={fallbackLoading} />}
    </BasicAppScreen>
  );
};
