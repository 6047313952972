import { Typography, VerticalSpacing } from '@community-group/components';
import { IconCheckmarkLine, IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler, ReactNode } from 'react';

import * as s from './MemberMissionItem.css';

type Props = {
  icon: ReactNode;
  title: string;
  isCompleted: boolean;
  onClick: MouseEventHandler;
};

const MemberMissionItem = ({ icon, title, isCompleted, onClick }: Props) => {
  const themeTextColor = isCompleted ? 'success' : 'gray700';

  return (
    <li className={s.MissionItemContainer({ isCompleted })} onClick={onClick}>
      {icon}
      <VerticalSpacing size={12} />
      <div className={s.MissionTitleWrapper}>
        <Typography
          typography="subtitle2Regular"
          color={themeTextColor}
          ellipsisAfterLines={1}
          as="h4"
        >
          {title}
        </Typography>
      </div>
      <VerticalSpacing size={12} />
      {isCompleted ? (
        <IconCheckmarkLine size={16} color={vars.$semantic.color.success} />
      ) : (
        <IconChevronRightFill size={16} color={vars.$scale.color.gray600} />
      )}
    </li>
  );
};

export default MemberMissionItem;
