import { vars } from '@seed-design/design-token';
import { useEffect, useRef, useState } from 'react';

import { ORDER_SEARCH } from '@/api/base/group';
import { useGetSearchGroupList } from '@/api/hooks/useGetSearchGroupList';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import { SearchResultGroupFilterProps } from '../SearchResultGroupFilter';
import SearchResultEmptyView from './EmptyView';
import * as s from './index.css';
import { SearchResultItem } from './SearchResultItem';
import { SearchResultLoader } from './SearchResultLoader';

type Props = Partial<SearchResultGroupFilterProps>;

const SearchResultList = ({
  selectedRegionRange,
  selectedAgeRange,
  selectedCategoryList,
  selectedSorting,
  setSelectedCategoryList,
}: Props) => {
  const { query = '' } = usePathParams();
  const { referrer = 'community_group.client', queryId } = useQueryParams();

  const { push } = useFlow();

  const listRef = useRef<HTMLUListElement>(null);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    if (!listRef.current) return;

    const scrollElement = listRef.current;

    const handleScroll = () => {
      requestAnimationFrame(() => {
        if (scrollElement.scrollTop <= 0) {
          setIsTop(true);
        } else {
          setIsTop(false);
        }
      });
    };

    scrollElement.addEventListener('scroll', handleScroll);

    return () => scrollElement.removeEventListener('scroll', handleScroll);
  }, [listRef]);

  const selectedCategoryListNullish = selectedCategoryList ?? [];
  const selectedCategoryIdList = selectedCategoryListNullish.map(({ id }) => id);
  const currentCategoryIdList =
    selectedCategoryIdList.length > 0 ? selectedCategoryIdList : undefined;

  const searchFilter = {
    query,
    category: currentCategoryIdList,
    order: selectedSorting ?? ORDER_SEARCH.RECOMMEND_SCORE_DESC,
    referrer,
    queryId,
    regionRange: selectedRegionRange,
    minAge: selectedAgeRange ? Number(selectedAgeRange) : undefined,
    maxAge: selectedAgeRange ? Number(selectedAgeRange) + 9 : undefined,
  };

  const { data, hasNextPage } = useGetSearchGroupList(searchFilter);

  const flattenSearchResultList = data?.pages.map(({ data }) => data.groups).flat(1) ?? [];

  if (
    flattenSearchResultList.length <= 0 &&
    selectedCategoryList &&
    selectedCategoryList.length <= 0 &&
    setSelectedCategoryList
  ) {
    return (
      <SearchResultEmptyView
        selectedCategoryList={selectedCategoryList}
        setSelectedCategoryList={setSelectedCategoryList}
      />
    );
  }

  return (
    <>
      <div
        className={s.spacing}
        style={{
          backgroundColor: isTop ? 'transparent' : vars.$semantic.color.divider1,
        }}
      />
      <ul
        style={{
          height: flattenSearchResultList.length > 4 ? 'calc(100% - 3.6875rem)' : undefined,
        }}
        ref={listRef}
      >
        {flattenSearchResultList.map((result, index) => (
          <SearchResultItem
            key={result.id}
            result={result}
            onClick={() => {
              trackEvent({
                event: 'click_simple_search_article',
                params: {
                  service_name: 'community_group',
                  origin: 'community_group.client',
                  referrer: referrer,
                  query: decodeURIComponent(query ?? ''),
                  size: 20,
                  offset: Math.floor((index ?? 0) / 20) * 20,
                  document_id: result.id.toString(),
                },
                loggerType: ['SEARCH_KARROT'],
              });

              trackEvent({
                event: 'click_search_result_list_item',
                params: {
                  groupName: result.name,
                  groupCategoryName: result.category?.name,
                  groupId: result.id,
                  listItemIndex: index,
                  filterOrder: searchFilter.order,
                },
                sample: true,
              });

              push('GroupDetailPage', {
                groupId: result.id.toString(),
              });
            }}
          />
        ))}
        {hasNextPage && <SearchResultLoader {...searchFilter} />}
        <div className={s.safeAreaBottom} />
      </ul>
    </>
  );
};

export default SearchResultList;
