import { Typography, useBottomSheet } from '@community-group/components';
import { useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { getGroupPath } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { queryClient } from '@/shared/api/instance';
import { getAgeDisplayText } from '@/components/common/TagLabel/AgeLabel';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { GroupSelectCategoryBottomSheet } from './GroupSelectCategoryBottomSheet';
import { MeetupPoisMenuItem } from './MeetupPoisMenuItem';
import SettingMenuItem from './SettingMenuItem';
import SettingSectionWrapper from './Wrapper';

const GroupInfoSettingSection = () => {
  const { push } = useFlow();

  const { groupId } = usePathParams();
  const { group } = useReadGroupDetail(groupId);

  const ageTagInfo = useMemo(() => {
    if (!group?.ageRangeTag)
      return {
        minAge: undefined,
        maxAge: undefined,
      };
    return group?.ageRangeTag;
  }, [group?.ageRangeTag]);

  const ageRangeDefaultValue = useMemo(
    () => ({
      minAge: ageTagInfo?.minAge,
      maxAge: ageTagInfo?.maxAge,
    }),
    [ageTagInfo]
  );

  const { setValue } = useForm<FieldValues>({
    defaultValues: {
      name: group?.name,
      description: group?.description || '',
      ageRange: ageRangeDefaultValue,
    },
  });

  useEffect(() => {
    setValue('ageRange', ageRangeDefaultValue);
  }, [ageRangeDefaultValue, setValue]);
  const { bridge } = useBridge();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: putGroup } = usePutGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: ({ data }) => {
      queryClient.setQueryData([getGroupPath(String(data.group?.id))], { data: data });
      bridge.openToast({
        toast: {
          body: `수정되었어요.`,
        },
      });
    },
  });
  const { categories } = useGetCategories();
  const { open: openBottomSheet } = useBottomSheet();
  const handleChangeCategory = () => {
    openBottomSheet({
      element: (
        <GroupSelectCategoryBottomSheet
          categoryList={categories}
          selectedCategoryId={group?.category.id ?? 1}
          setSelectedCategoryId={(selectedCategoryId) => {
            putGroup({
              id: group?.id.toString() ?? '0',
              groupModifyForm: {
                categoryId: selectedCategoryId,
              },
            });
          }}
        />
      ),
    });
  };

  return (
    <SettingSectionWrapper title="모임 정보">
      <SettingMenuItem
        title="카테고리"
        onClick={handleChangeCategory}
        renderRight={
          <Typography typography="label2Regular" color="primary" ellipsisAfterLines={1}>
            {group?.category.name}
          </Typography>
        }
      />
      <SettingMenuItem
        title="나이대"
        onClick={() => {
          push('BottomSheet/GroupSettingEditGroupAgeRangeBottomSheet', {
            groupId,
            initialGroupMinAge: ageTagInfo.minAge ? ageTagInfo.minAge.toString() : undefined,
            initialGroupMaxAge: ageTagInfo.maxAge ? ageTagInfo.maxAge.toString() : undefined,
          });
        }}
        renderRight={
          <Typography typography="label2Regular" color="primary" ellipsisAfterLines={1}>
            {ageTagInfo
              ? getAgeDisplayText({ min: ageTagInfo.minAge, max: ageTagInfo.maxAge })
              : '누구나'}
          </Typography>
        }
      />
      <MeetupPoisMenuItem title="자주 모이는 장소" />
    </SettingSectionWrapper>
  );
};

export default GroupInfoSettingSection;
