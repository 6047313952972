import { useCallback, useEffect } from 'react';

import { useStorage } from '@/hooks/useStorage';
import { useStore } from '@/store';

const useMainBannerFolded = () => {
  const [, setFoldedGroupDetailMainBanner] = useStorage('foldedGroupDetailMainBanner', true);
  const { foldedMainBanner, setFoldedMainBanner } = useStore();

  useEffect(() => {
    if (foldedMainBanner === undefined) setFoldedMainBanner(true);
  }, [foldedMainBanner, setFoldedMainBanner]);

  const handleToggle = useCallback(() => {
    const value = !foldedMainBanner;
    setFoldedMainBanner(value);
    setFoldedGroupDetailMainBanner(value);
  }, [foldedMainBanner, setFoldedMainBanner, setFoldedGroupDetailMainBanner]);

  const handleChangeState = useCallback(
    (value: boolean) => {
      setFoldedMainBanner(value);
      setFoldedGroupDetailMainBanner(value);
    },
    [setFoldedMainBanner, setFoldedGroupDetailMainBanner]
  );

  return { foldedMainBanner, handleToggle, handleChangeState };
};

export default useMainBannerFolded;
