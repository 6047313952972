import { VerticalSpacing } from '@community-group/components';
import { IconCamcorderLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { trackEvent } from '@/utils/analytics';

import useHandleUploadVideo from '../../hooks/useHandleUploadVideo';
import { GroupPostFormProps } from '../GroupPostForm';
import * as s from './index.css';

type Props = {
  formHandler: GroupPostFormProps['formHandler'];
  groupId: string;
  closeBottomSheet: () => Promise<void>;
};

function StartBottomSheetVideoUploadButton({ formHandler, groupId, closeBottomSheet }: Props) {
  const { handleVideoFileSelection } = useHandleUploadVideo({
    formHandler,
  });

  const onVideoFileSelection = () => {
    trackEvent({
      event: 'click_group_post_video_button',
      params: {
        buttonLocation: 'startbottomsheet',
        groupId,
      },
    });

    handleVideoFileSelection();
    closeBottomSheet();
  };

  return (
    <button className={s.ButtonItem} onClick={onVideoFileSelection}>
      <IconCamcorderLine size={20} color={vars.$scale.color.gray900} />
      <VerticalSpacing size={12} />
      <p>동영상</p>
    </button>
  );
}

export default StartBottomSheetVideoUploadButton;
