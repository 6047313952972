import { groupClient } from '@community-group/api';
import { GroupFeedType } from '@community-group/api/lib/group/models';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import humps from 'humps';
import { useMemo } from 'react';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupFeedListPath = (id: string) => {
  return groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedsGetPath(Number(id));
};

export const useGetGroupFeedList = (
  id: string,
  type?: GroupFeedType,
  categoryIds?: number[],
  onlyNoticed = false,
  initialCursor?: string
) => {
  const fetchInstance = useFetchInstance();
  const getGroupFeedList = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedsGet({
    axios: fetchInstance,
  });

  // 서버와 프론트 간의 OAS generation 방식 차이로 FE에서 generate는 snake_case로 되어있지만, 요청시에는 camelCase로 보내야함
  // https://daangn.slack.com/archives/C0336FFHA8L/p1722828005470419
  const convertedType = useMemo(() => {
    return type ? humps.camelize(type) : undefined;
  }, [type]);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useSuspenseInfiniteQuery({
    queryKey: [getGroupFeedListPath(id), categoryIds],
    queryFn: ({ pageParam = initialCursor }) => {
      return getGroupFeedList(
        Number(id),
        convertedType as GroupFeedType,
        !categoryIds || categoryIds[0] === 0 ? undefined : categoryIds,
        onlyNoticed,
        pageParam ?? undefined,
        20
      );
    },
    initialPageParam: initialCursor,

    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    refetchInterval: 10000,
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
