import { groupClient } from '@community-group/api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  meetupId: string;
};

type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const usePutGroupMeetupLike = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupMeetupLike = groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdLikePut({
    axios: fetchInstance,
  });

  const putGroupMeetupLikeWrapperFp = ({ groupId, meetupId }: Params) => {
    return putGroupMeetupLike(parseInt(groupId), parseInt(meetupId));
  };

  return useMutation({
    mutationFn: putGroupMeetupLikeWrapperFp,
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
