import { GroupMeetupCreationCycleEnum } from '@community-group/api/lib/group/models';
import { ChipToggleButton } from '@community-group/components';
import { IconArrowClockwiseCircularLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { formatDate, getWeekOfMonth } from '@/utils/date';
import { meetupFieldSchema } from '@/utils/validate/formSchema/meetup';

import { closeListItemFormFieldQuerySelector } from '../../utils/selector';
import { GroupMeetupFormListItem } from '../ListItem';
import * as s from './CreationCycleField.css';

interface Props<T extends object> extends React.InputHTMLAttributes<HTMLInputElement> {
  creationCycleName: Path<T>;
  dateName: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const GroupMeetupCreationCycleField = <T extends object>({
  creationCycleName,
  dateName,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const meetupDate = useMemo(() => new Date(watch(dateName)), [watch(dateName)]);

  const creationCycle = watch(creationCycleName);
  const creationCycleDisplayText = useMemo(
    () => getCreationCycleDisplayText(creationCycle, meetupDate),
    [creationCycle, meetupDate]
  );

  const creationCycleList = Object.values(meetupFieldSchema.creationCycle.enum);

  return (
    <GroupMeetupFormListItem
      {...props}
      fieldIcon={<IconArrowClockwiseCircularLine size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="반복"
      fieldName={creationCycleName}
      fieldValue={creationCycleDisplayText}
    >
      <div className={s.wrapper}>
        {creationCycleList.map((cycleItem) => (
          <ChipToggleButton
            key={cycleItem}
            type="button"
            isSelected={cycleItem === creationCycle}
            onClick={() => {
              setValue(creationCycleName, cycleItem);

              closeListItemFormFieldQuerySelector([
                'meetupTimeAt',
                'hasMeetupTimeAtOnlyDate',
                'creationCycle',
                'maximumParticipantsNumber',
              ]);
            }}
          >
            {getCreationCycleDisplayText(cycleItem, meetupDate)}
          </ChipToggleButton>
        ))}
      </div>
    </GroupMeetupFormListItem>
  );
};

const getCreationCycleDisplayText = (
  creationCycle: GroupMeetupCreationCycleEnum,
  currentDate: Date
): string => {
  if (creationCycle === 'weekly') return formatDate(currentDate, '매주 eeee');
  if (creationCycle === 'biWeekly') return formatDate(currentDate, '격주 eeee');
  // return 값 매월 두번째 토요일
  if (creationCycle === 'monthlyByWeekday') {
    const weekOfMonth = getWeekOfMonth(currentDate);
    return formatDate(currentDate, `매월 ${weekOfMonth}번째 eeee`);
  }
  if (creationCycle === 'monthlyByDay') return formatDate(currentDate, '매월 d일');

  return '없음';
};
