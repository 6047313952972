import { PostResponse } from '@community-group/api/lib/group/models';

export const postDetailResponse: PostResponse = {
  post: {
    id: 6885,
    content: 'gwregrewgwrgrwgeregr',
    publishType: 'public',
    emotion: {
      count: 0,
    },
    commentCount: 0,
    medias: [],
    images: [],
    author: {
      id: 5964,
      role: 'superHost',
      state: 'member',
      temperature: 36.5,
      userRegion: {
        id: 366,
        name: '서초4동',
        province: '서울특별시',
        city: '서초구',
        town: '서초4동',
      },
      nickname: '데입0002',
      profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
      isAccountDeleted: false,
      memberKey: '3773-5964',
      createdAt: '2024-04-09T10:45:25.165727Z',
      updatedAt: '2024-04-09T10:46:13.08418Z',
      verified: false,
      userRegionCheckInCount: 1,

      description: '',
    },
    isUpdated: false,
    isNoticed: false,
    isPublished: false,
    createdAt: '2024-11-19T08:40:35.692315Z',
    updatedAt: '2024-11-19T08:40:35.692315Z',
    boardCategories: [],
    readCount: 2,
    permalinkId: 'xjKexVP6',
    isSettingOnSubNickname: false,
    isPhotoContestEntry: false,
    pois: [],
    postType: {
      type: 'post',
      meetupId: 0,
    },
    deletedAt: null,
    groupInfo: {
      id: 3773,
      name: "avfvevreber;lmbreb'wembp",
      profileImage: {
        id: 'kr-7141#1522473446226821120',
        small:
          'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
        medium:
          'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
        large:
          'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
        url: 'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
        width: 192,
        height: 192,
      },
      isBoardManaged: false,
      isSettingOnSubNickname: false,
      joinType: 'free',
      region: {
        id: 366,
        name: '서초4동',
        province: '서울특별시',
        city: '서초구',
        town: '서초4동',
      },
      needVerification: false,
      joinApprovalRequireReason: 'none',
      approvalConditions: 'none',
      approvalQuestion: '',
      category: {
        id: 1,
        name: '운동',
      },
      age: {
        tags: [
          {
            type: 'GROUP',
            min: 30,
            max: 39,
            text: '30~39세',
          },
        ],
      },
      ageRangeTag: {
        minAge: 30,
        maxAge: 39,
      },
    },
    currentUserInfo: {
      id: 5964,
      role: 'superHost',
      userState: 'member',
      regionInfo: {
        userRegion: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        isGroupJoinableRegion: true,
        isCertificateRegion: true,
      },
    },
    challengeInfo: {
      name: '챌린지 매일매일 인증하자',
      id: 123,
      totalDays: 28,
      participantsCount: 10,
      status: 'closed',
      endedAt: '2024-12-10T00:00:00.000000Z',
      startedAt: '2024-11-10T00:00:00.000000Z',
      certifyIntervalTargetCount: 1,
      publicId: '123',
    },
  },
};
