import { StoreSlice } from '..';

export type GlobalStateSlice = {
  fixedLayout: boolean;
  profileCheckPending: boolean;
  setFixedLayout: (value: boolean) => void;
  setProfileCheckPending: (value: boolean) => void;

  is10월운동MarketingJoinable: boolean; // 10월 운동 가입 사전신청 이벤트 참가 가능 여부, 프로모션 종료 후 제거 가능
  set10월운동MarketingJoinable: (value: boolean) => void;
  is10월운동MarketingCreate: boolean; // 10월 운동 생성, 프로모션 종료 후 제거 가능
  set10월운동MarketingCreate: (value: boolean) => void;

  // 모임상세 메인배너 접힘 여부
  foldedMainBanner: boolean;
  setFoldedMainBanner: (value: boolean) => void;
};

export const createGlobalStateSlice: StoreSlice<GlobalStateSlice> = (set) => ({
  fixedLayout: true,
  profileCheckPending: false,

  is10월운동MarketingJoinable: false,
  set10월운동MarketingJoinable: (value) => {
    set((state) => ({ ...state, is10월운동MarketingJoinable: value }));
  },
  is10월운동MarketingCreate: false,
  set10월운동MarketingCreate: (value) => {
    set((state) => ({ ...state, is10월운동MarketingCreate: value }));
  },
  setFixedLayout: (value) => {
    set((state) => ({ ...state, fixedLayout: value }));
  },
  setProfileCheckPending: (value) => {
    set((state) => ({ ...state, profileCheckPending: value }));
  },

  foldedMainBanner: true,
  setFoldedMainBanner: (value) => {
    set((state) => ({ ...state, foldedMainBanner: value }));
  },
});
