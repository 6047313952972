import { isMember, Typography } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { IconPerson2Openarms } from '@daangn/react-multicolor-icon';
import { vars } from '@seed-design/design-token';

import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import * as s from './PostShortcutBanner.css';

const PostShortcutBanner = () => {
  const { groupId, postId } = usePathParams();
  const { utm_medium, refer_root } = useQueryParams();

  const currentUser = useCurrentGroupMe();

  const { push } = useFlow();

  const handlePushToHomeActivity = () => {
    trackEvent({
      event: 'click_post_shortcut_banner',
      params: {
        groupId,
        postId,
      },
      sample: true,
    });
    push('HomePage', {});
  };

  if (isMember(currentUser.role)) return null;
  if (!utm_medium && !refer_root) return null;

  return (
    <div className={s.ShortcutBannerWrapper} onClick={handlePushToHomeActivity}>
      <div className={s.ShortcutLeftSide}>
        <IconPerson2Openarms size={18} />
        <Typography typography="caption1Bold" color="gray700">
          다른 모임 더 보기
        </Typography>
      </div>
      <IconChevronRightFill size={15} color={vars.$scale.color.gray600} />
    </div>
  );
};

export default PostShortcutBanner;
