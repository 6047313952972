import {
  GroupFeedMemberPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler, useMemo } from 'react';

import ProfileListItem from '@/components/common/Profile/ListItem';

import * as s from './MemberListItem.css';

type Props = {
  member: GroupFeedMemberPresentation;
  currentUserId: number;
  currentUserRole: GroupMemberRoleEnum;
  onClick: (memberId: number, memberRole: GroupMemberRoleEnum) => MouseEventHandler;
  onActionButtonClick: (memberId: number) => MouseEventHandler;
};

const MemberItem = ({
  member,
  currentUserId,
  currentUserRole,
  onClick,
  onActionButtonClick,
}: Props) => {
  const hasActionButton = useMemo(
    () => currentUserRole !== 'none' && currentUserId !== member.id,
    [currentUserId, currentUserRole, member]
  );

  return (
    <li className={s.ListItem} onClick={onClick(member.id, member.role)}>
      <div className={s.LeftSide}>
        {/* FIXME: ProfileListItem 내에 호출하고 있는 훅을 제거해야 Storybook 사용 가능 */}
        <ProfileListItem
          user={{
            id: member.id,
            nickname: member.nickname,
            description: member.description,
            profileImage: member.profileImage,
            userRegion: member.checkInRegion,
            role: member.role,
            isAccountDeleted: false,
            subNickname: member.subNickname,
          }}
          config={{
            handledBadgeClick: false,
          }}
        />
      </div>
      {hasActionButton && (
        <div className={s.RightSide}>
          <IconDot3VerticalLine
            size={20}
            color={vars.$scale.color.gray900}
            onClick={onActionButtonClick(member.id)}
          />
        </div>
      )}
    </li>
  );
};

export default MemberItem;
