import { groupClient } from '@community-group/api';

import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId: string;
};

/*
 *  모임 상세 채팅방 관련 한번에 패치하는 함수
 */
export const refetchGroupRooms = ({ groupId }: Params) => {
  queryClient.refetchQueries({
    queryKey: [groupClient.api.TalkRoomApi.getapiV1GroupsIdRoomsGetPath(Number(groupId))],
  });
  queryClient.refetchQueries({
    queryKey: [groupClient.api.TalkRoomApi.getapiV1GroupsIdMyRoomsGetPath(Number(groupId))],
  });
};
