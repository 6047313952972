import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { BoxButton, Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { IconPersonShieldFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';
import { MouseEventHandler, useMemo } from 'react';

import IconApprovedGroup from '@/assets/images/icon_approved_group.svg';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';

import * as s from './index.css';
import SummaryLevelBanner from './SummaryLevelBanner';

type Props = {
  group: GroupDetailPresentation;
  isTargetParentingGroup: boolean;
  currentUser: GroupCurrentUser;
  onGroupEditClick: MouseEventHandler;
};

const GroupDetailInformation = ({
  group,
  isTargetParentingGroup,
  currentUser,
  onGroupEditClick,
}: Props) => {
  const verifiedInfoText = useMemo(() => {
    if (!group.needVerification) return '';

    let renderText = '본인인증 모임';
    if (group.canViewVerifiedUserInfo) renderText += ' ∙ 성별 및 나이대 공개';

    return renderText;
  }, [group.needVerification, group.canViewVerifiedUserInfo]);

  return (
    <div className={s.SectionWrapper}>
      <img
        className={s.GroupProfileImage}
        src={group.profileImage.medium}
        alt="모임 프로필 이미지"
      />
      <Spacing size={16} />
      <Typography typography="title3Bold" color="gray900" className={s.TextWrapper}>
        {group.name}
      </Typography>
      <Spacing size={2} />
      <Typography typography="caption1Regular" color="gray600" className={s.TextWrapper}>
        {dayjs(group.createdAt).format('YYYY.MM.DD')} 개설
      </Typography>
      <Spacing size={8} />
      {isTargetParentingGroup && (
        <div className={s.TextBox}>
          <img src={IconApprovedGroup} alt="대표 모임 뱃지" width={14} height={14} />
          <VerticalSpacing size={4} />
          <Typography typography="caption1Regular" color="carrot950">
            대표 육아 모임
          </Typography>
        </div>
      )}
      {isTargetParentingGroup && group.needVerification && <Spacing size={4} />}
      {group.needVerification && (
        <div className={s.TextBox}>
          <IconPersonShieldFill size={14} color={vars.$scale.color.green500} />
          <VerticalSpacing size={4} />
          <Typography typography="caption1Regular" color="gray700">
            {verifiedInfoText}
          </Typography>
        </div>
      )}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <Spacing size={16} />
        <BoxButton width="100%" variant="secondary" size="medium" onClick={onGroupEditClick}>
          모임 정보 수정
        </BoxButton>
      </CheckAccessibleRole>
      <SummaryLevelBanner />
    </div>
  );
};

export default GroupDetailInformation;
