import { BoxButton } from '@community-group/components';
import { useMemo, useState } from 'react';

import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePutGroupMeetupJoinStatus } from '@/api/hooks/usePutGroupMeetupJoinStatus';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';

import * as s from './GroupMeetupParticipantButton.css';
import { GroupMeetupDetailAccessoryBarWrapper } from './shared/GroupMeetupDetailAccessoryBarWrapper';

export const GroupMeetupParticipantButton = () => {
  const { push } = useFlow();

  const { groupId, meetupId } = usePathParams();
  const { data } = useReadGroupMeetupDetail({ groupId, meetupId });

  const [isLeaveLoading, setIsLeaveLoading] = useState(false);

  const reviewLabel = useMemo(
    () => (data.currentUserInfo.reviewWritten ? '남긴 후기 보기' : '후기 작성'),
    [data.currentUserInfo.reviewWritten]
  );
  const handleReviewClick = () => {
    if (data.currentUserInfo.reviewWritten) {
      trackEvent({
        event: 'click_group_meetup_review_post_button',
        params: {
          groupId,
          meetupId,
          groupName: data.groupInfo.name,
          groupMeetupName: data.title,
        },
      });

      return push('GroupPostDetailPage', {
        groupId,
        postId: (data.currentUserInfo.writtenReviewPostId ?? '').toString(),
      });
    }

    // todo: groupCategoryName 추가할 것
    trackEvent({
      event: 'click_write_group_meetup_review_button',
      params: {
        groupId,
        meetupId,
        groupName: data.groupInfo.name,
        groupMeetupName: data.title,
      },
    });
    // TODO: GroupMeetupReviewPage

    return push('GroupMeetupCreateReviewPage', {
      groupId,
      meetupId,
    });
  };

  const handleOpenChannelChat = () => {
    openLink(data?.channelInfo?.targetUri);
  };

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutGroupMeetupJoinStatus({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupMeetupDetail({
        groupId,
        meetupId,
      });
      refetchGroupDetail({ groupId });
    },
    onSettled: () => {
      setTimeout(() => {
        setIsLeaveLoading(false);
      }, 500);
    },
  });

  const handleLeaveClick = () => {
    setIsLeaveLoading(true);
    mutate(
      {
        groupId,
        meetupId,
        meetupJoinStatus: 'decline',
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_cancel_join_meetup',
            params: {
              meetupId,
              sectionName: 'meetup_detail_accessory_bar',
              groupId: data?.groupInfo.id,
              userRole: data?.currentUserInfo.role,
              maximumParticipantsNumber: data?.maximumParticipantsNumber,
              currentParticipantsNumber: data?.currentParticipantsNumber,
              currentMeetupJoinStatus: data?.currentUserInfo.meetupJoinStatus,
              isInstantMeetupJoin: false,
            },
          });
        },
      }
    );
  };

  if (data.progressStatus.status === 'closed') {
    return (
      <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
        <div className={s.buttonWrapper}>
          <BoxButton size="xlarge" width="100%" variant="secondary" onClick={handleOpenChannelChat}>
            채팅방
          </BoxButton>
          <BoxButton width="100%" size="xlarge" onClick={handleReviewClick}>
            {reviewLabel}
          </BoxButton>
        </div>
      </GroupMeetupDetailAccessoryBarWrapper>
    );
  }

  return (
    <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
      <div className={s.buttonWrapper}>
        <BoxButton
          isLoading={isLeaveLoading}
          size="xlarge"
          width="100%"
          variant="secondary"
          onClick={handleLeaveClick}
        >
          참여 취소
        </BoxButton>
        <BoxButton size="xlarge" width="100%" onClick={handleOpenChannelChat}>
          채팅방
        </BoxButton>
      </div>
    </GroupMeetupDetailAccessoryBarWrapper>
  );
};
