import {
  IconCalendarFill,
  IconLocationpinFill,
  IconPerson2Fill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './MeetupListCardItemInfo.css';

export type MeetupListCardItemInfoItem = {
  type: 'calendar' | 'user_group' | 'location'; // 추후 'money' 추가 필요
  text: string;
};
export type MeetupListCardItemInfoProps = {
  infoList: MeetupListCardItemInfoItem[];
  size?: 'small' | 'xsmall';
};

export const MeetupListCardItemInfo = ({
  infoList,
  size = 'xsmall',
}: MeetupListCardItemInfoProps) => {
  return (
    <div className={s.wrapper}>
      {infoList.map(({ type, text }) => {
        if (type === 'user_group') {
          return (
            <div className={s.item({ size })} key={type}>
              <div className={s.icon}>
                <IconPerson2Fill {...IconConfig} />
              </div>
              <p className={s.text({ size })}>{text}</p>
            </div>
          );
        }

        if (type === 'calendar') {
          return (
            <div className={s.item({ size })} key={type}>
              <div className={s.icon}>
                <IconCalendarFill {...IconConfig} />
              </div>
              <p className={s.text({ size })}>{text}</p>
            </div>
          );
        }

        if (type === 'location') {
          return (
            <div className={s.item({ size })} key={type}>
              <div className={s.icon}>
                <IconLocationpinFill {...IconConfig} />
              </div>
              <p className={s.text({ size })}>{text}</p>
            </div>
          );
        }

        return <></>;
      })}
    </div>
  );
};

const IconConfig = {
  width: '16px',
  height: '16px',
  color: vars.$scale.color.gray500,
};
