import { useMemo } from 'react';

import useActiveActivities from '@/hooks/useActiveActivities';

import { CREATE_GROUP_DEFAULT_FLOW } from '../utils/createGroupControl';

const useCreateGroupFunnel = () => {
  const { activeActivities } = useActiveActivities();
  const currentActivity = activeActivities[activeActivities.length - 1];

  // 다음 페이지 이름을 가져온다.
  const nextPageName = useMemo(() => {
    const nextPageIndex =
      CREATE_GROUP_DEFAULT_FLOW.findIndex((page) => page === currentActivity.name) + 1;
    return CREATE_GROUP_DEFAULT_FLOW[nextPageIndex];
  }, [currentActivity.name]);

  return { nextPageName };
};

export default useCreateGroupFunnel;
