import { Spacing } from '@community-group/components';
import { IconCheckmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { NotIncludeBackButtonControlModal } from '@/components/common/Modal/NotIncludeBackButtonControlModal';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';

import * as s from './SelectBoardTypeBottomSheet.css';

interface Props {
  groupId?: string;
  selectedCategory: number;
  setSelectedCategory: (sorting: number) => void;
}

const SelectBoardTypeBottomSheet = ({ selectedCategory, setSelectedCategory, groupId }: Props) => {
  const { data } = useGetBoardTypeList(Number(groupId));

  const handleClick = (value: number) => {
    setSelectedCategory(value);
  };

  if (!data) return <></>;

  return (
    <NotIncludeBackButtonControlModal
      type={MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET}
      innerPaddingBottom={0}
    >
      <div className={s.Wrapper}>
        <h3 className={s.Title}>게시판을 선택해주세요</h3>
        <Spacing size={16} />
        <ul className={s.SortingList}>
          {(data.boardCategories ?? []).map((item) => (
            <li
              className={s.SortingItem({
                selected: item.id === selectedCategory,
              })}
              key={item.category}
              onClick={() => {
                handleClick(item.id);
              }}
            >
              {item.category}
              {item.id === selectedCategory && (
                <div className={s.IconWrapper}>
                  <IconCheckmarkLine size={24} color={vars.$scale.color.carrot500} />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </NotIncludeBackButtonControlModal>
  );
};

export default SelectBoardTypeBottomSheet;
