import { Divider, Impression } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';

import { useGetAllMeetups } from '@/api/hooks/allTypeMeetup/useGetAllMeetups';
import SurveyFeedbackBanner from '@/components/common/Banner/SurveyFeedbackBanner';
import { Container } from '@/components/common/Container';
import { LoadMoreAllTypeMeetupContainer } from '@/components/common/LoadMoreContainer';
import { useUserInfoForTrackEvent } from '@/components/group/Home/hooks/useUserInfoForTrackEvent';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { MeetupFilterType } from '..';
import { MeetupHomeListCard } from '../MeetupHomeListCardItem';
import CreateMeetupButton from './CreateMeetupButton';
import NoMoreContentsBox from './NoMoreContentsBox';

const ExplorerMeetupList = ({ filter }: { filter: MeetupFilterType }) => {
  const { userConfig } = useUserConfig();
  const regionId = userConfig.regionId ?? 0;
  const { push } = useFlow();
  const { data, hasNextPage } = useGetAllMeetups({ regionId, ...filter });

  const userInfo = useUserInfoForTrackEvent();

  if (!data)
    return (
      <>
        <Spacing size={32} />
        <NoMoreContentsBox
          infoText={'우리동네는 아직 일정이 없어요.\n처음으로 일정을 만들어보세요.'}
          button={
            <CreateMeetupButton
              eventName="click_start_creating_meetup"
              eventParams={{
                referrer: 'first_to_create_in_neighborhood',
              }}
            />
          }
        />
      </>
    );

  const isShowFeedbackBanner =
    data?.pages[0]?.data?.meetups?.length && data.pages[0].data.meetups.length > 7;

  return (
    <>
      <motion.div style={{ backgroundColor: vars.$semantic.color.paperDefault }}>
        <Container paddingY={0} paddingX={0}>
          {data.pages &&
            data.pages.map(
              ({ data }, pageIndex) =>
                data.meetups &&
                data.meetups.map((meetup, index) => {
                  return (
                    <Impression
                      key={meetup.id}
                      onStart={() => {
                        if (index === 9 && pageIndex === 0) {
                          return trackEvent({
                            event: 'impression_10_unregistered_meetup_item',
                            params: {
                              ...userInfo,
                            },
                            sample: true,
                          });
                        }

                        if (pageIndex === 0) {
                          return;
                        }

                        trackEvent({
                          event: 'impresssion_unregistered_meetup_load_more_container',
                          params: {
                            lastItemIdx: pageIndex,
                            ...userInfo,
                          },
                        });
                      }}
                      actionType={index === 0 || index === 9 ? 'once' : 'none'}
                    >
                      <MeetupHomeListCard
                        key={meetup.id}
                        title={meetup.title}
                        headerLabel={[meetup.region.name]}
                        meetupAtDisplayText={meetup.meetupAtDisplayText}
                        currentParticipantsCount={meetup.currentParticipantsCount}
                        maximumParticipantsCount={meetup.maximumParticipantsCount}
                        image={meetup.image?.medium ?? ''}
                        hostInfo={{
                          image: meetup.groupInfo?.profileImage?.small ?? '',
                          displayText: meetup.groupInfo?.name ?? meetup.host.name,
                          imageShape: 'square',
                        }}
                        onClick={() => {
                          trackEvent({
                            event: 'click_group_meetup_item',
                            params: {
                              ...userInfo,
                            },
                            sample: true,
                          });
                          push('GroupMeetupDetailPage', {
                            groupId: (meetup.groupInfo?.id ?? '').toString(),
                            meetupId: meetup.id.toString(),
                          });
                        }}
                        viewCount={meetup.viewCount}
                      />
                      {isShowFeedbackBanner &&
                        30 === (pageIndex > 0 ? pageIndex * 20 + index : index) && (
                          <>
                            <SurveyFeedbackBanner
                              surveyId="homeExplorerMeetupList"
                              referrer="bottom_of_home_feed"
                              padding="0 1rem"
                              topSpacing={16}
                              bottomSpacing={16}
                            />
                            <Divider padding={16} />
                          </>
                        )}
                    </Impression>
                  );
                })
            )}
        </Container>
        {hasNextPage && <LoadMoreAllTypeMeetupContainer filter={{ regionId, ...filter }} />}

        <Spacing size={42} />

        <NoMoreContentsBox
          paddingTop="1rem"
          paddingBottom="2rem"
          infoText={'일정이 더 없어요. \n직접 만들어보실래요?'}
          button={
            <BoxButton
              onClick={() => {
                trackEvent({
                  event: 'click_start_creating_meetup',
                  params: {
                    referrer: 'home_bottom_banner',
                  },
                });
                push('BottomSheet/GroupMeetupCreateBottomSheet', {});
              }}
              variant="secondary"
              size="medium"
            >
              일정 만들기
            </BoxButton>
          }
        />
      </motion.div>
    </>
  );
};

export default ExplorerMeetupList;
