import { IconPictureLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import {
  HookFormImagePlugins,
  useHookFormImages,
} from '../../../components/HookForm/hooks/useHookFormImages';
import { useStickyInputFormContext } from '../provider/StickyInputFormProvider';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  plugins: HookFormImagePlugins;
  strokeColor?: string;
  size?: number;
}

export const StickyInputImageButton = ({
  plugins,
  strokeColor = vars.$scale.color.gray600,
  size,
  onClick,
  ...props
}: Props) => {
  const { setImages } = useHookFormImages({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleHookFormContext: useStickyInputFormContext,
    plugins,
  });

  const handleClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const originImages = await plugins.bridge.driver.compat.pickImages();

    setImages(originImages);

    onClick?.(event);
  };

  return (
    <div {...props} onClick={handleClick}>
      <IconPictureLine color={strokeColor} size={size} />
    </div>
  );
};
