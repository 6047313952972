import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Impression, Typography, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { Fragment, useMemo } from 'react';

import { useGetGroupFeedList } from '@/api/hooks/useGetGroupFeedList';
import { LoadMoreFeedContainer } from '@/components/common/LoadMoreContainer';
import { MemoizingFeedItem } from '@/components/group/Detail/components/Feed/FeedItem';
import { mapGroupFeedToPostDetail } from '@/components/group/Detail/utils/mapGroupFeedToPostDetail';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './FeedList.css';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  selectedCategoryValue: number;
  impressionItemIndex?: number;
  onImpressionItem?: () => void;
};

const FeedList = ({
  group,
  currentUser,
  selectedCategoryValue,
  impressionItemIndex,
  onImpressionItem,
}: Props) => {
  const { groupId } = usePathParams();
  // 0은 전체 게시판을 의미, 이 경우 feedType을 undefined로 설정해서 post, group_meetup 타입을 모두 조회
  // 그 외의 경우에는 post 타입만 조회, group_meetup은 별도의 API로 조회
  const feedType = useMemo(
    () => (selectedCategoryValue === 0 ? undefined : 'post'),
    [selectedCategoryValue]
  );
  const { data: feedListData, hasNextPage } = useGetGroupFeedList(groupId, feedType, [
    selectedCategoryValue,
  ]);

  if (!feedListData) return null;

  if (
    feedListData &&
    feedListData.pages[0].data.items &&
    feedListData.pages[0].data.items.length < 1
  ) {
    return (
      <Typography className={s.EmptyList} typography="bodyM1Regular" color="gray600">
        아직 게시글이 없어요. <br />
        가장 먼저 게시글을 남겨보세요.
      </Typography>
    );
  }

  return (
    <div className={s.Container}>
      {feedListData?.pages.map((post) =>
        post.data.items
          .map((item) => ({
            post: mapGroupFeedToPostDetail(group, currentUser)(item),
            meetup: item.meetupInfo,
            challenge: item.challengeInfo,
          }))
          .map(({ post, meetup, challenge }, index) => (
            <Fragment key={post.id}>
              {index === impressionItemIndex && onImpressionItem && (
                <Impression key={post.id} onStart={onImpressionItem} />
              )}
              <MemoizingFeedItem
                post={post}
                meetup={meetup}
                challenge={challenge}
                currentUser={currentUser}
                shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
              />
            </Fragment>
          ))
      )}
      {hasNextPage && (
        <LoadMoreFeedContainer selectedCategoryKey={selectedCategoryValue} feedType={feedType} />
      )}
    </div>
  );
};

export default withAsyncBoundary(FeedList, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
