import { Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconEyeLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { memo } from 'react';

import * as s from './index.css';

const PreviewPageGuideBanner = () => {
  return (
    <div className={s.Wrapper}>
      <IconEyeLine size={20} color={vars.$scale.color.gray800} />
      <VerticalSpacing size={8} />
      <Typography typography="bodyM2Regular" color="gray800">
        모임에 가입하지 않은 이웃이 보는 화면이에요.
      </Typography>
    </div>
  );
};

export default memo(PreviewPageGuideBanner);
