import { groupClient } from '@community-group/api';
import {
  MoneyTransfersTicketsRequestBody,
  MoneyTransferTicketData,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { openGroupPayTransferDirectChat } from '@/utils/link';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

type Props = UseMutationOptions<
  AxiosResponse<MoneyTransferTicketData>,
  Error,
  MoneyTransfersTicketsRequestBody
>;

export const useOpenGroupPayTransferDirectChat = ({
  onError,
  onSuccess,
  onMutate,
  onSettled,
}: Props) => {
  const fetchInstance = useFetchInstance();

  const postMoneyTransfersTickets = groupClient.api.GroupPayApi.apiV1MoneyTransfersTicketsPost({
    axios: fetchInstance,
  });

  const postMoneyTransfersTicketsFp = (body: MoneyTransfersTicketsRequestBody) => {
    return postMoneyTransfersTickets(body);
  };

  const { mutate, ...rest } = useThrottleMutation(postMoneyTransfersTicketsFp, {
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });

  return {
    openAsyncPayTransferDirectChat: mutate,
    ...rest,
  };
};
