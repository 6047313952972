import { IconAtLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useStickyInputFormContext } from '../provider/StickyInputFormProvider';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  strokeColor?: string;
  size?: number;
}

export const StickyInputMentionButton = ({
  strokeColor = vars.$scale.color.gray600,
  size,
  onClick,
  ...props
}: Props) => {
  const { setValue, getValues } = useStickyInputFormContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const prevContent = getValues('content');
    setValue('content', `${prevContent} @`);

    onClick?.(event);
  };

  return (
    <div {...props} onClick={handleClick}>
      <IconAtLine color={strokeColor} size={size} />
    </div>
  );
};
