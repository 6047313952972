import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

const getMembersInfoPath = (groupId: string) => `${getGroupPath(groupId)}/members/info`;

export const useGetMembersForMention = (groupId?: string, limit = 2000, initialCursor?: string) => {
  const fetchInstance = useFetchInstance();
  const getMemberList = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersInfoGet({
    axios: fetchInstance,
  });

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useSuspenseInfiniteQuery({
    queryKey: [getMembersInfoPath(groupId ?? '')],
    queryFn: ({ pageParam = initialCursor }) =>
      getMemberList(Number(groupId), pageParam ? pageParam : undefined, limit),
    initialPageParam: initialCursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    refetchInterval: 10000,
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
