import { useHandleUploadImages } from '../../../hooks/useHandleUploadImages';
import { GroupChallengeFormProps } from '../../../types';

export const useHandleImageClick = ({ formHandler }: GroupChallengeFormProps) => {
  const { pickImages, retryUploadImage } = useHandleUploadImages({
    originImages: formHandler.watch('originImages'),
    setImages: (pickImages) => {
      // Move the setValue call outside of render
      setTimeout(() => {
        formHandler.setValue('originImages', pickImages);
      }, 0);
    },
  });

  const handleImageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    pickImages();
  };
  return { handleImageClick, retryUploadImage };
};
