import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

type params = {
  groupId: string;
  postId: string;
  pollId: string;
};

export const useGetPollVotedMembers = ({ groupId, postId, pollId }: params) => {
  const fetchInstance = useFetchInstance();
  const getPollVotedMembers =
    groupClient.api.PollApi.apiV1GroupsGroupIdPostsPostIdPollsPollIdVotedMembersGet({
      axios: fetchInstance,
    });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [`${GROUP_URL}/${groupId}/posts/${postId}/polls/${pollId}/voted-members`],
    queryFn: () => getPollVotedMembers(Number(groupId), Number(postId), pollId),
  });

  return {
    data: data?.data,
    refetch,
  };
};
