import { isToday } from 'date-fns';

import { GroupChallengeBaseForm } from '../types';

export const validateCreateName = ({ name }: Pick<GroupChallengeBaseForm, 'name'>) => {
  if (!name || name.length < 1) return { message: '챌린지 이름을 입력해주세요.', isValid: false };
  if (name.length > 100)
    return { message: '챌린지 이름은 최대 100자까지 입력할 수 있어요.', isValid: false };

  return { message: '', isValid: true };
};

export const validateCreateDescription = ({
  description,
}: Pick<GroupChallengeBaseForm, 'description'>) => {
  if (description.length < 3)
    return { message: '본문이 너무 짧아요. 3자 이상 작성해주세요.', isValid: false };

  return { message: '', isValid: true };
};

export const validateCreateDate = ({ startedAt }: Pick<GroupChallengeBaseForm, 'startedAt'>) => {
  const isStartedAtToday = isToday(new Date(startedAt));

  if (!isStartedAtToday)
    return {
      message: '',
      isValid: true,
    };

  const koreaTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
  const isOver22Hour = koreaTime.getHours() >= 22;
  if (isOver22Hour)
    return {
      message: '22시 이후에는 오늘 날짜를 선택할 수 없어요. 다른 날짜를 선택해주세요.',
      isValid: false,
    };

  return { message: '', isValid: true };
};
