import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Request = { groupId?: string; relatedId?: string; commentId?: string };
type Props = {
  relatedContentType: 'post' | 'meetup';
} & UseMutationOptions<string | undefined, Error, Request>;

export const useDeleteComment = ({ relatedContentType, onError, onSuccess }: Props) => {
  const isPostComment = relatedContentType === 'post';
  const apiMethod = isPostComment
    ? groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsCommentIdDelete
    : groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsCommentIdDelete;

  const fetchInstance = useFetchInstance();
  const deleteComment = apiMethod({
    axios: fetchInstance,
  });

  return useThrottleMutation(
    async ({ groupId, relatedId, commentId }: Request) => {
      await deleteComment(Number(groupId), Number(relatedId), Number(commentId));
      return commentId;
    },
    {
      onError,
      onSuccess,
    }
  );
};
