import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { Dispatch, SetStateAction } from 'react';

import RemoveCircle from '@/components/common/Icons/removeCircle';
import useActiveActivities from '@/hooks/useActiveActivities';
import { useHandleUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';

import * as s from './index.css';

interface Props {
  setIsFocusedSearchBar?: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  placeholder?: string;
}

const MemberSearchBar = ({
  setIsFocusedSearchBar,
  searchValue,
  setSearchValue,
  placeholder = '검색',
}: Props) => {
  const updateStyleCurrentRouter = useHandleUpdateStyleCurrentRouter();

  const searchValueLength = searchValue.length;
  const { activeActivities } = useActiveActivities();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleResetClick = () => {
    setSearchValue('');
  };

  return (
    <div className={s.wrapper}>
      <div className={s.form}>
        <IconMagnifyingglassLine size={16} color={vars.$scale.color.gray500} />
        <input
          className={s.input}
          type="search"
          onFocus={() => {
            updateStyleCurrentRouter({ scrollable: false, backSwipable: false });
            setIsFocusedSearchBar?.(true);
          }}
          onBlur={() => {
            updateStyleCurrentRouter({
              scrollable: false,
              backSwipable: activeActivities.length === 1,
            });
            setIsFocusedSearchBar?.(false);
          }}
          value={searchValue}
          onChange={handleChange}
          placeholder={placeholder}
        />
        {searchValueLength > 0 && (
          <div className={s.adornment} onClick={handleResetClick}>
            <RemoveCircle size={15} fill={vars.$scale.color.gray600} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberSearchBar;
