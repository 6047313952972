import { AxiosInstance } from 'axios';

import { useFetchInstance } from './useFetchInstance';

type ReadClient<T> = ({ axios }: { axios?: AxiosInstance }) => T;

export const useRead = <T>(readClient: ReadClient<T>) => {
  const axios = useFetchInstance({});

  return readClient({
    axios,
  });
};
