import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';
import { IconLocationpinLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useState } from 'react';
import { Path, UseFormSetValue } from 'react-hook-form';

import {
  PoiStream,
  useSubscribedPoiStream,
} from '@/components/group/PostForm/hooks/useSubscribedPoiStream';
import { usePlaceSearchClient } from '@/hooks/usePlaceSearchClient';

import { GroupMeetupFormListItem, GroupMeetupFormListItemProps } from '../ListItem';
import * as s from './Poi.css';

interface Props<T extends object>
  extends Pick<GroupMeetupFormListItemProps, 'children' | 'onClick'> {
  name: Path<T>;
  setValue: UseFormSetValue<T>;
  defaultValue?: string;
  placeholder?: string;
}

export const GroupMeetupFormPoiField = <T extends object>({
  name,
  setValue,
  defaultValue,
  placeholder,
  ...props
}: Props<T>) => {
  const [poiName, setPoiName] = useState<PoiStream['poiName'] | undefined>(defaultValue);
  const { open: openBottomSheet, closeAsync: closeButtonSheet } = useBottomSheet();
  const { openSearchPoiPage } = usePlaceSearchClient();

  useSubscribedPoiStream({
    callback: (poi) => {
      setPoiName(poi.poiName);

      setValue(name, {
        id: poi.poiId,
        type: poi.type,
      });
    },
  });

  const handleAddPoi = () => {
    openSearchPoiPage({});
  };

  const handleRemovePoi = () => {
    setPoiName('');
    const nameKey = name.split('.');
    setValue(nameKey[0], []);
  };

  const handleEditPoi = () => {
    return openBottomSheet({
      element: (
        <ActionSheet bottomButton={{ label: '닫기' }}>
          <ActionList>
            <ActionListItem
              onClick={async () => {
                await closeButtonSheet();
                handleAddPoi();
              }}
            >
              수정
            </ActionListItem>
            <ActionListItem
              color={vars.$semantic.color.danger}
              onClick={async () => {
                await closeButtonSheet();
                handleRemovePoi();
              }}
            >
              삭제
            </ActionListItem>
          </ActionList>
        </ActionSheet>
      ),
    });
  };

  const handlePoiFieldClick = () => {
    if (!poiName) {
      return handleAddPoi();
    }

    handleEditPoi();
  };

  return (
    <GroupMeetupFormListItem
      {...props}
      fieldIcon={<IconLocationpinLine size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="장소"
      fieldName={name}
      fieldValue={
        <input
          className={s.input}
          value={poiName}
          onClick={handlePoiFieldClick}
          readOnly
          placeholder={placeholder}
        />
      }
    />
  );
};
