import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import QRCodeSVG from 'qrcode.react';

import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { DAANGN_WEB_BASE_URL } from '@/utils/link';

import * as s from './QRCodeSection.css';

type Params = {
  groupId: string;
  meetupId: string;
};

export const GroupMeetupAttendanceQRCodeSection = ({ groupId, meetupId }: Params) => {
  const { data } = useReadGroupMeetupDetail({ groupId, meetupId });

  const QRCodeLink = `${DAANGN_WEB_BASE_URL}/meetups/${data?.permalink.id}?code=${data.host?.userId}`;

  return (
    <section className={s.wrapper}>
      <div className={s.qrCodeWrapper}>
        <QRCodeSVG
          value={QRCodeLink}
          size={120}
          bgColor={vars.$static.color.staticWhite}
          fgColor={vars.$static.color.staticBlack}
          level="L"
          includeMargin={false}
          renderAs="svg"
        />
      </div>
      <Spacing size={16} />
      <Typography typography="bodyM2Regular" color="gray600">
        멤버들이 QR코드를 촬영하면 출석이 완료돼요.
      </Typography>
    </section>
  );
};
