import { BoxButton } from '@community-group/components';
import { useState } from 'react';

import { useDeleteGroup } from '@/api/hooks/useDeleteGroup';
import { getMyGroupListPath, useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { usePostLeftGroup } from '@/api/hooks/usePostLeftGroup';
import { queryClient } from '@/shared/api/instance';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

import * as s from './QuitGroup.css';

const DefaultValue = {
  quitGroups: [] as number[],
};

const QuitHostGroup = () => {
  const { bridge } = useBridge();
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(DefaultValue);

  const { data: myGroupData } = useGetMyGroupList();

  const myJoinedGroupData = myGroupData?.groups.filter((el) => el.isHost) ?? [];

  const handleChange = (groupId: number, check: boolean) => {
    if (check) {
      setFormValues((prevValues) => ({
        ...prevValues,
        ['quitGroups']: [...prevValues.quitGroups, groupId],
      }));
      return;
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      ['quitGroups']: prevValues.quitGroups.filter((id) => id !== groupId),
    }));
  };

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: deleteGroupMutate } = useDeleteGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '모임을 삭제했습니다.',
        },
      });
    },
  });

  const onSubmit = async () => {
    console.log('formValues.quitGroups', formValues.quitGroups);
    for (const groupId of formValues.quitGroups) {
      console.log('groupId', groupId);
      await deleteGroupMutate(groupId.toString());
      await delay(1000);
      console.log('deleteGroupMutate --- delay', groupId);
      queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
    }
    setFormValues({
      ['quitGroups']: [],
    });
  };

  const handleSelectAll = () => {
    const isSelectAll = myJoinedGroupData.length === formValues.quitGroups.length;
    const allGroups = myJoinedGroupData.map((group) => group.id) ?? [];
    setFormValues((prevValues) => ({
      ...prevValues,
      ['quitGroups']: isSelectAll ? [] : allGroups,
    }));
  };

  return (
    <div>
      <div
        className={s.TitleWrapper}
        onClick={() => {
          setOpen((prev) => !prev);
          queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
        }}
      >
        <h3>운영중인 모임 삭제하기</h3>
        {open ? '▲' : '▼'}
      </div>
      {open && (
        <form className={s.FormWrapper}>
          <div className={s.GroupListWrapper}>
            {myJoinedGroupData.map((group) => {
              return (
                <div key={group.id} className={s.ItemWrapper}>
                  {group.name}
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={formValues.quitGroups.includes(group.id)}
                    onChange={(e) => {
                      handleChange(group.id, e.target.checked);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div>선택 리스트 : {formValues.quitGroups.join(', ')}</div>
          <div className={s.RowButtonGroup}>
            <BoxButton size="small" onClick={handleSelectAll} variant="secondary">
              전체 선택
            </BoxButton>
            <BoxButton size="small" onClick={onSubmit}>
              삭제하기
            </BoxButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default QuitHostGroup;

// 딜레이 함수: milliseconds만큼 기다린 후 Promise를 해결합니다.
function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
