import { UploadStatus, useSnackbarAdapter } from '@community-group/components';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { ImageUploadState } from '../utils/fileUpload';
import { usePickImages } from './usePickImages';
import { useUploadImage } from './useUploadImage';

type ImageUploadStateExtendBlob = ImageUploadState;

type ImageUploadedState = ImageUploadState & {
  uuid: string;
};

type Props = {
  originImages: ImageUploadState[];
  setImages: (pickImages: ImageUploadState[]) => void;
};

export const useHandleUploadImages = ({ originImages = [], setImages: setParentImages }: Props) => {
  const [localImages, setLocalImages] = useState<ImageUploadState[]>(originImages);

  const { create: openSnackbar } = useSnackbarAdapter();

  const { mutateAsync: uploadImage } = useUploadImage({});
  const pickImages = usePickImages();

  useEffect(() => {
    setLocalImages(originImages);
  }, [originImages]);

  const validateImages = (blobs: Blob[]): { isValid: boolean; message?: string } => {
    if (!blobs) return { isValid: false };
    if (blobs.length < 1) return { isValid: false };
    if (blobs.length + originImages.length > 10)
      return { isValid: false, message: '사진은 최대 10장까지만 선택할 수 있어요.' };

    return { isValid: true };
  };

  const handleAppendImages = (images: ImageUploadState[]) => {
    setLocalImages((prevImages) => {
      const newImages = [...prevImages, ...images];
      setParentImages(newImages);

      return newImages;
    });
  };

  const handleReplaceImages = (images: ImageUploadedState[]) => {
    setLocalImages((prevImages) => {
      const newImages = prevImages.map((image): ImageUploadState => {
        const uploadedImage = images.find(({ uuid }) => uuid === image.id);

        if (uploadedImage) {
          return {
            id: uploadedImage.id,
            url: uploadedImage.url,
            status: uploadedImage.status,
            blob: uploadedImage.blob,
          };
        }

        return image;
      });

      setParentImages(newImages);

      return newImages;
    });
  };

  const handlePickImages = async () => {
    const blobs = await pickImages();

    const { isValid, message } = validateImages(blobs);
    if (!isValid) {
      if (message) {
        openSnackbar({
          message,
          timeout: 3000,
          type: 'default',
        });
      }

      return;
    }

    try {
      const imageUploadStateExtendBlobs: ImageUploadStateExtendBlob[] = blobs.map((blob) => ({
        id: `temp-${v4()}`,
        blob,
        url: URL.createObjectURL(blob),
        status: 'uploading',
      }));
      handleAppendImages(imageUploadStateExtendBlobs);

      const uploadedImages = await Promise.all(
        imageUploadStateExtendBlobs.map(async (stateBlobs): Promise<ImageUploadedState> => {
          try {
            const response = await uploadImage({ imageFile: stateBlobs.blob });

            return {
              uuid: stateBlobs.id,
              id: response.id,
              url: stateBlobs.url,
              status: 'completed',
              blob: stateBlobs.blob,
            };
          } catch (error) {
            return {
              uuid: stateBlobs.id,
              id: 'failed',
              url: stateBlobs.url,
              status: 'failed',
              blob: stateBlobs.blob,
            };
          }
        })
      );

      handleReplaceImages(uploadedImages);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageRetry = async (id: string) => {
    const image = localImages.find((image) => image.id === id);
    if (!image) return;

    const response = await uploadImage({ imageFile: image.blob });

    const updatedImage = {
      uuid: image.id,
      id: response.id.toString(),
      url: image.url,
      status: 'completed',
      blob: image.blob,
    } as ImageUploadedState;

    setLocalImages((prevImages) =>
      prevImages.map((image) => (image.id === id ? updatedImage : image))
    );
  };

  return {
    images: localImages,
    pickImages: handlePickImages,
    retryUploadImage: handleImageRetry,
  };
};
