import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetGroupSuperHostDetail = (groupId?: string) => {
  const fetchInstance = useFetchInstance();

  const getGroupSuperHostDetail = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersSuperHostGet({
    axios: fetchInstance,
  });

  const path = groupClient.api.GroupMemberApi.getapiV1GroupsIdMembersSuperHostGetPath(
    Number(groupId)
  );

  const { data, refetch, isInitialLoading } = useSuspenseQuery({
    queryKey: [path, groupId],
    queryFn: async () => {
      if (!groupId) return null;
      return await getGroupSuperHostDetail(Number(groupId));
    },
  });

  const response = data?.data;

  return { data: response, refetch, isLoading: isInitialLoading };
};
