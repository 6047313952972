import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupBotFeaturesPath = (groupId: string) => {
  const id = Number(groupId);
  return groupClient.api.GroupBotApi.getapiV1GroupsIdGroupBotFeaturesGetPath(id);
};

export const useGetGroupBotFeatures = (groupId: string) => {
  const fetchInstance = useFetchInstance();
  const getGroupBotFeatures = groupClient.api.GroupBotApi.apiV1GroupsIdGroupBotFeaturesGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getGroupBotFeaturesPath(groupId)],
    queryFn: () => {
      const id = Number(groupId);
      return getGroupBotFeatures(id);
    },
  });

  return { data, refetch };
};
