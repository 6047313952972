import 'dayjs/locale/ko';

import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { $Values } from 'utility-types';

import { GROUP_URL, ORDER_MEETUP } from '../../base/group';
import { useFetchInstance } from '../instance/useFetchInstance';

export interface AllTypeMeetupProps {
  regionId: number;
  categoryId?: number;
  meetupStatus?: ('opened' | 'fully_recruited' | 'closed')[];
  order: $Values<typeof ORDER_MEETUP>;
  day: string;
  limit?: number;
  showRenderLimit?: boolean;
  initialCursor?: string | undefined;
}

export const getAllMeetupsPath = `${GROUP_URL}/all-type-meetups`;

export const useGetMeetupsWithDayFilter = ({
  regionId,
  meetupStatus = ['opened'],
  order,
  day,
  limit = 20,
  showRenderLimit,
  initialCursor,
}: AllTypeMeetupProps) => {
  const fetchInstance = useFetchInstance();
  const getAllMeetups = groupClient.api.AllMeetupsApi.apiV1AllTypeMeetupsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getAllMeetupsPath, regionId, meetupStatus, order, day, limit, showRenderLimit],
      queryFn: ({ pageParam = initialCursor }) =>
        getAllMeetups(
          pageParam ? pageParam : undefined,
          limit,
          regionId,
          meetupStatus,
          undefined,
          order,
          day
        ),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  const meetups = data?.pages.map(({ data }) => data.meetups);
  const flatten = meetups?.flat(1) ?? ([] as groupClient.model.AllTypeMeetupPresentation[]);

  const filtered = flatten.reduce((acc, cur) => {
    if (showRenderLimit && acc.length > 2) return acc;
    if (cur !== undefined) {
      acc.push(cur);
    }
    return acc;
  }, [] as groupClient.model.AllTypeMeetupPresentation[]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data: filtered,
    refetch,
  };
};
