import { AvoidSnackbarOverlap } from '@community-group/components';
import { ReactNode } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { HeartButton } from '@/components/common/HeartButton';
import { useBridge } from '@/contexts/Bridge';
import { useToggleGroupMeetupLike } from '@/hooks/useToggleGroupMeetupLike';
import { trackEvent } from '@/utils/analytics';

import * as s from './GroupMeetupDetailAccessoryBarWrapper.css';

type Props = {
  groupId: string;
  meetupId: string;
  children: ReactNode;
};

export const GroupMeetupDetailAccessoryBarWrapper = ({ groupId, meetupId, children }: Props) => {
  const { data } = useReadGroupMeetupDetail({ groupId, meetupId });
  const { group } = useReadGroupDetail(groupId);
  const { bridge } = useBridge();
  const like = data?.currentUserInfo?.like ?? false;
  const toggleLike = useToggleGroupMeetupLike({ groupId, meetupId });

  const handleToggleLike = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    bridge.setHapticLightFeedback({});
    toggleLike(like);

    trackEvent({
      event: 'click_group_meetup_like_button',
      params: {
        meetupId,
        groupId: data?.groupInfo.id,
        meetupName: data?.title,
        groupName: data?.groupInfo.name,
        groupCategory: group?.category.name,
        isRequestLike: !like,
        isGroupMember: data?.currentUserInfo.groupJoined,
        isGroupMeetupMember: data?.currentUserInfo.meetupJoined,
      },
      sample: true,
    });
  };

  return (
    <AvoidSnackbarOverlap>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.likeWrapper}>
            <HeartButton filled={like} onClick={handleToggleLike} />
          </div>
          <div className={s.buttonWrapper}>{children}</div>
        </div>
      </div>
    </AvoidSnackbarOverlap>
  );
};
