// MentionMultilineTextField.jsx
import { MentionInputField, MentionInputFieldProps, Typography } from '@community-group/components';
import { IconExclamationmarkCircleFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React, { forwardRef } from 'react';
import { useMemo, useState } from 'react';

import * as s from './style.css';

type Props = MentionInputFieldProps & {
  isInvalid?: boolean;
  errorMessage?: string;
};

// forwardRef를 사용하여 ref를 전달
export const MentionMultilineTextField = forwardRef<HTMLDivElement, Props>(
  ({ isInvalid, errorMessage, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const shownErrorMessage = useMemo(() => {
      if (isInvalid && errorMessage) return true;
      return false;
    }, [isInvalid, errorMessage]);

    return (
      <>
        <div className={s.Wrapper({ isInvalid, isFocused })}>
          <div className={s.Inner}>
            <MentionInputField
              {...props}
              ref={ref}
              onFocus={() => {
                console.log('focus');
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
              }}
              style={InputStyle}
            />
          </div>
        </div>
        {shownErrorMessage && (
          <div className={s.DescriptionWrapper}>
            <IconExclamationmarkCircleFill size={16} color={vars.$semantic.color.danger} />
            <Typography typography="label3Regular" color="danger" style={{ paddingLeft: '4px' }}>
              {errorMessage}
            </Typography>
          </div>
        )}
      </>
    );
  }
);

const InputStyle = {
  width: '100%',
  backgroundColor: 'transparent',
  minHeight: '5.75rem',
  highlighter: {
    lineHeight: '1.35rem',
    letterSpacing: '-0.02rem',
  },
  '&multiLine': {
    input: {
      fontSize: '1rem',
      fontWeight: 400,
      color: `${vars.$scale.color.gray900}`,
      lineHeight: '1.35rem',
      userSelect: 'text',
      letterSpacing: '-0.02rem',
    },
    suggestions: {
      position: 'absolute',
      background: 'transparent',
      left: '0',
      zIndex: 9999,
      list: {
        marginTop: '16px',
        marginLeft: '-18px',
        maxHeight: '11.75rem',
        overflow: 'auto',
        width: 'calc(100vw - 30px)',
        backgroundColor: `${vars.$semantic.color.paperDefault}`,
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '0.375rem',
        zIndex: 999,
      },
      item: {
        width: '100%',
        padding: '8px 16px',
        backgroundColor: vars.$semantic.color.paperDefault,
        '&focused': {
          backgroundColor: vars.$semantic.color.grayPressed,
        },
      },
    },
  },
};
