import { hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactElement, ReactNode } from 'react';

import { queryClient } from '@/shared/api/instance';

type Props = {
  queryState: object;
  children: ReactNode | ((queryClient: QueryClient) => ReactElement);
};

const ClientSideQueryProvider = ({ children, queryState }: Props) => {
  hydrate(queryClient, queryState);

  return (
    <QueryClientProvider client={queryClient}>
      {typeof children === 'function' ? children(queryClient) : children}
    </QueryClientProvider>
  );
};

export default ClientSideQueryProvider;
