import { KarrotHeader } from '@community-group/api/lib/client/axios';

// TODO: AppFoundation 에서 가져오도록 변경
// import { AppFoundation } from '@/libs/app-foundation/types';

type AppFoundation = {
  user: {
    id: number;
    authToken: string;
  };
  app: {
    // userAgent: string;
    xUserAgent: string;
  };
  region: {
    id: number;
  };
};

export const getKarrotHeader = ({ app, user, region }: AppFoundation): KarrotHeader => {
  return {
    'X-Auth-Token': user.authToken ?? '',
    'X-User-Id': (user.id ?? '').toString(),
    'X-Region-Id': (region.id ?? '').toString(),
    'X-User-Agent': app.xUserAgent ?? '',
  };
};
