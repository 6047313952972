import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { $Values } from 'utility-types';

import { GROUP_URL, ORDER_HOME } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export type ViewRange = 'my' | 'adjacent' | 'range2' | 'range3';

export const getHomeListPath = (
  category?: number | number[],
  order?: $Values<typeof ORDER_HOME>,
  viewRange?: ViewRange
) => `${GROUP_URL}/summary?category=${category}&order=${order}&viewRange=${viewRange}`;

export const useGetHomeList = ({
  category,
  order,
  viewRange,
  initialCursor = undefined,
}: {
  category?: number | number[];
  order?: $Values<typeof ORDER_HOME>;
  viewRange?: ViewRange;
  initialCursor?: string | null;
}) => {
  const fetchInstance = useFetchInstance();
  const getHomeList = groupClient.api.GroupApi.apiV1GroupsSummaryGet({ axios: fetchInstance });

  const getCategoryParams = () => {
    if (typeof category === 'number') return [Number(category)];

    return category;
  };

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getHomeListPath(category, order, viewRange)],
      queryFn: ({ pageParam = initialCursor }) =>
        getHomeList(pageParam ? pageParam : undefined, 20, order, getCategoryParams(), viewRange),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
