import { BoxButton, Switch } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconMinusCircleLine, IconPlusLine } from '@daangn/react-monochrome-icon';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { trackEvent } from '@/utils/analytics';

import * as s from './pollForm.css';

export type PollFormValues = {
  pollOptionTitles: string[];
  isMultiple: boolean;
  title: string;
};

type Props = {
  onSubmit: (formValues: PollFormValues) => void;
  onInputClick?: () => void;
  onItemAddClick?: (itemCount: number) => void;
  onItemRemoveClick?: () => void;
  onMultipleClick?: (isMultiple: boolean) => void;
};
const REMOVEABLE_INDEX = 2;

const MAX_POLL_OPTION_LEGNTH = 10;
const MAX_TITLE_LENGTH = 30;

const PollForm = ({
  onSubmit,
  onInputClick,
  onItemAddClick,
  onItemRemoveClick,
  onMultipleClick,
}: Props) => {
  const { watch, register, setValue, setFocus, handleSubmit } = useFormContext<PollFormValues>();

  const formPollOptions = watch('pollOptionTitles');

  const onFormSubmit = (values: PollFormValues) => {
    if (!values) return;
    onSubmit(values);
    trackEvent({
      event: 'click_attach_poll',
      params: {
        pollName: values.title,
        pollOptionCount: values.pollOptionTitles.length,
        isMultiple: values.isMultiple,
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setFocus('title');
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMultiple = watch('isMultiple');
  const titleValue = watch('title');

  return (
    <form className={s.pollForm} id="poll-form" onSubmit={handleSubmit(onFormSubmit)}>
      <input
        className={s.titleInput}
        value={titleValue}
        {...register(`title`)}
        placeholder="투표 제목 입력"
        type="text"
        maxLength={MAX_TITLE_LENGTH}
      />
      <ul>
        {formPollOptions.map((option, index) => (
          <div className={s.optionInputWrapper} key={index}>
            <input
              type="text"
              className={s.optionInput}
              maxLength={MAX_TITLE_LENGTH}
              placeholder="항목 입력"
              {...register(`pollOptionTitles.${index}`)}
              onClick={onInputClick}
            />
            {index >= REMOVEABLE_INDEX && (
              <>
                <VerticalSpacing size={10} />
                <button
                  className={s.removeButton}
                  type="button"
                  onClick={() => {
                    const nextPollOptions = [...formPollOptions];
                    nextPollOptions.splice(index, 1);
                    setValue('pollOptionTitles', nextPollOptions, {
                      shouldValidate: true,
                    });
                    onItemRemoveClick?.();
                  }}
                >
                  <IconMinusCircleLine size={24} />
                </button>
              </>
            )}
          </div>
        ))}
      </ul>
      <div className={s.submitButtonWrapper}>
        <BoxButton
          width="100%"
          prefix={<IconPlusLine size={16} />}
          variant="secondary"
          size="large"
          isDisabled={formPollOptions.length >= MAX_POLL_OPTION_LEGNTH}
          onClick={() => {
            const nextPollOptions = [...formPollOptions, ''];
            setValue('pollOptionTitles', nextPollOptions, {
              shouldValidate: true,
            });
            setTimeout(() => {
              setFocus(`pollOptionTitles.${nextPollOptions.length - 1}`);
            });
            onItemAddClick?.(nextPollOptions.length);
          }}
        >
          항목 추가
        </BoxButton>
      </div>
      <div className={s.switchForm}>
        <div>복수 선택 가능</div>
        <Switch
          {...register('isMultiple')}
          isSelected={isMultiple}
          onChange={(isSelected) => {
            if (isSelected !== isMultiple) {
              setValue('isMultiple', isSelected);
              onMultipleClick?.(isSelected);
            }
          }}
        />
      </div>
    </form>
  );
};

export default PollForm;
