import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupMeetupAttendancePath = (groupId: string, meetupId: string) =>
  `/api/v1/groups/${groupId}/meetups/${meetupId}/attendance`;

export type GetGroupMeetupAttendanceProps = {
  groupId: string;
  meetupId: string;
  limit?: number;
} & PaginationQueryParams;

export const useGetGroupMeetupAttendanceMembers = ({
  groupId,
  meetupId,
  limit = 30,
  initialCursor = undefined,
}: GetGroupMeetupAttendanceProps) => {
  const fetchInstance = useFetchInstance();
  const getGroupMeetupAttendance =
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdAttendanceGet({
      axios: fetchInstance,
    });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch, isLoading, isFetching } =
    useSuspenseInfiniteQuery({
      queryKey: [getGroupMeetupAttendancePath(groupId, meetupId)],
      queryFn: ({ pageParam = undefined }) =>
        getGroupMeetupAttendance(parseInt(groupId), parseInt(meetupId), pageParam, limit),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
