import { initChallengeTimeAt } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useForm } from 'react-hook-form';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import GroupChallengeCreateAccessoryBar from '../components/GroupChallengeCreateAccessoryBar';
import GroupChallengeForm from '../components/GroupChallengeForm';
import { useTempCreateGroupChallengeForm } from '../hooks/useTempCreateGroupChallengeForm';
import { GroupChallengeBaseForm } from '../types';

export const defaultGroupChallengeData: GroupChallengeBaseForm = {
  name: '',
  description: '',
  startedAt: initChallengeTimeAt(),
  totalDays: 28,
  certifyIntervalDays: 7,
  certifyIntervalTargetCount: 7,
  images: [],
  originImages: [],
};

const GroupChallengeNewPage: ActivityComponentType = () => {
  const { groupId } = usePathParams();

  const { defaultValue, from } = useQueryParams();

  const defaultData = defaultValue ? JSON.parse(defaultValue) : undefined;
  const formHandler = useForm<GroupChallengeBaseForm>({
    defaultValues: {
      ...defaultGroupChallengeData,
      ...defaultData,
    },
  });

  const { group } = useReadGroupDetail(groupId);
  useEnterTrackEvent({
    event: 'enter_challenge_create_page',
    params: {
      groupId,
      groupName: group?.name,
      categoryId: group?.category.id,
      from,
    },
  });

  useTempCreateGroupChallengeForm({
    groupId,
    formHandler,
  });

  return (
    <AppScreen
      accessoryBar={
        <GroupChallengeCreateAccessoryBar groupId={groupId} formHandler={formHandler} />
      }
    >
      <GroupChallengeForm formHandler={formHandler} />
    </AppScreen>
  );
};

export default GroupChallengeNewPage;
