import { GroupMeetupMemberSummaryPresentationWithAttendanceStatus } from '@community-group/api/lib/group/models';

import { useGetGroupMeetupAttendanceMembers } from '@/api/hooks/useGetGroupMeetupAttendanceMembers';
import { usePutGroupMeetupAttendanceStatus } from '@/api/hooks/usePutGroupMeetupAttendanceStatus';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupMeetupAttendance } from '@/utils/refetch/groupMeetupAttendance';

import { AttendanceMemberListItem } from './AttendanceMemberListItem';
import { LoadMoreAttendanceMemberList } from './LoadMoreAttendanceMemberList';

type Params = {
  groupId: string;
  meetupId: string;
};
export const GroupMeetupAttendanceMemberList = ({ groupId, meetupId }: Params) => {
  const { push } = useFlow();

  const { data, hasNextPage } = useGetGroupMeetupAttendanceMembers({ groupId, meetupId });

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = memberList?.flat(1) ?? [];

  const handleProfileClick = (userId: string) => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId,
      from: 'meetup_member',
    });
  };

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isPending } = usePutGroupMeetupAttendanceStatus({
    onError: handleErrorWithToast,
    onSettled: () => {
      refetchGroupMeetupAttendance({ groupId, meetupId });
    },
    onSuccess: () => {
      trackEvent({
        event: 'update_group_meetup_attendance',
        params: {
          attendanceType: 'manual',
          groupId,
          meetupId,
        },
        sample: true,
        loggerType: ['AMPLITUDE', 'FIREBASE'],
      });
      trackEvent({
        event: 'click_update_group_meetup_attendance',
        params: {
          attendanceType: 'manual',
          groupId,
          meetupId,
        },
        sample: true,
        loggerType: ['KARROT'],
      });
    },
  });

  const handleAttendanceClick = (
    userId: string,
    prevAttendanceStatus: GroupMeetupMemberSummaryPresentationWithAttendanceStatus['attendanceStatus']
  ) => {
    const currentAttendanceStatus =
      prevAttendanceStatus === 'attendance' ? 'noAttendance' : 'attendance';

    mutate({
      groupId,
      meetupId,
      userId,
      meetupAttendanceStatus: currentAttendanceStatus,
    });
  };

  return (
    <section style={{ width: '100%' }}>
      {flattenMemberList.map((member) => (
        <AttendanceMemberListItem
          key={member.userId}
          member={member}
          onProfileClick={() => {
            handleProfileClick(member.userId.toString());
          }}
          isLoadingAttendanceButton={isPending}
          onAttendanceClick={(prevAttendanceStatus) => {
            handleAttendanceClick(member.userId.toString(), prevAttendanceStatus);
          }}
        />
      ))}
      {hasNextPage && <LoadMoreAttendanceMemberList groupId={groupId} meetupId={meetupId} />}
    </section>
  );
};
