import { ChallengeSummaryPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { PaginationList } from '@community-group/components/shared';

import ChallengeListItem from '../ChallengeListItem';
import * as s from './style.css';

type Props = {
  groupId: number;
  data: ChallengeSummaryPresentation[];
  title: string;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
};

const ChallengeList = ({
  groupId,
  title,
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: Props) => {
  const hasData = data.length > 0;

  if (!hasData) return null;

  return (
    <div className={s.Container}>
      <div className={s.TitleContainer}>
        <Typography typography="subtitle1Bold">{title}</Typography>
      </div>
      <PaginationList
        items={data}
        render={(challenge) =>
          challenge && (
            <ChallengeListItem key={challenge.id} groupId={groupId} challenge={challenge} />
          )
        }
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default ChallengeList;
