import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getRunningGrowthMissionPath = (groupId: number) =>
  groupClient.api.GroupRunningGrowthApi.getapiV1GroupsGroupIdRunningGrowthGetPath(groupId);

export const useGetRunningGrowthMission = (groupId: string) => {
  const fetchInstance = useFetchInstance();
  const getRunningGrowthMission =
    groupClient.api.GroupRunningGrowthApi.apiV1GroupsGroupIdRunningGrowthGet({
      axios: fetchInstance,
    });

  const { data, refetch, isInitialLoading } = useSuspenseQuery({
    queryKey: [getRunningGrowthMissionPath(Number(groupId))],
    queryFn: () => getRunningGrowthMission(Number(groupId)),

    refetchOnMount: true,
  });

  const response = data?.data.growthList;

  return {
    runningGrowthData: response,
    refetch,
    isLoading: isInitialLoading,
  };
};
