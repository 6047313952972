import { ChipToggleButton } from '@community-group/components';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';

import { useGetCategories } from '@/api/hooks/useGetCategories';
import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';
import { trackEvent } from '@/utils/analytics';

import * as s from './LeaderBoardCategoryFilter.css';

type LeaderBoardCategoryFilterProps = {
  categoryId: number;
  setCategoryId: Dispatch<SetStateAction<number>>;
  isCurrentWeek: boolean;
};

const LeaderBoardCategoryFilter = ({
  categoryId,
  setCategoryId,
  isCurrentWeek,
}: LeaderBoardCategoryFilterProps) => {
  const { data: leaderBoardData } = useGetGroupLeaderBoard({ weekAgo: isCurrentWeek ? 0 : 1 });

  const leaderBoardCategories = useMemo(() => {
    const categories = leaderBoardData?.categoryRankList.ableCategories;
    return categories?.sort((a, b) => {
      if (a.name === '동네친구') return 1;
      if (b.name === '동네친구') return -1;
      return 0;
    });
  }, [leaderBoardData]);

  const handleClickCategory = (categoryId: number) => {
    setCategoryId(categoryId);

    const category = leaderBoardCategories?.find((category) => category.id === categoryId);
    trackEvent({
      event: 'click_leaderboard_category_leaderboard_page',
      params: {
        category: category?.name?.toString() ?? '',
      },
    });
  };

  if (leaderBoardCategories && leaderBoardCategories.length === 0) {
    return <></>;
  }

  return (
    <div className={s.LeaderBoardCategoriesWrapper} role="radiogroup">
      <CategoryItem
        option={{ value: '0', label: '전체' }}
        selectCategoryId={categoryId}
        onChangeSelectCategory={(value) => handleClickCategory(value)}
      />
      {leaderBoardCategories?.map((item) => (
        <CategoryItem
          key={item.id}
          option={{ value: String(item.id), label: item.name }}
          selectCategoryId={categoryId}
          onChangeSelectCategory={(value) => handleClickCategory(value)}
        />
      ))}
    </div>
  );
};

const CategoryItem = ({
  option,
  selectCategoryId,
  onChangeSelectCategory,
}: {
  option: { value: string; label: string };
  selectCategoryId: number;
  onChangeSelectCategory: (categoryId: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isSelected = useMemo(
    () => selectCategoryId === Number(option.value),
    [selectCategoryId, option.value]
  );

  useEffect(() => {
    if (!ref.current || !isSelected) return;

    setTimeout(() => {
      if (!ref.current || !isSelected) return;
      ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    }, 250);
  }, [isSelected]);

  return (
    <ChipToggleButton
      size="small"
      ref={ref}
      flexShrink={0}
      isSelected={isSelected}
      onClick={() => {
        trackEvent({
          event: 'click_recommend_category',
          params: {
            clickCategoryId: option.value,
          },
        });
        onChangeSelectCategory(Number(option.value));
      }}
    >
      {option.label}
    </ChipToggleButton>
  );
};

export default LeaderBoardCategoryFilter;
