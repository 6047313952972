import { withAsyncBoundary } from '@community-group/components';
import { IconChevronUpLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import MemberMissionBanner from '@/components/common/MemberMissionBanner';
import MemberMissionList from '@/components/common/MemberMissionBanner/MemberMissionList';

import useMainBannerFolded from '../../hooks/useMainBannerFolded';
import useMemberMissionList from '../../hooks/useMemberMissionList';

type Props = {
  groupId: string;
};

const GroupDetailOnboardingBannerForMember = ({ groupId }: Props) => {
  const missionList = useMemberMissionList(groupId);

  const { foldedMainBanner, handleToggle } = useMainBannerFolded();
  const title = useMemo(() => {
    const defaultTitle = '모임 가입 미션';
    if (!foldedMainBanner) return defaultTitle;

    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    return mission?.title ?? defaultTitle;
  }, [foldedMainBanner, missionList]);

  const progress = useMemo(() => {
    const completedMissionCount = missionList.filter(({ isCompleted }) => isCompleted).length;
    const totalMissionCount = missionList.length;
    const value = (completedMissionCount / totalMissionCount) * 100;

    return Math.round(value / 10) * 10;
  }, [missionList]);

  const handleBannerClick = () => {
    if (!foldedMainBanner) return;
    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    mission?.onClick();
  };

  const handleToggleButtonClick = (e) => {
    e.stopPropagation();
    handleToggle();
  };

  if (missionList.length === 0) return null;

  return (
    <MemberMissionBanner
      title={title}
      progress={progress}
      size="large"
      onClick={handleBannerClick}
      actionButton={
        <button
          style={{
            transform: foldedMainBanner ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 200ms ease-in-out',
          }}
          onClick={handleToggleButtonClick}
        >
          <IconChevronUpLine size={20} color={vars.$scale.color.gray600} />
        </button>
      }
    >
      <MemberMissionList missionList={missionList} opened={!foldedMainBanner} />
    </MemberMissionBanner>
  );
};

export default withAsyncBoundary(GroupDetailOnboardingBannerForMember, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
