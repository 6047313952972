import { groupClient } from '@community-group/api';
import { CategoryItemType } from '@community-group/api/lib/group/models';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  curationKeywords,
  curationTabKeywords,
} from '@/components/group/Curation/Page/GroupCurationWithKeyword/curationKeywords';

import { useFetchInstance } from './instance/useFetchInstance';

const getCurationCategoriesPath = () =>
  groupClient.api.GroupCurationApi.getapiV1GroupsCurationCategoriesGetPath();

export const useGetCurationCategories = () => {
  const fetchInstance = useFetchInstance();

  const getCurationCategories = groupClient.api.GroupCurationApi.apiV1GroupsCurationCategoriesGet({
    axios: fetchInstance,
  });

  const { data } = useSuspenseQuery({
    queryKey: [getCurationCategoriesPath()],
    queryFn: () => getCurationCategories(),
    select: useCallback((data) => {
      // FE에서 관리하는 큐레이션 키워드
      const hardCodedKeywords = curationTabKeywords.map((keyword) => ({
        id: keyword.id,
        name: keyword.name,
      }));
      // 유저에게 노출 가능한 큐레이션 키워드 필터링
      const filteredCategory =
        data?.data.categories
          .filter((el) => el.topic?.length !== 0)
          .map((keyword) => {
            const data = curationKeywords.find((el) => el.name === keyword.topic);
            if (!data) return null;
            return { id: data?.id, name: data?.name };
          }) || [];

      return [...filteredCategory, ...hardCodedKeywords] as CategoryItemType[];
    }, []),
  });

  return { data: data ?? [] };
};
