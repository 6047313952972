import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = {
  groupId: number;
  meetupId: number;
  limit?: number;
  joinedUser?: boolean;
  initialCursor?: string | undefined;
};

export const getGroupMeetupEmotionListPath = (groupId: number, meetupId: number) =>
  groupClient.api.MeetupApi.getapiV1GroupsIdMeetupsMeetupIdEmotionsGetPath(groupId, meetupId);

export const useGetGroupMeetupEmotionList = ({
  groupId,
  meetupId,
  limit = 30,
  joinedUser,
  initialCursor = undefined,
}: Props) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.MeetupApi.apiV1GroupsIdMeetupsMeetupIdEmotionsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getGroupMeetupEmotionListPath(groupId, meetupId), joinedUser],
      queryFn: ({ pageParam = undefined }) => {
        const cursor = pageParam === null ? undefined : pageParam;
        return get(groupId, meetupId, cursor, limit, joinedUser);
      },
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
