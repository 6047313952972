import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const useGetCheckOpenRegion = (regionId: number) => {
  const fetchInstance = useFetchInstance();
  const getCheckOpenRegion = groupClient.api.GroupApi.apiV1GroupsCheckOpenRegionIdGet({
    axios: fetchInstance,
  });

  const { data, refetch, isLoading, isFetching } = useSuspenseQuery({
    queryKey: [`${GROUP_URL}/check-open-region`, regionId],
    queryFn: () => getCheckOpenRegion(regionId),
  });

  return { data, refetch, isLoading, isFetching };
};
