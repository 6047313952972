import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType, useActivity } from '@stackflow/react';
import { useMemo } from 'react';

import { useGetGroupPostEmotionList } from '@/api/hooks/useGetGroupPostEmotionList';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import EmotionUserList from '../components/EmotionUserList';

export type PostDetailListListPageParams = Pick<PageParams, 'groupId' | 'postId'>;

const PostDetailLikeListPage: ActivityComponentType<PostDetailListListPageParams> = () => {
  const { replace, pop } = useFlow();
  const { isRoot } = useActivity();
  const { groupId = '' } = usePathParams();

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (isRoot) {
      return replace('GroupDetailPage', {
        groupId,
      });
    } else {
      pop();
    }
  };

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        title: '공감',
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <PostLikeList />
      </AsyncBoundary>
    </AppScreen>
  );
};

const PostLikeList = () => {
  const { groupId, postId } = usePathParams();
  const groupNumberId = Number(groupId);
  const postNumberId = Number(postId);

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetGroupPostEmotionList({
    id: groupNumberId,
    postId: postNumberId,
    joinedUser: true,
  });
  const joinedUserList = useMemo(
    () => data?.pages.flatMap((page) => page.data.users) ?? [],
    [data]
  );

  const { push } = useFlow();
  const getMoveGroupUserProfileDetailPage = (userId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: userId.toString(),
      from: 'like_list',
    });
  };

  return (
    <LayoutWrapper>
      <EmotionUserList
        users={joinedUserList}
        onClickUserProfile={getMoveGroupUserProfileDetailPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
      />
      {!hasNextPage && (
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
          <PostLikeListNotJoinedUser
            groupId={groupNumberId}
            postId={postNumberId}
            onClickUserProfile={getMoveGroupUserProfileDetailPage}
          />
        </AsyncBoundary>
      )}
    </LayoutWrapper>
  );
};

type Props = {
  groupId: number;
  postId: number;
  onClickUserProfile: (userId: number) => () => void;
};

const PostLikeListNotJoinedUser = ({ groupId, postId, onClickUserProfile }: Props) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetGroupPostEmotionList({
    id: groupId,
    postId,
    joinedUser: false,
  });
  const notJoinedUserList = useMemo(
    () => data?.pages.flatMap((page) => page.data.users) ?? [],
    [data]
  );

  return (
    <EmotionUserList
      users={notJoinedUserList}
      onClickUserProfile={onClickUserProfile}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default PostDetailLikeListPage;
