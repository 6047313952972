import { groupClient } from '@community-group/api';
import {
  GroupChallengeCreateForm,
  GroupChallengeCreateResponse,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type Params = {
  groupId: number;
  groupChallengeCreateForm: GroupChallengeCreateForm;
};
type Props = UseMutationOptions<AxiosResponse<GroupChallengeCreateResponse>, Error, Params>;

export const usePostGroupChallenge = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postGroupChallenge = groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesPost({
    axios: fetchInstance,
  });

  const fetchPostGroupChallenge = ({ groupId, groupChallengeCreateForm }: Params) => {
    return postGroupChallenge(groupId, groupChallengeCreateForm);
  };

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  return useThrottleMutation(fetchPostGroupChallenge, {
    onError,
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);

      mutateEventLogLocation({
        eventName: 'create_group_meetup',
        groupId: variables.groupId,
      });
    },
  });
};
