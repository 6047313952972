import { GroupLeaderBoardPresentation } from '@community-group/api/lib/group/models';

export const leaderBoardResponse: GroupLeaderBoardPresentation = {
  categoryRankList: {
    ableCategories: [
      {
        id: 1,
        name: '운동',
      },
      {
        id: 2,
        name: '자기계발',
      },
      {
        id: 3,
        name: '아웃도어/캠핑',
      },
      {
        id: 4,
        name: '음식/음료',
      },
      {
        id: 5,
        name: '취미/오락',
      },
    ],
    rankList: {
      1: [
        {
          rank: 1,
          groupId: 225808,
          groupLevel: 5,
          groupName: '⛳️수원 골프(골린이 탈출)⛳️',
          groupThumbnailUrl: {
            id: 'kr-community#1754372806835011584',
            small:
              'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
            medium:
              'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
            large:
              'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
            url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
            width: 594,
            height: 458,
            isDefault: undefined,
          },
          weeklyActivityPoint: 1544,
          displayRegionName: '영통동',
        },
        {
          rank: 2,
          groupId: 242039,
          groupLevel: 4,
          groupName: '♡광교산~ 좋은 사람들! (등산,초보)',
          groupThumbnailUrl: {
            id: 'kr-community#1836929723310120960',
            small:
              'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
            medium:
              'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
            large:
              'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
            url: 'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
            width: 600,
            height: 800,
            isDefault: undefined,
          },
          weeklyActivityPoint: 1168,
          displayRegionName: '광교1동',
        },
        {
          rank: 3,
          groupId: 429996,
          groupLevel: 3,
          groupName: '공때리는 3040',
          groupThumbnailUrl: {
            id: 'kr-community#1854020557595578368',
            small:
              'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
            medium:
              'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
            large:
              'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
            url: 'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
            width: 800,
            height: 601,
            isDefault: undefined,
          },
          weeklyActivityPoint: 702,
          displayRegionName: '광교2동',
        },
        {
          rank: 4,
          groupId: 388405,
          groupLevel: 2,
          groupName: '약속도 부담돼요 편할때 즉흥모임방',
          groupThumbnailUrl: {
            id: 'kr-community#1846231172619821056',
            small:
              'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
            medium:
              'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
            large:
              'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
            url: 'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
            width: 800,
            height: 800,
            isDefault: undefined,
          },
          weeklyActivityPoint: 622,
          displayRegionName: '상현3동',
        },
        {
          rank: 5,
          groupId: 270579,
          groupLevel: 1,
          groupName: '당근영어 완전기초 부터 고급영어회화까지',
          groupThumbnailUrl: {
            id: 'kr-community#1858886799141752832',
            small:
              'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
            medium:
              'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
            large:
              'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
            url: 'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
            width: 800,
            height: 600,
            isDefault: undefined,
          },
          weeklyActivityPoint: 408,
          displayRegionName: '권선동',
        },
        {
          rank: 6,
          groupId: 339260,
          groupLevel: 5,
          groupName: '💫💫 90년생 수원 동네친구💫💫',
          groupThumbnailUrl: {
            id: 'kr-community#1810212457994649600',
            small:
              'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
            medium:
              'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
            large:
              'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
            url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
            width: 480,
            height: 304,
            isDefault: undefined,
          },
          weeklyActivityPoint: 354,
          displayRegionName: '원천동',
        },
        {
          rank: 7,
          groupId: 14470,
          groupLevel: 5,
          groupName: '달려라 하니(초보 러닝 크루)',
          groupThumbnailUrl: {
            id: 'kr-9794323#1599620399632007168',
            small:
              'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
            medium:
              'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
            large:
              'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
            url: 'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
            width: 402,
            height: 269,
            isDefault: undefined,
          },
          weeklyActivityPoint: 336,
          displayRegionName: '망포2동',
        },
        {
          rank: 8,
          groupId: 460143,
          groupLevel: 4,
          groupName: '💕75~85💕매력이들~모여라!!',
          groupThumbnailUrl: {
            id: 'kr-community#1855470227282853888',
            small:
              'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
            medium:
              'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
            large:
              'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
            url: 'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
            width: 800,
            height: 608,
            isDefault: undefined,
          },
          weeklyActivityPoint: 332,
          displayRegionName: '인계동',
        },
      ],
    },
  },
  baseYear: 2024,
  baseWeek: 47,
  baseDate: '2024-11-20T14:29:28.263036855Z',
  rankList: [
    {
      rank: 1,
      groupId: 225808,
      groupLevel: 5,
      groupName: '⛳️수원 골프(골린이 탈출)⛳️',
      groupThumbnailUrl: {
        id: 'kr-community#1754372806835011584',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240205/a0c151c4-efbb-44c3-bec9-86b1ebca6e7e.jpeg',
        width: 594,
        height: 458,
        isDefault: undefined,
      },
      weeklyActivityPoint: 1544,
      displayRegionName: '신동',
    },
    {
      rank: 2,
      groupId: 242039,
      groupLevel: 5,
      groupName: '♡광교산~ 좋은 사람들! (등산,초보)',
      groupThumbnailUrl: {
        id: 'kr-community#1836929723310120960',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240920/9610a9ec-2936-42cb-bf96-6471358886ed.jpeg',
        width: 600,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 1168,
      displayRegionName: '광교1동',
    },
    {
      rank: 3,
      groupId: 429996,
      groupLevel: 5,
      groupName: '공때리는 3040',
      groupThumbnailUrl: {
        id: 'kr-community#1854020557595578368',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241106/2a4e285c-2645-4305-a214-4f7dd5aa773d.jpeg',
        width: 800,
        height: 601,
        isDefault: undefined,
      },
      weeklyActivityPoint: 702,
      displayRegionName: '광교2동',
    },
    {
      rank: 4,
      groupId: 388405,
      groupLevel: 5,
      groupName: '약속도 부담돼요 편할때 즉흥모임방',
      groupThumbnailUrl: {
        id: 'kr-community#1846231172619821056',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241015/5c9dc807-9764-4b21-9a76-4c50f914d147.png',
        width: 800,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 622,
      displayRegionName: '상현3동',
    },
    {
      rank: 5,
      groupId: 270579,
      groupLevel: 5,
      groupName: '당근영어 완전기초 부터 고급영어회화까지',
      groupThumbnailUrl: {
        id: 'kr-community#1858886799141752832',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241119/4520e6e9-d9b6-4b9c-a1e2-7b595eccbda2.jpeg',
        width: 800,
        height: 600,
        isDefault: undefined,
      },
      weeklyActivityPoint: 408,
      displayRegionName: '권선동',
    },
    {
      rank: 6,
      groupId: 339260,
      groupLevel: 5,
      groupName: '💫💫 90년생 수원 동네친구💫💫',
      groupThumbnailUrl: {
        id: 'kr-community#1810212457994649600',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240708/3c76f0b0-6c5b-4aa8-b3b2-16ef6a7bb5d5.jpeg',
        width: 480,
        height: 304,
        isDefault: undefined,
      },
      weeklyActivityPoint: 354,
      displayRegionName: '원천동',
    },
    {
      rank: 7,
      groupId: 14470,
      groupLevel: 5,
      groupName: '달려라 하니(초보 러닝 크루)',
      groupThumbnailUrl: {
        id: 'kr-9794323#1599620399632007168',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20221205/709715dd-50ce-4a48-8fc9-6721b1903d69.jpeg',
        width: 402,
        height: 269,
        isDefault: undefined,
      },
      weeklyActivityPoint: 336,
      displayRegionName: '망포2동',
    },
    {
      rank: 8,
      groupId: 460143,
      groupLevel: 4,
      groupName: '💕75~85💕매력이들~모여라!!',
      groupThumbnailUrl: {
        id: 'kr-community#1855470227282853888',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241110/fbbb0e3d-2f66-4bad-9fb2-ce6d309d7aec.jpeg',
        width: 800,
        height: 608,
        isDefault: undefined,
      },
      weeklyActivityPoint: 332,
      displayRegionName: '인계동',
    },
    {
      rank: 9,
      groupId: 447646,
      groupLevel: 4,
      groupName: '🎀80-89 우리들의 우정은 지금부터🎀',
      groupThumbnailUrl: {
        id: 'kr-community#1856726565468090368',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241113/fceb7c50-79c7-4135-957d-b90f61b163fe.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241113/fceb7c50-79c7-4135-957d-b90f61b163fe.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241113/fceb7c50-79c7-4135-957d-b90f61b163fe.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241113/fceb7c50-79c7-4135-957d-b90f61b163fe.jpeg',
        width: 800,
        height: 784,
        isDefault: undefined,
      },
      weeklyActivityPoint: 298,
      displayRegionName: '인계동',
    },
    {
      rank: 10,
      groupId: 216024,
      groupLevel: 5,
      groupName: '✨🌕수원 30대 동네친구🌕✨',
      groupThumbnailUrl: {
        id: 'kr-community#1858137424392200192',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241117/a32aa553-9e18-4348-afcd-b7388c11bc5d.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241117/a32aa553-9e18-4348-afcd-b7388c11bc5d.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241117/a32aa553-9e18-4348-afcd-b7388c11bc5d.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241117/a32aa553-9e18-4348-afcd-b7388c11bc5d.png',
        width: 601,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 268,
      displayRegionName: '신동',
    },
    {
      rank: 11,
      groupId: 291758,
      groupLevel: 5,
      groupName: '겁쟁이 페달 YPC[초보,로드,자전거,샤방]',
      groupThumbnailUrl: {
        id: 'kr-community#1729306013443678208',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231128/62f0dfff-4a3b-492d-9b58-e8daabe2efb1.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231128/62f0dfff-4a3b-492d-9b58-e8daabe2efb1.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231128/62f0dfff-4a3b-492d-9b58-e8daabe2efb1.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20231128/62f0dfff-4a3b-492d-9b58-e8daabe2efb1.jpeg',
        width: 500,
        height: 722,
        isDefault: undefined,
      },
      weeklyActivityPoint: 236,
      displayRegionName: '망포동',
    },
    {
      rank: 12,
      groupId: 379291,
      groupLevel: 4,
      groupName: '어른을 위한 그림책',
      groupThumbnailUrl: {
        id: 'kr-community#1842820474132893696',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241006/e4d88256-c981-43b2-bc08-7d76b292ffbb.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241006/e4d88256-c981-43b2-bc08-7d76b292ffbb.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241006/e4d88256-c981-43b2-bc08-7d76b292ffbb.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241006/e4d88256-c981-43b2-bc08-7d76b292ffbb.jpeg',
        width: 800,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 230,
      displayRegionName: '매탄동',
    },
    {
      rank: 13,
      groupId: 240357,
      groupLevel: 5,
      groupName: '동네LP바 순례모임',
      groupThumbnailUrl: {
        id: 'kr-community#1706670586559860736',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20230926/7faea7dc-15e0-4d12-8e6a-cb4bf1e4ece3.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20230926/7faea7dc-15e0-4d12-8e6a-cb4bf1e4ece3.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20230926/7faea7dc-15e0-4d12-8e6a-cb4bf1e4ece3.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20230926/7faea7dc-15e0-4d12-8e6a-cb4bf1e4ece3.jpeg',
        width: 450,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 202,
      displayRegionName: '상현1동',
    },
    {
      rank: 14,
      groupId: 351337,
      groupLevel: 5,
      groupName: '배붕이들의 우당탕탕 배드민턴',
      groupThumbnailUrl: {
        id: 'kr-community#1834171783968821248',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240912/d1edcffb-8f02-4d92-bffa-45c3064f8e06.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240912/d1edcffb-8f02-4d92-bffa-45c3064f8e06.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240912/d1edcffb-8f02-4d92-bffa-45c3064f8e06.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240912/d1edcffb-8f02-4d92-bffa-45c3064f8e06.jpeg',
        width: 624,
        height: 756,
        isDefault: undefined,
      },
      weeklyActivityPoint: 178,
      displayRegionName: '매탄동',
    },
    {
      rank: 15,
      groupId: 399820,
      groupLevel: 4,
      groupName: '4050  우리들의 소소한 일탈',
      groupThumbnailUrl: {
        id: 'kr-community#1833413341930983424',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240910/c2dfd150-f389-4093-bc41-788dc9e545b5.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240910/c2dfd150-f389-4093-bc41-788dc9e545b5.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240910/c2dfd150-f389-4093-bc41-788dc9e545b5.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240910/c2dfd150-f389-4093-bc41-788dc9e545b5.jpeg',
        width: 790,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 158,
      displayRegionName: '권선동',
    },
    {
      rank: 16,
      groupId: 393685,
      groupLevel: 4,
      groupName: '건강한 집밥 만들기',
      groupThumbnailUrl: {
        id: 'kr-community#1802524613020835840',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240617/5ac14d62-70ee-48f8-9dc8-af0c93d6d177.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240617/5ac14d62-70ee-48f8-9dc8-af0c93d6d177.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240617/5ac14d62-70ee-48f8-9dc8-af0c93d6d177.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240617/5ac14d62-70ee-48f8-9dc8-af0c93d6d177.jpeg',
        width: 563,
        height: 751,
        isDefault: undefined,
      },
      weeklyActivityPoint: 150,
      displayRegionName: '망포동',
    },
    {
      rank: 17,
      groupId: 300730,
      groupLevel: 4,
      groupName: '55세~~65세 여성 모임  (백조 여성모임',
      groupThumbnailUrl: {
        id: 'kr-community#1802669862242353152',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240617/8c6b9613-d7e0-40ba-a6af-aee20f9ea1d6.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240617/8c6b9613-d7e0-40ba-a6af-aee20f9ea1d6.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240617/8c6b9613-d7e0-40ba-a6af-aee20f9ea1d6.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240617/8c6b9613-d7e0-40ba-a6af-aee20f9ea1d6.jpeg',
        width: 595,
        height: 767,
        isDefault: undefined,
      },
      weeklyActivityPoint: 148,
      displayRegionName: '인계동',
    },
    {
      rank: 18,
      groupId: 401876,
      groupLevel: 4,
      groupName: '수원 동네친구 “스껄“ 하실분들?',
      groupThumbnailUrl: {
        id: 'kr-community#1836539460855140352',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240918/0b400a58-9522-4f40-a519-8af6930ef3a4.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240918/0b400a58-9522-4f40-a519-8af6930ef3a4.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240918/0b400a58-9522-4f40-a519-8af6930ef3a4.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240918/0b400a58-9522-4f40-a519-8af6930ef3a4.png',
        width: 512,
        height: 512,
        isDefault: undefined,
      },
      weeklyActivityPoint: 126,
      displayRegionName: '이의동',
    },
    {
      rank: 18,
      groupId: 422338,
      groupLevel: 4,
      groupName: '수원3040 ',
      groupThumbnailUrl: {
        id: 'kr-community#1840606931924938752',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240930/1abfebce-07c7-4703-a9b3-39a414657ba0.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240930/1abfebce-07c7-4703-a9b3-39a414657ba0.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240930/1abfebce-07c7-4703-a9b3-39a414657ba0.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240930/1abfebce-07c7-4703-a9b3-39a414657ba0.jpeg',
        width: 767,
        height: 512,
        isDefault: undefined,
      },
      weeklyActivityPoint: 126,
      displayRegionName: '우만동',
    },
    {
      rank: 20,
      groupId: 226359,
      groupLevel: 5,
      groupName: '[수지] 테니스 치실 동네분들 모십니다 ^^',
      groupThumbnailUrl: {
        id: 'kr-community#1785657464272838656',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240501/626bf41b-cfaa-46ac-9c4b-a9c603252afc.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240501/626bf41b-cfaa-46ac-9c4b-a9c603252afc.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240501/626bf41b-cfaa-46ac-9c4b-a9c603252afc.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240501/626bf41b-cfaa-46ac-9c4b-a9c603252afc.jpeg',
        width: 800,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 114,
      displayRegionName: '상현동',
    },
    {
      rank: 21,
      groupId: 284348,
      groupLevel: 4,
      groupName: '수원 물생활 공유',
      groupThumbnailUrl: {
        id: 'kr-community#1729038308924817408',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231127/883989bf-7deb-4d1a-9fda-c86e95afd6fe.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231127/883989bf-7deb-4d1a-9fda-c86e95afd6fe.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231127/883989bf-7deb-4d1a-9fda-c86e95afd6fe.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20231127/883989bf-7deb-4d1a-9fda-c86e95afd6fe.jpeg',
        width: 800,
        height: 600,
        isDefault: undefined,
      },
      weeklyActivityPoint: 112,
      displayRegionName: '영통2동',
    },
    {
      rank: 22,
      groupId: 398869,
      groupLevel: 4,
      groupName: '수원골저스레이디(5060골프)',
      groupThumbnailUrl: {
        id: 'kr-community#1822655211282456576',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240811/764a9d2a-3d5e-4545-a223-c4a2e84846ce.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240811/764a9d2a-3d5e-4545-a223-c4a2e84846ce.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240811/764a9d2a-3d5e-4545-a223-c4a2e84846ce.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240811/764a9d2a-3d5e-4545-a223-c4a2e84846ce.jpeg',
        width: 800,
        height: 450,
        isDefault: undefined,
      },
      weeklyActivityPoint: 110,
      displayRegionName: '인계동',
    },
    {
      rank: 22,
      groupId: 438123,
      groupLevel: 4,
      groupName: '🌈뽀뽀뽀💝수원화성77~90모여놀자앙🌈 ',
      groupThumbnailUrl: {
        id: 'kr-community#1839834453627748352',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240928/60b129f3-419e-460e-ab2b-5989096bb70f.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240928/60b129f3-419e-460e-ab2b-5989096bb70f.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240928/60b129f3-419e-460e-ab2b-5989096bb70f.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240928/60b129f3-419e-460e-ab2b-5989096bb70f.jpeg',
        width: 800,
        height: 794,
        isDefault: undefined,
      },
      weeklyActivityPoint: 110,
      displayRegionName: '반월동',
    },
    {
      rank: 24,
      groupId: 444701,
      groupLevel: 4,
      groupName: '광교 스크린 골프모임 •버디버디•',
      groupThumbnailUrl: {
        id: 'kr-community#1843562200053157888',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241008/b3b3df73-5fef-4f52-b12d-146e817b874e.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241008/b3b3df73-5fef-4f52-b12d-146e817b874e.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241008/b3b3df73-5fef-4f52-b12d-146e817b874e.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241008/b3b3df73-5fef-4f52-b12d-146e817b874e.png',
        width: 800,
        height: 606,
        isDefault: undefined,
      },
      weeklyActivityPoint: 108,
      displayRegionName: '이의동',
    },
    {
      rank: 25,
      groupId: 429513,
      groupLevel: 5,
      groupName: '🇨🇦 캐나다 원어민과 여성전용 토킹모음',
      groupThumbnailUrl: {
        id: 'kr-community#1832291623439036416',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240907/67bc0e5b-7feb-4d9d-8ebb-b7399b0d8cce.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240907/67bc0e5b-7feb-4d9d-8ebb-b7399b0d8cce.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240907/67bc0e5b-7feb-4d9d-8ebb-b7399b0d8cce.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240907/67bc0e5b-7feb-4d9d-8ebb-b7399b0d8cce.png',
        width: 800,
        height: 599,
        isDefault: undefined,
      },
      weeklyActivityPoint: 106,
      displayRegionName: '이의동',
    },
    {
      rank: 26,
      groupId: 426198,
      groupLevel: 4,
      groupName: '❄️00~90 놀자❄️',
      groupThumbnailUrl: {
        id: 'kr-community#1829944646852284416',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240831/bb658390-0e45-4da9-acdd-3cf67be68547.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240831/bb658390-0e45-4da9-acdd-3cf67be68547.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240831/bb658390-0e45-4da9-acdd-3cf67be68547.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240831/bb658390-0e45-4da9-acdd-3cf67be68547.png',
        width: 800,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 104,
      displayRegionName: '인계동',
    },
    {
      rank: 27,
      groupId: 442344,
      groupLevel: 4,
      groupName: '🎲수원 90년대생 방탈출 보드게임 모임🎲',
      groupThumbnailUrl: {
        id: 'kr-community#1841856229060722688',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241003/fa0b3ea8-9fdf-4746-b18d-bfbece19c6ba.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241003/fa0b3ea8-9fdf-4746-b18d-bfbece19c6ba.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241003/fa0b3ea8-9fdf-4746-b18d-bfbece19c6ba.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241003/fa0b3ea8-9fdf-4746-b18d-bfbece19c6ba.png',
        width: 601,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 100,
      displayRegionName: '영통동',
    },
    {
      rank: 28,
      groupId: 423767,
      groupLevel: 4,
      groupName: '광교산 등산모임(야등)',
      groupThumbnailUrl: {
        id: 'kr-community#1828046117259010048',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240826/9e29b045-3356-4e0e-9eaa-385015067e76.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240826/9e29b045-3356-4e0e-9eaa-385015067e76.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240826/9e29b045-3356-4e0e-9eaa-385015067e76.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240826/9e29b045-3356-4e0e-9eaa-385015067e76.jpeg',
        width: 756,
        height: 756,
        isDefault: undefined,
      },
      weeklyActivityPoint: 98,
      displayRegionName: '이의동',
    },
    {
      rank: 28,
      groupId: 350364,
      groupLevel: 4,
      groupName: '동네 기타 모임 🎸',
      groupThumbnailUrl: {
        id: 'kr-community#1788744125705756672',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240510/30ab0420-288f-4818-91b4-993f6acda00d.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240510/30ab0420-288f-4818-91b4-993f6acda00d.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240510/30ab0420-288f-4818-91b4-993f6acda00d.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240510/30ab0420-288f-4818-91b4-993f6acda00d.jpeg',
        width: 783,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 98,
      displayRegionName: '신갈동',
    },
    {
      rank: 30,
      groupId: 244464,
      groupLevel: 4,
      groupName: '⛳️영통여성골프🏌‍♀️',
      groupThumbnailUrl: {
        id: 'kr-community#1852172235041800192',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241101/9d0505a4-f8f5-465a-8253-577623a28842.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241101/9d0505a4-f8f5-465a-8253-577623a28842.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241101/9d0505a4-f8f5-465a-8253-577623a28842.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241101/9d0505a4-f8f5-465a-8253-577623a28842.jpeg',
        width: 800,
        height: 328,
        isDefault: undefined,
      },
      weeklyActivityPoint: 96,
      displayRegionName: '영통3동',
    },
    {
      rank: 31,
      groupId: 8268,
      groupLevel: 4,
      groupName: '🔥🔥매일매일 5천보 인증 모임🏃🏻‍♀️🏃🏻🏃🏻‍♂️💨',
      groupThumbnailUrl: {
        id: 'kr-1181966#1595661315610128384',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20221124/27dfa620-5293-4478-b573-f90bcbe1d886.png',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20221124/27dfa620-5293-4478-b573-f90bcbe1d886.png',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20221124/27dfa620-5293-4478-b573-f90bcbe1d886.png',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20221124/27dfa620-5293-4478-b573-f90bcbe1d886.png',
        width: 800,
        height: 568,
        isDefault: undefined,
      },
      weeklyActivityPoint: 84,
      displayRegionName: '원천동',
    },
    {
      rank: 32,
      groupId: 418097,
      groupLevel: 4,
      groupName: '5060♡청춘은바로지금',
      groupThumbnailUrl: {
        id: 'kr-community#1849994637104738304',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241026/f8d9d346-210e-4e7e-bbdc-87028cd72776.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241026/f8d9d346-210e-4e7e-bbdc-87028cd72776.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241026/f8d9d346-210e-4e7e-bbdc-87028cd72776.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241026/f8d9d346-210e-4e7e-bbdc-87028cd72776.jpeg',
        width: 640,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 76,
      displayRegionName: '권선2동',
    },
    {
      rank: 32,
      groupId: 432076,
      groupLevel: 4,
      groupName: '<신생>동수산악회(동탄,수원 산악회)',
      groupThumbnailUrl: {
        id: 'kr-community#1855987430017040384',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241111/0ad932f6-d39a-4baa-b268-f74b00dfcb24.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241111/0ad932f6-d39a-4baa-b268-f74b00dfcb24.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241111/0ad932f6-d39a-4baa-b268-f74b00dfcb24.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241111/0ad932f6-d39a-4baa-b268-f74b00dfcb24.jpeg',
        width: 600,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 76,
      displayRegionName: '동탄1동',
    },
    {
      rank: 34,
      groupId: 280954,
      groupLevel: 4,
      groupName: '다이아 ( 다 이쁜데 아줌마야) ',
      groupThumbnailUrl: {
        id: 'kr-community#1722693170459922432',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231109/fbc4215f-9aca-454d-8ccc-5813ec87597b.png',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231109/fbc4215f-9aca-454d-8ccc-5813ec87597b.png',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231109/fbc4215f-9aca-454d-8ccc-5813ec87597b.png',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20231109/fbc4215f-9aca-454d-8ccc-5813ec87597b.png',
        width: 370,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 74,
      displayRegionName: '영통동',
    },
    {
      rank: 35,
      groupId: 446537,
      groupLevel: 4,
      groupName: '5060청바지',
      groupThumbnailUrl: {
        id: 'kr-community#1847760897251078144',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241019/7da0317e-8ed6-469f-bac8-9fc6a2901a24.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241019/7da0317e-8ed6-469f-bac8-9fc6a2901a24.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241019/7da0317e-8ed6-469f-bac8-9fc6a2901a24.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241019/7da0317e-8ed6-469f-bac8-9fc6a2901a24.jpeg',
        width: 768,
        height: 432,
        isDefault: undefined,
      },
      weeklyActivityPoint: 72,
      displayRegionName: '하동',
    },
    {
      rank: 35,
      groupId: 298099,
      groupLevel: 4,
      groupName: '유튜브/블로그/인스타/SNS 소셜 스터디모임',
      groupThumbnailUrl: {
        id: 'kr-community#1733772733000548352',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231210/81ea3e62-2437-486d-b945-dc562b94e575.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231210/81ea3e62-2437-486d-b945-dc562b94e575.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20231210/81ea3e62-2437-486d-b945-dc562b94e575.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20231210/81ea3e62-2437-486d-b945-dc562b94e575.jpeg',
        width: 800,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 72,
      displayRegionName: '권선동',
    },
    {
      rank: 37,
      groupId: 401082,
      groupLevel: 4,
      groupName: '⛳️2030 명랑 골프👀⛳️(영통/동탄)',
      groupThumbnailUrl: {
        id: 'kr-community#1829441777833197568',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240830/813f5650-2b39-4a7e-a812-9188f0810c07.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240830/813f5650-2b39-4a7e-a812-9188f0810c07.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240830/813f5650-2b39-4a7e-a812-9188f0810c07.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240830/813f5650-2b39-4a7e-a812-9188f0810c07.png',
        width: 361,
        height: 369,
        isDefault: undefined,
      },
      weeklyActivityPoint: 62,
      displayRegionName: '농서동',
    },
    {
      rank: 37,
      groupId: 439229,
      groupLevel: 4,
      groupName: '🐷95년생 모여라🐷',
      groupThumbnailUrl: {
        id: 'kr-community#1839686368486350848',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240927/8529b115-c6ff-48c6-8e06-ce43db68ac48.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240927/8529b115-c6ff-48c6-8e06-ce43db68ac48.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240927/8529b115-c6ff-48c6-8e06-ce43db68ac48.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240927/8529b115-c6ff-48c6-8e06-ce43db68ac48.png',
        width: 763,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 62,
      displayRegionName: '인계동',
    },
    {
      rank: 39,
      groupId: 338985,
      groupLevel: 4,
      groupName: '용띠맘 모임🐉',
      groupThumbnailUrl: {
        id: 'kr-community#1764690392772603904',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240304/b04f894e-2236-48d8-b184-507f33f994ba.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240304/b04f894e-2236-48d8-b184-507f33f994ba.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240304/b04f894e-2236-48d8-b184-507f33f994ba.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240304/b04f894e-2236-48d8-b184-507f33f994ba.jpeg',
        width: 800,
        height: 767,
        isDefault: undefined,
      },
      weeklyActivityPoint: 56,
      displayRegionName: '신갈동',
    },
    {
      rank: 40,
      groupId: 458529,
      groupLevel: 4,
      groupName: 'No Friend🎄',
      groupThumbnailUrl: {
        id: 'kr-community#1856328427557199872',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241112/c70fbeee-b687-47f7-b3d4-02cb13bde6bb.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241112/c70fbeee-b687-47f7-b3d4-02cb13bde6bb.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241112/c70fbeee-b687-47f7-b3d4-02cb13bde6bb.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241112/c70fbeee-b687-47f7-b3d4-02cb13bde6bb.png',
        width: 800,
        height: 787,
        isDefault: undefined,
      },
      weeklyActivityPoint: 52,
      displayRegionName: '원천동',
    },
    {
      rank: 40,
      groupId: 384373,
      groupLevel: 4,
      groupName: '⛹️⛹️‍♀️수원광교 용인 즐농 농구🏀',
      groupThumbnailUrl: {
        id: 'kr-community#1795457839326646272',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240528/109aa870-be52-4bdd-aa7d-8add52e29775.png',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240528/109aa870-be52-4bdd-aa7d-8add52e29775.png',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240528/109aa870-be52-4bdd-aa7d-8add52e29775.png',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240528/109aa870-be52-4bdd-aa7d-8add52e29775.png',
        width: 800,
        height: 715,
        isDefault: undefined,
      },
      weeklyActivityPoint: 52,
      displayRegionName: '상현동',
    },
    {
      rank: 40,
      groupId: 376207,
      groupLevel: 4,
      groupName: '인생은!사십부터!!즐겁게!!!',
      groupThumbnailUrl: {
        id: 'kr-community#1790303297601622016',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240514/a0aef818-b17c-4793-ac7e-c7f81b77c270.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240514/a0aef818-b17c-4793-ac7e-c7f81b77c270.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240514/a0aef818-b17c-4793-ac7e-c7f81b77c270.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240514/a0aef818-b17c-4793-ac7e-c7f81b77c270.jpeg',
        width: 800,
        height: 729,
        isDefault: undefined,
      },
      weeklyActivityPoint: 52,
      displayRegionName: '원천동',
    },
    {
      rank: 43,
      groupId: 438896,
      groupLevel: 4,
      groupName: '8090 로맨틱&소울메이트 맛집 ❤️ 🤍 ',
      groupThumbnailUrl: {
        id: 'kr-community#1839823911219453952',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240928/8ca5e5d6-d27f-499d-85ba-e0e4c1c2d8ae.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240928/8ca5e5d6-d27f-499d-85ba-e0e4c1c2d8ae.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240928/8ca5e5d6-d27f-499d-85ba-e0e4c1c2d8ae.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240928/8ca5e5d6-d27f-499d-85ba-e0e4c1c2d8ae.png',
        width: 589,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 50,
      displayRegionName: '권선동',
    },
    {
      rank: 43,
      groupId: 374227,
      groupLevel: 4,
      groupName: '⚾️ 야구 팬 모임 베스 (가입불가❌)⚾️',
      groupThumbnailUrl: {
        id: 'kr-community#1792057004622184448',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240519/3d17a5f5-1bf4-4b3e-a984-3105dd177afc.png',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240519/3d17a5f5-1bf4-4b3e-a984-3105dd177afc.png',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240519/3d17a5f5-1bf4-4b3e-a984-3105dd177afc.png',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240519/3d17a5f5-1bf4-4b3e-a984-3105dd177afc.png',
        width: 800,
        height: 789,
        isDefault: undefined,
      },
      weeklyActivityPoint: 50,
      displayRegionName: '권선2동',
    },
    {
      rank: 43,
      groupId: 423746,
      groupLevel: 4,
      groupName: '⛺️비 to the 박⛺️(평일반 백패킹)',
      groupThumbnailUrl: {
        id: 'kr-community#1831631595652648960',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20240905/70f0f6ab-8d8f-4ff5-a665-c2c814f2e7dc.png',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20240905/70f0f6ab-8d8f-4ff5-a665-c2c814f2e7dc.png',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20240905/70f0f6ab-8d8f-4ff5-a665-c2c814f2e7dc.png',
        url: 'https://img.kr.gcp-karroter.net/community/community/20240905/70f0f6ab-8d8f-4ff5-a665-c2c814f2e7dc.png',
        width: 450,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 50,
      displayRegionName: '권선동',
    },
    {
      rank: 43,
      groupId: 357303,
      groupLevel: 4,
      groupName: '광교상현동골프선수촌스크린골프',
      groupThumbnailUrl: {
        id: 'kr-community#1777691752568807424',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240409/ab42c7d7-b229-46c8-a126-38e35c02d4b4.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240409/ab42c7d7-b229-46c8-a126-38e35c02d4b4.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240409/ab42c7d7-b229-46c8-a126-38e35c02d4b4.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240409/ab42c7d7-b229-46c8-a126-38e35c02d4b4.jpeg',
        width: 800,
        height: 600,
        isDefault: undefined,
      },
      weeklyActivityPoint: 50,
      displayRegionName: '상현동',
    },
    {
      rank: 43,
      groupId: 329518,
      groupLevel: 4,
      groupName: '내향형 여자사람 모이세요~(독서O,술X)',
      groupThumbnailUrl: {
        id: 'kr-community#1758154170482905088',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240215/ff347773-a915-4266-aaee-87745b0e4105.jpeg',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240215/ff347773-a915-4266-aaee-87745b0e4105.jpeg',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240215/ff347773-a915-4266-aaee-87745b0e4105.jpeg',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240215/ff347773-a915-4266-aaee-87745b0e4105.jpeg',
        width: 560,
        height: 512,
        isDefault: undefined,
      },
      weeklyActivityPoint: 50,
      displayRegionName: '영덕2동',
    },
    {
      rank: 43,
      groupId: 437075,
      groupLevel: 4,
      groupName: '🍭오늘모해🍭 수원친목모임',
      groupThumbnailUrl: {
        id: 'kr-community#1855219575357046784',
        small:
          'https://img.kr.gcp-karroter.net/community/community/20241109/9527f138-7636-401c-b8af-a4ac4da5ea2e.jpeg',
        medium:
          'https://img.kr.gcp-karroter.net/community/community/20241109/9527f138-7636-401c-b8af-a4ac4da5ea2e.jpeg',
        large:
          'https://img.kr.gcp-karroter.net/community/community/20241109/9527f138-7636-401c-b8af-a4ac4da5ea2e.jpeg',
        url: 'https://img.kr.gcp-karroter.net/community/community/20241109/9527f138-7636-401c-b8af-a4ac4da5ea2e.jpeg',
        width: 800,
        height: 450,
        isDefault: undefined,
      },
      weeklyActivityPoint: 50,
      displayRegionName: '매탄동',
    },
    {
      rank: 49,
      groupId: 217855,
      groupLevel: 4,
      groupName: '고양이 집사 모임',
      groupThumbnailUrl: {
        id: 'kr-community#1693785538697453568',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20230822/3a702c40-7954-4444-8e27-53d185e363dd.png',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20230822/3a702c40-7954-4444-8e27-53d185e363dd.png',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20230822/3a702c40-7954-4444-8e27-53d185e363dd.png',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20230822/3a702c40-7954-4444-8e27-53d185e363dd.png',
        width: 600,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 44,
      displayRegionName: '인계동',
    },
    {
      rank: 50,
      groupId: 317847,
      groupLevel: 4,
      groupName: '❤️💛광교/수지 육아맘💛❤️',
      groupThumbnailUrl: {
        id: 'kr-community#1749355962461966336',
        small:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240122/8fccecd3-0edc-482d-819b-a3fb48071fdf.png',
        medium:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240122/8fccecd3-0edc-482d-819b-a3fb48071fdf.png',
        large:
          'https://dnvefa72aowie.cloudfront.net/community/community/20240122/8fccecd3-0edc-482d-819b-a3fb48071fdf.png',
        url: 'https://dnvefa72aowie.cloudfront.net/community/community/20240122/8fccecd3-0edc-482d-819b-a3fb48071fdf.png',
        width: 746,
        height: 800,
        isDefault: undefined,
      },
      weeklyActivityPoint: 42,
      displayRegionName: '상현동',
    },
  ],
};
