import { FeedbackRatingEnum } from '@community-group/api/lib/group/models';
import {
  IconThumbDownFill,
  IconThumbDownLine,
  IconThumbUpFill,
  IconThumbUpLine,
} from '@daangn/react-monochrome-icon';

import { ToggleButton } from './ToggleButton';

type Props = {
  value?: FeedbackRatingEnum | undefined;
  onChange: (selectedRating?: FeedbackRatingEnum | undefined) => void;
};

export const MemberFeedbackRatingField = ({ value, onChange }: Props) => {
  return (
    <>
      <ToggleButton
        isSelected={value === 'LIKE'}
        onChange={(isSelected) => {
          onChange(isSelected ? 'LIKE' : undefined);
        }}
      >
        {(isSelected) =>
          isSelected ? <IconThumbUpFill size="16" /> : <IconThumbUpLine size="16" />
        }
      </ToggleButton>
      <ToggleButton
        isSelected={value === 'DISLIKE'}
        onChange={(isSelected) => {
          onChange(isSelected ? 'DISLIKE' : undefined);
        }}
      >
        {(isSelected) =>
          isSelected ? <IconThumbDownFill size="16" /> : <IconThumbDownLine size="16" />
        }
      </ToggleButton>
    </>
  );
};
