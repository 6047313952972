import { groupClient } from '@community-group/api';
import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getChallengeDetailPath = (groupId: string, challengeId: string) =>
  groupClient.api.GroupChallengeApi.getapiV1GroupsGroupIdChallengesChallengeIdDetailGetPath(
    Number(groupId),
    Number(challengeId)
  );

export const useGetChallengeDetail = ({
  groupId,
  challengeId,
}: {
  groupId: string;
  challengeId: string;
}) => {
  const fetchInstance = useFetchInstance();

  const getChallengeDetail =
    groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesChallengeIdDetailGet({
      axios: fetchInstance,
    });

  const { data, ...rest } = useSuspenseQuery({
    queryKey: [getChallengeDetailPath(groupId, challengeId)],
    queryFn: async () => {
      if (!challengeId) return null;

      return await getChallengeDetail(Number(groupId), Number(challengeId));
    },
  });

  const response = data?.data as ChallengeDetailPresentation;

  return {
    data: response,
    ...rest,
  };
};
