import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React from 'react';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { GROUP_LEVEL_ICONS } from '@/components/group/GroupLevel/utils/levelIcons';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './SummaryLevelBanner.css';

const SummaryLevelBanner = () => {
  const { groupId = '' } = usePathParams();

  const { data: levelData } = useGetGroupLevel({ groupId: Number(groupId) });
  const { push } = useFlow();

  const handleClickBanner = () => {
    push('GroupLevelDetailPage', {
      groupId,
    });
  };

  const currentLevel = levelData?.currentLevel ?? 0;
  const LevelBadgeImage = GROUP_LEVEL_ICONS._2d.large[currentLevel];

  if (!levelData) return null;

  return (
    <div className={s.Container}>
      <div className={s.Header} onClick={handleClickBanner}>
        <img src={LevelBadgeImage} className={s.BadgeImage} />
        <VerticalSpacing size={12} />
        <div className={s.HeaderContent}>
          <Typography typography="subtitle2Regular" color="gray900">
            이번 달 모임 레벨
          </Typography>
          <Spacing size={2} />
          <Typography typography="subtitle2Bold" color="gray800">
            Lv.{levelData?.currentLevel}
          </Typography>
        </div>
        <VerticalSpacing size={12} />
        <IconChevronRightFill size={20} color={vars.$scale.color.gray600} />
      </div>
    </div>
  );
};

export default SummaryLevelBanner;
