import { Typography } from '@community-group/components';

import { useBridge } from '@/contexts/Bridge';

import * as s from './BannerFooter.css';

type Props = {
  onToggle: () => void;
  initialSlide: number;
  setInitialSlide: (slide: number) => void;
};

const BannerFooter = ({ initialSlide, setInitialSlide }: Props) => {
  const { bridge } = useBridge();

  const handleGuideClick = () => {
    // 러닝 그로스 통합 Url
    const url = 'https://daangn.notion.site/11c28c3a9f8f80569504e755c96a9e66';
    bridge.pushRouter({
      router: {
        remote: url,
        navbar: true,
        enableSafeAreaInsets: false,
        backSwipable: true,
      },
    });
  };

  const pageMoveButtonText = initialSlide === 0 ? '다음 미션 보기' : '이전 미션 보기';

  const handlePageMoveClick = () => {
    if (initialSlide === 0) {
      setInitialSlide(initialSlide + 1);
    } else {
      setInitialSlide(initialSlide - 1);
    }
  };

  return (
    <div className={s.Container}>
      <div className={s.ButtonContainer} onClick={handleGuideClick}>
        <Typography typography="label3Regular" color="gray600">
          미션 가이드
        </Typography>
      </div>
      <div className={s.DividerWrapper} />
      <div className={s.ButtonContainer} onClick={handlePageMoveClick}>
        <Typography typography="label3Regular" color="gray600">
          {pageMoveButtonText}
        </Typography>
      </div>
    </div>
  );
};

export default BannerFooter;
