import { BoxButton, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import {
  IconDot3HorizontalChatbubbleLeftLine,
  IconThumbUpFill,
  IconThumbUpLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useDeleteLikePost } from '@/api/hooks/useDeleteLikePost';
import { getGroupPostEmotionListPath } from '@/api/hooks/useGetGroupPostEmotionList';
import {
  deleteLikePhotoContestEntries,
  patchLikePhotoContestEntries,
} from '@/api/hooks/useGetPhotoContestEntries';
import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import { usePatchLikePost } from '@/api/hooks/usePatchLikePost';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useJoinGroup } from '@/hooks/useJoinGroup';
import { queryClient } from '@/shared/api/instance';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '../../JoinGroupState/hooks/useHandleGroupOnly';
import * as s from './PostNotMemberAccessoryView.css';

const PostNotMemberAccessoryView = () => {
  const { photoContestEntryId = '' } = useQueryParams();
  const { groupId, postId } = usePathParams();
  const { bridge } = useBridge();
  const { push } = useFlow();

  const { post } = useGetPostDetail(groupId, postId);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateDislike } = useDeleteLikePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      const queryKey = getGroupPostEmotionListPath(Number(groupId), Number(postId));
      queryClient.refetchQueries({
        queryKey: [queryKey],
      });
    },
  });

  const { mutate: mutateLike } = usePatchLikePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      const queryKey = getGroupPostEmotionListPath(Number(groupId), Number(postId));
      queryClient.refetchQueries({
        queryKey: [queryKey],
      });
      trackEvent({
        event: 'click_like_not_member_accessory_group_detail',
        params: {
          contentType: 'post',
        },
        sample: true,
      });
    },
  });

  const handleNeedToSubNickname = () => {
    push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
      groupId: groupId,
    });
  };

  const { buttonText, handleClick: handleJoinClick } = useJoinGroup({
    groupId,
    buttonText: '가입',
    successCallback: () => {
      if (!groupInfo.isSettingSubNickname) return;
      handleNeedToSubNickname();
    },
    referrerPage: 'group_post_detail',
  });

  const handlePushGroupDetail = () => {
    trackEvent({
      event: 'click_post_not_member_accessory_group_detail',
      params: {
        groupId,
        name: groupInfo.name,
        category: groupInfo.categoryName,
      },
      sample: true,
    });
    push('GroupDetailPage', {
      groupId,
    });
  };

  const handleLikeIconClick = (event) => {
    event.stopPropagation();
    bridge.setHapticLightFeedback({});
    if (post.emotion.myEmotion) {
      mutateDislike(
        {
          groupId,
          postId,
          boardCategories: post?.boardCategories?.map((categories) => categories.id),
        },
        {
          onSuccess: () => {
            if (photoContestEntryId) {
              deleteLikePhotoContestEntries(photoContestEntryId);
            }
          },
        }
      );

      return;
    }
    mutateLike(
      {
        groupId,
        postId,
        boardCategories: post?.boardCategories?.map((categories) => categories.id),
      },
      {
        onSuccess: () => {
          if (photoContestEntryId) {
            patchLikePhotoContestEntries(photoContestEntryId);
          }
        },
      }
    );
  };

  const { handleGroupOnly, groupInfo } = useHandleGroupOnly({ groupId });
  const handleCommentIconClick = () => {
    handleGroupOnly({
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getComment,
      onSettled: () => {
        const commentSection = document.getElementById('post-comment-section');
        if (commentSection) {
          commentSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      },
      onSuccess() {
        if (groupInfo.isSettingSubNickname) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  return (
    <div className={s.AccessoryViewWrapper}>
      <div className={s.AccessoryViewLeftSide}>
        <div className={s.IconButtonWrapper} onClick={handleLikeIconClick}>
          {post.emotion.myEmotion ? (
            <IconThumbUpFill width={22} height={22} color={vars.$semantic.color.primary} />
          ) : (
            <IconThumbUpLine width={22} height={22} color={vars.$scale.color.gray800} />
          )}
          {post.emotion.count > 0 && (
            <Typography
              className={s.IconButtonText}
              typography="caption1Regular"
              color={post.emotion.myEmotion ? 'primary' : 'gray900'}
            >
              {post.emotion.count}
            </Typography>
          )}
        </div>
        <VerticalSpacing size={20} />
        <div className={s.IconButtonWrapper}>
          <IconDot3HorizontalChatbubbleLeftLine
            width={22}
            height={22}
            color={vars.$scale.color.gray800}
            onClick={handleCommentIconClick}
          />
          {post.commentCount > 0 && (
            <Typography className={s.IconButtonText} typography="caption1Regular" color={'gray900'}>
              {post.commentCount}
            </Typography>
          )}
        </div>
      </div>
      <div className={s.AccessoryViewRightSide}>
        <BoxButton size="small" variant="secondary" minWidth={105} onClick={handlePushGroupDetail}>
          모임 둘러보기
        </BoxButton>
        <BoxButton size="small" variant="primary" onClick={handleJoinClick} minWidth={53}>
          {buttonText}
        </BoxButton>
      </div>
    </div>
  );
};

export default PostNotMemberAccessoryView;
