import {
  AsyncBoundary,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { $Values } from 'utility-types';

import { ORDER_HOME } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import { useGetHomeList, ViewRange } from '@/api/hooks/useGetHomeList';
import SurveyFeedbackBanner from '@/components/common/Banner/SurveyFeedbackBanner';
import { Container } from '@/components/common/Container';
import GroupInfoListViewItem from '@/components/common/GroupInfoListItem/ListView';
import { Impression } from '@/components/common/Impression';
import { LoadMoreHomeContainer } from '@/components/common/LoadMoreContainer';
import ListSkeleton from '@/components/group/Home/components/ExplorerGroupTab/ExplorerGroupListSection/ListSkeleton';
import useCheckScrollActionParams from '@/components/group/Home/hooks/useCheckScrollActionParams';
import { useUserInfoForTrackEvent } from '@/components/group/Home/hooks/useUserInfoForTrackEvent';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import FilterSkeleton from './FilterSkeleton';
import GroupListFilter from './GroupListFilter';
import * as s from './index.css';

export type FilterType = {
  order: $Values<typeof ORDER_HOME>;
  category?: number;
  viewRange?: ViewRange;
};

const ExplorerGroupListSection = ({ appendTop }: { appendTop?: ReactNode }) => {
  const { categories } = useGetCategories();
  const { groupCategory, keyword } = useQueryParams();

  const initCategoryFilter = useMemo(() => {
    return (
      categories.find((item) => item.id === Number(groupCategory) || item.name === keyword)?.id ??
      undefined
    );
  }, [categories, groupCategory, keyword]);

  const [filter, setFilter] = useState<FilterType>({
    order: ORDER_HOME.RECOMMEND_SCORE_DESC,
    category: initCategoryFilter,
    viewRange: undefined,
  });

  const handleSetFilter = (filter: React.SetStateAction<FilterType>) => {
    setFilter(filter);
  };

  const explorerGroupTabRef = useRef<HTMLDivElement>(null);
  const checkScrollActionParams = useCheckScrollActionParams();

  // 페이지 로드 후 순차적으로 확인해 화면 전환 or 바텀시트가 실행되어야 하지 확인하는 로직
  useDidMountSequentialProcess([
    checkScrollActionParams({
      params: 'EXPLORER_GROUP_TAB',
      callback: async () => {
        setTimeout(() => {
          explorerGroupTabRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 600);
      },
    }),
  ]);

  return (
    <>
      <div className={s.AutoScrollPositionWrapper}>
        <div ref={explorerGroupTabRef} className={s.AutoScrollPositionInnerWrapper} />
      </div>
      {appendTop}
      <AsyncBoundary pendingFallback={<FilterSkeleton />} rejectedFallback={<></>}>
        <GroupListFilter setFilter={handleSetFilter} filter={filter} />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<ListSkeleton />} rejectedFallback={<></>}>
        <ExplorerGroupWrapper filter={filter} />
      </AsyncBoundary>
    </>
  );
};

const ExplorerGroupWrapper = ({ filter }: { filter: FilterType }) => {
  const { push } = useFlow();

  const { data, hasNextPage } = useGetHomeList({ ...filter });

  const userInfo = useUserInfoForTrackEvent();

  const flattenGroupList = data?.pages.map(({ data }) => data.groups).flat(1) ?? [];
  const flattenGroupListLength = flattenGroupList.length;

  if (flattenGroupListLength <= 0) {
    return (
      <>
        <Spacing size={32} />
        <div className={s.NoGroupWrapper}>
          <p>
            우리동네는 아직 모임이 없어요. <br />
            처음으로 모임을 만들어보세요.
          </p>
          <button
            onClick={() => {
              trackEvent({
                event: 'click_start_creating_group',
                params: {
                  referrer: 'first_to_create_in_neighborhood',
                },
              });
              push('GroupNewSetGroupCategoryRecommendPage', {});
            }}
          >
            모임 만들기
          </button>
        </div>
      </>
    );
  }

  return (
    <motion.div style={{ backgroundColor: vars.$semantic.color.paperDefault }}>
      <Container paddingY={0} paddingX={0}>
        {flattenGroupList.map((group, index) => {
          const shownFeedbackBanner = flattenGroupList.length > 7 && index === 30;

          return (
            <Impression
              key={group?.id}
              onStart={() => {
                if (index < 9) {
                  return;
                }

                if (index === 9) {
                  return trackEvent({
                    event: 'impression_10_unregistered_group_item',
                    params: {
                      ...userInfo,
                    },
                    sample: true,
                  });
                }

                trackEvent({
                  event: 'impresssion_unregistered_group_load_more_container',
                  params: {
                    lastItemIdx: parseInt(`${index / 10}`),
                    ...userInfo,
                  },
                });
              }}
              actionType={index === 0 || index === 9 ? 'once' : 'none'}
            >
              <GroupInfoListViewItem
                key={group?.id}
                group={group}
                config={{
                  enableLazyLoadImage: false,
                }}
                onClick={() => {
                  trackEvent({
                    event: 'click_explorer_group_list_item',
                    params: {
                      groupName: group?.name,
                      groupCategoryName: group?.category?.name,
                      groupId: group?.id,
                      listItemIndex: index + 1,
                      filterOrder: filter.order,
                      filterCategory: filter.category,
                    },
                    sample: true,
                  });

                  push('GroupDetailPage', {
                    groupId: group?.id.toString(),
                  });
                }}
              />
              {shownFeedbackBanner && (
                <SurveyFeedbackBanner
                  surveyId="homeExplorerGroupList"
                  referrer="bottom_of_home_feed"
                  padding="0 1rem"
                  topSpacing={16}
                  bottomSpacing={16}
                />
              )}
            </Impression>
          );
        })}
      </Container>
      {hasNextPage && <LoadMoreHomeContainer filter={filter} />}
      <Spacing size={42} />
      {!hasNextPage && (
        <div className={s.NoGroupWrapper}>
          <p>
            찾고있는 모임이 없나요? <br />
            직접 만들어보실래요?
          </p>
          <button
            onClick={() => {
              trackEvent({
                event: 'click_start_creating_group',
                params: {
                  referrer: 'home_bottom_banner',
                },
              });
              push('GroupNewSetGroupCategoryRecommendPage', {});
            }}
          >
            {' '}
            모임 만들기
          </button>
        </div>
      )}
    </motion.div>
  );
};

export default withAsyncBoundary(ExplorerGroupListSection, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
