import { DismissableInlineAlert } from '@daangn/sprout-components-inline-alert';

import { useFlow } from '@/stackflow';

import useGroupDetailGroupChallengeBannerClosed from '../../hooks/useGroupDetailGroupChallengeBannerClosed';
import * as s from './index.css';

type Props = {
  groupId: number;
};

const GroupDetailGroupChallengeBanner = ({ groupId }: Props) => {
  const { push } = useFlow();
  const [_, setGroupChallengeBannerClosed] = useGroupDetailGroupChallengeBannerClosed(
    groupId.toString()
  );

  const handleClose = () => {
    setGroupChallengeBannerClosed(true);
  };
  const handleClick = (event) => {
    const contentDiv = event.currentTarget.querySelector('[data-part="content"]');
    if (!contentDiv || !contentDiv.contains(event.target)) return;

    setGroupChallengeBannerClosed(true);
    push('ChallengeListPage', {
      groupId: groupId.toString(),
    });
  };

  return (
    <div onClick={handleClick} className={s.Banner}>
      <DismissableInlineAlert
        variant="info"
        title="새기능"
        description="챌린지를 만들고, 모임 멤버들과 함께 목표를 달성해보세요."
        onClose={handleClose}
      />
    </div>
  );
};

export default GroupDetailGroupChallengeBanner;
