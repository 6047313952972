import { Dialog, useDialog } from '@community-group/components';

type Params = {
  onPostAnyway: () => void;
};

// 챌린지 인증 글 작성 > 완료 클릭시 사진이 포함되지 않은 경우 띄어주는 다이얼로그
export const useOpenDialogValidateFormChallengeCertify = () => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const openDialogValidateFormChallengeCertify = ({ onPostAnyway }: Params) => {
    openDialog({
      element: (
        <Dialog
          title="사진이 포함된 글을 작성해야 챌린지 인증이 완료돼요."
          description="챌린지 인증글을 남기려면 사진이 포함된 글을 작성해주세요."
          primaryActionLabel="수정하기"
          onPrimaryAction={closeDialog}
          secondaryActionLabel="이대로 올리기"
          onSecondaryAction={() => {
            closeDialog();
            onPostAnyway();
          }}
          secondaryActionIntent="nonpreferred"
        />
      ),
    });
  };

  return openDialogValidateFormChallengeCertify;
};
