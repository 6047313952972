import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';

export const useCheckRunningGrowthGroupStep = (groupId: string) => {
  const { runningGrowthData = [] } = useGetRunningGrowthMission(groupId);
  const currentStep = runningGrowthData?.find((data) => !data.isStepCompleted);

  //현재 진행중인 RunningGrowthStep 반환
  const checkCurrentRunningGrowthStep = () => {
    if (!currentStep) return undefined;

    //현재 스텝의 미션은 수행했으나 리워드 발송이 안된 경우
    if (!currentStep.isStepCompleted && currentStep.isStepSuccess) return undefined;

    const stepIndex = runningGrowthData?.indexOf(currentStep);

    //첫번째 스텝이라면 스텝 그대로 반환
    if (stepIndex === 0) {
      return currentStep.step;
    }

    //PM이 이전 스텝에 대해 확인 완료해야만 러닝그로스 미션 수행 가능
    if (runningGrowthData[stepIndex - 1]?.isStepCompleted) {
      return currentStep.step;
    }
  };

  const checkSelectedRunningGrowthStep = (selectedStep: number) => {
    const stepIndex = selectedStep - 1;
    const incompleteIndex = runningGrowthData.findIndex(
      (item, index) => index < stepIndex && !item.isStepCompleted
    );
    const runningGrowthItem = runningGrowthData?.find((data) => data.step === selectedStep);
    return {
      isStepSuccess: runningGrowthData[stepIndex]?.isStepSuccess,
      isStepCompleted: runningGrowthData[stepIndex]?.isStepCompleted,
      previousStepComplete: incompleteIndex === -1,
      isRewardSent: runningGrowthItem ? runningGrowthItem?.isStepCompleted : false,
    };
  };

  return { checkCurrentRunningGrowthStep, checkSelectedRunningGrowthStep };
};
