import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { OPEN_GRAPH_URL } from '../base/opengraph';
import { openGraphResponse } from '../mock/openGraph';
import { useFetchInstance } from './instance/useFetchInstance';

export const useGetOpenGraph = (url?: string) => {
  const fetchInstance = useFetchInstance();
  const getOpenGraph = async (url?: string) => {
    const { data } = await fetchInstance.get<
      { url: string },
      AxiosResponse<typeof openGraphResponse>
    >(OPEN_GRAPH_URL, {
      params: {
        url: url,
      },
    });

    return data;
  };

  const { data } = useSuspenseQuery({
    queryKey: [`${OPEN_GRAPH_URL}?url=${url}`],
    queryFn: () => getOpenGraph(url),

    retry: 1,
  });

  return { data: data as typeof openGraphResponse };
};
