import {
  Dialog,
  MultilineTextField,
  Spacing,
  Typography,
  useDialog,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useGetGroupBotFeatures } from '@/api/hooks/useGetGroupBotFeatures';
import { usePutGroupBotChatGreetingMessage } from '@/api/hooks/usePutGroupBotChatGreetingMessage';
import FormGuideCallout from '@/components/common/FormGuideCallout';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useConnectedFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { groupBotMessageScheme } from '@/utils/validate/formSchema/groupBot';

import * as s from './GroupSettingEditGroupBotPage.css';

type Params = Pick<PageParams, 'groupId' | 'calloutType'>;

const GroupSettingEditGroupBotPage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { calloutType } = useQueryParams();

  const { data: groupBotFeatures, refetch: refetchGroupBotFeatures } =
    useGetGroupBotFeatures(groupId);
  const currentUser = useCurrentGroupMe(groupId);

  const { pop } = useConnectedFlow();
  const { open: openDialog, close: closeDialog } = useDialog();

  const prevMessage = groupBotFeatures?.data?.groupChatGreetingMessage ?? '';
  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      message: prevMessage,
    },
  });

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: putGroupBotChatGreetingMessage } = usePutGroupBotChatGreetingMessage({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupBotFeatures();
      pop().send('submit');
    },
  });
  const updateGroupBotChatGreetingMessage = useCallback(
    async (message: string) => {
      await putGroupBotChatGreetingMessage({
        groupId,
        form: { message },
      });

      trackEvent({
        event: 'click_bot_message_update',
        params: {
          message,
          role: currentUser.role,
        },
      });
    },
    [currentUser.role, groupId, putGroupBotChatGreetingMessage]
  );

  const nextMessage = watch('message') ?? '';

  const checkMessageInvalid = useCallback((message) => {
    const scheme = groupBotMessageScheme.safeParse(message);
    return !scheme.success;
  }, []);

  const submitDisabled = useMemo(() => {
    if (prevMessage === nextMessage) return true;
    return checkMessageInvalid(nextMessage);
  }, [prevMessage, nextMessage, checkMessageInvalid]);

  const handleBackClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (submitDisabled) {
      pop().send('back');
      return;
    }

    openDialog({
      element: (
        <Dialog
          title="변경한 내용을 저장할까요?"
          description="이 화면을 나가면 변경한 내용이 사라져요."
          primaryActionLabel="저장"
          onPrimaryAction={async () => {
            await closeDialog();
            updateGroupBotChatGreetingMessage(nextMessage);
          }}
          secondaryActionLabel="취소"
          onSecondaryAction={async () => {
            await closeDialog();
            pop().send('back');
          }}
        />
      ),
    });
  };

  const handleSubmitClick = handleSubmit(async (data) => {
    if (checkMessageInvalid(data.message)) return;
    updateGroupBotChatGreetingMessage(data.message);
  });

  return (
    <AppScreen
      appBar={{
        title: '전체 채팅방 환영 메시지',
        backButton: {
          onClick: handleBackClick,
        },
        renderRight: () => (
          <button onClick={handleSubmitClick} className={s.SubmitButton} disabled={submitDisabled}>
            <Typography typography="label2Regular" color={submitDisabled ? 'gray400' : 'gray900'}>
              완료
            </Typography>
          </button>
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <div className={s.Wrapper}>
        <MultilineTextField
          value={watch('message') ?? ''}
          onChange={(textValue) => setValue('message', textValue)}
          placeholder="내용을 입력해주세요."
          maxLength={300}
          config={{ invalidAfterBlurred: false }}
        />
        <Spacing size={24} />
        <FormGuideCallout calloutType={calloutType} />
        <Typography typography="caption1Regular" color="gray600">
          * 환영 메시지는 새로 들어온 멤버만 볼 수 있어요.
        </Typography>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditGroupBotPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
