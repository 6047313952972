import { postUploadImageV2 } from '@community-group/api';
import {
  isMember,
  StickyInput,
  StickyInputFormData,
  useStickInputStore,
} from '@community-group/components';
import { forwardRef, memo, Ref, useMemo } from 'react';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useBridge } from '@/contexts/Bridge';
import { useMembersForMentions } from '@/hooks/useMembersForMentions';
import { useFlow } from '@/stackflow';

import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '../../JoinGroupState/hooks/useHandleGroupOnly';

type CoreProps = {
  groupId: string;
  onSubmit: (
    formData: StickyInputFormData,
    callbackHandler: {
      onSuccess: () => void;
      onError?: () => void;
    }
  ) => void;
  placeholder?: string;
};

const CommentFormAccessoryBarCore = forwardRef(
  ({ groupId, onSubmit, placeholder }: CoreProps, ref: Ref<HTMLDivElement>) => {
    const { group } = useReadGroupDetail(groupId);

    const { data: groupMe } = useGetGroupMe(groupId);

    const currentUserRole = groupMe?.currentUser.role;
    const { handleGroupOnly } = useHandleGroupOnly({ groupId });

    const { stickyInputProps } = useStickInputStore();
    const isEditMode = useMemo(() => stickyInputProps?.type === 'edit', [stickyInputProps?.type]);

    const { push } = useFlow();
    const { bridge } = useBridge();
    const fetchCommunityInstance = useFetchInstance();
    const membersForMentions = useMembersForMentions(groupId as string);

    const handleStopPropagation = (event) => {
      if (isMember(currentUserRole)) return;

      event.stopPropagation();
    };

    const handleClickCapture = (event) => {
      if (isMember(currentUserRole)) return;

      event.stopPropagation();
      handleGroupOnly({
        blockGroupOnlyTitle: '모임 가입 안내',
        blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getComment,
        onSuccess() {
          if (group?.subNicknameSetting.isSettingOn) {
            push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
              groupId,
            });
          }
        },
      });
    };

    return (
      <div
        ref={ref}
        onTouchStartCapture={handleStopPropagation}
        onMouseDownCapture={handleStopPropagation}
        onClickCapture={handleClickCapture}
      >
        <StickyInput
          fixed={false}
          type={isEditMode ? 'edit' : 'new'}
          initialValues={isEditMode ? stickyInputProps?.initialValues : undefined}
          mentionConfig={[
            {
              trigger: '@',
              name: 'mentionedUserIds',
              mentionList: membersForMentions ?? [],
            },
          ]}
          onSubmit={onSubmit}
          placeholder={placeholder ?? '댓글을 입력해주세요.'}
          plugins={{
            bridge,
            postUploadImageV2: (imageFile) =>
              postUploadImageV2({ imageFile, fetchCommunityInstance }),
          }}
        />
      </div>
    );
  }
);

export default memo(CommentFormAccessoryBarCore);
