import { Switch } from '@community-group/components';
import { IconPostLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { GroupMeetupFormListItem, GroupMeetupFormListItemProps } from '../ListItem';

interface Props<T extends object> extends Pick<GroupMeetupFormListItemProps, 'children'> {
  name: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const GroupMeetupFormPublishedField = <T extends object>({
  name,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  return (
    <GroupMeetupFormListItem
      style={{
        padding: '10px 0',
      }}
      {...props}
      fieldIcon={<IconPostLine size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="동네생활에 공유"
      fieldName={name}
      fieldValue={
        <Switch
          isSelected={watch(name)}
          onChange={() => {
            setValue(name, !watch(name));
          }}
        />
      }
    />
  );
};
