import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { ColorValue } from '@community-group/components';
import { differenceInDays, format, isAfter } from 'date-fns';

import { getDateDiffFromFuture, getDateDiffFromNow } from '../ChallengeDetail/utils/date';

type ReturnType = {
  text: string;
  color: ColorValue;
  durationText: string;
};

export const getChallengeDateInfo = ({
  startedAt,
  endedAt,
  status,
  totalDays,
}: Pick<
  ChallengeDetailPresentation,
  'startedAt' | 'endedAt' | 'status' | 'totalDays'
>): ReturnType => {
  const startedAtDate = new Date(startedAt);
  const endedAtDate = new Date(endedAt);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (status === 'closed') {
    return {
      text: '종료',
      color: 'gray600',
      durationText: `${format(startedAtDate, 'M월 dd일')} ~ ${format(
        endedAtDate,
        'M월 dd일'
      )} 진행`,
    };
  }

  if (status === 'prepared') {
    const { text } = getDateDiffFromFuture(startedAtDate);

    const diffDate = Math.max(0, differenceInDays(startedAtDate, today));

    return {
      durationText: `${text} 후 시작`,
      text: `D-${diffDate}`,
      color: 'yellow400',
    };
  }

  const { text } = getDateDiffFromNow(startedAtDate);
  return {
    durationText: `진행 ${text}차`, //진행 11일차
    text: `진행 ${text}차`, //진행 11일차
    color: 'primary',
  };
};
