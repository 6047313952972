import { vars } from '@seed-design/design-token';
import { MouseEventHandler } from 'react';

import ChevronDown from '@/components/common/Icons/chevronDown';

import * as s from './FilterChipButton.css';

type Props = {
  label: string;
  selectedValue?: string;
  onClick: MouseEventHandler;
};

const FilterChipButton = ({ label, selectedValue, onClick }: Props) => {
  const active = Boolean(selectedValue);

  return (
    <button className={s.ChipButtonRecipe({ active: active })} onClick={onClick}>
      {selectedValue ?? label}
      <div className={s.IconWrapper}>
        <ChevronDown strokeColor={active ? vars.$scale.color.gray00 : vars.$scale.color.gray900} />
      </div>
    </button>
  );
};

export default FilterChipButton;
