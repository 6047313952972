import { groupClient } from '@community-group/api';
import {
  BoardCategoryResponse,
  BoardManagedResponse,
  PostDetail,
} from '@community-group/api/lib/group/models';

export const postArticleRequest = {
  content: '' as string,
  images: [] as string[],
  isGroupOnly: false,
};

export const postArticleResponse = {
  post: {
    id: 0,
    content: 'string',
    emotion: {
      count: 0,
      myEmotion: '' as string | undefined,
    },
    boardCategories: [],
    commentCount: 0,
    images: [],
    author: {
      id: 0,
      role: groupClient.model.GroupMemberRoleEnum.superHost,
      state: 'member',
      temperature: 0,
      userRegion: {
        id: 0,
        name: 'string',
        province: 'string',
        city: 'string',
        town: 'string',
      },
      nickname: 'string',
      profileImage: 'https://picsum.photos/seed/picsum/500/600?random=1',
      description: 'string',
      isAccountDeleted: false,
      memberKey: 'string',
      createdAt: '2022-08-25T07:42:13.605Z',
      updatedAt: '2022-08-25T07:42:13.605Z',
      deletedAt: '2022-08-25T07:42:13.605Z',
    },
    postType: {
      type: 'meetupReview',
      meetupId: 0,
      challengeId: 0,
    },
    publishType: 'public',
    isUpdated: true,
    noticedAt: '2022-08-25T07:42:13.605Z',
    isNoticed: false,
    isPublished: false,
    createdAt: '2022-08-25T07:42:13.605Z',
    updatedAt: '2022-08-25T07:42:13.605Z',
    deletedAt: '2022-08-25T07:42:13.605Z',
    pois: undefined,
    poll: {
      id: '1',
      title: 'Sample Poll',
      options: [
        {
          id: 'option1',
          title: 'Option 1',
          voteCount: 100,
        },
        {
          id: 'option2',
          title: 'Option 2',
          voteCount: 150,
        },
      ],
      isMultiple: false,
      totalVoteCount: 250,
      totalVoterCount: 200,
      voteEndDate: '2023-06-30',
    },
    myPollVoting: { optionIds: ['option1', 'option2'] },
  } as unknown as PostDetail,
};

export const boardManagedStatusResponse = {
  isBoardManaged: true,
} as BoardManagedResponse;

export const boardCategoiresResponse: BoardCategoryResponse = {
  boardCategories: [
    {
      type: 'default',
      category: '자유 게시판',
      id: 1,
    },
    {
      type: 'custom',
      category: '일정 후기',
      id: 2,
    },
  ],
};
