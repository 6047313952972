import {
  AccessStatusServiceEnum,
  GroupGrowthEnum,
  JoinableRegionRangeEnum,
  PostTypePresentationType,
} from '@community-group/api/lib/group/models';
import { ContentType } from '@community-group/api/lib/group/models/content-type';
import { useActivityParams } from '@stackflow/react';
import { $Values } from 'utility-types';

import { CertificationCandidateType } from '@/components/group/new/Verification/hooks/useHandleVerificationBottomSheet';
import { FROM_QUERY_PARAMS_TYPE } from '@/constants/path';
import { ReferParams } from '@/utils/analytics/type';

/*
 * 서비스에서 사용하는 QueryParams를 정의합니다.
 */

export interface ActivityQueryParams {
  from?: string | FROM_QUERY_PARAMS_TYPE | GROUP_DETAIL_FROM | MEETUP_DETAIL_FROM;
  activeHomeTab?: $Values<typeof HOME_ACTIVE_TAB>;
  referrer?: 'community_group.client';
  queryId?: string;
  categoryId?: string;
  meetupId?: string;
  postId?: string;
  instantJoinMeetupId?: string;
  requestMeetupReviewId?: string;
  activeTab?: GROUP_DETAIL_ACTIVE_TAB;
  keyboardUp?: string;
  joinPrivateRoomId?: string;
  isPrivate?: string;
  joinType?: string;
  question?: string;
  requiredQuestion?: string;
  haveQuestion?: string;
  scrollTo?: string;
  tab?: 'joint' | 'pending';
  name?: string;
  maximumMemberCount?: string;
  minAgeTag?: string;
  maxAgeTag?: string;
  postType?: PostTypePresentationType;
  title?: string;
  content?: string;
  answer?: string;
  description?: string;
  isNew?: string;
  fromTalkRoomId?: string;
  initialJoinType?: 'approval' | 'free';
  initialPublishPost?: 'true' | 'false';
  initialQuestionValue?: string;
  initialQuestionRequired?: 'true' | 'false';
  initialMaximumMemberCount?: string;
  currentMemberCount?: string;
  initialGroupName?: string;
  initialGroupDescription?: string;
  initialGroupMinAge?: string;
  initialGroupMaxAge?: string;
  joinPublicRoomId?: string;
  profileImageId?: string;
  joinableRegionRange?: JoinableRegionRangeEnum;
  regionId?: string;
  pollOptionValueJSONString?: string;
  meetupCreateForm?: string;
  meetupModifyForm?: string;
  backgroundImageId?: string;
  groupCategory?: string;
  isOnboarding?: string;
  //포인트 그로스 이벤트 모임 생성용 params
  createType?: string; // CREATE_PRE_OPEN_GROUP_FROM_PARAMS value의 유무로 판단
  groupCreateForm?: string;
  pointEventRegion?: string;
  groupJoined?: string;
  settedProfileImage?: string;
  keyword?: string;
  boardCategoryKey?: string;
  cloneGroupMeetupFormData?: string;
  poiName?: string;
  showEntryPointGuide?: string;
  meetupCalendarDay?: string;
  block1on1Chat?: 'true' | 'false';
  postIndex?: string;
  targetPermission?: string;
  groupId?: string;
  userId?: string;
  initialBoardCategoryName?: string;
  scrollToBottom?: 'true' | 'false';
  hasSubNickname?: 'true' | 'false';
  subNicknameQuestion?: string;
  needVerification?: 'true' | 'false';
  certificationCandidateType: CertificationCandidateType;
  defaultValue?: string;
  canViewVerifiedUserInfo?: 'true' | 'false';
  isNewGroup?: 'true' | 'false';

  calloutType?:
    | 'self-introduction'
    | 'first-post-mission'
    | 'member-self-introduction'
    | 'member-profile-set-mission'
    | 'popular-group-description'
    | 'running-growth-mission1-set-group-description'
    | 'running-growth-mission2-set-notice'
    | 'running-growth-mission3-set-running-course'
    | 'running-growth-mission4-set-first-meetup'
    | 'running-growth-mission5-set-3-meetup'
    | 'group-bot-greeting-message'
    | 'member-grade-setting';

  applicationStatus?: 'not_applied' | 'cancelled' | 'applied' | 'accepted' | 'rejected';

  searchCurrentTab?: 'all' | 'group' | 'meetup' | 'onlyGroup';
  openSidebar?: 'true' | 'false';
  userName?: string;

  promotion_type?: 'pay_promotion' | string;
  promotion_code?: string;

  step?: string;

  optionId?: string; // 모임 일정 후기 작성시 선택한 옵션 아이디

  groupIdForChangeVerification?: string;

  initialPollOptionValueJSONString?: string;
  refer_root?: ReferParams['refer_root'];
  refer_section?: ReferParams['refer_section'];
  refer_content?: ReferParams['refer_content'];
  refer_content_index?: ReferParams['refer_content_index'];
  refer_action?: ReferParams['refer_action'];
  utm_medium?: string;
  utm_campaign?: string; //그로스 캠피언용 utm

  serviceType?: AccessStatusServiceEnum;
  accessStatusOption?: 'restricted' | 'not_found';

  contentType?: ContentType;
  contentId?: string;

  // 카테고리/키워드 마이그레이션 후 카테고리 설정 플로우 진입시 사용
  open?: string;

  // 비슷한 모임 추천 클릭시 이전에 보던 모임을 제외하고 보여주기 위한 파라미터
  prevViewedGroupId?: string;

  joinGrowth?: GroupGrowthEnum;
  // 전국 운동 그로스 모임인지, 어떤 운동인지 params를 받아 생성시 전달하기 위한 파라미터
  joinExerciseGrowth?: string;

  // SNS 태그 세팅할때 사용하는 파라미터
  snsSetBottomSheetType?: 'new' | 'edit';
  snsSetBottomInitialToggle?: 'facebook' | 'instagram' | 'x';

  // 댓글 상세에서 사용하는 파라미터
  commentCount?: string;
  groupName?: string;
  disabledCommentInput?: 'true' | 'false';
  directChatType?: 'profile_direct_chat' | 'profile_pay_chat';

  // 게시글 공유용
  permalinkId?: string;
  // 이미지 앨범 뷰어 index
  initialIndex?: string;
  imageUrls?: string;
  photoContestEntryId?: string;

  version?: string;

  videoId?: string; //video POC
  videoIds?: string; //video POC
  imageIds?: string; //video POC

  isEqualCurrentCommentAuthor?: 'true' | 'false';
  hasEmotionCount?: 'true' | 'false';
  hasPermission?: 'true' | 'false';
  primaryLabel?: string;
  secondaryLabel?: string;
  openActivityPoint?: 'true' | 'false'; // 모임 레벨 상세 페이지에서 활동점수 섹션 열기 default 여부
  level?: string;
  type?: string;

  baseDate?: string; // 리더보드 기준 날짜
  createGroupFlowType?: 'normal' | 'recommend'; // 생성 단축 실험 타입
  isCurrentWeek?: 'true' | 'false'; // 리더보드 현재 주 여부

  kidBirthday?: string;
  kidGender?: 'male' | 'female';
  createKidProfileFlowType?: 'withSubNickname' | 'onlyKidProfile' | 'fromChatRoom';

  challengeId?: string;
  regionRange?: 'MY' | 'RANGE_2' | 'RANGE_3' | 'UNLIMITED';
  ageRange?: '20' | '30' | '40' | '50' | '60';
}

export const useQueryParams = (): ActivityQueryParams => {
  return useActivityParams<ActivityQueryParams>();
};

export const HOME_ACTIVE_TAB = {
  MT_GROUP_TAB: 'myGroupTab',
  EXPLORER_GROUP_TAB: 'explorerGroupTab',
  MEETUP_TAB: 'meetupTab',
} as const;

type GROUP_DETAIL_FROM =
  | 'CHANGE_ROLE_TO_HOST_NOTIFICATION'
  | 'CHANGE_MEETUP_V2_TO_GROUP_NOTIFICATION'
  | 'COMPLETE_MEETUP_V2_TO_GROUP';

export type GROUP_DETAIL_ACTIVE_TAB = 'home' | 'feed' | 'meetups' | 'photos' | 'talks';

type MEETUP_DETAIL_FROM = 'meetup_member';
