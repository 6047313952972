import {
  GroupFeedListResponse,
  MyGroupPostListResponse,
} from '@community-group/api/lib/group/models';

import { postArticleResponse } from './post';

export const groupFeedResponse = {
  posts: [
    {
      ...postArticleResponse.post,
    },
  ],
  endCursor: 'string',
  hasNext: true,
};

export const myGroupFeedResponse: MyGroupPostListResponse = {
  posts: [
    {
      id: 6885,
      content: '11111gwregrewgwrgrwgeregr',
      publishType: 'public',
      emotion: {
        count: 0,
      },
      commentCount: 0,
      medias: [],
      images: [],
      author: {
        id: 5964,
        role: 'superHost',
        state: 'member',
        temperature: 36.5,
        userRegion: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        isAccountDeleted: false,
        memberKey: '3773-5964',
        createdAt: '2024-04-09T10:45:25.165727Z',
        updatedAt: '2024-04-09T10:46:13.08418Z',
        verified: false,
        userRegionCheckInCount: 1,

        description: '',
      },
      isUpdated: false,
      isNoticed: false,
      isPublished: false,
      createdAt: '2024-11-19T08:40:35.692315Z',
      updatedAt: '2024-11-19T08:40:35.692315Z',
      boardCategories: [],
      readCount: 1,
      permalinkId: 'xjKexVP6',
      isSettingOnSubNickname: false,
      isPhotoContestEntry: false,
      pois: [],
      postType: {
        type: 'challenge',
        meetupId: 0,
        challengeId: 36,
      },
      challengeInfo: {
        name: '챌린지 매일매일 인증하자',
        id: 36,
        totalDays: 28,
        participantsCount: 10,
        status: 'opened',
        endedAt: '2024-12-10T00:00:00.000000Z',
        startedAt: '2024-11-10T00:00:00.000000Z',
        certifyIntervalTargetCount: 1,
        publicId: '123',
      },
      deletedAt: undefined,
      groupInfo: {
        id: 3773,
        name: "avfvevreber;lmbreb'wembp",
        profileImage: {
          id: 'kr-7141#1522473446226821120',
          small:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          medium:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          large:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          url: 'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          width: 192,
          height: 192,
        },
        isBoardManaged: false,
        isSettingOnSubNickname: false,
        joinType: 'free',
        region: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        needVerification: false,
        joinApprovalRequireReason: 'none',
        approvalConditions: 'none',
        approvalQuestion: '',
        category: {
          id: 1,
          name: '운동',
        },
        age: {
          tags: [
            {
              type: 'GROUP',
              min: 30,
              max: 39,
              text: '30~39세',
            },
          ],
        },
        ageRangeTag: {
          minAge: 30,
          maxAge: 39,
        },
      },
      currentUserInfo: {
        id: 5964,
        role: 'superHost',
        userState: 'member',
        regionInfo: {
          userRegion: {
            id: 366,
            name: '서초4동',
            province: '서울특별시',
            city: '서초구',
            town: '서초4동',
          },
          isGroupJoinableRegion: true,
          isCertificateRegion: true,
        },
      },
    },
    {
      id: 6885,
      content: 'gwregrewgwrgrwgeregr',
      publishType: 'public',
      emotion: {
        count: 0,
      },
      commentCount: 0,
      medias: [],
      images: [],
      author: {
        id: 5964,
        role: 'superHost',
        state: 'member',
        temperature: 36.5,
        userRegion: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        isAccountDeleted: false,
        memberKey: '3773-5964',
        createdAt: '2024-04-09T10:45:25.165727Z',
        updatedAt: '2024-04-09T10:46:13.08418Z',
        verified: false,
        userRegionCheckInCount: 1,

        description: '',
      },
      isUpdated: false,
      isNoticed: false,
      isPublished: false,
      createdAt: '2024-11-19T08:40:35.692315Z',
      updatedAt: '2024-11-19T08:40:35.692315Z',
      boardCategories: [],
      readCount: 1,
      permalinkId: 'xjKexVP6',
      isSettingOnSubNickname: false,
      isPhotoContestEntry: false,
      pois: [],
      postType: {
        type: 'challenge',
        meetupId: 0,
        challengeId: 36,
      },
      challengeInfo: {
        name: '챌린지 매일매일 인증하자',
        id: 36,
        totalDays: 28,
        participantsCount: 10,
        status: 'closed',
        endedAt: '2024-12-10T00:00:00.000000Z',
        startedAt: '2024-11-10T00:00:00.000000Z',
        certifyIntervalTargetCount: 1,
        publicId: '123',
      },
      deletedAt: undefined,
      groupInfo: {
        id: 3773,
        name: "avfvevreber;lmbreb'wembp",
        profileImage: {
          id: 'kr-7141#1522473446226821120',
          small:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          medium:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          large:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          url: 'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          width: 192,
          height: 192,
        },
        isBoardManaged: false,
        isSettingOnSubNickname: false,
        joinType: 'free',
        region: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        needVerification: false,
        joinApprovalRequireReason: 'none',
        approvalConditions: 'none',
        approvalQuestion: '',
        category: {
          id: 1,
          name: '운동',
        },
        age: {
          tags: [
            {
              type: 'GROUP',
              min: 30,
              max: 39,
              text: '30~39세',
            },
          ],
        },
        ageRangeTag: {
          minAge: 30,
          maxAge: 39,
        },
      },
      currentUserInfo: {
        id: 5964,
        role: 'superHost',
        userState: 'member',
        regionInfo: {
          userRegion: {
            id: 366,
            name: '서초4동',
            province: '서울특별시',
            city: '서초구',
            town: '서초4동',
          },
          isGroupJoinableRegion: true,
          isCertificateRegion: true,
        },
      },
    },
    {
      id: 6885,
      content: 'gwregrewgwrgrwgeregr',
      publishType: 'public',
      emotion: {
        count: 0,
      },
      commentCount: 0,
      medias: [],
      images: [],
      author: {
        id: 5964,
        role: 'superHost',
        state: 'member',
        temperature: 36.5,
        userRegion: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        isAccountDeleted: false,
        memberKey: '3773-5964',
        createdAt: '2024-04-09T10:45:25.165727Z',
        updatedAt: '2024-04-09T10:46:13.08418Z',
        verified: false,
        userRegionCheckInCount: 1,

        description: '',
      },
      isUpdated: false,
      isNoticed: false,
      isPublished: false,
      createdAt: '2024-11-19T08:40:35.692315Z',
      updatedAt: '2024-11-19T08:40:35.692315Z',
      boardCategories: [],
      readCount: 1,
      permalinkId: 'xjKexVP6',
      isSettingOnSubNickname: false,
      isPhotoContestEntry: false,
      pois: [],
      postType: {
        type: 'challenge',
        meetupId: 0,
        challengeId: 36,
      },
      challengeInfo: {
        name: '챌린지 매일매일 인증하자',
        id: 36,
        totalDays: 28,
        participantsCount: 10,
        status: 'prepared',
        endedAt: '2024-12-10T00:00:00.000000Z',
        startedAt: '2024-11-10T00:00:00.000000Z',
        certifyIntervalTargetCount: 1,
        publicId: '123',
      },
      deletedAt: undefined,
      groupInfo: {
        id: 3773,
        name: "avfvevreber;lmbreb'wembp",
        profileImage: {
          id: 'kr-7141#1522473446226821120',
          small:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          medium:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          large:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          url: 'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          width: 192,
          height: 192,
        },
        isBoardManaged: false,
        isSettingOnSubNickname: false,
        joinType: 'free',
        region: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        needVerification: false,
        joinApprovalRequireReason: 'none',
        approvalConditions: 'none',
        approvalQuestion: '',
        category: {
          id: 1,
          name: '운동',
        },
        age: {
          tags: [
            {
              type: 'GROUP',
              min: 30,
              max: 39,
              text: '30~39세',
            },
          ],
        },
        ageRangeTag: {
          minAge: 30,
          maxAge: 39,
        },
      },
      currentUserInfo: {
        id: 5964,
        role: 'superHost',
        userState: 'member',
        regionInfo: {
          userRegion: {
            id: 366,
            name: '서초4동',
            province: '서울특별시',
            city: '서초구',
            town: '서초4동',
          },
          isGroupJoinableRegion: true,
          isCertificateRegion: true,
        },
      },
    },
    {
      id: 6885,
      content: 'gwregrewgwrgrwgeregr',
      publishType: 'public',
      emotion: {
        count: 0,
      },
      commentCount: 0,
      medias: [],
      images: [],
      author: {
        id: 5964,
        role: 'superHost',
        state: 'member',
        temperature: 36.5,
        userRegion: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        isAccountDeleted: false,
        memberKey: '3773-5964',
        createdAt: '2024-04-09T10:45:25.165727Z',
        updatedAt: '2024-04-09T10:46:13.08418Z',
        verified: false,
        userRegionCheckInCount: 1,

        description: '',
      },
      isUpdated: false,
      isNoticed: false,
      isPublished: false,
      createdAt: '2024-11-19T08:40:35.692315Z',
      updatedAt: '2024-11-19T08:40:35.692315Z',
      boardCategories: [],
      readCount: 1,
      permalinkId: 'xjKexVP6',
      isSettingOnSubNickname: false,
      isPhotoContestEntry: false,
      pois: [],
      postType: {
        type: 'challengeCertify',
        meetupId: 36,
        challengeId: 36,
      },
      challengeInfo: {
        name: '챌린지 매일매일 인증하자',
        id: 36,
        totalDays: 28,
        participantsCount: 10,
        status: 'opened',
        endedAt: '2024-12-10T00:00:00.000000Z',
        startedAt: '2024-11-10T00:00:00.000000Z',
        certifyIntervalTargetCount: 1,
        publicId: '123',
      },
      deletedAt: undefined,
      groupInfo: {
        id: 3773,
        name: "avfvevreber;lmbreb'wembp",
        profileImage: {
          id: 'kr-7141#1522473446226821120',
          small:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          medium:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          large:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          url: 'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          width: 192,
          height: 192,
        },
        isBoardManaged: false,
        isSettingOnSubNickname: false,
        joinType: 'free',
        region: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        needVerification: false,
        joinApprovalRequireReason: 'none',
        approvalConditions: 'none',
        approvalQuestion: '',
        category: {
          id: 1,
          name: '운동',
        },
        age: {
          tags: [
            {
              type: 'GROUP',
              min: 30,
              max: 39,
              text: '30~39세',
            },
          ],
        },
        ageRangeTag: {
          minAge: 30,
          maxAge: 39,
        },
      },
      currentUserInfo: {
        id: 5964,
        role: 'superHost',
        userState: 'member',
        regionInfo: {
          userRegion: {
            id: 366,
            name: '서초4동',
            province: '서울특별시',
            city: '서초구',
            town: '서초4동',
          },
          isGroupJoinableRegion: true,
          isCertificateRegion: true,
        },
      },
    },
    {
      id: 6884,
      content: 'gwregwergrgrwwgr',
      publishType: 'public',
      emotion: {
        count: 0,
      },
      commentCount: 0,
      medias: [],
      images: [],
      author: {
        id: 5964,
        role: 'superHost',
        state: 'member',
        temperature: 36.5,
        userRegion: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        isAccountDeleted: false,
        memberKey: '3773-5964',
        createdAt: '2024-04-09T10:45:25.165727Z',
        updatedAt: '2024-04-09T10:46:13.08418Z',
        verified: false,
        userRegionCheckInCount: 1,

        description: '',
      },
      isUpdated: false,
      isNoticed: false,
      isPublished: false,
      createdAt: '2024-11-19T08:40:13.926113Z',
      updatedAt: '2024-11-19T08:40:13.926113Z',
      boardCategories: [],
      readCount: 1,
      permalinkId: 'mAq0yEjE',
      isSettingOnSubNickname: false,
      isPhotoContestEntry: false,
      pois: [],
      postType: {
        type: 'post',
        meetupId: 0,
      },
      deletedAt: undefined,
      groupInfo: {
        id: 3773,
        name: "avfvevreber;lmbreb'wembp",
        profileImage: {
          id: 'kr-7141#1522473446226821120',
          small:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          medium:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          large:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          url: 'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png',
          width: 192,
          height: 192,
        },
        isBoardManaged: false,
        isSettingOnSubNickname: false,
        joinType: 'free',
        region: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        needVerification: false,
        joinApprovalRequireReason: 'none',
        approvalConditions: 'none',
        approvalQuestion: '',
        category: {
          id: 1,
          name: '운동',
        },
        age: {
          tags: [
            {
              type: 'GROUP',
              min: 30,
              max: 39,
              text: '30~39세',
            },
          ],
        },
        ageRangeTag: {
          minAge: 30,
          maxAge: 39,
        },
      },
      currentUserInfo: {
        id: 5964,
        role: 'superHost',
        userState: 'member',
        regionInfo: {
          userRegion: {
            id: 366,
            name: '서초4동',
            province: '서울특별시',
            city: '서초구',
            town: '서초4동',
          },
          isGroupJoinableRegion: true,
          isCertificateRegion: true,
        },
      },
    },
    {
      id: 6882,
      content: '어떻게 보이나요 동네생활에서는?',
      publishType: 'public',
      emotion: {
        count: 0,
      },
      commentCount: 0,
      medias: [],
      images: [],
      author: {
        id: 9651,
        role: 'superHost',
        state: 'member',
        temperature: 43.5,
        userRegion: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        nickname: '카야ios개인번호닉네임',
        profileImage:
          'https://d4zbl7j2h4ct2.cloudfront.net/origin/profile/202206/C6297454DF350F25E2DC65F33337BD8B4F613CC5CBF5E4D2A3F745B9E27EE00A.jpg?q=100&s=480x480&t=inside',
        isAccountDeleted: false,
        memberKey: '3298-9651',
        createdAt: '2023-12-14T02:44:38.354107Z',
        updatedAt: '2024-03-22T14:35:55.928257Z',
        verified: true,
        userRegionCheckInCount: 9,
        subNickname: '릴래래레리리레리리리리리리리리리',
        description: '안뇽',
      },
      isUpdated: false,
      isNoticed: false,
      isPublished: true,
      createdAt: '2024-11-19T06:13:56.391408Z',
      updatedAt: '2024-11-19T06:13:56.391408Z',
      boardCategories: [
        {
          id: 6147,
          type: 'default',
          category: '자유 게시판',
        },
      ],
      readCount: 2,
      permalinkId: 'xjvGyYA7',
      isSettingOnSubNickname: true,
      isPhotoContestEntry: false,
      pois: [
        {
          id: 4459431,
          name: '장소소소',
          type: 'LOCAL_PROFILE',
          address: '서울특별시 서초구 강남대로2길 3(양재동)',
          jibunAddress: '서울특별시 서초구 양재동 353-5',
          coordinates: {
            latitude: 37.503519372,
            longitude: 127.024049637,
          },
          reviewPostCount: 0,
        },
      ],
      postType: {
        type: 'post',
        meetupId: 0,
      },

      publishedAt: '2024-11-19T06:13:56.391408Z',
      deletedAt: undefined,
      groupInfo: {
        id: 3298,
        name: '\uD83D\uDC97\uD83D\uDC97GE작업 확인용 모임 입니다욜\uD83D\uDC97\uD83D\uDC97',
        profileImage: {
          id: 'kr-community#1760936323578859520',
          small:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20240223/ce9bd421-3269-4514-9329-eb6b18f9bae4.png',
          medium:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20240223/ce9bd421-3269-4514-9329-eb6b18f9bae4.png',
          large:
            'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20240223/ce9bd421-3269-4514-9329-eb6b18f9bae4.png',
          url: 'https://d4zbl7j2h4ct2.cloudfront.net/community/community/20240223/ce9bd421-3269-4514-9329-eb6b18f9bae4.png',
          width: 800,
          height: 799,
        },
        isBoardManaged: true,
        isSettingOnSubNickname: true,
        joinType: 'free',
        region: {
          id: 366,
          name: '서초4동',
          province: '서울특별시',
          city: '서초구',
          town: '서초4동',
        },
        needVerification: true,
        joinApprovalRequireReason: 'none',
        approvalConditions: 'none',
        approvalQuestion: '',
        category: {
          id: 11,
          name: '문화/예술',
        },
      },
      currentUserInfo: {
        id: 5964,
        role: 'member',
        userState: 'member',
        regionInfo: {
          userRegion: {
            id: 366,
            name: '서초4동',
            province: '서울특별시',
            city: '서초구',
            town: '서초4동',
          },
          isGroupJoinableRegion: true,
          isCertificateRegion: true,
        },
      },
    },
  ],
  hasNext: true,
  endCursor: '6885',
};

export const feedResponse: GroupFeedListResponse = {
  items: [
    {
      id: 6886,
      type: 'challenge',
      content: '-----12313123',
      publishType: 'public',
      boardCategories: [
        {
          id: 6671,
          type: 'default',
          category: '자유 게시판',
        },
      ],
      emotion: {
        count: 0,
        myEmotion: undefined,
      },
      commentCount: 0,
      readCount: 1,
      images: [],
      medias: [],
      places: [],
      author: {
        id: 5964,
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        role: 'superHost',
        state: 'member',
        isAccountDeleted: false,
        subNickname: undefined,
      },
      createdAt: '2024-11-19T10:44:03.177406Z',
      isNoticed: false,
      isPublished: false,
      permalinkId: 'Qda2yQj3',
      meetupInfo: undefined,
      challengeInfo: {
        id: 36,
        status: 'closed',
        name: '챌린지 이름',
        totalDays: 28,
        participantsCount: 10,
        endedAt: '2024-12-10T00:00:00.000000Z',
        startedAt: '2024-11-10T00:00:00.000000Z',
        certifyIntervalTargetCount: 1,
        publicId: '123',
      },
      poll: undefined,
    },
    {
      id: 6802,
      type: 'group_meetup',
      content: '241414142124124',
      publishType: 'public',
      boardCategories: [],
      emotion: {
        count: 0,
        myEmotion: undefined,
      },
      commentCount: 0,
      readCount: 1,
      images: [],
      medias: [],
      places: [],
      author: {
        id: 5964,
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        role: 'superHost',
        state: 'member',
        isAccountDeleted: false,
        subNickname: undefined,
      },
      createdAt: '2024-11-19T09:47:11.335666Z',
      isNoticed: false,
      isPublished: false,
      permalinkId: 'yPxEkBd0',
      meetupInfo: {
        id: 6802,
        progressStatus: {
          status: 'closed',
          description: '종료',
          color: '#868B94',
        },
        title: '22412141242',
        exposureRange: 'ALL',
        meetDate: '2024-11-19',
        currentParticipantsNumber: 1,
        maximumParticipantsNumber: 4,
        meetupType: 'default',
        imageUrl: undefined,
        meetTime: '2024-11-19T10:00:00Z',
        meetupTimeAtDisplayText: '이번 주 화요일, 오후 7:00',
      },
      challengeInfo: undefined,
      poll: undefined,
    },
    {
      id: 6885,
      type: 'challenge',
      content: 'gwregrewgwrgrwgeregr',
      publishType: 'public',
      boardCategories: [
        {
          id: 6671,
          type: 'default',
          category: '자유 게시판',
        },
      ],
      emotion: {
        count: 0,
        myEmotion: undefined,
      },
      commentCount: 0,
      readCount: 3,
      images: [],
      medias: [],
      places: [],
      author: {
        id: 5964,
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        role: 'superHost',
        state: 'member',
        isAccountDeleted: false,
        subNickname: undefined,
      },
      createdAt: '2024-11-19T08:40:35.692315Z',
      isNoticed: false,
      isPublished: false,
      permalinkId: 'xjKexVP6',
      meetupInfo: undefined,
      challengeInfo: {
        id: 36,
        status: 'closed',
        name: '챌린지 이름',
        totalDays: 28,
        participantsCount: 10,
        endedAt: '2024-12-10T00:00:00.000000Z',
        startedAt: '2024-11-10T00:00:00.000000Z',
        certifyIntervalTargetCount: 1,
        publicId: '123',
      },
      poll: undefined,
    },
    {
      id: 6884,
      type: 'post',
      content: 'gwregwergrgrwwgr',
      publishType: 'public',
      boardCategories: [
        {
          id: 6671,
          type: 'default',
          category: '자유 게시판',
        },
      ],
      emotion: {
        count: 0,
        myEmotion: undefined,
      },
      commentCount: 0,
      readCount: 1,
      images: [],
      medias: [],
      places: [],
      author: {
        id: 5964,
        nickname: '데입0002',
        profileImage: 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        role: 'superHost',
        state: 'member',
        isAccountDeleted: false,
        subNickname: undefined,
      },
      createdAt: '2024-11-19T08:40:13.926113Z',
      isNoticed: false,
      isPublished: false,
      permalinkId: 'mAq0yEjE',
      meetupInfo: undefined,
      challengeInfo: undefined,
      poll: undefined,
    },
  ],
  hasNext: false,
  endCursor: undefined,
};
