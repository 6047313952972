import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getMyGroupListPath = () =>
  groupClient.api.MyGroupsApi.getapiV1GroupsSummaryMyGroupsGetPath();

export const useGetMyGroupList = () => {
  const fetchInstance = useFetchInstance();

  const getMyGroupList = groupClient.api.MyGroupsApi.apiV1GroupsSummaryMyGroupsGet({
    axios: fetchInstance,
  });
  const { data, refetch } = useSuspenseQuery({
    queryKey: [getMyGroupListPath()],
    queryFn: () => getMyGroupList({ fetchInstance }),
  });

  return { data: data?.data, refetch };
};
