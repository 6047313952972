import { PostTypePresentationType } from '@community-group/api/lib/group/models';
import { z } from 'zod';

const images = z.array(z.string()).max(10, '이미지는 10개까지만 올릴 수 있어요.');

const medias = z
  .array(
    z.object({
      type: z.enum(['image', 'video']), // type 필드: "image" 또는 "video"만 허용
      id: z.string(), // id 필드: 문자열 타입, 필수
    })
  )
  .max(10, '첨부파일은 10개까지만 올릴 수 있어요.');

const postType = z
  .object({
    type: z.enum<PostTypePresentationType, any>(['meetupReview', 'post', 'challengeCertify']),
  })
  .optional();

export const postFormSchema = z.object({
  images,
  medias,
  postType,
});
