import {
  AsyncBoundary,
  HelpBubbleTrigger,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { IconQuestionmarkCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useRef, useState } from 'react';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupMeetupAttendance } from '@/utils/refetch/groupMeetupAttendance';

import MeetupDetailHeader from '../../Review/components/common/MeetupDetailHeader/MeetupDetailHeader';
import { GroupMeetupAttendanceCheckGuideTipSection } from '../components/Attendance/AttendanceCheckGuideTipSection';
import { GroupMeetupAttendanceMemberList } from '../components/Attendance/AttendanceMemberList';
import { GroupMeetupAttendanceQRCodeSection } from '../components/Attendance/QRCodeSection';

const GUIDE_TIP_ELEMENT_ID = 'group-meetup-attendance-guide-tip';

export type GroupMeetupDetailAttendancePageParams = Pick<PageParams, 'groupId' | 'meetupId'>;

const GroupMeetupDetailAttendancePage: ActivityComponentType<
  GroupMeetupDetailAttendancePageParams
> = () => {
  const { groupId, meetupId } = usePathParams();
  const [shownTooltip, setShownTooltip] = useState(false);
  const tooltipRef = useRef<NodeJS.Timeout | undefined>();

  const handleGuideTipClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (tooltipRef.current) {
      clearTimeout(tooltipRef.current);
    }
    setShownTooltip(true);
    tooltipRef.current = setTimeout(() => {
      setShownTooltip(false);
    }, 3000);

    const guideTipElement = document.getElementById(GUIDE_TIP_ELEMENT_ID);

    if (guideTipElement) {
      guideTipElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const handlePullToRefresh = () => {
    refetchGroupMeetupAttendance({ groupId, meetupId });
  };

  useEnterTrackEvent({
    event: 'enter_group_meetup_attendance_page',
    sample: true,
  });

  return (
    <AppScreen
      onPull={handlePullToRefresh}
      appBar={{
        overflow: 'visible',
        title: '출석 체크',
        renderRight: () => (
          <HelpBubbleTrigger
            isOpen={shownTooltip}
            positioning={{
              placement: 'bottom',
              offset: {
                mainAxis: 3,
              },
            }}
            title="하단 TIP을 참고해주세요"
          >
            <IconQuestionmarkCircleLine
              size={24}
              color={vars.$scale.color.gray900}
              onClick={handleGuideTipClick}
              style={{ marginRight: '0.5rem' }}
            />
          </HelpBubbleTrigger>
        ),
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupMeetupDetailAttendanceView />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupMeetupDetailAttendancePage;

const GroupMeetupDetailAttendanceView = () => {
  const { groupId, meetupId } = usePathParams();

  return (
    <>
      <MeetupDetailHeader />
      <GroupMeetupAttendanceQRCodeSection groupId={groupId} meetupId={meetupId} />
      <GroupMeetupAttendanceMemberList groupId={groupId} meetupId={meetupId} />
      <GroupMeetupAttendanceCheckGuideTipSection id={GUIDE_TIP_ELEMENT_ID} />
    </>
  );
};
