import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import GuidebookImage from '@/assets/images/thumbnail_guide_image.png';
import { trackEvent } from '@/utils/analytics';
import { openGuideBook } from '@/utils/link';

import * as s from './GuidebookBanner.css';

type Props = {
  referrer?: string;
};

const GuidebookBanner = ({ referrer }: Props) => {
  return (
    <div
      className={s.wrapper}
      onClick={() => {
        trackEvent({
          event: 'click_guide_book_page',
          params: {
            referrer,
          },
        });
        openGuideBook();
      }}
    >
      <div>
        <p className={s.title}>모임을 어떻게 시작할지 잘 모르겠나요? 가이드북을 확인해보세요!</p>
        <p className={s.linkButton}>
          자세히 보기
          <IconChevronRightFill size={16} color={vars.$scale.color.gray700} />
        </p>
      </div>
      <img src={GuidebookImage} />
    </div>
  );
};

export default GuidebookBanner;
