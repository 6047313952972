import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Request = { id: number; challengeId: number; form: model.ChallengeNotificationSettingRequest };

type Props = UseMutationOptions<AxiosResponse, Error, Request>;
const usePatchChallengeNotificationSetting = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const patchNotificationSetting =
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdNotificationSettingsMePatch(
      {
        axios: fetchInstance,
      }
    );

  const patchNotificationSettingWrapperFp = (req: Request) => {
    return patchNotificationSetting(req.id, req.challengeId, req.form);
  };

  return useThrottleMutation(patchNotificationSettingWrapperFp, {
    onError,
    onSuccess,
  });
};

export default usePatchChallengeNotificationSetting;
