import { model } from '@community-group/api/lib/group';
import {
  LastTalkInfoPresentation,
  TalkRoomCreateFromRoomType,
} from '@community-group/api/lib/group/models';
import { IconCalendarFill, IconLockFill } from '@daangn/react-monochrome-icon';
import { IconBellSlashFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { ReactNode, useMemo } from 'react';

import NewBadge from '@/components/common/Icons/newBadge';
import { getElementFromMention } from '@/components/common/Mention';
import DefaultTalkRoomProfileImages from '@/components/group/TalkRoom/DetailSetting/components/DefaultTalkRoomProfileImages';
import { getDateDiffFromNow } from '@/utils/date';
import { openLink } from '@/utils/link';

import * as s from './Item.css';

type Props = {
  imageSrc?: string;
  name?: string;
  description?: string;
  isNew?: boolean;
  roomId?: number;
  children?: ReactNode;
  memberCount?: number;
  lastTalkInfo?: LastTalkInfoPresentation;
  type: 'my' | 'all';
  admissionType: string;
  roomType: TalkRoomCreateFromRoomType;
  profileImages?: string[];
  chatInfo?: model.ChannelInfoPresentation;
  notificationState?: boolean;
};

const RoomItem = ({
  imageSrc,
  name,
  description,
  isNew,
  children,
  memberCount,
  lastTalkInfo,
  type,
  roomType,
  admissionType,
  chatInfo,
  profileImages = [],
  notificationState,
}: Props) => {
  const activityText = useMemo(() => {
    if (roomType !== 'initialization') return '';
    if (!lastTalkInfo) return '';

    const parsedDate = new Date(Date.parse(lastTalkInfo.createdAt));
    return `${getDateDiffFromNow(parsedDate).text} 전`;
  }, [lastTalkInfo, roomType]);

  const renderThumbnail = () => {
    return (
      <DefaultTalkRoomProfileImages
        size={24}
        borderSize={2}
        groupProfileImage={roomType === 'group_meetup' ? undefined : imageSrc}
        userProfileImages={profileImages}
      />
    );
  };

  return (
    <motion.div
      className={s.container}
      whileTap={{
        backgroundColor: vars.$scale.color.grayAlpha50,
      }}
      onClick={() => {
        if (type !== 'my') return;

        openLink(chatInfo?.targetUri);
      }}
    >
      <div className={s.wrapper}>
        <div className={s.leftBox}>{renderThumbnail()}</div>
        <div className={s.rightBox}>
          <div>
            <div className={s.rightTextTopBox}>
              {['private', 'PRIVATE'].includes(admissionType) && <IconLockFill size={16} />}
              {roomType === 'group_meetup' && <IconCalendarFill size={16} />}
              <p>{name}</p>
              {memberCount !== 0 && <span>{memberCount}</span>}
              {!notificationState && type === 'my' && (
                <IconBellSlashFill size={14} color={vars.$scale.color.gray600} />
              )}
            </div>
            <p className={s.rightTextBottom}>
              {lastTalkInfo?.message?.content
                ? getElementFromMention(lastTalkInfo.message.content ?? '', 'string')
                : description}
            </p>
          </div>

          <div className={s.myGroupRightBox}>
            {children ? children : <span>{activityText}</span>}
            {isNew && (
              <div className={s.newBadge}>
                <NewBadge />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default RoomItem;
