import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.BoardCategoryResponse>,
  Error,
  {
    id: number;
    postBoardCategoryModifyForm?: model.BoardCategoryModifyForm;
  }
>;

export const usePutBoardCategories = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putModify = groupClient.api.PostBoardApi.apiV1GroupsIdPostsBoardCategoriesPut({
    axios: fetchInstance,
  });

  const putModifyWrapperFp = ({
    id,
    postBoardCategoryModifyForm = { boardCategories: [] },
  }: {
    id: number;
    postBoardCategoryModifyForm?: model.BoardCategoryModifyForm;
  }) => {
    return putModify(id, postBoardCategoryModifyForm);
  };

  return useThrottleMutation(putModifyWrapperFp, {
    onError,
    onSuccess,
  });
};
