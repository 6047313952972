import { $Values } from 'utility-types';

import { getMyGroupListPath, ORDER_HOME, ORDER_MEETUP } from '@/api/base/group';
import { getAllMeetupsPath } from '@/api/hooks/allTypeMeetup/useGetAllMeetups';
import { getJustStartedGroupListPath } from '@/api/hooks/useGetJustStartedGroupList';
import { getMyGroupPostListPath, ViewRange } from '@/api/hooks/useGetMyGroupPostList';
import { queryClient } from '@/shared/api/instance';

import { UNSAFE_appInfo } from '../bridge/appInfo';
import { getHomeListPath } from './../../api/hooks/useGetHomeList';

/*
 *  모임 홈에서 사용하는 쿼리를 한번에 리페치하는 함수
 *  TODO: 현재 탈퇴하기에서만 필요한 리페치만 추가했어요.
 */
export const refetchHome = () => {
  refetchHomeMyGroupTab();
};

export type HomeExplorerGroupTabProps = {
  category?: number | number[];
  order?: $Values<typeof ORDER_HOME>;
  viewRange?: ViewRange;
};
export const refetchHomeExplorerGroupTab = ({
  category,
  order,
  viewRange,
}: HomeExplorerGroupTabProps) => {
  queryClient.refetchQueries({
    queryKey: [getJustStartedGroupListPath()],
  });
  queryClient.refetchQueries({
    queryKey: [getHomeListPath(category, order, viewRange)],
  });
};

export type HomeExplorerMeetupTabProps = {
  regionId?: number;
  categoryId?: number;
  meetupStatus?: ('opened' | 'fully_recruited' | 'closed')[];
  order: $Values<typeof ORDER_MEETUP>;
};
export const refetchHomeExplorerMeetupTab = ({
  regionId,
  meetupStatus,
  categoryId,
  order,
}: HomeExplorerMeetupTabProps) => {
  const currentRegionId = regionId ?? UNSAFE_appInfo.getData().region.id;

  queryClient.refetchQueries({
    queryKey: [getAllMeetupsPath, currentRegionId, meetupStatus, categoryId, order],
  });
};

export const refetchHomeMyGroupTab = () => {
  queryClient.refetchQueries({
    queryKey: [getMyGroupListPath()],
  });
  queryClient.refetchQueries({
    queryKey: [getMyGroupPostListPath()],
  });
};
