import { Dialog, useDialog } from '@community-group/components';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePostLeftGroup } from '@/api/hooks/usePostLeftGroup';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import { DOMAIN_URL } from '@/constants/path';
import useActiveActivities from '@/hooks/useActiveActivities';
import useBackToRootActivity from '@/hooks/useBackToRootActivity';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { routes } from '@/stackflow/activity/routes';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { openGroupReport, replaceWebviewHome } from '@/utils/link';
import { refetchHome } from '@/utils/refetch/home';

import SettingGroupDeleteMenuItem from './SettingGroupDeleteMenuItem';
import SettingMenuItem from './SettingMenuItem';
import SettingSectionWrapper from './Wrapper';

const OtherSettingSection = () => {
  const { push, pop, replace } = useFlow();
  const { diffActivitiesFromCurrent } = useActiveActivities();

  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe();
  const { group } = useReadGroupDetail(groupId);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { handleBackToRootActivity } = useBackToRootActivity();

  const { mutate: leftMutate } = usePostLeftGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      const homePageActivityIndex = diffActivitiesFromCurrent('HomePage');
      if (homePageActivityIndex !== -1) {
        refetchHome();
        for (let i = 0; i < homePageActivityIndex; i++) pop();
        return;
      }
      handleBackToRootActivity(() => {
        refetchHome();
        replace('HomePage', {});
      });
    },
  });

  const handleLeaveConfirm = () => {
    openDialog({
      element: (
        <Dialog
          title="모임에서 나갈까요?"
          description="나간 후에 작성한 글은 수정할 수 없어요. 필요한 경우 모임을 나가기 전에 수정하거나 삭제해주세요."
          primaryActionLabel="나가기"
          secondaryActionLabel="취소"
          onSecondaryAction={async () => {
            trackEvent({
              event: 'click_cancel',
              params: {
                type: 'leave_group',
              },
            });
            await closeDialog();
          }}
          onPrimaryAction={async () => {
            await closeDialog();
            leftMutate(String(groupId), {
              onSuccess: () => {
                trackEvent({
                  event: 'click_leave_group',
                  params: {
                    groupId: group?.id,
                    groupCategoryName: group?.category.name,
                  },
                });
              },
            });
          }}
        />
      ),
    });
  };

  const handleDelegateSuperHost = () => {
    push('GroupSettingMemberDelegateSuperHostPage', { groupId: String(groupId) });
  };

  return (
    <SettingSectionWrapper title="기타">
      {/* 개설자만 */}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingMenuItem title="모임장 위임" onClick={handleDelegateSuperHost} />
        <SettingGroupDeleteMenuItem />
      </CheckAccessibleRole>

      {/* 개설자 외 나머지 */}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['manager', 'member']}>
        <SettingMenuItem
          title="신고하기"
          onClick={() => {
            trackEvent({
              event: 'click_report',
              params: {
                type: 'group',
              },
            });
            openGroupReport({
              groupId: String(group?.id),
            });
          }}
        />
        <SettingMenuItem warning title="모임 나가기" onClick={handleLeaveConfirm} />
      </CheckAccessibleRole>
    </SettingSectionWrapper>
  );
};

export default OtherSettingSection;
