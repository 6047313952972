export const categoriesResponse = {
  categories: [
    {
      id: 1,
      name: '운동',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 2,
      name: '스터디',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/b7f799cc-4351-4d03-a0b6-c1e25c78b89e0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 3,
      name: '가족/육아',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/0eec692c-e25a-4d70-8898-e0e185a439150.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 4,
      name: '동네친구',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/61e8b2c8-bf1c-4f3d-99c7-fd53d446b0970.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 5,
      name: '공예/만들기',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/18b1ef64-11e5-45f9-8420-92bcbaed1d770.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 6,
      name: '반려동물',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/cea549f6-20e2-4696-b526-c8975fc47fea0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 7,
      name: '게임',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/9ab695ba-5322-4cfd-8976-e497d988a3ba0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 8,
      name: '음악',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/54c6ed4b-e72a-43a2-b019-b7b239e037ea0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 9,
      name: '영화',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/313fefa6-869a-4ec1-ae4c-ba5696cd791b0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 10,
      name: '음식',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/106d3a38-5574-4e83-93db-28796c9752d60.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 11,
      name: '문화/예술',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/5fe30cd1-48e7-46cd-b7c3-ea05832f8db40.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 12,
      name: '여행',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/d6e813c4-3842-454b-8b5c-2a585c3a4d280.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 13,
      name: '사진/영상',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/e1b1d4b7-6ac8-4e8b-beb7-fa90613e93950.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 14,
      name: '인문학/책',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/1842a68a-b219-41c9-bb5d-3e76c15bb8af0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 15,
      name: '패션',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/51b289d9-3a0c-4058-bf2b-37b35d812a9e0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 16,
      name: '테크',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/bcbfac21-686c-44ce-9173-a25561907ce20.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 17,
      name: '직무',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/9b87ddd6-6060-4483-9540-a24195a2fb840.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 18,
      name: '차/오토바이',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/6893eb49-e383-4e86-b54a-974ef0d85feb0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 19,
      name: '투자/금융',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/861f45f9-6c4d-4bd5-a2fd-9cf1a1a7fd250.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 20,
      name: '봉사활동',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/dcb2ecc9-2a70-457b-9bfa-b14b6237bdeb0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
    {
      id: 21,
      name: '기타',
      icon: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/acf02731-b535-422c-9664-6916d68c76cd0.png',
      createdAt: '2022-05-06T00:00:00',
      profileImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
      backgroundImage:
        'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220506/ebcc9afd-4b08-4b13-be14-9d25856df0720.png',
    },
  ],
};
