import { IconArrowClockwiseCircularLine, IconXmarkFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import React, { HTMLAttributes, useMemo } from 'react';

import { Typography } from '../../base';
import { LoadingProgressCircle } from '../../LoadingProgressCircle';
import { VerticalSpacing } from '../../Spacing';
import { FileUploadState } from '../types/Video';
import { videoDurationText as getVideoDurationText } from '../utils/videoUtils';
import * as s from './VideoFieldItem.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  video: FileUploadState;
  onRemoveClick: (video: FileUploadState) => void;
  onRetryClick?: (video: FileUploadState) => void;
}

const DEFAULT_THUMBNAIL =
  'https://assetstorage.krrt.io/1025317940251365954/6df82f85-ae99-46c6-971b-8302f38c9140/width=192,height=192.png';

const VideoFieldItem: React.FC<Props> = ({ video, onRemoveClick, onRetryClick, ...props }) => {
  const videoDurationText = useMemo(() => {
    return video.uploadedVideoInfo?.duration
      ? getVideoDurationText(video.uploadedVideoInfo?.duration)
      : '';
  }, [video.uploadedVideoInfo?.duration]);

  return (
    <>
      <div {...props} className={clsx([props.className, s.wrapper])}>
        <div className={s.OverlayBorder} />
        <div
          className={s.removeWrapper}
          onClick={(event) => {
            event.stopPropagation();
            onRemoveClick(video);
          }}
          role="button"
          aria-label="Remove video"
        >
          <IconXmarkFill size={12} color={vars.$scale.color.gray00} />
        </div>
        {video.status === 'failed' && (
          <div
            className={s.statusWrapper({ shown: true })}
            onClick={() => onRetryClick?.(video)}
            role="button"
            aria-label="Retry upload"
          >
            <IconArrowClockwiseCircularLine
              width={24}
              height={24}
              color={vars.$static.color.staticWhite}
            />
            <Typography color="staticWhite" typography="label4Bold">
              재시도
            </Typography>
          </div>
        )}
        {video.status === 'uploading' && (
          <div className={s.statusWrapper({ shown: true })}>
            <LoadingProgressCircle
              progress={video.progress}
              width="24px"
              height="24px"
              progressColor={vars.$static.color.staticWhite}
              trackColor={vars.$static.color.staticWhiteAlpha200}
              strokeWidth={6} // 디자인 가이드 border 3px => 두께 6px
            />
          </div>
        )}
        <img
          className={s.image}
          src={video?.thumbnail ?? DEFAULT_THUMBNAIL}
          alt={`Thumbnail of video ${video.id}`}
          width={64}
          height={64}
          loading="lazy"
        />
        {videoDurationText && (
          <div className={s.duration}>
            <Typography color="staticWhite" typography="label6Regular">
              {videoDurationText}
            </Typography>
          </div>
        )}
      </div>
      <VerticalSpacing size={8} />
    </>
  );
};

export default React.memo(VideoFieldItem);
