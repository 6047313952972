import { Typography, VerticalSpacing } from '@community-group/components';

import * as s from './GuideContents.css';
import LevelUpGuideTable from './LevelUpGuideTable';

export interface ContentItem {
  title?: string;
  table?: React.ComponentType<{ currentLevel: number }>;
  component?: React.ReactNode;
  content?: ContentItem[];
}

export const GuideContentData: ContentItem[] = [
  {
    title: '모임 레벨이란?',
    content: [
      {
        title: '모임이 활성화되고 성장할 수 있도록 돕는 체계예요.',
      },
      {
        title:
          '모임에서 다양한 활동을 통해 포인트를 쌓으면, Lv.0에서 최대 Lv.5까지 승급할 수 있어요.',
      },
      {
        title:
          '모임장 및 멤버의 활동에 따라 점수가 제공되며, 점수가 높아짐에 따라 레벨이 정해져요.',
      },
    ],
  },
  {
    title: '모임 레벨이 높아지면 어떤 점이 좋나요?',
    content: [
      { title: 'Lv.1 모임부터 모임 상단에 레벨 배지가 노출돼요.' },
      { title: 'Lv.3 이상부터 추후 모임 지원 이벤트 진행 시, 신청할 자격을 얻어요.' },
      {
        title:
          "Lv.5이 되면, 모임 첫화면에 ‘우수 모임' 배지가 노출되어 더 많은 사용자들에게 우리 모임을 알릴 수 있어요.",
      },
    ],
  },
  {
    title: '레벨 및 달성 조건 안내',
    content: [
      {
        title: '아래 내용을 참고하여 모임 활동을 계획하고, 목표를 달성해보세요!',
      },
      {
        table: LevelUpGuideTable,
      },
      {
        title:
          '활동 점수는 게시물, 댓글 등록을 비롯한 일정의 참여, 모임 방문 등의 활동을 통해 얻을 수 있어요.',
      },
       {
        title:
          '모임 방문은 가입한 모임의 상세 화면, 게시글 상세 화면, 모임 참여 일정 상세 화면에서 카운트돼요.',
      },
      {
        title: '모임 방문 횟수는 멤버당 하루 최대 48회에 한해 카운트돼요.',
      },
    ],
  },
  {
    title: '5단계 승급 안내',
    content: [
      {
        title:
          'Lv.5는 모임 레벨 제도의 최고 레벨이에요. 최고 레벨로 승급하기 위한 조건은 다음과 같아요.',
        content: [
          {
            title: '기존 Lv.4 모임은 추가로 4,500포인트를 획득하여 총 5,000포인트를 달성해야 해요.',
          },
          {
            title:
              '승급 후, Lv.5를 유지하기 위해서는 매월 최소 1,000포인트를 추가로 획득해야 해요.',
          },
        ],
      },
    ],
  },
  {
    title: '강등 안내',
    content: [
      {
        title: 'Lv.4 모임부터는 매월 활동 유지 조건을 충족하지 못하면 한 단계 강등될 수 있어요.',
      },
      {
        title:
          '2개월 연속으로 해당 등급의 포인트를 획득하지 못할 경우, 최대 2단계까지 강등될 수 있어요.',
      },
      {
        title: '더 낮은 레벨의 배지가 모임 상단에 표시돼요.',
      },
      {
        title: "Lv.5에서 강등될 경우, '우수 모임' 배지를 잃게 돼요.",
      },
    ],
  },
  {
    title: '유의사항',
    content: [
      {
        title: '아래의 경우 포인트 회수 및 레벨이 강등될 수 있어요.',
        content: [
          {
            title: '무의미하거나 반복적인 글, 댓글을 작성한 경우',
          },
          {
            title: '운영 정책을 위반한 글, 댓글을 작성한 경우',
          },
          {
            title: '이미지 무단 도용 등 타인의 권리를 침해하는 경우',
          },
        ],
      },
      {
        title: '승급별 포인트와 조건 및 혜택은 당근의 내부 판단에 따라 변경될 수 있어요.',
      },
      {
        title: '포인트와 레벨이 반영되기까지 최대 24시간이 걸릴 수 있어요.',
      },
    ],
  },
];
