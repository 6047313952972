import { groupClient } from '@community-group/api';
import { GroupPermissionModifyRequest } from '@community-group/api/lib/group/models';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  groupPermissionModifyRequest: GroupPermissionModifyRequest;
};

type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePutModifyGroupPermission = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const putModifyGroupPermission = groupClient.api.PermissionsApi.apiV1GroupsIdPermissionsPut({
    axios: fetchInstance,
  });

  const putModifyGroupPermissionWrapperFp = ({ groupId, groupPermissionModifyRequest }: Params) => {
    return putModifyGroupPermission(parseInt(groupId), groupPermissionModifyRequest);
  };

  return useMutation({
    mutationFn: putModifyGroupPermissionWrapperFp,
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
