import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import {
  getDateDiffFromNow,
  MotionContainer,
  resizeImage,
  Spacing,
  Thumbnail,
  Typography,
} from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler, PropsWithChildren, useMemo } from 'react';

import GroupLevel2DBadge from '@/components/common/GroupLevel/GroupLevel2DBadge';
import TransparentNavTitleShowObserver from '@/components/common/TransparentNavScreen/TransparentNavTitleShowObserver';
import { useFlow } from '@/stackflow';

import useRedTeamGroupLevel from '../../GroupLevel/hooks/useRedTeamGroupLevel';
import GroupDetailHeroSlider from '../../ImageViewerPage/components/GroupDetailHeroSlider';
import * as s from './GroupDetailHeader.css';

type Props = PropsWithChildren<{
  groupId: number;
  title: string;
  postCount: number;
  memberCount: number;
  profileImageUrl: string;
  lastActivatedAt?: string;
  onClickTitleText?: MouseEventHandler;
  isExistHeroSlider: boolean;
  role: GroupMemberRoleEnum;
  heroImageUrl?: string;
  onlyPublic?: boolean;
  groupLevel?: number;
}>;

const GroupDetailHeader = ({
  groupId,
  title,
  postCount,
  memberCount,
  profileImageUrl,
  lastActivatedAt,
  onClickTitleText,
  children: Banner,
  isExistHeroSlider,
  role,
  heroImageUrl,
  onlyPublic,
  groupLevel,
}: Props) => {
  const useGroupLevel = useRedTeamGroupLevel();
  const existGroupLevel = groupLevel && groupLevel !== 0;
  const { push } = useFlow();

  const handleGroupThumbnailClick = () => {
    const resizedImageUrl = resizeImage(profileImageUrl, {
      quality: 'high',
      size: '1200x1200',
      type: 'outside',
      file: 'webp',
    });
    push('ImageViewerPage', {
      content: 'images',
      imageUrls: JSON.stringify([resizedImageUrl]),
      initialIndex: '0',
    });
  };

  const activityLog = useMemo(() => {
    if (!lastActivatedAt) return null;
    const activityDate = new Date(Date.parse(lastActivatedAt));
    const dateDiff = getDateDiffFromNow(activityDate);

    // 10일
    const DAY10 = 1000 * 60 * 60 * 24 * 10;
    if (dateDiff.diff / DAY10 > 1) return null;

    const renderText = `${dateDiff.text} 전 활동`;
    return (
      <>
        <VerticalSpacing size={4} />
        <Typography typography="caption1Regular" color="gray600">
          ∙
        </Typography>
        <VerticalSpacing size={4} />
        <Typography typography="caption1Regular" color="primary">
          {renderText}
        </Typography>
      </>
    );
  }, [lastActivatedAt]);

  const renderTopHero = useMemo(() => {
    return (
      isExistHeroSlider && (
        <GroupDetailHeroSlider
          groupId={groupId.toString()}
          initialIndex={0}
          role={role}
          onlyPublic={onlyPublic}
        />
      )
    );
  }, [groupId, isExistHeroSlider, onlyPublic, role]);

  return (
    <MotionContainer initial={false} paddingX={0} paddingY={0}>
      {renderTopHero}
      <TransparentNavTitleShowObserver />
      {Banner}
      <section className={s.InformationSection}>
        <Thumbnail
          src={profileImageUrl}
          onClick={handleGroupThumbnailClick}
          size={48}
          badge={
            useGroupLevel && existGroupLevel ? (
              <GroupLevel2DBadge
                level={groupLevel}
                type="small"
                size={28}
                className={s.GroupLevelBadge}
              />
            ) : null
          }
        />
        <VerticalSpacing size={16} />
        <div className={s.TextContainer}>
          <div className={s.TitleContainer} onClick={onClickTitleText}>
            <Typography typography="title3Bold" color="gray900" ellipsisAfterLines={1}>
              {title}
            </Typography>
            <VerticalSpacing size={2} />
            <div className={s.ArrowIconContainer}>
              <IconChevronRightFill size={16} color={vars.$scale.color.gray900} />
            </div>
          </div>
          <Spacing size={4} />
          <div className={s.DescriptionContainer}>
            <Typography typography="caption1Regular" color="gray600">
              멤버 {memberCount} ∙ 게시글 {postCount}
            </Typography>

            {activityLog}
          </div>
        </div>
      </section>
    </MotionContainer>
  );
};

export default GroupDetailHeader;
