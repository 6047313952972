import { Divider, Spacing, Typography, withAsyncBoundary } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';

import { useGetComments } from '@/api/hooks/useGetComments';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupMeetupDetailCommentListForMember from './GroupMeetupDetailCommentListForMember';
import GroupMeetupDetailCommentListForNotMember from './GroupMeetupDetailCommentListForNotMember';
import GroupMeetupDetailCommentListInputButton from './GroupMeetupDetailCommentListInputButton';
import GroupMeetupDetailCommentListMoreButton from './GroupMeetupDetailCommentListMoreButton';
import * as s from './index.css';

type Props = {
  groupId: string;
  meetupId: string;
  isNotMember: boolean;
  totalCommentCount: number;
};

const GroupMeetupDetailCommentList = ({
  groupId,
  meetupId,
  isNotMember,
  totalCommentCount,
}: Props) => {
  const { data: groupMe } = useGetGroupMe(groupId);
  const { data } = useGetComments({
    groupId,
    relatedId: meetupId,
    relatedContentType: 'meetup',
    limit: 11,
  });
  const meetupComments = data?.pages.flatMap((page) => page.data.comments) ?? [];

  const { push } = useFlow();
  const { handleGroupOnly, groupInfo } = useHandleGroupOnly({ groupId });
  const handleMoreButtonClick = () => {
    trackEvent({
      event: 'click_group_meetup_comment_more',
      params: {
        role: groupMe?.currentUser.role,
      },
      sample: true,
    });

    const moveToCommentListPage = () => {
      push('CommentListPage', {
        groupId,
        relatedId: meetupId,
        relatedContentType: 'meetup',
        commentCount: totalCommentCount.toString(),
        groupName: groupInfo?.name,
      });
    };

    handleGroupOnly({
      isBlock: isNotMember,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getReadAllComment,
      onSettled: moveToCommentListPage,
      onSuccess: moveToCommentListPage,
    });
  };

  if (totalCommentCount === 0) {
    return (
      <>
        <Divider />
        <div className={s.Header}>
          <Typography typography="title3Bold" color="gray900">
            댓글
          </Typography>
        </div>
        <GroupMeetupDetailCommentListInputButton
          groupId={groupId}
          meetupId={meetupId}
          totalCommentCount={totalCommentCount}
        >
          가장 먼저 댓글을 남겨보세요.
        </GroupMeetupDetailCommentListInputButton>
        <Spacing size={16} />
      </>
    );
  }

  return (
    <>
      <Divider />
      <div className={s.Header}>
        <Typography typography="title3Bold" color="gray900">
          댓글 {totalCommentCount}
        </Typography>
        {isNotMember && (
          <button className={s.MoreButton} onClick={handleMoreButtonClick}>
            <IconChevronRightLine size={20} color="gray900" />
          </button>
        )}
      </div>
      {isNotMember ? (
        <GroupMeetupDetailCommentListForNotMember
          comment={meetupComments[0]}
          onClick={handleMoreButtonClick}
        />
      ) : (
        <GroupMeetupDetailCommentListForMember comments={meetupComments.splice(0, 10)}>
          <GroupMeetupDetailCommentListInputButton
            groupId={groupId}
            meetupId={meetupId}
            totalCommentCount={totalCommentCount}
          >
            댓글을 입력해주세요.
          </GroupMeetupDetailCommentListInputButton>
          {totalCommentCount > 10 && (
            <GroupMeetupDetailCommentListMoreButton
              groupId={groupId}
              meetupId={meetupId}
              totalCommentCount={totalCommentCount}
            />
          )}
        </GroupMeetupDetailCommentListForMember>
      )}
    </>
  );
};

export default withAsyncBoundary(GroupMeetupDetailCommentList, {});
