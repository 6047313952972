import { GroupLeaderBoardItem, Image } from '@community-group/api/lib/group/models';
import {
  resizeImage,
  ResizeOptions,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import React from 'react';

import { useFlow } from '@/stackflow';

import * as s from './LeaderBoardItem.css';

type Props = Omit<GroupLeaderBoardItem, 'displayRegionName' | 'groupLevel'> & {
  onClick?: (groupId: number) => void;
};

const LeaderBoardItem = ({
  rank,
  groupId,
  groupName,
  groupThumbnailUrl,
  weeklyActivityPoint,
  onClick,
}: Props) => {
  const { push } = useFlow();

  const handleClick = () => {
    if (onClick) {
      onClick(groupId);
      return;
    }
    push('GroupDetailPage', { groupId: groupId.toString() });
  };

  return (
    <div className={s.Wrapper} onClick={handleClick}>
      <div className={s.Rank}>
        <Typography typography="subtitle2Bold">{rank}</Typography>
      </div>
      <VerticalSpacing size={10} />
      <div className={s.ThumbnailWrapper}>
        <div className={s.ThumbnailOverlay} />
        <img
          src={resizeImage(groupThumbnailUrl.small, {
            size: '48x48',
            quality: 'high',
            type: 'crop',
          })}
          alt={groupName}
          className={s.Thumbnail}
        />
      </div>
      <VerticalSpacing size={10} />
      <div className={s.GroupName}>
        <Typography typography="subtitle2Bold" ellipsisAfterLines={1}>
          {groupName}
        </Typography>
      </div>
      <VerticalSpacing size={10} />
      <div className={s.ActivityPoint}>
        <Typography typography="caption2Regular" color="gray700" className={s.ActivityPoint}>
          {weeklyActivityPoint.toLocaleString()}점
        </Typography>
      </div>
    </div>
  );
};

export default LeaderBoardItem;
