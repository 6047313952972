import { GroupFeedBulletinSummary } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import {
  IconCalendarFill,
  IconChevronDownFill,
  IconChevronUpFill,
  IconMegaphoneFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { MouseEventHandler, useMemo, useState } from 'react';

import { getElementFromMention } from '@/components/common/Mention';

import * as s from './BulletinBannerSection.css';

type Props = {
  bulletins: GroupFeedBulletinSummary[];
  onFoldedBulletinItemClick: MouseEventHandler;
  onBulletinItemClick: (bulletin: GroupFeedBulletinSummary) => MouseEventHandler;
};

const BulletinBannerSection = ({
  bulletins,
  onFoldedBulletinItemClick,
  onBulletinItemClick,
}: Props) => {
  const [fold, setFold] = useState(false);
  const foldable = useMemo(() => bulletins.length > 1, [bulletins.length]);

  const bulletinIconMap = useMemo(
    () => ({
      post: <IconMegaphoneFill size={14} color={vars.$scale.color.gray700} />,
      group_meetup: <IconCalendarFill size={14} color={vars.$scale.color.gray700} />,
    }),
    []
  );
  const toggleFold = () => {
    setFold((prev) => !prev);
  };
  const handleBulletinItemClick = (bulletin: GroupFeedBulletinSummary) => (e) => {
    if (fold) {
      onFoldedBulletinItemClick(e);
      toggleFold();
      return;
    }
    onBulletinItemClick(bulletin)(e);
  };
  const handleFoldButtonClick = (e) => {
    e.stopPropagation();
    toggleFold();
  };

  return (
    <section className={s.Container}>
      <ul>
        {bulletins.slice(0, fold ? 1 : 4).map((item, index) => {
          const showFoldButton = foldable && index === 0;

          return (
            <motion.li
              key={item.id}
              className={s.BulletinItem}
              onClick={handleBulletinItemClick(item)}
              whileTap={{
                backgroundColor: vars.$scale.color.grayAlpha50,
              }}
            >
              <div className={s.BulletinItemTextContainer}>
                <div className={s.IconWrapper}>{bulletinIconMap[item.type]}</div>
                <VerticalSpacing size={8} />
                {item.prefix && (
                  <>
                    <Typography
                      className={s.BulletinPrefixTextWrapper}
                      typography="subtitle1Regular"
                      color="gray600"
                    >
                      {item.prefix}
                    </Typography>
                    <VerticalSpacing size={4} />
                  </>
                )}
                <Typography typography="subtitle1Regular" color="gray900" ellipsisAfterLines={1}>
                  {getElementFromMention(item.content, 'string')}
                </Typography>
              </div>
              {showFoldButton && (
                <>
                  <VerticalSpacing size={8} />
                  <button onClick={handleFoldButtonClick}>
                    {fold ? (
                      <IconChevronDownFill size={14} color={vars.$scale.color.gray600} />
                    ) : (
                      <IconChevronUpFill size={14} color={vars.$scale.color.gray600} />
                    )}
                  </button>
                </>
              )}
            </motion.li>
          );
        })}
      </ul>
    </section>
  );
};

export default BulletinBannerSection;
