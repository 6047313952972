import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import {
  ActionListItem,
  ActionSheet,
  Dialog,
  isNotMember,
  isSuperHost,
  useBottomSheet,
  useDialog,
  useSnackbarAdapter,
} from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useDeleteChallenge } from '@/api/hooks/useDeleteChallenge';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import usePatchChallenge from '@/api/hooks/usePatchChallenge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { useBack } from '@/stackflow/hooks/useBack';
import { trackEvent } from '@/utils/analytics';
import { shareGroupChallengePermalink } from '@/utils/link';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

import * as s from './style.css';

const MoreMenu = ({
  challengeDetail,
  groupId,
}: {
  challengeDetail: ChallengeDetailPresentation;
  groupId: string;
}) => {
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const { push } = useFlow();
  const { userConfig } = useUserConfig();
  const { data: me } = useGetGroupMe(groupId);
  const { create, dismiss } = useSnackbarAdapter();
  const { open: openDialog, close: closeDialog } = useDialog();
  const useChallengeDelete = useFeatureFlag('useChallengeDelete');
  const back = useBack();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: patchChallenge } = usePatchChallenge({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId: challengeDetail.id.toString() });
      create({
        message: '챌린지가 종료되었어요.',
        timeout: 3000,
        type: 'default',
        onClick: dismiss,
      });
    },
  });

  const { mutate: deleteChallenge } = useDeleteChallenge({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId: challengeDetail.id.toString() });
      create({
        message: '챌린지가 삭제되었어요.',
        timeout: 3000,
        type: 'default',
        onClick: dismiss,
      });
      back();
    },
  });

  const handleCopyShareLink = () => {
    shareGroupChallengePermalink(challengeDetail.publicId);
  };

  const handleCloseChallenge = () => {
    openDialog({
      element: (
        <Dialog
          title="챌린지를 종료할까요?"
          description="이웃이 더이상 챌린지에 참여할 수 없고, 수정할 수 없어요."
          onPrimaryAction={async () => {
            await closeDialog();
            patchChallenge({
              id: Number(groupId),
              challengeId: challengeDetail.id,
              form: {
                status: 'closed',
              },
            });
          }}
          primaryActionLabel="종료"
          onSecondaryAction={closeDialog}
          secondaryActionLabel="취소"
        />
      ),
    });
  };

  const handleDeleteChallenge = (challengeId: number) => {
    openDialog({
      element: (
        <Dialog
          title="챌린지를 삭제할까요?"
          description="모든 데이터가 삭제되고 다시 볼 수 없어요."
          primaryActionLabel="삭제"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            await closeDialog();
            deleteChallenge({
              groupId: groupId.toString(),
              challengeId: challengeId.toString(),
            });
          }}
          onSecondaryAction={async () => {
            trackEvent({
              event: 'click_cancel',
              params: {
                type: 'delete',
              },
            });
            await closeDialog();
          }}
        />
      ),
    });
  };

  const handleOpenMoreOptions = () => {
    const isWriter = userConfig.userId === challengeDetail.author.id;
    const isDeletedWriter =
      challengeDetail.author.isAccountDeleted || isNotMember(challengeDetail.author.role);

    /* 글쓴이 또는 호스트는 종료되지 않은 챌린지를 종료 할 수 있다 */
    const challengeClosePermission =
      (isWriter || isSuperHost(me?.currentUser.role)) &&
      challengeDetail &&
      challengeDetail.status !== 'closed';

    const challengeDeletePermission =
      useChallengeDelete && (isWriter || isSuperHost(me?.currentUser.role));

    openBottomSheet({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          {isWriter && (
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();

                push('ChallengeEditPage', {
                  groupId,
                  challengeId: challengeDetail.id.toString(),
                });
              }}
            >
              수정
            </ActionListItem>
          )}
          {challengeClosePermission && (
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();
                handleCloseChallenge();
              }}
            >
              챌린지 종료
            </ActionListItem>
          )}
          {challengeDeletePermission && (
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();
                handleDeleteChallenge(challengeDetail.id);
              }}
              color={vars.$semantic.color.danger}
            >
              삭제
            </ActionListItem>
          )}

          {/* 탈퇴 사용자의 글인 경우 신고 버튼만 표시 */}
          {/* TODO: 챌린지 신고 기능 개발 완료 시 오픈 */}
          {/* {(isDeletedWriter || !isWriter) && (
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();
                // TODO: 챌린지 신고 기능 연동
                // openGroupChallengeReport(challengeDetail.id, challengeDetail.author.id.toString());
              }}
            >
              신고
            </ActionListItem>
          )} */}
        </ActionSheet>
      ),
      onDimClose: closeBottomSheet,
    });
  };

  const isAuthor = me?.currentUser.id === challengeDetail.author.id;

  if (!isAuthor && !isSuperHost(me?.currentUser.role)) {
    return null;
  }

  return (
    <div className={s.MoreMenu}>
      {/* TODO: 챌린지 공유 기능 개발 완료시 공유기능 오픈 */}
      {/* <IconArrowUpBracketDownLine
        size={20}
        color={vars.$scale.color.gray900}
        onClick={handleCopyShareLink}
      />
      <VerticalSpacing size={16} /> */}
      <IconDot3VerticalLine
        size={20}
        color={vars.$scale.color.gray900}
        onClick={handleOpenMoreOptions}
      />
    </div>
  );
};

export default MoreMenu;
