import { GroupSidebarPresentationFeatureRedDots } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { IconGearLine, IconPerson2Line, IconPictureLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { memo, MouseEventHandler, useMemo } from 'react';

import * as s from './SidebarFeatureList.css';

type Props = {
  redDots: GroupSidebarPresentationFeatureRedDots[];
  onAlbumMenuClick: MouseEventHandler;
  onMemberMenuClick: MouseEventHandler;
  onSettingMenuClick: MouseEventHandler;
};

const SidebarFeatureList = ({
  redDots,
  onAlbumMenuClick,
  onMemberMenuClick,
  onSettingMenuClick,
}: Props) => {
  const featureMenuList = useMemo(
    () => [
      {
        onClick: onAlbumMenuClick,
        icon: <IconPictureLine color={vars.$scale.color.gray900} size={20} />,
        text: '앨범',
        type: 'album',
      },
      {
        onClick: onMemberMenuClick,
        icon: <IconPerson2Line color={vars.$scale.color.gray900} size={20} />,
        text: '멤버',
        type: 'member',
      },
      {
        onClick: onSettingMenuClick,
        icon: <IconGearLine color={vars.$scale.color.gray900} size={20} />,
        text: '설정',
        type: 'setting',
      },
    ],
    [onAlbumMenuClick, onMemberMenuClick, onSettingMenuClick]
  );

  return (
    <ul className={s.FeatureList}>
      {featureMenuList.map((item, index) => {
        const featureType = item.type as GroupSidebarPresentationFeatureRedDots;
        const hasRedDot = redDots.includes(featureType);

        return (
          <li
            key={`${item.text}-${index}`}
            className={s.FeatureItem}
            onClick={item.onClick}
            role="link"
          >
            <div className={s.IconContainer}>
              {item.icon}
              {hasRedDot && <div className={s.RedDot} />}
            </div>
            <Spacing size={6} />
            <Typography typography="caption2Regular" color="gray900">
              {item.text}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};

export default memo(SidebarFeatureList);
