import { GroupAgeRangeTagPresentation } from '@community-group/api/lib/group/models';
import { HelpBubbleTrigger, Typography, VerticalSpacing } from '@community-group/components';
import {
  IconCalendarLine,
  IconCheckmarkShieldLine,
  IconILowercaseSerifCircleLine,
  IconPersonLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import IconApprovedGroup from '@/assets/images/icon_approved_group.svg';
import { getAgeDisplayText } from '@/components/common/TagLabel/AgeLabel';
import { useGetParentingGroupStorage } from '@/hooks/useGetParentingGroupStorage';
import { useSetParentingGroupStorage } from '@/hooks/useSetParentingGroupStorage';

import * as s from './GroupDetailInfo.css';

type Props = {
  groupId: string;
  createdAt: string;
  ageRangeTag?: GroupAgeRangeTagPresentation;
  canViewVerifiedUserInfo: boolean;
  needVerification: boolean;
};

const GroupDetailInfo = ({
  groupId,
  createdAt,
  ageRangeTag,
  needVerification,
  canViewVerifiedUserInfo,
}: Props) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  useSetParentingGroupStorage();
  const { isTargetGroup } = useGetParentingGroupStorage(groupId);

  const ageRangeDisplayText = useMemo(() => {
    if (!ageRangeTag) return null;
    const { minAge, maxAge } = ageRangeTag;
    const displayText = getAgeDisplayText({ min: minAge, max: maxAge });
    return displayText;
  }, [ageRangeTag]);

  const verifyAgeRangeDisplayText = useMemo(() => {
    const text: string[] = [];
    if (needVerification) text.push('본인인증 필요');
    if (canViewVerifiedUserInfo) text.push('나이대 공개');

    if (text.length === 0) return null;
    return text.join(', ');
  }, [canViewVerifiedUserInfo, needVerification]);

  return (
    <div className={s.ListWrapper}>
      {isTargetGroup && (
        <div className={s.RowWrapper} onClick={() => setOpenTooltip((prev) => !prev)}>
          <div className={s.RowIconWrapper}>
            <img src={IconApprovedGroup} alt="대표 모임 뱃지" width={16} height={16} />
          </div>
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="carrot950">
            대표 육아 모임
          </Typography>
          <VerticalSpacing size={4} />
          <HelpBubbleTrigger
            title={`당근이 직접 선정하고 지원하는\n대표 육아 모임이에요`}
            isOpen={openTooltip}
            UNSAFE_style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            <IconILowercaseSerifCircleLine size={16} color={vars.$scale.color.gray600} />
          </HelpBubbleTrigger>
        </div>
      )}
      {createdAt && (
        <div className={s.RowWrapper}>
          <div className={s.RowIconWrapper}>
            <IconCalendarLine size={16} color={vars.$scale.color.gray900} />
          </div>
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray900">
            {dayjs(createdAt).format('YYYY년 M월 D일')} 개설
          </Typography>
        </div>
      )}
      {ageRangeDisplayText && (
        <div className={s.RowWrapper}>
          <div className={s.RowIconWrapper}>
            <IconPersonLine size={16} color={vars.$scale.color.gray900} />
          </div>
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray900">
            {ageRangeDisplayText} 모집
          </Typography>
        </div>
      )}
      {verifyAgeRangeDisplayText && (
        <div className={s.RowWrapper}>
          <div className={s.RowIconWrapper}>
            <IconCheckmarkShieldLine size={16} color={vars.$scale.color.gray900} />
          </div>
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray900">
            {verifyAgeRangeDisplayText}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default GroupDetailInfo;
