import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';

import MoreMenu from './MoreMenu';

export const ChallengeDetailAppbarRightButton = ({
  challengeDetail,
  groupId,
}: {
  challengeDetail: ChallengeDetailPresentation;
  groupId: string;
}) => {
  return <MoreMenu challengeDetail={challengeDetail} groupId={groupId} />;
};
