import { getGroupMePath } from '@/api/hooks/useGetGroupMe';
import { queryClient } from '@/shared/api/instance';
type Params = {
  groupId: string;
};

/*
 *  currentUser 상태관련 refetch
 */
export const refetchCurrentUser = ({ groupId }: Params) => {
  queryClient.refetchQueries({
    queryKey: [getGroupMePath(groupId)],
  });
};
