import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = {
  groupId: number;
  challengeId: number;
} & PaginationQueryParams;

export const getChallengeParticipantsRankPath = ({ groupId, challengeId }: Props) =>
  groupClient.api.GroupChallengeMemberApi.getapiV1GroupsGroupIdChallengesChallengeIdRankingsGetPath(
    groupId,
    challengeId
  );

export const useGetChallengeParticipantsRank = ({ groupId, challengeId, initialCursor }: Props) => {
  const fetchInstance = useFetchInstance();
  const getChallengeParticipantsRank =
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdRankingsGet({
      axios: fetchInstance,
    });

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useSuspenseInfiniteQuery({
    queryKey: [getChallengeParticipantsRankPath({ groupId, challengeId })],
    queryFn: ({ pageParam = initialCursor }) => {
      return getChallengeParticipantsRank(groupId, challengeId, pageParam);
    },
    initialPageParam: initialCursor,
    getNextPageParam: (data) =>
      data.data.pagination?.hasNext ? data.data.pagination.endCursor : undefined,
    select: (data) => ({
      pages: data.pages.flatMap((page) => page.data.contents),
      pageParams: data.pageParams,
    }),
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
