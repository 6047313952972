import { Spacing, Typography } from '@community-group/components';
import { IconArrowClockwiseCircularLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { useUserConfig } from '@/contexts/UserConfig';
import { trackEvent } from '@/utils/analytics';

import * as s from './RefreshListSection.css';

interface Props {
  maxCount: number;
  currentCount: number;
  onRefresh: (nextPageIndex: number) => void;
  buttonText: string;
  children: React.ReactNode;
}

const RefreshListSection = ({ children, buttonText, maxCount, currentCount, onRefresh }: Props) => {
  const { userConfig } = useUserConfig();

  const handleRefresh = async () => {
    trackEvent({
      event: 'click_just_started_refresh',
      params: {
        userId: (userConfig.userId ?? 0).toString(),
        currentCount,
      },
      sample: true,
    });
    if (currentCount + 1 >= maxCount) {
      onRefresh(0);
      return;
    }
    onRefresh(currentCount + 1);
  };

  return (
    <AnimatePresence>
      <div className={s.Wrapper}>
        <motion.div
          key={currentCount}
          className={s.ChildWrapper}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          {children}
        </motion.div>
        <Spacing size={8} />
        <div className={s.OuterButtonWrapper}>
          <div className={s.RefreshButton} onClick={handleRefresh}>
            <motion.div
              key={currentCount}
              initial={{ transform: 'rotate(0deg)' }}
              transition={{
                duration: 0.3,
              }}
              animate={{
                transform: 'rotate(360deg)',
              }}
            >
              <IconArrowClockwiseCircularLine
                size={16}
                color={vars.$scale.color.gray900}
                className={s.RetryIconWrapper}
              />
            </motion.div>

            <Typography typography="label3Bold">
              {buttonText} {currentCount + 1}/{maxCount}
            </Typography>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default RefreshListSection;
