import { TextButton } from '@community-group/components';

import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';
import { useFlow } from '@/stackflow';

import * as s from './LeaderBoardAppScreenRightButton.css';

type Props = {
  isCurrentWeek?: boolean | string;
};

const LeaderBoardAppScreenRightButton = ({ isCurrentWeek }: Props) => {
  const { push } = useFlow();
  const isCurrentWeekBoolean = !isCurrentWeek || isCurrentWeek !== 'false';

  const { data: leaderBoardData } = useGetGroupLeaderBoard({
    weekAgo: isCurrentWeekBoolean ? 0 : 1,
  });

  return (
    <div
      className={s.IconWrapper}
      onClick={() =>
        push('BottomSheet/GroupLeaderBoardGuideBottomSheet', {
          baseDate: leaderBoardData?.baseDate ?? '',
        })
      }
    >
      <TextButton variant="secondary">안내</TextButton>
    </div>
  );
};

export default LeaderBoardAppScreenRightButton;
