import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_URL } from '../base/group';
export const getGroupDetailPath = (id?: string) => `${GROUP_URL}/${id}`;

export const useReadGroupDetailNoSuspense = (groupId?: string) => {
  const read = useRead(groupClient.api.GroupApi.apiV1GroupsIdGet);

  const { data, refetch, isPending } = useQuery({
    queryKey: [getGroupDetailPath(groupId)],
    queryFn: async () => {
      if (!groupId) return null;
      return await read(Number(groupId));
    },
  });

  const response = data?.data.group;

  return { group: response, refetch, isLoading: isPending };
};

export const useReadGroupDetail = (groupId?: string) => {
  const read = useRead(groupClient.api.GroupApi.apiV1GroupsIdGet);

  const { data, refetch, isPending } = useSuspenseQuery({
    queryKey: [getGroupDetailPath(groupId)],
    queryFn: async () => {
      if (!groupId) return null;
      return await read(Number(groupId));
    },
  });

  const response = data?.data.group;

  return { group: response, refetch, isLoading: isPending };
};
