import { GroupMeetupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupMeetupMemberList from './GroupMeetupMemberList';
import * as s from './index.css';

type Params = {
  groupId: string;
  meetupId: string;
};

export const GroupMeetupMemberListSection = ({ groupId, meetupId }: Params) => {
  const { data: meetup } = useReadGroupMeetupDetail({ groupId, meetupId });
  const { handleGroupOnly } = useHandleGroupOnly({ groupId });

  const { push } = useFlow();

  const handleMoreButtonClick = () => {
    const groupJoined = meetup?.currentUserInfo.groupJoined.toString() ?? '';
    trackEvent({
      event: 'click_group_meetup_member_list_more_button',
      params: {
        groupId,
        meetupId,
        groupJoined,
      },
      sample: true,
    });

    const moveToMeetupMemberListPage = () => {
      push('GroupMeetupDetailMemberListPage', {
        groupId,
        meetupId,
        groupJoined,
      });
    };

    handleGroupOnly({
      isBlock: !meetup.currentUserInfo.groupJoined,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getReadAllMeetupMember,
      onSettled: moveToMeetupMemberListPage,
      onSuccess: moveToMeetupMemberListPage,
    });
  };

  const handleMeetupMemberListItemClick =
    (member: GroupMeetupMemberSummaryPresentation) => (event) => {
      event.stopPropagation();
      event.preventDefault();

      trackEvent({
        event: 'click_profile',
        params: {
          clickedUserId: member.userId,
          clickedUserRole: member.meetupRole,
          name: 'groupMeetupMemberItem',
        },
      });
      push('GroupUserProfileDetailPage', {
        groupId,
        userId: member.userId.toString(),
        from: 'meetup_member',
      });
    };

  return (
    <section className={s.Section}>
      <div className={s.Header}>
        <Typography as="h6" typography="title3Bold" color="gray900">
          참여 중인 이웃{' '}
          <strong style={{ color: vars.$semantic.color.primary }}>
            {meetup?.currentParticipantsNumber ?? 0}
          </strong>
          /{meetup?.maximumParticipantsNumber ?? 0}
        </Typography>
        <button className={s.MoreButton} onClick={handleMoreButtonClick}>
          <IconChevronRightLine size={20} fill={vars.$scale.color.gray900} />
        </button>
      </div>
      <GroupMeetupMemberList
        groupId={groupId}
        meetupId={meetupId}
        hostUserId={meetup?.host?.userId ?? 0}
        onMeetupMemberListItemClick={handleMeetupMemberListItemClick}
        onMoreButtonClick={handleMoreButtonClick}
      />
    </section>
  );
};
