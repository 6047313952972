import { getGroupFeedReviewWriteableMeetupPath } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';
import { getMemberMissionListPath } from '@/api/hooks/useGetMemberMissionList';
import { getSuperHostOnBoardingMissionPath } from '@/api/hooks/useGetSuperHostOnBoardingMission';
import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId: string;
  userId: string;
};

/*
 *  현재 모임의 프로필 상태를 refetch하는 함수
 */
export const refetchGroupUserProfile = ({ groupId, userId }: Params) => {
  const numberUserId = Number(userId);
  queryClient.refetchQueries({
    queryKey: ['GroupProfile', numberUserId],
  });

  const numberGroupId = Number(groupId);
  queryClient.refetchQueries({
    queryKey: [getGroupFeedReviewWriteableMeetupPath(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getMemberMissionListPath(numberGroupId)],
  });
  queryClient.refetchQueries({
    queryKey: [getSuperHostOnBoardingMissionPath(numberGroupId)],
  });
};
