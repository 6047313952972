import { groupClient } from '@community-group/api';
import { GroupPermissionsResponsePermissions } from '@community-group/api/lib/group/models';
import { Divider, ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback } from 'react';
import { $Keys } from 'utility-types';

import { useGetPermission } from '@/api/hooks/useGetPermission';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupSettingPageParams = Pick<PageParams, 'groupId'>;

export const GROUP_ROLE_TO_TEXT = {
  [groupClient.model.GroupPermissionRoleEnum.superHost]: '모임장',
  [groupClient.model.GroupPermissionRoleEnum.manager]: '모임장, 운영진',
  [groupClient.model.GroupPermissionRoleEnum.member]: '모든 멤버',
};

const GroupSettingPermissionPage: ActivityComponentType<GroupSettingPageParams> = () => {
  const { groupId } = usePathParams();

  const { push } = useFlow();

  const { data: permissionData } = useGetPermission(groupId);

  const renderTranslateToKoreanRole = (role: Array<groupClient.model.GroupPermissionRoleEnum>) => {
    if (role.includes(groupClient.model.GroupPermissionRoleEnum.member)) {
      return '모든 멤버';
    }
    if (role.includes(groupClient.model.GroupPermissionRoleEnum.manager)) {
      return '모임장, 운영진';
    }
    return '모임장';
  };

  const handleOpenBottomSheet = useCallback(
    (permission: $Keys<GroupPermissionsResponsePermissions>) => {
      push('BottomSheet/GroupSettingEditPermissionBottomSheet', {
        groupId,
        targetPermission: permission,
      });
    },
    [groupId, push]
  );

  return (
    <AppScreen
      appBar={{
        title: '멤버 권한 관리',
      }}
    >
      <SettingList.Wrapper title="게시판">
        <SettingList.Item
          title="공지사항 등록"
          description={renderTranslateToKoreanRole(
            permissionData?.addNoticePost.currentRoles ?? []
          )}
          onClick={() => handleOpenBottomSheet('addNoticePost')}
        />
        <SettingList.Item
          title="다른 멤버의 게시글, 댓글 삭제"
          description={renderTranslateToKoreanRole(
            permissionData?.deletePostAndComment.currentRoles ?? []
          )}
          onClick={() => handleOpenBottomSheet('deletePostAndComment')}
        />
        <SettingList.Item
          title="게시글 공개 범위 수정"
          description={renderTranslateToKoreanRole(
            permissionData?.modifyPostGroupOnly.currentRoles ?? []
          )}
          onClick={() => handleOpenBottomSheet('modifyPostGroupOnly')}
        />
      </SettingList.Wrapper>
      <Divider />
      <SettingList.Wrapper title="채팅">
        <SettingList.Item
          title="1:1 채팅"
          description={renderTranslateToKoreanRole(
            permissionData?.openDirectChat.currentRoles ?? []
          )}
          onClick={() => handleOpenBottomSheet('openDirectChat')}
        />
      </SettingList.Wrapper>
      <Divider />
      <SettingList.Wrapper title="멤버 관리">
        <SettingList.Item
          title="멤버 가입 승인"
          description={renderTranslateToKoreanRole(
            permissionData?.applyApplicationMember.currentRoles ?? []
          )}
          onClick={() => handleOpenBottomSheet('applyApplicationMember')}
        />
        <SettingList.Item
          title="멤버 내보내기 및 차단"
          description={renderTranslateToKoreanRole(
            permissionData?.restrictMember.currentRoles ?? []
          )}
          onClick={() => handleOpenBottomSheet('restrictMember')}
        />
      </SettingList.Wrapper>
      <Divider />
      <SettingList.Wrapper title="기타">
        <SettingList.Item
          title="모임장봇 설정"
          description={renderTranslateToKoreanRole(
            permissionData?.modifyGroupBotSetting.currentRoles ?? []
          )}
          onClick={() => handleOpenBottomSheet('modifyGroupBotSetting')}
        />
      </SettingList.Wrapper>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingPermissionPage, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
