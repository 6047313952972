import { PostDetail } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { FeedPageProps } from '../base/feed';
import { GROUP_URL } from '../base/post';
import { queryClient } from '@/shared/api/instance';
import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<string | undefined, Error, { groupId?: string; postId?: string }>;

export const useDeletePost = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deletePost = async ({ groupId, postId }: { groupId?: string; postId?: string }) => {
    await fetchInstance.delete<{ content: string }, undefined>(
      `${GROUP_URL}/${groupId}/posts/${postId}`
    );

    return postId;
  };

  const afterDeletePost = (postId: string, prevData: FeedPageProps) => {
    const newData = prevData.pages.map((page) => {
      return {
        ...page,
        posts: page.data?.posts?.filter((post: PostDetail) => String(post.id) !== postId),
      };
    });

    return {
      pages: newData,
      pageParams: prevData.pageParams,
    };
  };

  const handleSuccess: typeof onSuccess = (data, variables, context) => {
    queryClient.setQueryData<FeedPageProps | undefined>(
      [`${GROUP_URL}/${variables.groupId}/posts`],
      (prevData) => {
        if (!data || !prevData) return;
        return afterDeletePost(data, prevData);
      }
    );

    onSuccess?.(data, variables, context);
  };
  return useThrottleMutation(deletePost, {
    onError,
    onSuccess: handleSuccess,
  });
};
