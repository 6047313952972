import { getPostCommentsPath } from '@/api/hooks/useGetComments';
import { usePutComment } from '@/api/hooks/usePutComment';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId: string;
  postId: string;
};

export const useHandlePutComment = ({ groupId, postId }: Params) => {
  const handleErrorWithToast = useHandleErrorWithToast();
  return usePutComment({
    relatedContentType: 'post',
    onError: handleErrorWithToast,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [getPostCommentsPath(groupId, postId)],
      });
    },
  });
};
