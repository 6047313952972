import { Spacing, Typography } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import { useDeleteRestrictGroup } from '@/api/hooks/useDeleteRestrictGroup';
import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePostRestrictGroup } from '@/api/hooks/usePostRestrictGroup';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import * as s from './index.css';

export type RestrictGroupBottomSheetParams = Pick<PageParams, 'groupId'>;

export const RestrictGroupBottomSheet: ActivityComponentType<
  RestrictGroupBottomSheetParams
> = () => {
  const { groupId } = usePathParams();
  const { group } = useReadGroupDetail(groupId);
  const [restrictReason, setRestrictReason] = useState<string>('');

  const handleErrorWithToast = useHandleErrorWithToast();
  const { bridge } = useBridge();
  const { mutate: deleteRestrictGroup } = useDeleteRestrictGroup({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '고스트밴 제재가 해제되었습니다.',
        },
      });
    },
    onError: handleErrorWithToast,
  });

  const { mutate: postRestrictGroup } = usePostRestrictGroup({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '고스트밴 제재가 설정되었습니다.',
        },
      });
    },
    onError: handleErrorWithToast,
  });

  const handlePostGhostBan = () => {
    if (!restrictReason) {
      alert('고스트밴 제재 사유를 입력해주세요.');
      return;
    }

    const result = confirm(`"${group?.name}"모임에 고스트밴 제재를 설정할까요?`);

    if (result) {
      postRestrictGroup({ id: Number(groupId), restrictForm: { reason: restrictReason } });
      return;
    }
    return;
  };

  const handleDeleteGhostBan = () => {
    const result = confirm(`"${group?.name}"모임에 고스트밴 제재를 해제할까요?`);
    if (result) {
      deleteRestrictGroup({ id: Number(groupId) });
      return;
    }
    return;
  };

  return (
    <BottomSheet>
      <Spacing size={20} />
      <Typography className={s.title} as="h4" typography="title2Bold" color="gray900">
        어드민 그룹 설정
      </Typography>
      <Typography className={s.title} as="h6" typography="bodyL2Regular" color="gray900">
        이 설정은 내부 유저에게만 보여요.
      </Typography>
      <Spacing size={32} />
      <div className={s.internalManageWrapper}>
        <Typography as="h4" typography="bodyM1Bold" color="gray900" style={{ padding: '0 16px' }}>
          고스트밴 제재
        </Typography>
        <Spacing size={16} />
        <input
          className={s.input}
          placeholder="고스트밴 제재 사유를 입력해주세요."
          type="text"
          onChange={(e) => setRestrictReason(e.target.value)}
          value={restrictReason}
        />
        <Spacing size={16} />
        <div className={s.internalManageRow}>
          <BoxButton width="100%" variant="primaryLow" size="xlarge" onClick={handleDeleteGhostBan}>
            제재해제
          </BoxButton>
          <BoxButton width="100%" size="xlarge" onClick={handlePostGhostBan}>
            제재하기
          </BoxButton>
        </div>
      </div>
      <Spacing size={20} />
    </BottomSheet>
  );
};
