import { BoxButton } from '@community-group/components';
import { Typography, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useGetGroupMeetups } from '@/api/hooks/useGetGroupMeetups';
import { MeetupHomeListCard } from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection/MeetupHomeListCardItem';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { filteredPublicMeetup } from '../../utils/meetup';
import * as s from './index.css';

const RecommendGroupMeetupSection = () => {
  const { push } = useFlow();
  const { userConfig } = useUserConfig();

  const { data } = useGetGroupMeetups({ regionId: userConfig.regionId ?? 0 });
  const meetups = data?.pages[0].data.meetups ?? [];

  const filteredMeetups = filteredPublicMeetup(meetups);

  if (filteredMeetups.length <= 1) {
    return <></>;
  }

  return (
    <>
      <Spacing size={8} fill={vars.$semantic.color.paperBackground} />
      <section className={s.wrapper}>
        <Typography as="h3" typography="title3Bold" color="gray900">
          다른 모임 일정 둘러보기
        </Typography>
        <Spacing size={8} />
        <div className={s.listWrapper}>
          {filteredMeetups.map((meetup, index) => {
            if (index > 2) return null;

            return (
              <MeetupHomeListCard
                key={meetup.id}
                title={meetup.title}
                headerLabel={[meetup.groupInfo.groupCreatedRegion?.name ?? '']}
                meetupAtDisplayText={meetup.meetupTimeAtDisplayText}
                currentParticipantsCount={meetup.currentParticipantsNumber}
                maximumParticipantsCount={meetup.maximumParticipantsNumber}
                image={meetup.image?.medium ?? ''}
                hostInfo={{
                  image: meetup.groupInfo.profileImage?.small ?? '',
                  displayText: meetup.groupInfo.name ?? '',
                  imageShape: 'square',
                }}
                onClick={(event) => {
                  event.stopPropagation();

                  push('GroupMeetupDetailPage', {
                    groupId: (meetup.groupInfo.id ?? '').toString(),
                    meetupId: meetup.id.toString(),
                  });

                  // todo: groupCategoryName 추가할 것
                  trackEvent({
                    event: 'click_recommend_meetup',
                    params: {
                      meetupId: meetup.id,
                      meetupTitle: meetup.title,
                      groupId: meetup.groupInfo.id,
                      groupName: meetup.groupInfo.name,
                      listItemIndex: index,
                    },
                    sample: true,
                  });
                }}
              />
            );
          })}
        </div>

        <Spacing size={8} />
        {filteredMeetups.length > 3 && (
          <BoxButton
            variant="secondary"
            size="medium"
            width="100%"
            onClick={() => {
              push('HomePage', {
                activeHomeTab: 'meetupTab',
              });
              trackEvent({ event: 'click_recommend_meetup_more', sample: true });
            }}
            suffix={
              <div className={s.iconWrapper}>
                <IconChevronRightFill size={14} fill={vars.$scale.color.gray900} />
              </div>
            }
          >
            더보기
          </BoxButton>
        )}
      </section>
    </>
  );
};

export default withAsyncBoundary(RecommendGroupMeetupSection, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
