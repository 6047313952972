import {
  MultilineTextField,
  Spacing,
  useCheckOpenKakao,
  useSnackbarAdapter,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import OpenKakaoBanner from '@/components/common/OpenKakaoBanner';
import { useUserConfig } from '@/contexts/UserConfig';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';
import { refetchGroupRunningGrowth } from '@/utils/refetch/runningGrowth';
import { groupFieldSchema } from '@/utils/validate/formSchema/group';
import { validateSchemaWithBoolean, validateSchemaWithMessage } from '@/utils/validate/util';

import RunningGrowthCallout from '../../Detail/components/RunningGrowth/RunningGrowthCallout';
import { getCurrentRunningGrowthStep } from '../../Detail/components/RunningGrowth/utils/curerntLevel';
import PopularGroupDescriptionList from '../components/SettingEditGroupDescription/PopularGroupDescriptionList';
import * as s from './GroupSettingEditGroupDescription.css';

//2024.10.11 현재 러닝그로스 1번 미션에서만 사용 중
//2024.11.01 레벨 미션을 위해서도 접근 가능하도록 했으나, 소개는 default로 채워져 있기 때문에 사용할 일이 없을 것으로 보임
const GroupSettingEditGroupDescription: ActivityComponentType = () => {
  const { groupId } = usePathParams();
  const { initialGroupDescription = '', calloutType } = useQueryParams();
  const { group } = useReadGroupDetail(groupId);
  const { pop } = useFlow();
  const { runningGrowthData = [], refetch } = useGetRunningGrowthMission(groupId);
  const { data: level } = useGetGroupLevel({ groupId: Number(groupId) });
  const snackbarAdapter = useSnackbarAdapter();
  const {
    userConfig: { userId },
  } = useUserConfig();

  const { watch, setValue } = useForm<FieldValues>({
    defaultValues: {
      description: initialGroupDescription,
    },
  });

  useEffect(() => {
    if (!group) return;
    setValue('description', group?.description ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEnterTrackEvent({
    event: 'enter_edit_group_description',
    params: {
      groupId,
      calloutType,
      currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
      currentLevel: level?.currentLevel,
      currentProgressPercentage: level?.forLevelUp.percentage,
      userId: (userId ?? 0).toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
    },
  });

  const descriptionFieldValue = watch('description');

  const { showOpenKakaoBanner } = useCheckOpenKakao(undefined, descriptionFieldValue, false);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isPending } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });
      refetchGroupRunningGrowth({ groupId: parseInt(groupId) });

      snackbarAdapter.create({
        message: '수정되었어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
    },
  });

  const isDisable = useMemo(() => {
    if ((descriptionFieldValue as string)?.length === 0) {
      return true;
    }
    return false;
  }, [descriptionFieldValue]);

  const handleSubmit = () => {
    if (runningGrowthData.length > 0 && (descriptionFieldValue as string)?.length < 200) {
      snackbarAdapter.create({
        message: '미션을 완료하려면, 모임 소개를 200자 이상 입력해주세요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
      return;
    }

    mutate(
      {
        id: groupId,
        groupModifyForm: {
          description: descriptionFieldValue,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'finish_modify_group_description',
            params: {
              groupId,
              calloutType,
              currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
            },
            loggerType: ['AMPLITUDE', 'FIREBASE'],
          });
          trackEvent({
            event: 'click_finish_modify_group_description',
            params: {
              groupId,
            },
            loggerType: ['KARROT'],
          });
          refetch();
          refetchGroupRunningGrowth({ groupId: parseInt(groupId) });

          pop();
        },
      }
    );
  };

  return (
    <AppScreen
      appBar={{
        title: '모임 소개 수정',
        renderRight: () => (
          <p
            className={clsx(s.SubmitButton, isDisable ? 'disabled' : '')}
            onClick={() => {
              !isDisable && !isPending && handleSubmit();
            }}
          >
            완료
          </p>
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <div className={s.Wrapper}>
        <div className={s.ContentWrapper}>
          <MultilineTextField
            value={watch('description') ?? ''}
            onChange={(text) => {
              setValue('description', text);
            }}
            placeholder="모임 소개를 입력해주세요"
            maxLength={500}
            isInvalid={
              !validateSchemaWithBoolean(groupFieldSchema.description, descriptionFieldValue)
            }
            errorMessage={
              validateSchemaWithMessage(groupFieldSchema.description, descriptionFieldValue)
                ?.message ?? ''
            }
            config={{ invalidAfterBlurred: false }}
          />
        </div>
        <Spacing size={16} />
        {showOpenKakaoBanner && (
          <div className={s.KakaoCalloutWrapper}>
            <OpenKakaoBanner marginX={false} />
          </div>
        )}

        <div className={s.CalloutWrapper}>
          <RunningGrowthCallout />
        </div>
        <PopularGroupDescriptionList />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditGroupDescription, {
  rejectedFallback: <ViewError />,
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
