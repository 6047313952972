import { Dialog, useDialog } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';

import useBackToActivity from '@/hooks/useBackToActivity';
import { TypeActivities } from '@/stackflow';

type Props = {
  popActivityName?: keyof TypeActivities;
  needConfirm?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  dialog: {
    title: string;
    description: string;
    primaryActionLabel?: string;
    secondaryActionLabel?: string;
  };
};

const AppBarCloseButton = ({
  popActivityName = 'HomePage',
  needConfirm = true,
  onCancel,
  onConfirm,
  dialog: { title, description, primaryActionLabel = '나가기', secondaryActionLabel = '취소' },
}: Props) => {
  const handleBack = useBackToActivity();
  const { open: openDialog, close: closeDialog } = useDialog();

  const handleBackToActivity = () => handleBack(popActivityName);

  const handleBackDialog = () => {
    if (!needConfirm) {
      onConfirm?.();
      handleBackToActivity();
      return;
    }

    const handleConfirm = async () => {
      onConfirm?.();
      await closeDialog();
      handleBackToActivity();
    };

    openDialog({
      element: (
        <Dialog
          title={title}
          description={description}
          primaryActionLabel={primaryActionLabel}
          secondaryActionLabel={secondaryActionLabel}
          onPrimaryAction={handleConfirm}
          onSecondaryAction={async () => {
            onCancel?.();
            await closeDialog();
          }}
        />
      ),
    });
  };

  return (
    <div style={{ margin: '0 8px' }} onClick={handleBackDialog}>
      <IconXmarkLine />
    </div>
  );
};

export default AppBarCloseButton;
