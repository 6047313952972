import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import throttle from 'lodash-es/throttle';

import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

export const useThrottleMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: UseMutationOptions<TData, TError, TVariables, TContext>['mutationFn'],
  options: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
  wait?: number
) => {
  const handleWithToast = useHandleErrorWithToast();

  const handleError = (error: TError, variables: TVariables, context: TContext | undefined) => {
    if (options?.onError) {
      options.onError(error, variables, context);
      return;
    }

    handleWithToast(error);
  };

  const mutation = useMutation<TData, TError, TVariables, TContext>({
    mutationFn,
    ...options,
    onError: handleError,
  });

  const throttleMutate = throttle(mutation.mutate, wait ?? 500);

  return {
    ...mutation,

    mutate: throttleMutate,
  };
};

export type UseThrottleMutationOption = Omit<
  Parameters<typeof useThrottleMutation>[0],
  'mutationFn'
>;
