import { Checkbox, HelpBubbleTrigger } from '@community-group/components';
import { IconQuestionmarkCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useState } from 'react';

type Props = {
  isSelected: boolean;
  onChange: (isSelected: boolean) => void;
  disabled?: boolean;
  displayHelpBubble?: boolean;
};

export const PhotoContextCheckbox = ({
  isSelected,
  onChange,
  disabled,
  displayHelpBubble,
}: Props) => {
  const [shownHelpBubble, setShownHelpBubble] = useState(false);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox isSelected={isSelected} onChange={onChange} isDisabled={disabled} size="large">
        &apos;전국모임자랑&apos; 참여
      </Checkbox>
      {displayHelpBubble && (
        <HelpBubbleTrigger
          title={'모임 멤버들과 찍은 인증샷 자랑하고\n최대 5만원의 당근머니 받아가세요!'}
          UNSAFE_style={{ whiteSpace: 'pre-wrap' }}
          positioning={{ placement: 'top' }}
          isOpen={shownHelpBubble}
          closeOnInteractOutside={true}
          onOpenChange={() => {
            setShownHelpBubble(!shownHelpBubble);
          }}
        >
          <IconQuestionmarkCircleLine
            size={20}
            color={disabled ? vars.$scale.color.gray400 : vars.$scale.color.gray600}
          />
        </HelpBubbleTrigger>
      )}
    </div>
  );
};
