import { groupClient } from '@community-group/api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getApplicationPath = (groupId: string) => `${getGroupPath(groupId)}/applications`;

export const useGetApplications = (id?: string, initialCursor?: string) => {
  const fetchInstance = useFetchInstance();
  const getApplications = groupClient.api.GroupApplicationApi.apiV1GroupsIdApplicationsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getApplicationPath(id ?? '')],
      queryFn: ({ pageParam = initialCursor }) => getApplications(Number(id), pageParam, 30),
      initialPageParam: initialCursor,
      getNextPageParam: (lastPage) => (lastPage.data.hasNext ? lastPage.data.endCursor : undefined),
      refetchInterval: 10000,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
