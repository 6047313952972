import {
  BoxButton,
  formatDate,
  Spacing,
  Typography,
  useBottomSheet,
  useSnackbarAdapter,
  withAsyncBoundary,
} from '@community-group/components';
import {
  IconChevronLeftLine,
  IconChevronRightLine,
  IconPlusLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { isFuture } from 'date-fns';
import { useMemo } from 'react';

import { useDeleteParentingGroupKidProfile } from '@/api/hooks/useDeleteParentingGroupKidProfile';
import { useGetParentingGroupKids } from '@/api/hooks/useGetParentingGroupKids';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useConnectedFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import ParentingGroupKidProfileEditBottomSheet from '../components/ParentingGroupKidProfileEditBottomSheet';
import { KID_COUNT_TEXT } from '../constants';
import * as s from './ParentingGroupKidProfileListPage.css';

const ParentingGroupKidProfileListPage: ActivityComponentType = () => {
  const { groupId } = usePathParams();

  return (
    <AppScreen
      appBar={{
        title: '내 아이 정보',
        backButton: {
          renderIcon: () => <IconChevronLeftLine size={24} />,
        },
        closeButton: {
          renderIcon: () => <IconChevronLeftLine size={24} />,
        },
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <div className={s.Container}>
        <Spacing size={8} />
        <ParentingGroupKidProfileList groupId={groupId} />
      </div>
    </AppScreen>
  );
};

type Props = {
  groupId: string;
};

const ParentingGroupKidProfileList = withAsyncBoundary(({ groupId }: Props) => {
  const { data, refetch: refetchParentingGroupKids } = useGetParentingGroupKids(groupId);
  const kids = useMemo(() => {
    return data?.kids ?? [];
  }, [data?.kids]);

  useEnterTrackEvent({
    event: 'enter_kid_profile_list_page',
    params: {
      groupId,
      kidsCount: kids.length,
    },
  });

  const { push } = useConnectedFlow();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const { create: openSnackbar, dismiss: closeSnackbar } = useSnackbarAdapter();

  const { mutateAsync: deleteKidProfile } = useDeleteParentingGroupKidProfile({
    onSuccess: () => {
      refetchParentingGroupKids();
      openSnackbar({
        message: '아이 정보를 삭제했어요.',
        type: 'default',
        timeout: 3000,
        onClick: closeSnackbar,
      });
    },
  });

  return (
    <>
      <ul>
        {kids.map((kid, index) => {
          const titleText = `${KID_COUNT_TEXT[index]} 아이`;

          const birthday = new Date(kid.birthday);
          const postfixText = isFuture(birthday) ? '예정' : '출생';
          const birthdayText = formatDate(birthday, `yyyy년 M월 d일 ${postfixText}`);
          const genderText = kid.gender === 'male' ? '남자' : '여자';

          return (
            <li
              key={kid.id}
              className={s.ListItem}
              onClick={() => {
                openBottomSheet({
                  element: (
                    <ParentingGroupKidProfileEditBottomSheet
                      onEditClick={async () => {
                        await closeBottomSheet();
                        const result = await push('ParentingGroupKidProfileEditPage', {
                          groupId: groupId,
                          kidId: kid.id.toString(),
                          kidBirthday: kid.birthday.replaceAll('-', ''),
                          kidGender: kid.gender,
                          title: titleText,
                        });
                        if (result !== 'submit') return;

                        refetchParentingGroupKids();
                        openSnackbar({
                          message: '아이 정보를 수정했어요.',
                          type: 'default',
                          timeout: 3000,
                          onClick: closeSnackbar,
                        });
                      }}
                      onDeleteClick={async () => {
                        await closeBottomSheet();
                        await deleteKidProfile({ groupId: Number(groupId), kidId: kid.id });
                      }}
                    />
                  ),
                  onDimClose: closeBottomSheet,
                });
              }}
            >
              <div className={s.ListItemContent}>
                <Typography typography="subtitle1Regular" color="gray900">
                  {titleText}
                </Typography>
                <Typography typography="caption1Regular" color="gray600">
                  {birthdayText}, {genderText}
                </Typography>
              </div>
              <IconChevronRightLine size={20} color={vars.$scale.color.gray900} />
            </li>
          );
        })}
      </ul>
      {KID_COUNT_TEXT.length > kids.length && (
        <div className={s.ButtonWrapper}>
          <BoxButton
            variant="secondary"
            size="medium"
            width="100%"
            prefix={<IconPlusLine size={16} color={vars.$scale.color.gray900} />}
            onClick={async () => {
              const kidIndex = data?.kids.length ?? 0;

              trackEvent({ event: 'click_add_kid_profile', params: { groupId, kidIndex } });

              const result = await push('ParentingGroupKidProfileCreatePage', {
                groupId: groupId,
                title: `${KID_COUNT_TEXT[kidIndex]} 아이`,
              });
              if (result !== 'submit') return;

              refetchParentingGroupKids();
              openSnackbar({
                message: '아이 정보를 추가했어요.',
                type: 'default',
                timeout: 3000,
                onClick: closeSnackbar,
              });
            }}
          >
            아이 추가하기
          </BoxButton>
        </div>
      )}
    </>
  );
}, {});

export default ParentingGroupKidProfileListPage;
