import { BoxButton } from '@community-group/components';
import { useState } from 'react';

import { getMyGroupListPath, useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { usePostLeftGroup } from '@/api/hooks/usePostLeftGroup';
import { queryClient } from '@/shared/api/instance';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

import * as s from './QuitGroup.css';

const DefaultValue = {
  quitGroups: [] as number[],
};

const QuitGroup = () => {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(DefaultValue);

  const { data: myGroupData } = useGetMyGroupList();

  const myJoinedGroupData = myGroupData?.groups.filter((el) => !el.isHost) ?? [];

  const handleChange = (groupId: number, check: boolean) => {
    if (check) {
      setFormValues((prevValues) => ({
        ...prevValues,
        ['quitGroups']: [...prevValues.quitGroups, groupId],
      }));
      return;
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      ['quitGroups']: prevValues.quitGroups.filter((id) => id !== groupId),
    }));
  };

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: leftMutate } = usePostLeftGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {},
  });
  const onSubmit = async () => {
    for (const groupId of formValues.quitGroups) {
      await leftMutate(groupId.toString());
      await delay(1000);
    }
    setFormValues({
      ['quitGroups']: [],
    });
  };

  const handleSelectAll = () => {
    const isSelectAll = myJoinedGroupData.length === formValues.quitGroups.length;
    const allGroups = myJoinedGroupData.map((group) => group.id) ?? [];
    setFormValues((prevValues) => ({
      ...prevValues,
      ['quitGroups']: isSelectAll ? [] : allGroups,
    }));
  };

  return (
    <div>
      <div
        className={s.TitleWrapper}
        onClick={() => {
          setOpen((prev) => !prev);
          queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
        }}
      >
        <h3>참여하고 있는 모임 나가기</h3>
        {open ? '▲' : '▼'}
      </div>
      {open && (
        <form className={s.FormWrapper}>
          <div className={s.GroupListWrapper}>
            {myJoinedGroupData.map((group) => {
              return (
                <div key={group.id}>
                  {group.name}
                  <input
                    type="checkbox"
                    checked={formValues.quitGroups.includes(group.id)}
                    onChange={(e) => {
                      handleChange(group.id, e.target.checked);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div>선택 리스트 : {formValues.quitGroups.join(', ')}</div>
          <div className={s.RowButtonGroup}>
            <BoxButton size="small" onClick={handleSelectAll}>
              전체 선택
            </BoxButton>
            <BoxButton size="small" onClick={onSubmit}>
              탈퇴하기
            </BoxButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default QuitGroup;

// 딜레이 함수: milliseconds만큼 기다린 후 Promise를 해결합니다.
function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
