import { StickyInputFormData } from '@community-group/components';
import { RefObject } from 'react';

import { getMeetupCommentsPath } from '@/api/hooks/useGetComments';
import { usePutComment } from '@/api/hooks/usePutComment';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import { useHandlePostMeetupComment } from './useHandlePostMeetupComment';

type Props = {
  listRef: RefObject<HTMLDivElement>;
  groupId: string;
  meetupId: string;
  parentCommentId?: string;
  isEditMode?: boolean;
};

const useMeetupCommentSubmit = ({
  listRef,
  groupId,
  meetupId,
  parentCommentId,
  isEditMode,
}: Props) => {
  const { mutate: mutatePostComment } = useHandlePostMeetupComment({
    ref: listRef,
    groupId,
    meetupId,
    commentId: parentCommentId ?? '',
  });

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutatePutComment } = usePutComment({
    relatedContentType: 'meetup',
    onError: handleErrorWithToast,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [getMeetupCommentsPath(groupId, meetupId)],
      });
    },
  });

  const { from } = useQueryParams();
  const handleCreateComment = (formData: StickyInputFormData, callbackHandler) => {
    trackEvent({
      event: 'click_comment_input',
      params: {
        groupId,
        meetupId,
        contentType: 'meetup',
        from,
      },
      sample: true,
    });

    mutatePostComment(
      {
        groupId,
        relatedId: meetupId,
        commentCreateForm: {
          parentId: parentCommentId ? Number(parentCommentId) : null,
          content: formData.content,
          images: formData.images.map(({ id }) => id),
          mentionedUserIds: formData.mentionedUserIds as number[],
        },
      },
      {
        ...callbackHandler,
      }
    );
  };

  const handleEditComment = (formData: StickyInputFormData, callbackHandler) => {
    mutatePutComment(
      {
        groupId: groupId,
        relatedId: meetupId,
        commentId: formData?.id as string,
        commentModifyForm: {
          content: formData.content,
          mentionedUserIds: formData.mentionedUserIds as number[],
        },
      },
      {
        ...callbackHandler,
      }
    );
  };

  const handleSubmit = (formData: StickyInputFormData, callbackHandler) => {
    if (isEditMode) {
      return handleEditComment(formData, callbackHandler);
    }

    handleCreateComment(formData, callbackHandler);
  };

  return handleSubmit;
};

export default useMeetupCommentSubmit;
