import { groupClient } from '@community-group/api';
import {
  ChallengeListPresentation,
  ChallengeStatusEnum,
  ChallengeSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { InfiniteData, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFetchInstance } from './instance/useFetchInstance';

export const getChallengeListPath = (
  id: number,
  status: ChallengeStatusEnum,
  cursor?: string,
  limit?: number
) =>
  groupClient.api.GroupChallengeApi.getapiV1GroupsGroupIdChallengesGetPath(
    id,
    status,
    cursor,
    limit
  );

export const useGetChallengeList = ({
  id,
  status,
  initialCursor = undefined,
  limit = 30,
}: {
  id: number;
  status: ChallengeStatusEnum;
  initialCursor?: string;
  limit?: number;
}) => {
  const fetchInstance = useFetchInstance();
  const getChallengeList = groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getChallengeListPath(id, status, undefined, limit), status],
      queryFn: ({ pageParam = initialCursor }) => {
        return getChallengeList(Number(id), status, pageParam, limit);
      },
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) =>
        data.pagination?.hasNext ? data?.pagination.endCursor : undefined,
      refetchInterval: 10000,
    });

  const response = useMemo(() => {
    return selectPaginationFlatten(data) as ChallengeSummaryPresentation[];
  }, [data]);

  return {
    data: response,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

function selectPaginationFlatten(
  data: InfiniteData<{ data: ChallengeListPresentation }> | undefined
): ChallengeSummaryPresentation[] {
  if (!data) return [];
  return data.pages.flatMap((page) => page.data.contents ?? []);
}
