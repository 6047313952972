import {
  getTemperature,
  HelpBubbleTrigger,
  HorizontalSpacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import {
  IconILowercaseSerifCircleLine,
  IconTriangleDownSmallFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useState } from 'react';

import { ProgressBar } from '@/components/common/ProgressBar';

import * as s from './ProfileTemperature.css';
type Props = {
  temperature: number;
};
const ProfileTemperature = ({ temperature }: Props) => {
  const [shownTooltip, setShownTooltip] = useState(false);
  return (
    <div className={s.TemperatureBox}>
      <div className={s.TemperatureBoxTitle}>
        <Typography as="p" typography="subtitle2Bold" color="gray900">
          매너온도
        </Typography>
        <VerticalSpacing size={4} />
        <HelpBubbleTrigger
          title={`매너온도는 당근 사용자로부터 받은 \n칭찬, 후기, 비매너 평가, 운영자 제재 등을\n종합해서 만든 매너 지표예요.`}
          isOpen={shownTooltip}
          UNSAFE_style={{
            whiteSpace: 'pre-wrap',
          }}
          positioning={{
            placement: 'bottom-start',
          }}
          zIndex={3}
          marginLeft={-14}
          closeOnInteractOutside
          onOpenChange={() => setShownTooltip((p) => !p)}
        >
          <IconILowercaseSerifCircleLine data-tooltip-id="info-mode" size={16} />
        </HelpBubbleTrigger>
      </div>
      <HorizontalSpacing size={12} />
      <div className={s.ProgressBox}>
        <div className={s.TemperatureGuideBox}>
          <Typography as="p" color="gray600" typography="caption1Regular">
            첫 온도 36.5°
          </Typography>
          <IconTriangleDownSmallFill size={16} style={{ color: vars.$scale.color.gray600 }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={s.Temperature} style={{ color: getTemperature(temperature).color }}>
            {getTemperature(temperature).displayText}
          </span>
          <VerticalSpacing size={6} />
          <img
            src={getTemperature(temperature).emoji}
            alt="temperature"
            style={{ width: '1.5rem' }}
          />
        </div>
        <HorizontalSpacing size={12} />
        <ProgressBar
          height="0.625rem"
          value={temperature}
          max={92.17}
          borderRadius="999px"
          fillColor={getTemperature(temperature).color}
          animateOnRender
          transitionDuration="0.5s"
        />
      </div>
    </div>
  );
};

export default ProfileTemperature;
