import { IconCategoryGroup, Typography } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { IconPerson2Openarms } from '@daangn/react-multicolor-icon';

import { useFlow } from '@/stackflow';

import * as s from './GotoGroupHomeBanner.css';

const GotoGroupHomeBanner = ({ isExistHeroSliderPhotos }: { isExistHeroSliderPhotos: boolean }) => {
  const { push } = useFlow();

  const handleGotoGroupHome = () => {
    push('HomePage', {
      from: 'NotMemberGroupDetail',
    });
  };
  return (
    <div
      className={s.Banner({ isPaperColorBackground: !isExistHeroSliderPhotos })}
      onClick={handleGotoGroupHome}
    >
      <div className={s.TextWrapper}>
        <IconPerson2Openarms size={16} />
        <Typography typography="caption1Bold" color="gray700">
          당근 모임 바로가기
        </Typography>
      </div>
      <IconChevronRightFill size={16} />
    </div>
  );
};

export default GotoGroupHomeBanner;
