import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import {
  IconChevronDownFill,
  IconChevronUpFill,
  IconFlagHillFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './BannerHeader.css';

type Props = {
  title: string;
  subTitle: string;

  isOpened: boolean;
  onToggle: () => void;
};

// 모임 상세 - 러닝크루 배너
const BannerHeader = ({ title, subTitle, isOpened, onToggle }: Props) => {
  return (
    <div className={s.Container} onClick={onToggle}>
      <div className={s.ImageContainer}>
        <IconFlagHillFill size={22} color={vars.$scale.color.gray700} />
      </div>
      <VerticalSpacing size={16} />
      <div className={s.TitleContainer}>
        <Typography typography={'caption1Regular'}>{subTitle}</Typography>
        <Spacing size={2} />
        <Typography typography={'subtitle2Bold'}>{title}</Typography>
      </div>
      <VerticalSpacing size={16} />
      <div className={s.ToggleContainer}>
        {isOpened ? (
          <IconChevronUpFill size={20} color={vars.$scale.color.gray600} />
        ) : (
          <IconChevronDownFill size={20} color={vars.$scale.color.gray600} />
        )}
      </div>
    </div>
  );
};

export default BannerHeader;
