import { Typography } from '@community-group/components';

import ChallengeSurveyCallout from '../ChallengeSurveyCallout';
import * as s from './style.css';

const EmptyChallengeList = () => {
  return (
    <div className={s.Container}>
      <ChallengeSurveyCallout />
      <div className={s.EmptyTextContainer({ withSurvey: true })}>
        <Typography typography="subtitle1Regular" textAlign="center" color="gray600">
          아직 챌린지가 없어요
          <br />
          가장 먼저 챌린지를 만들어보세요.
        </Typography>
      </div>
    </div>
  );
};

export default EmptyChallengeList;
