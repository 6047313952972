import {
  AsyncBoundary,
  ChipToggleButton,
  useBottomSheet,
  useKeyboardSize,
  ViewLoader,
} from '@community-group/components';
import { useState } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import {
  MEMBER_LIST_ORDER_TYPE,
  MemberListOrderType,
  useGetMemberList,
} from '@/api/hooks/useGetMemberList';
import { Container } from '@/components/common/Container';
import ChevronDown from '@/components/common/Icons/chevronDown';
import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import MemberSearchBar from '@/components/common/MemberSearchBar';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import { MemberListFilter } from '../pages/MemberListForHost';
import { MemberOrderTypeBottomSheet } from './MemberOrderTypeBottomSheet';
import * as s from './MemberProfileDetailList.css';
import { MemberProfileDetailSection } from './MemberProfileDetailSection';

type Props = {
  memberListFilter: MemberListFilter;
  setMemberListFiler: React.Dispatch<React.SetStateAction<MemberListFilter>>;
  selectedMemberOrderType: MemberListOrderType;
  setSelectedMemberOrderType: (selectedMemberOrderType: MemberListOrderType) => void;
};

export const MemberProfileDetailList = ({
  memberListFilter,
  setMemberListFiler,
  selectedMemberOrderType,
  setSelectedMemberOrderType,
}: Props) => {
  const { groupId } = usePathParams();

  const [isFocusedSearchBar, setIsFocusedSearchBar] = useState(false);
  const { keyboardHeight } = useKeyboardSize();

  const [searchValue, setSearchValue] = useState('');

  const handleFilterTrackEvent = ({
    orderType,
    prevOrderType,
    memberListFilter,
  }: {
    orderType: MemberListOrderType;
    prevOrderType: MemberListOrderType;
    memberListFilter: MemberListFilter;
  }) => {
    trackEvent({
      event: 'click_member_list_order_type',
      params: {
        orderType,
        prevOrderType,
        role: myInfo?.currentUser.role,
        shownOnlyHost: memberListFilter.shownOnlyHost,
      },
      sample: true,
    });
  };

  const { open: openBottomSheet } = useBottomSheet();
  const { data: myInfo } = useGetGroupMe(groupId);

  const handleSelectedMemberOrderType = (orderType: MemberListOrderType) => {
    handleFilterTrackEvent({
      orderType,
      prevOrderType: selectedMemberOrderType,
      memberListFilter,
    });

    setSelectedMemberOrderType(orderType);
  };
  const handleOrderTypeChipClick = () => {
    openBottomSheet({
      element: (
        <MemberOrderTypeBottomSheet
          selectedOrderType={selectedMemberOrderType}
          setSelectedOrderType={handleSelectedMemberOrderType}
        />
      ),
    });
  };

  return (
    <div
      className={s.wrapper}
      style={{
        paddingBottom: isFocusedSearchBar
          ? `calc(env(safe-area-inset-bottom) + ${keyboardHeight}px )`
          : 'env(safe-area-inset-bottom)',
      }}
    >
      <div className={s.searchWrapper}>
        <MemberSearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setIsFocusedSearchBar={setIsFocusedSearchBar}
          placeholder="닉네임을 검색해보세요"
        />
      </div>
      <div className={s.filterWrapper}>
        <div className={s.filterItem}>
          <ChipToggleButton onClick={handleOrderTypeChipClick}>
            <div className={s.chipWrapper}>
              {MEMBER_LIST_ORDER_TYPE[selectedMemberOrderType as MemberListOrderType]}{' '}
              <ChevronDown size={14} />
            </div>
          </ChipToggleButton>
        </div>

        <div className={s.filterItem}>
          <ChipToggleButton
            isSelected={memberListFilter.shownMemberActivities}
            onClick={() => {
              handleFilterTrackEvent({
                orderType: selectedMemberOrderType,
                prevOrderType: selectedMemberOrderType,
                memberListFilter,
              });

              setMemberListFiler({
                ...memberListFilter,
                shownMemberActivities: !memberListFilter.shownMemberActivities,
              });
            }}
          >
            활동 요약
          </ChipToggleButton>
        </div>
        <div className={s.filterItem}>
          <ChipToggleButton
            isSelected={memberListFilter.shownMemberApplication}
            onClick={() => {
              handleFilterTrackEvent({
                orderType: selectedMemberOrderType,
                prevOrderType: selectedMemberOrderType,
                memberListFilter,
              });

              setMemberListFiler({
                ...memberListFilter,
                shownMemberApplication: !memberListFilter.shownMemberApplication,
              });
            }}
          >
            가입 질문
          </ChipToggleButton>
        </div>
        <div className={s.filterItem}>
          <ChipToggleButton
            isSelected={memberListFilter.shownOnlyHost}
            onClick={() => {
              handleFilterTrackEvent({
                orderType: selectedMemberOrderType,
                prevOrderType: selectedMemberOrderType,
                memberListFilter,
              });

              setMemberListFiler({
                ...memberListFilter,
                shownOnlyHost: !memberListFilter.shownOnlyHost,
              });
            }}
          >
            운영진
          </ChipToggleButton>
        </div>
      </div>
      <AsyncBoundary pendingFallback={<ViewLoader height={500} />} rejectedFallback={<></>}>
        <MemberList
          groupId={groupId}
          selectedMemberOrderType={selectedMemberOrderType}
          memberListFilter={memberListFilter}
          searchValue={searchValue}
        />
      </AsyncBoundary>
    </div>
  );
};

type MemberListProps = {
  groupId: string;
  selectedMemberOrderType: MemberListOrderType;
  memberListFilter: MemberListFilter;
  searchValue: string;
};
const MemberList = ({
  groupId,
  selectedMemberOrderType,
  memberListFilter,
  searchValue,
}: MemberListProps) => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetMemberList({
    groupId,
    order: selectedMemberOrderType,
    ...memberListFilter,
  });

  const currentUser = useCurrentGroupMe();
  const { group } = useReadGroupDetail(groupId);

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = memberList?.flat(1) ?? [];
  const filteredMemberList = flattenMemberList.filter(({ nickname }) => {
    if (!searchValue) return true;

    return nickname.toLowerCase().includes(searchValue.toLowerCase());
  });

  const handleLoadMoreImpression = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Container paddingX={0} paddingY={0}>
      <ul className={s.list}>
        {filteredMemberList.map((member) => (
          <MemberProfileDetailSection
            key={member.id}
            user={member}
            currentUserRole={currentUser.role}
            memberListFilter={memberListFilter}
            selectedMemberOrderType={selectedMemberOrderType}
            canViewVerifiedUserInfo={group?.canViewVerifiedUserInfo ?? false}
            needVerification={group?.needVerification ?? false}
          />
        ))}
      </ul>
      {hasNextPage && <LoadMoreListContainer callback={handleLoadMoreImpression} />}
    </Container>
  );
};
