import { AsyncBoundary, GroupAvatar, ViewError, ViewLoader } from '@community-group/components';
import type { ActivityComponentType } from '@stackflow/react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetPollVotedMembers } from '@/api/hooks/useGetPollVotedMembers';
import EmptySection from '@/components/common/Empty';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { convertNicknameDisplay } from '@/utils/nickname';

import * as s from './groupPollVotedMembersPage.css';

export type PollVotedMembersPageParams = {
  groupId: string;
  postId: string;
  pollId: string;
};

const GroupPollVotedMembersPage: ActivityComponentType<PollVotedMembersPageParams> = () => {
  return (
    <AsyncBoundary
      pendingFallback={
        <AppScreen>
          <ViewLoader />
        </AppScreen>
      }
      rejectedFallback={
        <AppScreen>
          <ViewError />
        </AppScreen>
      }
    >
      <GroupPollVotedMembersWrapper />
    </AsyncBoundary>
  );
};

const GroupPollVotedMembersWrapper = () => {
  const { groupId, postId, pollId } = usePathParams();
  const { push } = useFlow();

  const { data } = useGetPollVotedMembers({
    groupId,
    postId,
    pollId,
  });

  const { group } = useReadGroupDetail(groupId);

  useEnterTrackEvent({
    event: 'enter_poll_result_page',
    params: {
      groupId: groupId,
      groupName: group?.name,
      postId: postId,
      pollId: pollId,
    },
    sample: true,
  });

  if (data && data?.votedMembers.length < 1) {
    return (
      <AppScreen>
        <EmptySection>
          <p>투표 결과가 없어요.</p>
        </EmptySection>
      </AppScreen>
    );
  }

  return (
    <AppScreen
      appBar={{
        title: data?.title,
      }}
    >
      <div>
        {data &&
          data.votedMembers.map((item) => {
            if (item.count === 0) return <></>;
            return (
              <div className={s.pollResultItem} key={item.id}>
                <p>
                  {item.title} {item.count}
                </p>
                <div className={s.pollResultProfileWrapper}>
                  {item.members.map((member) => (
                    <div
                      className={s.pollResultProfileItem}
                      key={member.memberId}
                      onClick={() => {
                        push('GroupUserProfileDetailPage', {
                          groupId: groupId,
                          userId: member.memberId?.toString() ?? '',
                        });
                      }}
                    >
                      <GroupAvatar src={member.profileImage} size="small" />
                      <p key={member.memberId}>
                        {convertNicknameDisplay({
                          nickname: member.nickName ?? '',
                          subNickname: member.subNickname ?? '',
                        })}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </AppScreen>
  );
};

export default GroupPollVotedMembersPage;
