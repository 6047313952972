import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetPermission = (groupId?: string) => {
  const fetchInstance = useFetchInstance();
  const getPermission = groupClient.api.PermissionsApi.apiV1GroupsIdPermissionsGet({
    axios: fetchInstance,
  });

  const { data, refetch, isPending } = useQuery({
    queryKey: [groupClient.api.PermissionsApi.getapiV1GroupsIdPermissionsGetPath(Number(groupId))],
    queryFn: () => getPermission(Number(groupId)),
  });

  return { data: data?.data.permissions, refetch, isLoading: isPending };
};
