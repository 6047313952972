import { ParentingGroupKidForm } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Divider,
  Spacing,
  Typography,
  useKeyboardSize,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { usePostParentingGroupKids } from '@/api/hooks/usePostParentingGroupKids';
import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { formatDateStringToKebabCase } from '@/utils/date';
import { groupMemberProfileFieldSchema } from '@/utils/validate/formSchema/subNickname';

import ParentingGroupKidProfileCreateHooks from '../components/ParentingGroupKidProfileCreateHooks';
import ParentingGroupKidProfileDescription from '../components/ParentingGroupKidProfileDescription';
import ParentingGroupKidProfileFormList from '../components/ParentingGroupKidProfileFormList';
import ParentingGroupPreview from '../components/ParentingGroupPreview';
import { DEFAULT_KID_PROFILE } from '../constants';
import * as s from './ParentingGroupMemberKidProfileCreatePage.css';

type FormValues = {
  kids: ParentingGroupKidForm[];
};

export type Params = Pick<PageParams, 'groupId' | 'createKidProfileFlowType'>;

const ParentingGroupMemberKidProfileCreatePage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { createKidProfileFlowType } = useQueryParams();
  const createKidProfileFlowFromChatRoom = useMemo(() => {
    return createKidProfileFlowType === 'fromChatRoom';
  }, [createKidProfileFlowType]);

  useEnterTrackEvent({
    event: 'enter_kid_profile_form_page',
    params: {
      groupId,
      type: 'member',
    },
  });

  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const handleMoveDescription = useCallback((e) => {
    e.preventDefault();
    scrollTargetRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }, []);

  const methods = useForm<FormValues>({
    defaultValues: {
      kids: [DEFAULT_KID_PROFILE],
    },
  });
  const { handleSubmit, watch } = methods;

  const back = useBack();
  const { bridge } = useBridge();
  const { isKeyboardOn } = useKeyboardSize(bridge);
  useSetFixedLayoutSize();
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false }, true);

  const { mutateAsync: postKids } = usePostParentingGroupKids({
    onSuccess: () => back(),
  });

  const handleComplete = handleSubmit(async (form) => {
    trackEvent({ event: 'click_form_complete_kid_profile', params: { groupId } });

    const formattedForm = {
      kids: form.kids.map((kid) => ({
        ...kid,
        birthday: formatDateStringToKebabCase(kid.birthday.trim()),
      })),
    };

    await postKids({
      groupId: Number(groupId),
      form: formattedForm,
    });
  });

  const formValue = watch();
  const isButtonDisabled = useMemo(() => {
    return !formValue.kids.every((kid) => {
      const kidResult = groupMemberProfileFieldSchema.kidProfile.safeParse(kid);
      return kidResult.success;
    });
  }, [formValue]);

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
      }}
      CUPERTINO_ONLY_modalPresentationStyle="fullScreen"
      accessoryBar={
        !isKeyboardOn && (
          <div className={s.SubmitButtonWrapper}>
            <BoxButton
              size="xlarge"
              width="100%"
              type="submit"
              variant="primary"
              onClick={handleComplete}
              isDisabled={isButtonDisabled}
            >
              완료
            </BoxButton>
          </div>
        )
      }
    >
      <FormProvider {...methods}>
        <div className={s.Container}>
          <section className={s.Section}>
            <Typography typography="title1Bold" color="gray900">
              축하해요, 우리 모임이
              <br />
              대표 육아 모임으로 선정됐어요!
            </Typography>
            <Spacing size={8} />
            <Typography typography="bodyL1Regular" color="gray900">
              당근이 직접 선정하고 관리하는 대표 모임으로 선정됐어요.
            </Typography>
            <Spacing size={24} />
            <ParentingGroupPreview groupId={groupId} />
            <Spacing size={40} />
          </section>
          <section className={s.Section}>
            <Typography typography="title2Bold" color="gray900">
              원활한 모임 활동 지원을 위해
              <br />
              아이 정보를 입력해주세요
            </Typography>
            <Spacing size={6} />
            <Typography typography="bodyL1Regular" color="gray900">
              아이 정보는 모임장만 볼 수 있어요.{' '}
              <span className={s.AnchorText} onClick={handleMoveDescription}>
                왜 아이 정보가 필요한가요?
              </span>
            </Typography>
          </section>
          <section className={s.Section}>
            <Spacing size={32} />
            <ParentingGroupKidProfileFormList />
            <Spacing size={32} />
            <Divider />
            <Spacing ref={scrollTargetRef} size={32} />
            <ParentingGroupKidProfileDescription />
          </section>
        </div>
      </FormProvider>
      <ParentingGroupKidProfileCreateHooks
        groupId={groupId}
        isFlowFromChatRoom={createKidProfileFlowFromChatRoom}
      />
    </AppScreen>
  );
};

export default ParentingGroupMemberKidProfileCreatePage;
