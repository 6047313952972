import { getGroupMeetupAttendancePath } from '@/api/hooks/useGetGroupMeetupAttendanceMembers';
import { queryClient } from '@/shared/api/instance';
type Params = {
  groupId: string;
  meetupId: string;
};

/*
 *  일정 출석체크 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupMeetupAttendance = ({ groupId, meetupId }: Params) => {
  queryClient.refetchQueries({
    queryKey: [getGroupMeetupAttendancePath(groupId, meetupId)],
  });
};
