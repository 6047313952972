import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: number;
  imageId: string;
};
type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const useDeletePinHeroImage = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deletePinHeroImage = groupClient.api.ImageApi.apiV1GroupsGroupIdPhotosPinImageIdDelete({
    axios: fetchInstance,
  });

  const deletePinHeroImageWrapperFp = ({
    groupId,
    imageId,
  }: {
    groupId: number;
    imageId: string;
  }) => {
    return deletePinHeroImage(groupId, imageId);
  };

  return useThrottleMutation(deletePinHeroImageWrapperFp, {
    onError,
    onSuccess,
  });
};
