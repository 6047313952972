import {
  MultilineTextField as SproutMultilineTextField,
  SeedMultilineTextFieldProps,
  SeedTextFieldProps,
  TextField as SproutTextField,
} from '@daangn/sprout-components-text-field';
import { useRef, useState } from 'react';

import * as s from './style.css';

type ComponentProps<T> = Omit<T, 'fieldType' | 'Component' | 'config'>;

type InputConfig = {
  scrollIntoView?: boolean;
  invalidAfterBlurred?: boolean;
  screenLock?: boolean;
};

type BaseFieldProps<T> = T & {
  isSubmitted?: boolean;
  config?: InputConfig;
  fieldType: 'input' | 'textarea';
  Component: React.ComponentType<ComponentProps<T>>;
};

const BaseField = <T extends SeedTextFieldProps | SeedMultilineTextFieldProps>({
  isSubmitted = false,
  config = { scrollIntoView: false, invalidAfterBlurred: true, screenLock: false },
  fieldType,
  Component,
  ...props
}: BaseFieldProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [blurredInput, setBlurredInput] = useState(false);

  const canCheckInvalid = isSubmitted || (config.invalidAfterBlurred ? blurredInput : true);
  const realInputWrapperRef = useRef<HTMLDivElement>(null);

  const handleFakeInputClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const realInput = realInputWrapperRef.current?.querySelector(fieldType);
    if (realInput) {
      (realInput as HTMLElement)?.focus();
      setTimeout(() => setIsFocused(true), 50);

      const scrollIntoViewTimer =
        config.scrollIntoView &&
        setTimeout(() => {
          realInput.scrollIntoView({ behavior: 'smooth' });
        }, 300);

      return () => {
        if (scrollIntoViewTimer) clearTimeout(scrollIntoViewTimer);
      };
    }
  };

  const showFakeInput = config.screenLock ? !isFocused : false;
  const realInputStyle = s.FieldWrapper({ focused: config.screenLock ? isFocused : true });

  return (
    <>
      {showFakeInput && (
        <div onClick={handleFakeInputClick}>
          <div className={s.FieldBlocker}>
            <Component
              {...(props as ComponentProps<T>)}
              isInvalid={canCheckInvalid && props.isInvalid}
            />
          </div>
        </div>
      )}
      <div ref={realInputWrapperRef} className={realInputStyle}>
        <Component
          {...(props as ComponentProps<T>)}
          onBlur={(e) => {
            props.onBlur?.(e);
            setIsFocused(false);
            setBlurredInput(true);
          }}
          isInvalid={canCheckInvalid && props.isInvalid}
        />
      </div>
    </>
  );
};

export const TextField = (
  props: SeedTextFieldProps & { isSubmitted?: boolean; config?: InputConfig }
) => <BaseField {...props} fieldType="input" Component={SproutTextField} />;

export const MultilineTextField = (
  props: SeedMultilineTextFieldProps & { isSubmitted?: boolean; config?: InputConfig }
) => <BaseField {...props} fieldType="textarea" Component={SproutMultilineTextField} />;
