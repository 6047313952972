import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupMemberManagementListPath = (groupId?: string) =>
  `${GROUP_URL}/${groupId}/members/managements`;

export const useGetGroupMemberManagementList = (groupId?: string) => {
  const fetchInstance = useFetchInstance();
  const getGroupMemberManagementList =
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersManagementsGet({ axios: fetchInstance });

  const { data, refetch, isPending } = useQuery({
    queryKey: [getGroupMemberManagementListPath(groupId)],
    queryFn: () => {
      return getGroupMemberManagementList(Number(groupId));
    },
    enabled: !!groupId,
  });

  const response = data?.data;

  return { data: response, refetch, isLoading: isPending };
};
