import { GroupSidebarPresentationDefaultBoards } from '@community-group/api/lib/group/models';
import { IconCalendarFill, IconHouseFill, IconMegaphoneFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler, useMemo } from 'react';

import * as s from './SidebarBoardList.css';
import SidebarMenuListItem from './SidebarMenuListItem';

type Props = {
  boardList: GroupSidebarPresentationDefaultBoards[];
  upcomingMeetupCount: number;
  onHomeMenuClick: MouseEventHandler;
  onMeetupMenuClick: MouseEventHandler;
  onNoticeMenuClick?: MouseEventHandler;
};

const SidebarBoardList = ({
  boardList,
  upcomingMeetupCount,
  onHomeMenuClick,
  onMeetupMenuClick,
  onNoticeMenuClick,
}: Props) => {
  const menuMap = useMemo(
    () => ({
      home: {
        onClick: onHomeMenuClick,
        icon: <IconHouseFill size={14} color={vars.$scale.color.gray700} />,
        text: '홈',
      },
      notice: {
        onClick: onNoticeMenuClick,
        icon: <IconMegaphoneFill size={14} color={vars.$scale.color.gray700} />,
        text: '공지사항',
      },
      meetup: {
        onClick: onMeetupMenuClick,
        icon: <IconCalendarFill size={14} color={vars.$scale.color.gray700} />,
        text: '모임일정',
        subtext: upcomingMeetupCount > 0 ? String(upcomingMeetupCount) : undefined,
      },
    }),
    [onHomeMenuClick, onNoticeMenuClick, onMeetupMenuClick, upcomingMeetupCount]
  );
  const menuList = useMemo(() => boardList.map((board) => menuMap[board]), [menuMap, boardList]);

  return (
    <ul>
      <SidebarMenuListItem
        text={menuMap.home.text}
        icon={<div className={s.ListItemIconWrapper}>{menuMap.home.icon}</div>}
        onClick={menuMap.home.onClick}
      />
      {menuList.map((item, index) => (
        <SidebarMenuListItem
          key={`${item.text}-${index}`}
          text={item.text}
          icon={<div className={s.ListItemIconWrapper}>{item.icon}</div>}
          onClick={item.onClick}
        />
      ))}
      <SidebarMenuListItem
        text={menuMap.meetup.text}
        subtext={menuMap.meetup.subtext}
        icon={<div className={s.ListItemIconWrapper}>{menuMap.meetup.icon}</div>}
        onClick={menuMap.meetup.onClick}
      />
    </ul>
  );
};

export default SidebarBoardList;
