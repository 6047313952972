import { useEffect } from 'react';

import { useStorage } from '@/hooks/useStorage';

const useGroupDetailGroupChallengeBannerClosed = (
  targetId: string
): [boolean, (nextValue: boolean) => void] => {
  const [storage, setStorage] = useStorage('groupDetailGroupChallengeBannerClosed', {
    [targetId]: false,
  });

  useEffect(() => {
    setStorage({
      [targetId]: false,
      ...storage,
    });
  }, [setStorage, storage, targetId]);

  const setTargetStorage = (nextValue: boolean) => {
    setStorage({
      ...storage,
      [targetId]: nextValue,
    });
  };

  // NOTE: SSR 시점에 스토리지 조회를 실패하면 배너를 노출하지 않는 게 UX를 해치지 않아서 기본값은 true로 설정해요
  return [typeof window === 'undefined' ? true : storage?.[targetId] ?? true, setTargetStorage];
};

export default useGroupDetailGroupChallengeBannerClosed;
