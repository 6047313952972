import { Typography, VerticalSpacing } from '@community-group/components';
import { IconCheckmarkLine, IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { RunningGrowthMission } from '../hooks/useRunningGrowthMission';
import * as s from './style.css';

type Props = RunningGrowthMission;

// 모임 상세 - 러닝그로스 배너 내부 러닝그로스 미션 아이템
const RunningGrowthBannerItem = ({ title, step, onButtonClick, isCompleted }: Props) => {
  const handleClickItem = () => {
    onButtonClick();
  };

  return (
    <div className={s.ItemContainer({ isSuccess: isCompleted })} onClick={handleClickItem}>
      <div className={s.TitleContainer}>
        <div className={s.StepWrapper({ isSuccess: isCompleted })}>
          <Typography typography="caption2Bold" color={isCompleted ? 'success' : 'gray700'}>
            {step}
          </Typography>
        </div>
        <VerticalSpacing size={12} />
        <Typography
          typography="subtitle2Regular"
          color={isCompleted ? 'green700' : 'gray900'}
          ellipsisAfterLines={1}
        >
          {title}
        </Typography>
      </div>
      <VerticalSpacing size={12} />
      <div className={s.StatusContainer}>
        {isCompleted ? (
          <IconCheckmarkLine size={16} color={vars.$semantic.color.success} />
        ) : (
          <IconChevronRightLine size={16} color={vars.$scale.color.gray600} />
        )}
      </div>
    </div>
  );
};

export default RunningGrowthBannerItem;
