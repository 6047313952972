import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<AxiosResponse<void>, Error, { id: number }>;

// 그룹 고스트 밴
export const useDeleteRestrictGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const DeleteRestrictGroup = groupClient.api.GroupRestrictApi.apiV1GroupsIdRestrictDelete({
    axios: fetchInstance,
  });

  const DeleteRestrictGroupWrapperFp = ({ id }: { id: number }) => {
    return DeleteRestrictGroup(id);
  };

  return useThrottleMutation(DeleteRestrictGroupWrapperFp, {
    onError,
    onSuccess,
  });
};
