import { PostPoiPresentationType } from '@community-group/api/lib/group/models';
import linkify from 'linkify-it';

import { DOMAIN_URL } from '@/constants/path';
import { bridge } from '@/contexts/Bridge';
import { routes } from '@/stackflow/activity/routes';

import { getWebviewSchemePrefix } from './appLinkStore';
import { getAppVersion } from './version';

const { appEnv, isAppEnvProduction } = globalThis.appConfig;

/**
 * @description
 * 당근 앱 딥링크 프로토콜
 * (towneers는 당근 초창기 서비스 이름 후보중 하나로 아직 모바일 앱 등에서 사용되고 있는 곳들이 있어요. towneers가 보이면 karrot으로 바꿔야 해요.)
 */
export const APP_DEEP_LINK_PROTOCOL = isAppEnvProduction ? 'karrot://' : 'karrot.alpha://';

/**
 * @description
 * 당근 Web BASE URL
 */
export const DAANGN_WEB_BASE_URL = isAppEnvProduction
  ? 'https://www.daangn.com/kr'
  : 'https://alpha.daangn.com/kr';

export const PoiWebviewBaseUrl = isAppEnvProduction
  ? 'https://place.karrotwebview.com/#/home/'
  : 'https://place.alpha.karrotwebview.com/#/home/';

export const BIZ_PROFILE_BASE_URL = isAppEnvProduction
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com';

export const REPORT_WEB_BASE_URL = isAppEnvProduction
  ? 'https://report.karrotwebview.com'
  : 'https://report.alpha.karrotwebview.com';

export const APT_V2_BASE_URL = isAppEnvProduction
  ? 'https://community-web-apartment.kr.karrotmarket.com'
  : 'https://community-web-apartment.alpha.kr.karrotmarket.com';

export const getPermalink = (publicId?: string) => {
  return isAppEnvProduction
    ? `https://www.daangn.com/kr/groups/${publicId}?utm_medium=copy_link`
    : `https://alpha.daangn.com/kr/groups/${publicId}?utm_medium=copy_link`;
};

export const getGroupPostPermalink = (publicId: string) => {
  return isAppEnvProduction
    ? `https://www.daangn.com/kr/group-posts/${publicId}`
    : `https://alpha.daangn.com/kr/group-posts/${publicId}`;
};

export const getGroupMeetupPermalink = (publicId: string) => {
  return `${DAANGN_WEB_BASE_URL}/meetups/${publicId}`;
};

export const getGroupChallengePermalink = (publicId: string) => {
  return `${DAANGN_WEB_BASE_URL}/group/challenge/${publicId}`;
};

export const shareGroupPostPermalink = (publicId: string, text = '당근 모임 글 공유하기') => {
  bridge.share({
    share: {
      url: getGroupPostPermalink(publicId),
      text,
    },
  });
};

export const shareGroupMeetupPermalink = (publicId: string, text = '당근 모임 글 공유하기') => {
  bridge.share({
    share: {
      url: getGroupMeetupPermalink(publicId),
      text,
    },
  });
};

export const shareGroupChallengePermalink = (
  publicId: string,
  text = '당근 모임 챌린지 공유하기'
) => {
  bridge.share({
    share: {
      url: getGroupChallengePermalink(publicId),
      text,
    },
  });
};

export const openLandingPage = () => {
  window.location.href = isAppEnvProduction
    ? `karrot://${getWebviewSchemePrefix()}/router?remote=https%3A%2F%2Fevent.karrotwebview.com%2F%23%2Fwv%2Fkr%2F61%3Fnavbar%3Dfalse%26scrollable%3Dtrue`
    : `karrot.alpha://${getWebviewSchemePrefix()}/router?remote=https%3A%2F%2Fevent.alpha.karrotwebview.com%2F%23%2Fwv%2Fkr%2F148%3Fnavbar%3Dfalse%26scrollable%3Dtrue`;
};

export const openSurvey = () => {
  window.location.href = isAppEnvProduction
    ? 'karrot://web/ad/user_surveys/7826'
    : 'karrot.alpha://web/ad/user_surveys/61';
};

export const openRunningGrowthGuideBook = (step: number) => {
  const stepUrls = {
    1: 'https://daangn.notion.site/1-11728c3a9f8f8071b5c9d050737bc3cf?pvs=4',
    2: 'https://daangn.notion.site/2-11728c3a9f8f8074853fe3480e1ad514?pvs=4',
    3: 'https://daangn.notion.site/3-11728c3a9f8f8016af78daed22334f65?pvs=4',
    4: 'https://daangn.notion.site/4-11728c3a9f8f80dab710d308820b93c8?pvs=4',
    5: 'https://daangn.notion.site/5-11728c3a9f8f8041aab3ca0e54810f3a?pvs=4',
  };

  const url = stepUrls[step];
  if (!url) return;

  return bridge.pushRouter({
    router: {
      remote: url,
      navbar: true,
      enableSafeAreaInsets: false,
      backSwipable: true,
    },
  });
};

export const openRunningGrowthRewardSurvey = (step: number) => {
  const surveyUrls = {
    1: 'karrot://minikarrot/router?remote=https%3A%2F%2Fkarrot-form-webview.kr.karrotwebview.com%2Fsurvey%2F2171&navbar=false&scrollable=false',
    2: 'karrot://minikarrot/router?remote=https%3A%2F%2Fkarrot-form-webview.kr.karrotwebview.com%2Fsurvey%2F2172&navbar=false&scrollable=false',
    3: 'karrot://minikarrot/router?remote=https%3A%2F%2Fkarrot-form-webview.kr.karrotwebview.com%2Fsurvey%2F2173&navbar=false&scrollable=false',
    4: 'karrot://minikarrot/router?remote=https%3A%2F%2Fkarrot-form-webview.kr.karrotwebview.com%2Fsurvey%2F2174&navbar=false&scrollable=false',
    5: 'karrot://minikarrot/router?remote=https%3A%2F%2Fkarrot-form-webview.kr.karrotwebview.com%2Fsurvey%2F2175&navbar=false&scrollable=false',
  };
  const url = surveyUrls[step as number];
  if (url) window.location.href = url;
};

export const openGuideBook = () => {
  return bridge.pushRouter({
    router: {
      remote: 'https://daangn-group.oopy.io',
      navbar: true,
      enableSafeAreaInsets: false,
      backSwipable: true,
    },
  });
};

export const openNotification = () => {
  window.location.href = `${APP_DEEP_LINK_PROTOCOL}notifications`;
};

export const openGroupReport = ({ groupId }: { groupId: string }) => {
  const remote = `${REPORT_WEB_BASE_URL}/?kind=group&group_id=${groupId}`;

  bridge.pushRouter({
    router: {
      remote,
      navbar: false,
      scrollable: false,
    },
  });
};

export const openGroupCommentReport = (commentId: string, commentAuthorId: string) => {
  const remote = `${REPORT_WEB_BASE_URL}/?kind=group_comment&comment_id=${commentId}&comment_author_id=${commentAuthorId}`;

  bridge.pushRouter({
    router: {
      remote,
      navbar: false,
      scrollable: false,
    },
  });
};

export const openGroupProfileReport = ({
  groupId,
  userId,
}: {
  groupId: string;
  userId: string;
}) => {
  const remote = `${REPORT_WEB_BASE_URL}/?kind=group_member&group_id=${groupId}&user_id=${userId}`;

  bridge.pushRouter({
    router: {
      remote,
      navbar: false,
      scrollable: false,
    },
  });
};

export const openGroupPostReport = ({ groupId, postId }: { groupId: string; postId: string }) => {
  const remote = `${REPORT_WEB_BASE_URL}/?kind=group_post&group_id=${groupId}&post_id=${postId}`;

  bridge.pushRouter({
    router: {
      remote,
      navbar: false,
      scrollable: false,
    },
  });
};

export const openGroupMeetupReport = ({
  meetupId,
  hostId,
}: {
  meetupId: string;
  hostId: string; // 참고(URL: https://daangn.slack.com/archives/CUFCHRMTR/p1697525819317569?thread_ts=1696993924.788829&cid=CUFCHRMTR)
}) => {
  const remote = `${REPORT_WEB_BASE_URL}/?kind=group_meetup&meetup_id=${meetupId}&user_id=${hostId}`;

  bridge.pushRouter({
    router: {
      remote,
      navbar: false,
      scrollable: false,
    },
  });
};

export const openGroupHome = () => {
  const url = DOMAIN_URL;

  const groupHomePath = routes.HomePage;

  if (appEnv === 'dev') {
    return window.open(`${url}/${groupHomePath}`, 'newWindow');
  }

  return bridge.pushRouter({
    router: {
      remote: `${url}/${groupHomePath}`,
      navbar: false,
      enableSafeAreaInsets: false,
      backSwipable: true,
      scrollable: false,
    },
  });
};

export const replaceWebviewHome = () => {
  bridge.replaceRouter({
    router: {
      remote: `${DOMAIN_URL}${routes.HomePage}`,
      navbar: false,
      enableSafeAreaInsets: false,
      backSwipable: false,
      scrollable: false,
    },
  });
};

export const openPoiDetailPage = ({
  id,
  type = 'POI',
}: {
  id: number;
  type?: PostPoiPresentationType;
}) => {
  if (type === 'POI') {
    const remoteUrl = `${PoiWebviewBaseUrl}${id}?referrer=community_group.place_search`;
    bridge.pushRouter({
      router: {
        remote: remoteUrl,
        navbar: false,
        scrollable: false,
      },
    });
    return;
  }

  if (type === 'LOCAL_PROFILE') {
    const baseURL = encodeURIComponent(
      `${BIZ_PROFILE_BASE_URL}/local_profiles/${id}/map?referrer=community_group.place_search`
    );

    const baseSchema = `${APP_DEEP_LINK_PROTOCOL}minikarrot/router?remote=${baseURL}&navbar=false&scrollable=false`;
    window.location.href = baseSchema;
  }

  if (type === 'BUSINESS_ACCOUNT') {
    const baseURL = encodeURIComponent(
      `${BIZ_PROFILE_BASE_URL}/biz_accounts/${id}/viewer/home?referrer=community_group.place_search`
    );

    const baseSchema = `${APP_DEEP_LINK_PROTOCOL}minikarrot/router?remote=${baseURL}&navbar=false&scrollable=false`;
    window.location.href = baseSchema;
  }
};

export type QueryObjectType = { [key: string]: string | number | boolean | undefined };

export const queryObjectToQureyString = (queryObject: QueryObjectType | undefined) => {
  if (!queryObject) return '';
  return `?${Object.keys(queryObject)
    .map((key) => {
      if (key && queryObject[key]) return `${key}=${queryObject[key]}`;
      return `${key}=undefined`;
    })
    .join('&')}`;
};

export const openLink = (uri?: string | null, navbar?: boolean) => {
  if (!uri) return new Error('uri is required');
  const reg = /^karrot(...)*/;
  const isKarrotScheme = reg.test(uri);
  if (isKarrotScheme) {
    window.location.href = uri;
    return;
  }
  return bridge.pushRouter({
    router: {
      remote: uri,
      navbar: navbar,
      enableSafeAreaInsets: false,
      backSwipable: true,
    },
  });
};

export const openCommunityHome = () => {
  window.location.href = isAppEnvProduction ? 'karrot://tab/story' : 'karrot.alpha://tab/story';
};

export const openVerificationUser = () => {
  window.location.href = isAppEnvProduction
    ? 'karrot://identification?type=account&referrer=community'
    : 'karrot.alpha://identification?type=account&referrer=community';
};

export const openMyWatches = () => {
  window.location.href = isAppEnvProduction
    ? 'karrot://my_watches?referrer=community'
    : 'karrot.alpha://my_watches?referrer=community';
};

export const copyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

// TODO: 컨텐츠위버 쪽에서 type 분기 필요
export const getMultiRoomPermalink = (publicId?: string) => {
  return isAppEnvProduction
    ? `https://www.daangn.com/kr/group-multi-rooms/${publicId}`
    : `https://alpha.daangn.com/kr/group-multi-rooms/${publicId}`;
};

export const getMyInfoGroupMeetupLikedPageLink = () => {
  const url = DOMAIN_URL;

  const subPath = `remote=${encodeURIComponent(`${url}/my-info/group/meetups/liked-list`)}`;
  const schemaParams = '&navbar=false&scrollable=false';

  return `${APP_DEEP_LINK_PROTOCOL}community-web/router?${subPath}${schemaParams}`;
};

export const openHref = (uri?: string) => {
  if (!uri) return;
  window.location.href = uri;
};

export const openAppStore = () => {
  const { os } = getAppVersion();
  if (os === 'iOS') {
    window.location.href = 'https://apps.apple.com/app/id1018769995';
    return;
  }

  window.location.href = 'https://play.google.com/store/apps/details?id=com.towneers.www';
};

// 종교 포교를 해요 - 사용자 신고
export const openGroupProfilePropagationReport = ({
  groupId,
  targetUserId,
}: {
  groupId: string;
  targetUserId: string;
}) => {
  const remote = `${REPORT_WEB_BASE_URL}/group_member/?userId=${targetUserId}&groupId=${groupId}&reportItemId=%2F6002&mode=form`;

  bridge.pushRouter({
    router: {
      remote,
      navbar: false,
      scrollable: false,
    },
  });
};

// 모임 제재 시 소명 할 수 있는 문의 링크
export const openRestrictedDealFeedback = () => {
  const link = `${APP_DEEP_LINK_PROTOCOL}web/wv/feedbacks/new?kind=deal_problem`;

  if (typeof window !== 'undefined') {
    window.location.href = link;
  }
};

export const getNativeChatTabScheme = () => {
  return `${APP_DEEP_LINK_PROTOCOL}tab/chat`;
};

export const openGroupPayTransferDirectChat = (transferTicket: string) => {
  const link = isAppEnvProduction
    ? `karrot://pay/send-money/ticket/${transferTicket}`
    : `karrot.alpha://pay/send-money/ticket/${transferTicket}`;

  window.location.href = link;
};

export const parseLink = (text: string) => {
  const linkifyInstance = linkify();
  linkifyInstance.add('mailto:', null);
  linkifyInstance.set({ fuzzyEmail: true });

  return linkifyInstance.match(text)?.[0]?.url;
};

export const openAptV2Detail = (aptId: string) => {
  window.location.href = `${APT_V2_BASE_URL}/apt/${aptId}/detail`;
};
