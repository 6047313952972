import { JoinableRegionRange } from '@community-group/api/lib/group/models';
import { MapInstance } from '@community-group/components';
import { GeoJSONSourceRaw } from 'mapbox-gl';

import SouthKoreaGeo from '@/assets/south-korea-geo.json';

import { RegionRangeType } from '../model';

export const getGeoJson = (
  placeName: string,
  coordinates: GeoJSON.Position[][][]
): GeoJSONSourceRaw => {
  return {
    type: 'geojson' as const,
    data: {
      properties: { name: placeName },
      type: 'Feature' as const,
      geometry: {
        type: 'MultiPolygon' as const,
        coordinates,
      },
    },
  };
};

const getRegionPolygons = (regionRanges, rangeKey) => {
  const targetRegionRange = regionRanges.find((range) => range.key === rangeKey) ?? regionRanges[0];
  return targetRegionRange.regionPolygons.map(([lng, lat]) => [lat, lng]);
};

export const getCoordinates = (
  joinableRegionRanges: JoinableRegionRange[],
  regionRange: RegionRangeType
): GeoJSON.Position[][][] => {
  const regionPolygons = getRegionPolygons(joinableRegionRanges, regionRange);
  return regionRange === 'unlimited' ? SouthKoreaGeo.coordinates : [[regionPolygons]];
};

export const addPolygonLayer = (mapInstance: MapInstance, polygonCoordinates: GeoJSONSourceRaw) => {
  mapInstance.addPolygonLayer(polygonCoordinates, {
    removeOthers: true,
    moveToCenter: true,
    animate: false,
    areaMarginPixel: {
      top: 10,
      bottom: 10,
      left: 10,
      right: 10,
    },
    style: {
      fillPaint: {
        'fill-color': '#FF7E36',
        'fill-opacity': 0.15,
      },
      linePaint: {
        'line-color': '#FF7E36',
        'line-width': 1,
      },
    },
  });
};
