import {
  IconHorizline2VerticalChatbubbleRectangularRightFill,
  IconPenHorizlineFill,
  IconPersonFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ReactNode, useEffect, useMemo } from 'react';

import { useGetMemberMissionList } from '@/api/hooks/useGetMemberMissionList';
import { useUserConfig } from '@/contexts/UserConfig';
import { TypeActivityNames, useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import useShownMemberMissionCompletedMessage from './useShownMemberMissionCompletedMessage';

export type MemberMission = {
  icon: ReactNode;
  title: string;
  onClick: () => void;
  isCompleted: boolean;
};

const useMemberMissionList = (groupId: string): MemberMission[] => {
  const { push } = useFlow();
  const { userConfig } = useUserConfig();
  const { data } = useGetMemberMissionList(groupId);

  const missionList = useMemo(() => {
    const {
      isSetProfile = false,
      isFirstPostCreated = false,
      isFirstCommentCreated = false,
    } = data?.data.groupMemberOnboarding ?? {};

    const getThemeIconColor = (isCompleted) =>
      isCompleted ? vars.$semantic.color.success : vars.$scale.color.gray700;

    const onTrackEvent = (activityName: TypeActivityNames) => {
      trackEvent({
        event: 'click_onboarding_mission_button',
        params: {
          groupId,
          activityName,
        },
        sample: true,
      });
    };

    return [
      {
        icon: <IconPersonFill size={16} color={getThemeIconColor(isSetProfile)} />,
        title: '프로필 완성하기',
        onClick: () => {
          onTrackEvent('GroupProfileEditPage');
          push('GroupProfileEditPage', {
            groupId,
            userId: userConfig.userId ?? 0,
            calloutType: 'member-profile-set-mission',
          });
        },
        isCompleted: isSetProfile,
      },

      {
        icon: <IconPenHorizlineFill size={16} color={getThemeIconColor(isFirstPostCreated)} />,
        title: '가입 인사 글쓰기',
        onClick: () => {
          onTrackEvent('GroupPostNewPage');
          push('GroupPostNewPage', {
            groupId,
            calloutType: 'member-self-introduction',
          });
        },
        isCompleted: isFirstPostCreated,
      },
      {
        icon: (
          <IconHorizline2VerticalChatbubbleRectangularRightFill
            size={16}
            color={getThemeIconColor(isFirstCommentCreated)}
          />
        ),
        title: '댓글 작성하기',
        onClick: () => {
          onTrackEvent('GroupDetailFeedListPage');
          push('GroupDetailFeedListPage', { groupId });
        },
        isCompleted: isFirstCommentCreated,
      },
    ].sort((prev, current) => {
      if (prev.isCompleted && !current.isCompleted) {
        return -1;
      }
      if (!prev.isCompleted && current.isCompleted) {
        return 1;
      }
      return 0;
    });
  }, [data?.data.groupMemberOnboarding, groupId, push, userConfig.userId]);

  const [shownMessage, setShownMessage] = useShownMemberMissionCompletedMessage(groupId);
  useEffect(() => {
    if (!data?.data.groupMemberOnboarding?.isOnboardingCompleted) {
      return;
    }

    setShownMessage(true);
  }, [data?.data.groupMemberOnboarding?.isOnboardingCompleted, setShownMessage]);

  return shownMessage ? [] : missionList;
};

export default useMemberMissionList;
