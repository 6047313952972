import {
  GroupCurrentUser,
  GroupSuperHostPresentation,
} from '@community-group/api/lib/group/models';
import {
  Dialog,
  GroupAvatar,
  HelpBubbleTrigger,
  isNotMember,
  Typography,
  useBottomSheet,
  useDialog,
} from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import {
  IconILowercaseSerifCircleLine,
  IconLocationpinFill,
  IconPersonShieldFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useState } from 'react';

import ImageSliderBottomSheet from '@/components/common/base/slider/ImageSlider/ImageSliderBottomSheet';
import { BLOCK_GROUP_ONLY_TEXT } from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useHandleGroupOnlyForProfile } from '@/components/group/profile/MemberProfileDetail/hooks/useHandleGroupOnlyForProfile';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { getTemperatureColor } from '@/utils/temperature';

import * as s from './SuperHostInformation.css';

type Props = {
  profile: GroupSuperHostPresentation;
  currentUser?: GroupCurrentUser;
  onClickProfileImage?: () => void;
  visibleVerifiedInfoIcon?: boolean; // tooltip 컴포넌트 스크롤시 따라오지 못하는 이슈로 임시로 추가
};

const SuperHostInformation = ({
  profile,
  currentUser,
  onClickProfileImage,
  visibleVerifiedInfoIcon = true,
}: Props) => {
  const [shownTooltip, setShownTooltip] = useState(true);

  const handleShownTooltip = () => {
    setShownTooltip(!shownTooltip);
  };

  const renderVerifiedInfo = () => {
    if (!profile?.verified) return;

    return `본인인증 완료`;
  };

  const { groupId } = usePathParams();

  const { open: openBottomSheet } = useBottomSheet();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { handleGroupOnly } = useHandleGroupOnlyForProfile({
    groupId,
  });

  const handleImageClick = () => {
    if (onClickProfileImage) {
      onClickProfileImage();
      return;
    }
    const isBlock = currentUser && isNotMember(currentUser?.role);
    trackEvent({
      event: 'click_user_profile_image',
      params: { groupId: groupId, isJoined: !isBlock, userState: currentUser?.state },
      sample: true,
    });
    if (currentUser?.state === 'applied') {
      openDialog({
        element: (
          <Dialog
            title="모임 가입 안내"
            description="가입 승인 후 프로필을 볼 수 있어요. 승인 후 다시 확인해주세요."
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
            }}
          />
        ),
        onOutsideClick: closeDialog,
      });
      return;
    }

    handleGroupOnly({
      isBlock,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getMemberProfileImage,
      onSettled: () => {
        openBottomSheet({
          element: <ImageSliderBottomSheet initialIndex={0} images={[profile.profileImage]} />,
          config: {
            isDimAnimation: false,
            bottomSheetAnimationDuration: 0.5,
          },
        });
      },
      onSuccess: () => {
        openBottomSheet({
          element: <ImageSliderBottomSheet initialIndex={0} images={[profile.profileImage]} />,
          config: {
            isDimAnimation: false,
            bottomSheetAnimationDuration: 0.5,
          },
        });
      },
    });
  };

  return (
    <div>
      <div className={s.top}>
        <div className={s.leftBox} onClick={handleImageClick}>
          <GroupAvatar size="large" src={profile.profileImage}></GroupAvatar>
        </div>
        <div className={s.rightBox}>
          <div className={s.topContentWrapper}>
            <div className={s.titleWrapper} style={{ marginBottom: '0' }}>
              <Typography className={s.name} typography="title3Bold" color="gray900">
                {profile?.nickname}
              </Typography>
              <span
                className={s.temperature}
                style={{
                  color: getTemperatureColor(profile?.temperature)?.color,
                  background: getTemperatureColor(profile?.temperature)?.background,
                }}
              >
                {profile?.temperature.toFixed(1)}°C
              </span>
            </div>
            <Typography color="gray900" typography="subtitle1Regular">
              {profile?.subNickname}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        {profile?.description && (
          <>
            <Typography
              color="gray900"
              typography="bodyM1Regular"
              whiteSpace="pre-wrap"
              style={{
                lineHeight: '1.625rem',
                maxHeight: 'auto',
              }}
            >
              {profile?.description.trim()}
            </Typography>
            <Spacing size={16} />
          </>
        )}
        {profile?.verified && (
          <>
            <div className={s.iconWrapper}>
              <IconPersonShieldFill size={16} color={vars.$scale.color.green500} />
              <VerticalSpacing size={8} />
              <Typography color="gray900" typography="label3Regular">
                {renderVerifiedInfo()}
              </Typography>
              <VerticalSpacing size={4} />
              {visibleVerifiedInfoIcon && (
                <HelpBubbleTrigger
                  title={`휴대폰 본인인증 절차를 통과한\n이웃이에요.`}
                  isOpen={!shownTooltip}
                  UNSAFE_style={{
                    whiteSpace: 'pre-wrap',
                  }}
                  positioning={{
                    placement: 'bottom-start',
                  }}
                  marginLeft={-14}
                >
                  <IconILowercaseSerifCircleLine
                    data-tooltip-id="info-mode"
                    width={16}
                    height={16}
                    color={vars.$scale.color.gray600}
                    onClick={handleShownTooltip}
                  />
                </HelpBubbleTrigger>
              )}
            </div>
            <Spacing size={8} />
          </>
        )}

        <div className={s.iconWrapper}>
          <IconLocationpinFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={8} />
          <Typography color="gray900" typography="label3Regular">
            {profile?.userRegionCheckIn.regionName}{' '}
            {profile?.userRegionCheckIn.count !== 0 && `인증 ${profile?.userRegionCheckIn.count}회`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SuperHostInformation;
