import { Spacing, withAsyncBoundary } from '@community-group/components';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';

import useMainBannerFolded from '@/components/group/DetailV2/hooks/useMainBannerFolded';
import { TypeActivityNames } from '@/stackflow';

import useRunningGrowthMission from '../hooks/useRunningGrowthMission';
import BannerFooter from './BannerFooter';
import BannerHeader from './BannerHeader';
import RunningGrowthBannerItem from './RunningGrowthBannerItem';
import * as s from './style.css';

export type QuestionListType = {
  step: number;
  title: React.ReactNode | string;
  description: string;
  onButtonClick: () => void;
  isCompleted: boolean;
  activityName?: TypeActivityNames;
};

const RunningGrowthBanner = () => {
  const { foldedMainBanner, handleToggle } = useMainBannerFolded();
  const questList = useRunningGrowthMission();

  // 슬라이드 페이지를 나누기 위해 3개씩 묶어줌
  const questChunk = questList.reduce((acc, curr, index) => {
    const chunkIndex = Math.floor(index / 3);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(curr);
    return acc;
  }, [] as QuestionListType[][]);

  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    //questChunk 별로 모두 isSuccess가 아닌 chunk의 index
    const initialSlideIndex = questChunk.findIndex((chunk) =>
      chunk.some((quest) => !quest.isCompleted)
    );
    setInitialSlide(Math.max(initialSlideIndex, 0));
  }, []);

  const subTitle = '각 미션을 신청하고 리워드 얻어보세요.';
  const title = '러닝 모임 미션';

  const handleChangeCloseBanner = () => {
    handleToggle();
  };

  const animateConfig = foldedMainBanner
    ? { opacity: 0, height: 0, transitionEnd: { display: 'none' } }
    : {
        opacity: 1,
        height: 'auto',
        transitionEnd: {
          display: 'block',
        },
      };

  return (
    <AnimatePresence>
      <motion.div className={s.Container}>
        <motion.div className={s.ListContainer}>
          <BannerHeader
            title={title}
            subTitle={subTitle}
            isOpened={!foldedMainBanner}
            onToggle={handleChangeCloseBanner}
          />

          <motion.div
            style={{ width: '100%' }}
            animate={animateConfig}
            initial={false}
            transition={{
              opacity: {
                duration: 0.3,
                type: 'just',
                bounce: 0.26,
              },
              height: {
                duration: 0.3,
                type: 'just',
                bounce: 0.26,
              },
            }}
          >
            <div className={s.ListContainer}>
              <Spacing size={16} />
              {questChunk[initialSlide].map((item, index) => (
                <React.Fragment key={'RunningGrowthBanner-' + index}>
                  <RunningGrowthBannerItem {...item} />
                  <Spacing size={8} />
                </React.Fragment>
              ))}
              <Spacing size={8} />
              <BannerFooter
                onToggle={handleChangeCloseBanner}
                initialSlide={initialSlide}
                setInitialSlide={setInitialSlide}
              />
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default withAsyncBoundary(RunningGrowthBanner, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
