import { useMemo } from 'react';

import { useStorage } from './useStorage';

export const useGetParentingGroupStorage = (groupId: string) => {
  const [parentingGroup] = useStorage('parentingGroup', {});
  return useMemo(
    () =>
      parentingGroup?.[groupId] ?? {
        isKidProfileEnabled: false,
        isTargetGroup: false,
        isShowNotMemberParentingGroupBannerEnabled: false,
      },
    [groupId, parentingGroup]
  );
};
