import { Typography, withAsyncBoundary } from '@community-group/components';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';

import { GroupMeetupDetailAccessoryBar } from './GroupMeetupDetailAccessoryBar';
import { GroupMeetupRightAppBar } from './GroupMeetupRightAppBar';

type Props = {
  children: React.ReactNode;
};

export const GroupMeetupDetailAppScreen = ({ children }: Props) => {
  const { groupId, meetupId } = usePathParams();

  return (
    <AppScreen
      onPull={() => {
        refetchGroupMeetupDetail({
          groupId,
          meetupId,
        });
      }}
      appBar={{
        title: <GroupNameTitleAppBar />,
        overflow: 'visible',
        renderRight: () => <GroupMeetupRightAppBar />,
      }}
      accessoryBar={<GroupMeetupDetailAccessoryBar />}
      disabledClientOnly={true}
    >
      {children}
    </AppScreen>
  );
};

const GroupNameTitleAppBar = withAsyncBoundary(() => {
  const { groupId } = usePathParams();
  const { group } = useReadGroupDetail(groupId);
  const { push } = useFlow();

  const moveToGroupDetail = () => {
    push('GroupDetailPage', {
      groupId,
      activeTab: 'feed',
    });
  };
  return (
    <Typography
      typography="title3Bold"
      onClick={moveToGroupDetail}
      style={{
        verticalAlign: 'middle',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {group?.name}
    </Typography>
  );
}, {});
