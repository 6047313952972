import { Image } from '@community-group/api/lib/group/models';
import { BoxButton, Spacing, Typography } from '@community-group/components';

import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';
import { useFlow } from '@/stackflow';

import PrevWeekBanner from './PrevWeekBanner';
import RankGroupItem from './RankGroupItem';
import * as s from './RankGroupList.css';

const RankGroupList = ({
  isCurrentWeek,
  usePrevWeekLeaderBoardBanner,
  categoryId,
}: {
  categoryId: number;
  isCurrentWeek: boolean;
  usePrevWeekLeaderBoardBanner: boolean;
}) => {
  const { push } = useFlow();
  const { data: leaderBoardData } = useGetGroupLeaderBoard({
    weekAgo: isCurrentWeek ? 0 : 1,
  });

  const isAvailableCategory = leaderBoardData?.categoryRankList?.ableCategories?.some(
    (category) => category.id === categoryId
  );

  const leaderBoardList =
    categoryId !== 0 && isAvailableCategory
      ? leaderBoardData?.categoryRankList?.rankList[categoryId] ?? leaderBoardData?.rankList ?? []
      : leaderBoardData?.rankList ?? [];

  const showPrevWeekBanner = isCurrentWeek && usePrevWeekLeaderBoardBanner;
  const showCreateGroupButton = leaderBoardList.length >= 5 && leaderBoardList.length <= 10;

  return (
    <div className={s.Wrapper}>
      {leaderBoardList?.map((item, index) => {
        const rankOneCount = leaderBoardList?.filter((item) => item.rank === 1)?.length;
        const rankFiveCount = leaderBoardList?.filter((item) => item.rank === 5)?.length;

        const previousRank = index > 0 ? leaderBoardList[index - 1].rank : undefined;
        const isLastTopRank = previousRank !== item.rank && item.rank === 1;
        const isLastRankFive = previousRank !== item.rank && item.rank === 5;

        const showPrevWeekBanner_ = () => {
          if (!showPrevWeekBanner) return false;
          if (item.rank === 5) {
            if (rankFiveCount > 1 && isLastRankFive) return true;
            if (rankFiveCount === 1) return true;
          }
          return false;
        };

        const BottomSpacingSize = () => {
          if (isLastTopRank && rankOneCount > 1) return 0;
          if (item.rank === 1) return 16;
          return 24;
        };

        return (
          <div key={item.groupId}>
            <RankGroupItem
              rank={item.rank}
              groupId={Number(item.groupId)}
              groupName={item.groupName}
              groupThumbnailUrl={item.groupThumbnailUrl as Image}
              groupLevel={item.groupLevel}
              displayRegionName={item.displayRegionName}
              weeklyActivityPoint={item.weeklyActivityPoint}
            />
            <Spacing size={BottomSpacingSize()} />
            {showPrevWeekBanner_() && (
              <>
                <PrevWeekBanner />
                <Spacing size={24} />
              </>
            )}
          </div>
        );
      })}
      {showCreateGroupButton && (
        <div className={s.CreateGroupButton}>
          <Typography typography="subtitle1Regular">마음에 드는 모임이 없나요?</Typography>
          <Spacing size={4} />
          <Typography typography="subtitle2Regular" color="gray600">
            모임을 직접 만들어보세요.
          </Typography>
          <Spacing size={38} />
          <BoxButton
            size="medium"
            variant="secondary"
            onClick={() => {
              push('GroupNewSetGroupCategoryRecommendPage', {
                from: 'GroupLeaderBoardPage',
              });
            }}
          >
            모임 만들기
          </BoxButton>
        </div>
      )}
    </div>
  );
};

export default RankGroupList;
