import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { ContainerWithGap, GroupAvatar, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconChevronRightFill, IconCrownFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler } from 'react';

import { isHigherManager } from '@/utils/role';

import * as s from './SidebarProfile.css';

type Props = {
  title: string;
  imageUrl: string;
  description?: string;
  role?: GroupMemberRoleEnum;
  onProfileClick?: MouseEventHandler;
};

const SidebarProfile = ({ title, imageUrl, description, role, onProfileClick }: Props) => {
  return (
    <div className={s.Container} onClick={onProfileClick}>
      <div className={s.InnerContainer}>
        <GroupAvatar src={imageUrl} size="medium" />
        <VerticalSpacing size={16} />
        <ContainerWithGap className={s.TextContainer} name="SidebarProfile" gap={2}>
          <div className={s.TitleContainer}>
            <Typography typography="title3Bold" ellipsisAfterLines={1}>
              {title}
            </Typography>
            {isHigherManager(role) && (
              <div className={s.BadgeContainer}>
                <VerticalSpacing size={4} />
                <IconCrownFill size={16} color={role === 'superHost' ? '#f7be68' : '#30c795'} />
              </div>
            )}
          </div>
          {description && (
            <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
              {description}
            </Typography>
          )}
        </ContainerWithGap>
        <VerticalSpacing size={16} />
      </div>
      <IconChevronRightFill size={18} color={vars.$scale.color.gray600} />
    </div>
  );
};

export default SidebarProfile;
