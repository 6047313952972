import { model } from '@community-group/api/lib/group';
import { useSuspenseQuery } from '@tanstack/react-query';

import { CATEGORIES_URL, getCategories } from '../base/categories';
import { useFetchInstance } from './instance/useFetchInstance';

export const useGetCategories = () => {
  const fetchInstance = useFetchInstance();
  const { data } = useSuspenseQuery({
    queryKey: [CATEGORIES_URL],
    queryFn: () => getCategories(fetchInstance),
  });

  return data as model.CategoriesResponse;
};
