import { Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconDocumentLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { memo, MouseEventHandler, ReactNode } from 'react';

import * as s from './SidebarMenuListItem.css';

type Props = {
  text: string;
  subtext?: string;
  bold?: boolean;
  icon?: ReactNode;
  onClick?: MouseEventHandler;
};

/**
 * @deprecated "공통레포의 SidemenuBoard.Item을 사용하세요." https://daangn.height.app/T-78418
 */
const SidebarMenuListItem = ({ text, subtext, bold, icon, onClick }: Props) => {
  return (
    <li className={s.ListItem} onClick={onClick} role="link">
      {icon ?? (
        <div className={s.IconWrapper}>
          <IconDocumentLine size={16} color={vars.$scale.color.gray500} />
        </div>
      )}
      <VerticalSpacing size={8} />
      <div className={s.TextWrapper}>
        <Typography
          typography={bold ? 'subtitle1Bold' : 'subtitle1Regular'}
          color="gray900"
          ellipsisAfterLines={1}
        >
          {text}
        </Typography>
        {subtext && (
          <>
            <VerticalSpacing size={4} />
            <Typography typography="subtitle1Regular" color="gray600" ellipsisAfterLines={1}>
              {subtext}
            </Typography>
          </>
        )}
      </div>
    </li>
  );
};

export default memo(SidebarMenuListItem);
