import { GroupPhotoPostPresentation } from '@community-group/api/lib/group/models';
import { getDateDiffFromNow, Spacing, Typography } from '@community-group/components';
import { IconExclamationmarkCircleLine, IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { Suspense, useCallback, useMemo } from 'react';
import React from 'react';

import { useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';
import Loading from '@/components/common/Loading';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { trackEvent } from '@/utils/analytics';

import MediaViewerSlider, { MediaListType } from '../components/MediaViewerSlider';
import SetHeroMediaOptions from '../components/SetHeroMediaOptions';
import { getInfinitiveImagesFlatList } from '../utils/imageUtil';
import * as s from './ImageViewer.css';

export type AlbumImageViewerProps = {
  groupId: string;
  initialIndex: number;
};

// 앨범 이미지 뷰어 : 게시물 더보기 버튼, 상단 고정 버튼
const AlbumImageViewer = ({ groupId, initialIndex }: AlbumImageViewerProps) => {
  const [currentMedia, setCurrentMedia] = React.useState<MediaListType>();
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetGroupPhotoList({
    groupId,
  });
  const { push, pop } = useFlow();

  const photoList = useMemo(() => getInfinitiveImagesFlatList(data), [data]);

  const pinnedPhotoCount = useMemo(
    () =>
      photoList.reduce((acc, curr) => {
        if (curr.isPinned) return ++acc;
        return acc;
      }, 0),
    [photoList]
  );

  const flattenPhotoList = photoList.map((el) => {
    return {
      id: el.image.id,
      imageUrl: el.image.large,
      postId: el.post.id,
      post: el.post,
      isPinned: el.isPinned,
      isPinnable: el.isPinnable,
      type: el.type,
      media: el.media,
    };
  });

  const handleMoveToPost = useCallback(() => {
    if (currentMedia?.post?.deletedAt) return;
    if (currentMedia?.post?.isPostViewable === false) return;

    trackEvent({
      event: 'click_imageviewer_see_the_post',
      params: {
        groupId,
        postId: currentMedia?.postId?.toString() ?? '',
      },
      sample: true,
    });

    push('GroupPostDetailPage', {
      groupId,
      postId: currentMedia?.postId?.toString() ?? '',
    });
  }, [
    currentMedia?.post?.deletedAt,
    currentMedia?.post?.isPostViewable,
    currentMedia?.postId,
    groupId,
    push,
  ]);

  const renderBottom = useCallback(
    (image: MediaListType) => {
      if (!image.post) return null;
      const post = image.post;
      const readCount = `조회수 ${post.readCount}`;
      const createdAt = ` • ${getDateDiffFromNow(new Date(Date.parse(post.createdAt))).text} 전`;

      return (
        <>
          <div className={s.BottomRenderSection} onClick={handleMoveToPost}>
            <div className={s.BottomRenderProfileSection}>
              <img className={s.UserProfile} src={post.author.profileImageUrl} alt="profile" />
              <Typography typography="subtitle2Bold" color="staticWhite">
                {post.author.nickname}
              </Typography>
            </div>
            <Spacing size={12} />
            <Contents {...post} />
            <Spacing size={4} />
            {!post?.deletedAt && (
              <Typography
                typography="caption2Regular"
                color="onPrimary"
                style={{
                  opacity: 0.7,
                }}
              >
                {readCount}
                {createdAt}
              </Typography>
            )}
          </div>
          <div className={s.TopGradation} />
          <div className={s.BottomGradation} />
        </>
      );
    },
    [handleMoveToPost]
  );

  return (
    <AppScreen hasAppBar={false} layoutRef={undefined} onPull={undefined}>
      <div className={clsx(s.Wrapper, 'imageSliderWrapper')}>
        <Suspense
          fallback={
            <div className={s.LoaderWrapper}>
              <Loading size="medium" />
            </div>
          }
        >
          <MediaViewerSlider
            zoomable={currentMedia?.isPinnable}
            slideStyle={{
              height: 'calc(100% - 38px)',
            }}
            setCurrentMedia={setCurrentMedia}
            mediaData={{
              mediaList: flattenPhotoList,
              initialIndex,
              hasNextPage,
              fetchNextPage,
              isFetchingNextPage,
            }}
            renderBottom={renderBottom}
            onClickMedia={(media) => {
              trackEvent({
                event: 'click_mediaviewer_contents',
                params: {
                  groupId,
                  postId: media.postId?.toString() ?? '',
                  mediaType: media.type,
                },
                sample: true,
              });
            }}
          />
          <div className={s.CloseButton} onClick={pop}>
            <IconXmarkLine size={24} color={vars.$static.color.staticWhite} />
          </div>
          <SetHeroMediaOptions
            groupId={groupId}
            currentMedia={currentMedia}
            pinnedPhotoCount={pinnedPhotoCount}
            isPinnable={currentMedia?.isPinnable ?? false}
          />
        </Suspense>
      </div>
    </AppScreen>
  );
};

export default AlbumImageViewer;

const Contents = (post: GroupPhotoPostPresentation) => {
  if (!post) return null;
  if (post?.deletedAt)
    return (
      <div className={s.ContentsWrapper}>
        <IconExclamationmarkCircleLine size={18} color={vars.$scale.color.gray600} />
        <Typography typography="bodyM1Regular" color="gray600">
          삭제된 게시글이에요.
        </Typography>
      </div>
    );

  if (post.isPostViewable === false) {
    return (
      <div className={s.ContentsWrapper}>
        <IconExclamationmarkCircleLine size={18} color={vars.$scale.color.gray600} />
        <Typography typography="bodyM1Regular" color="gray600">
          모임에만 공개된 게시글이에요.
        </Typography>
      </div>
    );
  }

  const regexForMarkup = /\[@(.*)\]\{.*\}/g;
  const result = post.content.replaceAll(regexForMarkup, '@$1').replaceAll(/\n/g, ' ');

  return (
    <Typography typography="bodyL1Regular" color="staticWhite" ellipsisAfterLines={1}>
      {result}
    </Typography>
  );
};
