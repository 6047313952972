import { Spinner } from '@community-group/components';
import { IconTriangleRightFill } from '@daangn/react-monochrome-icon';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { border } from '@/components/common/Image/JoinedNeigborImageList/index.css';

import useGetVideoData from '../hooks/useGetVideoData';

type Props = {
  id: string;
};

const VideoPlayerItem = ({ id }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [playerState, setPlayerState] = useState<'playing' | 'paused' | 'loading' | 'ready'>(
    'loading'
  );

  const { data } = useGetVideoData({ videoId: id });
  const videoUrl = data?.video.playbackUrl;
  const thumbnailUrl = data?.video.thumbnailUrl;

  const ratio = data ? data?.video.width / data?.video.height : 1;

  const renderContents = useCallback(() => {
    if (!data) return <Spinner />;

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          aspectRatio: `${ratio}`,
        }}
      >
        <video
          controls
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
          }}
          onCanPlay={() => {
            console.log('onCanPlay');
            if (playerState === 'loading') {
              setPlayerState('ready');
            }
          }}
          onPlay={() => {
            console.log('onPlay');
            setPlayerState('playing');
          }}
          onPause={() => {
            console.log('onPause');
            setPlayerState('paused');
          }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    );
  }, [data, playerState, ratio, videoUrl]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        aspectRatio: `${ratio}`,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '20px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          aspectRatio: `${ratio}`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: playerState === 'playing' ? -1 : 10,
        }}
        onClick={() => {
          const videoEl = videoRef.current as HTMLVideoElement;
          if (!videoEl) return;
          videoEl?.play();
          videoEl?.requestFullscreen();
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            background: 'white',
            borderRadius: '50%',
            padding: '10px',
          }}
        >
          <IconTriangleRightFill size={30} color={'black'} />
        </div>
        <img
          src={thumbnailUrl}
          style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
        />
      </div>
      {renderContents()}
    </div>
  );
};

export default VideoPlayerItem;
