import { Typography } from '@community-group/components';
import { useMemo } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './MeetupDetailHeader.css';

const MeetupDetailHeader = () => {
  const { groupId, meetupId } = usePathParams();
  const { data: groupMeetupData } = useReadGroupMeetupDetail({
    groupId,
    meetupId,
  });

  const { group: groupData } = useReadGroupDetail(groupId);

  const subText = useMemo(() => {
    const groupName = groupData?.name;
    const meetupTimeAtDisplayText = groupMeetupData.meetupTimeAtDisplayText;
    return `${groupName} • ${meetupTimeAtDisplayText}`;
  }, [groupData?.name, groupMeetupData.meetupTimeAtDisplayText]);

  return (
    <div className={s.OuterWrapper}>
      <div className={s.Wrapper}>
        <div className={s.ImageWrapper}>
          <img src={groupData?.profileImage.medium} alt="meetup" className={s.Img} />
        </div>
        <div className={s.ContentWrapper}>
          <Typography typography="subtitle1Regular">{groupMeetupData.title}</Typography>
          <Typography typography="caption1Regular" color="gray600">
            {subText}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default MeetupDetailHeader;
