import {
  ShareContentRequest,
  ShareTargetListPresentation,
} from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { Suspense } from 'react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';

import ShareContentsActionButtonGroup from './components/ShareContentsActionButtonGroup';
import ShareMessageInput from './components/ShareMessageInput';
import TargetList from './components/TargetList';
import { CloseIconWrapper, Header, LoadTargetWrapper, Wrapper } from './SharePostBottomSheet.css';

export type SharePostBottomSheetParams = Pick<
  PageParams,
  'contentId' | 'contentType' | 'permalinkId' | 'groupId'
>;

export type ShareContentFormType = {
  targets: ShareTargetListPresentation[];
} & Omit<ShareContentRequest, 'targets'>;

const SharePostBottomSheet: ActivityComponentType<SharePostBottomSheetParams> = () => {
  const { pop } = useFlow();

  const [isFocused, setIsFocused] = React.useState(false);
  const {
    permalinkId = '',
    contentId = '',
    contentType = 'groupPost',
    groupId = '',
  } = useQueryParams();

  useSetFixedLayoutSize(false);

  const formHandler = useForm<ShareContentFormType>({
    defaultValues: {
      targets: [],
      content: {
        type: contentType,
        id: Number(contentId),
      },
      message: '',
    },
  });

  const { setValue, watch } = formHandler;

  const isRenderMessageInput = watch('targets').length > 0;

  return (
    <BottomSheet
      backgroundColor={vars.$semantic.color.paperSheet}
      safeAreaInsetBottomRemove
      style={{
        padding: '0 !important',
      }}
    >
      <div className={Wrapper({ isFocused })}>
        <div className={Header}>
          <Typography color="gray900" typography="subtitle1Bold">
            채팅방에 공유
          </Typography>
          <div className={CloseIconWrapper} onClick={pop}>
            <IconXmarkLine width={24} height={24} />
          </div>
        </div>
        <Suspense fallback={<div className={LoadTargetWrapper} />}>
          <TargetList
            contentType={contentType}
            contentId={contentId}
            selectedTargetList={watch('targets')}
            setSelectedTargetList={(selectedList: ShareTargetListPresentation[]) => {
              setValue('targets', selectedList);
            }}
          />
        </Suspense>

        <Spacing size={20} />

        {isRenderMessageInput ? (
          <ShareMessageInput
            groupId={groupId}
            setValue={setValue}
            watch={watch}
            handleFocus={() => setIsFocused(true)}
            handleBlur={() => setIsFocused(false)}
          />
        ) : (
          <ShareContentsActionButtonGroup
            contentType={contentType}
            permalinkId={permalinkId}
            groupId={groupId}
          />
        )}
      </div>
    </BottomSheet>
  );
};

export default SharePostBottomSheet;
