import { groupClient } from '@community-group/api';
import {
  GroupFeedbackCreateForm,
  GroupFeedbackIdPresentation,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  groupFeedbackCreateForm: GroupFeedbackCreateForm;
};
type Props = UseMutationOptions<AxiosResponse<GroupFeedbackIdPresentation>, Error, Params>;

export const usePostGroupFeedback = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postGroupFeedback = groupClient.api.GroupFeedbackApi.apiV1GroupsGroupIdFeedbacksPost({
    axios: fetchInstance,
  });

  const fetchPostGroupFeedback = ({ groupId, groupFeedbackCreateForm }: Params) => {
    return postGroupFeedback(Number(groupId), groupFeedbackCreateForm);
  };

  return useThrottleMutation(fetchPostGroupFeedback, {
    onError,
    onSuccess,
  });
};
