import { AsyncBoundary, BoxButton, Typography } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';

import { useGetNotifications } from '@/api/hooks/useGetNotifications';
import ImageNotification from '@/assets/images/img_notification.svg';
import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import useTurnOnNotificationBottomSheet from '../hooks/useTurnOnNotificationBottomSheet';
import * as s from './TurnOnNotificationBottomSheet.css';

export type NotificationBottomSheetParams = Pick<PageParams, 'groupId' | 'from'>;

const TurnOnNotificationBottomSheet: ActivityComponentType<NotificationBottomSheetParams> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <TurnOnNotificationBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const TurnOnNotificationBottomSheetWrapper = () => {
  const { pop } = useFlow();
  const { groupId = '' } = usePathParams();
  const { refetch: refetchGroupNotificationState } = useGetNotifications(groupId);
  const { bridge } = useBridge();
  const { putDaangnActivityNotification } = useTurnOnNotificationBottomSheet(groupId, () => {
    trackEvent({
      event: 'click_daangn_activity_notification',
      params: {
        from: 'notification_bottom_sheet',
      },
    });
    refetchGroupNotificationState();
    pop();
    bridge.openToast({
      toast: {
        body: '알림이 설정되었어요.',
      },
    });
  });

  useEnterTrackEvent({
    event: 'enter_daangn_activity_notification',
  });

  return (
    <div className={s.wrapper}>
      <div
        className={s.closeIconWrapper}
        onClick={() => {
          ({
            closedBottomSheet: true,
          });
          pop();
        }}
      >
        <IconXmarkLine />
      </div>
      <img src={ImageNotification} />
      <Spacing size={8} />
      <Typography as="h5" typography="title2Bold" color="gray900">
        모임 소식을 놓치지 마세요
      </Typography>
      <Spacing size={6} />
      <Typography as="p" typography="subtitle1Regular" color="gray700" textAlign="center">
        대화, 새 글 알림을 받으려면
        <br />
        활동 알림을 켜주세요.
      </Typography>
      <Spacing size={20} />
      <BoxButton
        width="100%"
        size="xlarge"
        onClick={() => {
          putDaangnActivityNotification({
            putActiveNotificationModifyForm: { daangnGroupNotificationState: 'all_on' },
            id: Number(groupId ?? 0),
          });
        }}
      >
        알림 켜기
      </BoxButton>
    </div>
  );
};

export default TurnOnNotificationBottomSheet;
