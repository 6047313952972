import { Identify, identify, init } from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';
import { setUserId } from '@amplitude/analytics-browser';
import { AxiosInstance } from 'axios';

import { getUsersMeDemography } from '@/api/base/common';
import { getMyGroupList } from '@/api/base/group';
import { AMPLITUDE_SAMPLE_RATE } from '@/utils/analytics/userSampling';

import { UNSAFE_appInfo } from '../bridge/appInfo';
import {
  AMPLITUDE_AB_EXPERIMENTAL_RATE,
  getUserExperimentalABCGroup,
  getUserExperimentalABGroup,
} from './experiment/userExperimentalGroup';
import { GroupEventParams, SpecificEventType } from './type';

const AMPLITUDE_DEV_KEY = '84d71a58e9da0adf277cffcd6889d39a';
const AMPLITUDE_PRODUCTION_KEY = '434009e9442913f0611fca3780cacc38';
const AMPLITUDE_API_KEY = globalThis.appConfig.isAppEnvProduction
  ? AMPLITUDE_PRODUCTION_KEY
  : AMPLITUDE_DEV_KEY;

export async function initAmplitude(callback?: () => void) {
  const userId = UNSAFE_appInfo.getData()?.user.id.toString();

  init(AMPLITUDE_API_KEY, {
    userId,
    defaultTracking: {
      attribution: true,
      pageViews: false,
      sessions: true,
      formInteractions: false,
    },
  })
    .promise.then(() => {
      console.log('[[[[[Amplitude Initiated]]]]]');
      callback?.();
    })
    .catch((error) => {
      console.log('[[[[[Amplitude Init Error]]]]]', error);
    });
}

export function setInitialUserDataToAmplitude() {
  const userRegionData = UNSAFE_appInfo.getData()?.region;
  const userNativeData = UNSAFE_appInfo.getData()?.user;
  const indentifyObj = new Identify();
  //!!! setUserId 는 절대 절대 절대 바꾸지 마세요. 바꾸는 순간 모든 데이터 다 날라감 (참고: https://daangn.slack.com/archives/C0324MBCA9Z/p1662570661305459)
  setUserId('karrot-' + userNativeData?.id);

  indentifyObj.set('checkinCity', userRegionData.name1);
  indentifyObj.set('checkinGu', userRegionData.name2);
  indentifyObj.set('checkinDong', userRegionData.name3);
  indentifyObj.set('checkinMostAccurate', userRegionData.name);

  identify(indentifyObj);
}

type Params = {
  fetchInstance: AxiosInstance;
};
export async function setAdditionalUserDataToAmplitude({ fetchInstance }: Params) {
  try {
    const { userGenderAgePresentation } = await getUsersMeDemography({ fetchInstance });
    const { groups } = await getMyGroupList({ fetchInstance });
    const groupNum = groups?.length ?? 0;

    const indentifyObj = new Identify();
    if (userGenderAgePresentation.ageRange) {
      // response data 형식(https://daangn.slack.com/archives/C0324MBCA9Z/p1663665460932799)
      const ageRangeMinMaxArr = userGenderAgePresentation.ageRange.split('-');
      const minAge = ageRangeMinMaxArr[0];
      const maxAge = ageRangeMinMaxArr[1] !== '' ? ageRangeMinMaxArr[1] : '60';
      indentifyObj.set('ageRange', {
        min: minAge,
        max: maxAge,
      });
      indentifyObj.set('ageRangeRaw', userGenderAgePresentation.ageRange);
    }
    if (userGenderAgePresentation.gender) {
      indentifyObj.set('gender', userGenderAgePresentation.gender);
    }
    indentifyObj.set('joinedGroupCount', groupNum);
    identify(indentifyObj);
  } catch (error) {
    console.log('[Amplitude setAdditionalUserDataToAmplitude]: ', error);
  }
}

export const setAdditionalUserSegmentsToAmplitude = () => {
  const indentifyObj = new Identify();
  const sampledTarget = UNSAFE_appInfo.getData().user.sample;

  indentifyObj.set('sampledTarget', sampledTarget);
  indentifyObj.set('sampleRate', AMPLITUDE_SAMPLE_RATE.toString());

  identify(indentifyObj);
};

export const setAdditionalUserExperimentalGroupToAmplitude = () => {
  const indentifyObj = new Identify();
  const user = UNSAFE_appInfo.getData().user;
  const experimentalABCGroup = getUserExperimentalABCGroup(user.id);
  const experimentalABGroup = getUserExperimentalABGroup(user.id, AMPLITUDE_AB_EXPERIMENTAL_RATE);

  indentifyObj.set('experimentalABCGroup', experimentalABCGroup);
  indentifyObj.set('experimentalABGroup', experimentalABGroup);
  identify(indentifyObj);
};

export function trackAmplitudeEvent(event: SpecificEventType, params?: GroupEventParams) {
  // Amplitude
  track(event, params);
}
