import { UploadImageType } from '@community-group/api';
import { Spacing, Typography } from '@community-group/components';
import { IconLocationpinFill, IconPersonFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './CreateGroupProfileImagePreview.css';

interface Props {
  name?: string;
  profileImage?: UploadImageType;
  description?: string;
  categoryName?: string;
  regionName?: string;
  defaultImage: {
    profileImage: string;
    backgroundImage: string;
  };
}

const CreateGroupProfileImagePreview = ({
  name = '',
  profileImage,
  description,
  defaultImage,
  categoryName,
  regionName,
}: Props) => {
  return (
    <div className={s.wrapper}>
      <div className={s.GroupInfoWrapper}>
        <img className={s.Thumbnail} src={profileImage?.medium ?? defaultImage.profileImage} />
        <div className={s.infoWrapper}>
          <Typography typography="subtitle2Bold" ellipsisAfterLines={1}>
            {name}
          </Typography>
          <Spacing size={2} />
          <Typography typography="caption2Regular" ellipsisAfterLines={1} color="gray700">
            {description}
          </Typography>
          <Spacing size={6} />
          <div className={s.GroupSubInfoWrapper}>
            <div className={s.InfoItemWrapper}>
              <div className={s.InfoIconWrapper}>
                <IconLocationpinFill size={12} color={vars.$scale.color.gray500} />
              </div>
              <Typography typography="caption2Regular" color="gray600">
                {regionName}
              </Typography>
            </div>
            <Typography typography="caption2Regular" color="gray600">
              ·
            </Typography>
            <div className={s.InfoItemWrapper}>
              <div className={s.InfoIconWrapper}>
                <IconPersonFill size={12} color={vars.$scale.color.gray500} />
              </div>
              <Typography typography="caption2Regular" color="gray600">
                33명
              </Typography>
            </div>
            <Typography typography="caption2Regular" color="gray600">
              ·
            </Typography>
            <div className={s.InfoItemWrapper}>
              <Typography typography="caption2Regular" color="gray600">
                {categoryName}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGroupProfileImagePreview;
