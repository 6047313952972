import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Form = { groupId: number; kidId: string };

type Props = UseMutationOptions<AxiosResponse, Error, Form>;

export const useDeleteParentingGroupKidProfile = (props: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteKid = groupClient.api.ParentingGroupApi.apiV1GroupsGroupIdParentingKidKidIdDelete({
    axios: fetchInstance,
  });

  return useThrottleMutation(({ groupId, kidId }: Form) => {
    return deleteKid(groupId, kidId);
  }, props);
};
