import { Spacing, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconCalendarFill, IconLocationpinFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './GroupMeetupInfoList.css';

type Props = {
  meetupDateText?: string;
  meetupLocationText?: string;
};

const GroupMeetupInfoList = ({ meetupDateText, meetupLocationText }: Props) => {
  if (!meetupDateText && !meetupLocationText) return null;

  return (
    <ul className={s.MeetupInfoList}>
      {meetupDateText && (
        <li className={s.MeetupInfoItem}>
          <IconCalendarFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
            {meetupDateText}
          </Typography>
        </li>
      )}
      {meetupDateText && meetupLocationText && <Spacing size={8} />}
      {meetupLocationText && (
        <li className={s.MeetupInfoItem}>
          <IconLocationpinFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={8} />
          <Typography typography="label2Regular" color="gray800" ellipsisAfterLines={1}>
            {meetupLocationText}
          </Typography>
        </li>
      )}
    </ul>
  );
};

export default GroupMeetupInfoList;
