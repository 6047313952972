import { Typography } from '@community-group/components';
import { IconEyeFill, IconPerson2Fill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { Fragment, HTMLAttributes } from 'react';

import * as s from './MeetupHomeListCardItem.css';

export const DEFAULT_PROFILE_IMG =
  'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png';

export type MeetupProgressStatus = {
  status: 'opened' | 'fully_recruited' | 'closed' | 'delected' | 'canceled';
  description: string;
};

export const MeetupHomeListCard = ({
  headerLabel = [],
  meetupAtDisplayText,
  hostInfo,
  currentParticipantsCount,
  maximumParticipantsCount,
  title,
  image,
  viewCount,
  ...props
}: MeetupListCardProps) => {
  return (
    <div {...props} className={clsx([props.className, s.container])}>
      <div className={s.wrapper}>
        <div className={s.header}>
          <div className={s.memberList}>
            <img
              className={s.profileImage}
              src={hostInfo.image ?? DEFAULT_PROFILE_IMG}
              style={{ borderRadius: hostInfo.imageShape === 'circle' ? '10000px' : '4px' }}
            />
            <p className={s.profileText}>{hostInfo.displayText ?? 'HOST'}</p>
          </div>
          {headerLabel?.length > 0 &&
            headerLabel.map((label) => {
              return (
                <Fragment key={label}>
                  <p>∙</p>
                  <p>{label}</p>
                </Fragment>
              );
            })}
        </div>
        <div className={s.body}>
          <div className={s.bodyLeft}>
            {typeof title === 'string' ? (
              <Typography
                className={s.title}
                typography="subtitle1Bold"
                color="gray900"
                ellipsisAfterLines={1}
              >
                {title}
              </Typography>
            ) : (
              title
            )}
            <Typography typography="label3Regular" color="gray600">
              {meetupAtDisplayText}
            </Typography>
            <div className={s.bodyFooter}>
              <div className={s.count}>
                <IconPerson2Fill color={vars.$scale.color.gray500} size={15} />
                <span className={s.countText}>
                  {currentParticipantsCount}/{maximumParticipantsCount}명
                </span>
              </div>
              {viewCount !== undefined && viewCount > 0 && (
                <div className={s.count}>
                  <IconEyeFill color={vars.$scale.color.gray500} width={15} height={16} />
                  <span className={s.countText}>{viewCount}</span>
                </div>
              )}
            </div>
          </div>
          {image && (
            <div className={s.bodyRight}>
              <img className={s.image} src={image} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export type MeetupListCardProps = {
  headerLabel: string[];
  title: string;
  meetupAtDisplayText: string;
  currentParticipantsCount: number;
  maximumParticipantsCount: number;
  image?: string;
  hostInfo: {
    imageShape: 'square' | 'circle';
    image: string;
    displayText: string;
  };
  viewCount?: number;
} & HTMLAttributes<HTMLDivElement>;
