import { SettingList } from '@community-group/components/features';

import { useGetPermission } from '@/api/hooks/useGetPermission';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import BoardCategorySection from './BoardCategorySection';

const GroupManagementSettingSection = () => {
  const { push } = useFlow();
  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe();
  const { data: permission } = useGetPermission(groupId);
  const memberGradeEnabled = useFeatureFlag('memberGradeEnabled');

  return (
    <SettingList.Wrapper title="모임 운영">
      <SettingList.Item
        title="멤버 관리"
        onClick={() => {
          push('GroupMemberListForHostPage', {
            groupId,
          });
        }}
      />
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingList.Item
          title="멤버 권한 관리"
          onClick={() => {
            push('GroupSettingPermissionPage', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      {memberGradeEnabled && (
        <CheckAccessibleRole
          currentRole={currentUser.role}
          accessibleRoles={['superHost', 'manager']}
        >
          <SettingList.Item
            title="멤버 등급 관리"
            description="멤버 등급을 나누고 관리해요."
            onClick={() => {
              push('GroupSettingMemberGradePage', {
                groupId,
              });
            }}
          />
        </CheckAccessibleRole>
      )}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <BoardCategorySection />
      </CheckAccessibleRole>
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingList.Item
          title="별명"
          description="닉네임 옆에 모임에서만 사용하는 별명을 표시해요."
          onClick={() => {
            push('GroupSettingEditSubNicknamePage', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingList.Item
          title="본인인증 설정"
          description="멤버의 본인인증, 성별과 나이대 공개 여부를 설정해요."
          onClick={() => {
            push('GroupSettingEditVerification', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      <CheckAccessibleRole
        currentRole={currentUser.role}
        accessibleRoles={permission?.modifyGroupBotSetting.currentRoles}
      >
        <SettingList.Item
          title="모임장봇 설정"
          onClick={() => {
            push('GroupSettingGroupBotPage', {
              groupId,
              from: 'setting',
            });
          }}
        />
      </CheckAccessibleRole>
    </SettingList.Wrapper>
  );
};

export default GroupManagementSettingSection;
