import { groupClient } from '@community-group/api';
import {
  ChatSection,
  GroupSection,
  MeetupSection,
  MemberSection,
  NoticeSection,
  PhotoSection,
  PostSection,
  SectionsResponse,
} from '@community-group/api/lib/group/models';

import { postArticleResponse } from '../post';

const { apiV1GroupsIdHomeGetMockServe } = groupClient.api.GroupHomeApi;

const groupSection: GroupSection = {
  id: 123,
  name: '모임 이름',
  description: '모임 설명',
  lastActivatedAt: '2023-05-06T13:30:00Z',
  profileImage: {
    id: 'abc1234',
    url: 'https://picsum.photos/seed/picsum/500/600?random=1',
    small: 'https://picsum.photos/seed/picsum/500/600?random=1',
    medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
    large: 'https://example.com/images/large.jpg',
    isDefault: true,
    width: 800,
    height: 600,
  },
  backgroundImage: {
    id: 'abc1235',
    url: 'https://picsum.photos/seed/picsum/500/600?random=1',
    small: 'https://picsum.photos/seed/picsum/500/600?random=1',
    medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
    large: 'https://example.com/images/large.jpg',
    isDefault: true,
    width: 800,
    height: 600,
  },
  createdRegion: {
    id: 123,
    name: '서울특별시 강남구',
    province: '서울특별시',
    city: '강남구',
    town: '서초 4등',
  },
  category: {
    id: 1,
    name: '스포츠',
  },
  joinableAgeRangeText: '만 20세 이상',
  counts: {
    memberCount: 50,
    postCount: 100,
    meetupCount: 20,
  },
};

const meetupSection: MeetupSection = {
  meetupCount: 123,
  meetups: [
    {
      id: 1234,
      title: '모임 밋업 제목',
      meetDate: '2023-05-20',
      meetTime: '2023-05-20T13:00:00Z',
      meetupTimeAtDisplayText: '5월 20일 오후 1시',
      location: '모임 장소',
      poi: {
        id: 123,
        type: 'POI',
        name: '모임 장소',
        address: '서울특별시 강남구 역삼동 123-456',
        jibunAddress: '서울특별시 강남구 역삼동 123-456',
        region: {
          id: 123,
          name: '서울특별시 강남구',
          province: '서울특별시',
          city: '강남구',
          town: '',
        },
        coordinates: {
          latitude: 37.12345,
          longitude: 127.12345,
        },
        reviewPostCount: 10,
        categoryName: '카페',
        tagName: '분위기 좋은',
      },
      image: {
        id: 'abc1236',
        url: 'https://picsum.photos/seed/picsum/500/600',
        small: 'https://picsum.photos/seed/picsum/500/600?random=1',
        medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
        large: 'https://example.com/images/large.jpg',
        isDefault: true,
        width: 800,
        height: 600,
      },
      participantsProfileImages: [
        'https://example.com/images/profile1.jpg',
        'https://example.com/images/profile2.jpg',
        'https://example.com/images/profile3.jpg',
      ],
      currentParticipantsNumber: 3,
      maximumParticipantsNumber: 10,
      progressStatus: {
        status: 'opened',
        description: '모집 중',
        color: '#123456',
      },
      exposureRange: 'ALL',
      createdAt: '2023-05-06T14:00:00Z',
      updatedAt: '2023-05-06T15:00:00Z',
      groupInfo: {
        id: 456,
        name: '모임 이름',
        category: {
          id: 1,
          name: '스포츠',
        },
        profileImage: {
          id: 'abc1237',
          url: 'https://picsum.photos/seed/picsum/500/600?random=1',
          small: 'https://picsum.photos/seed/picsum/500/600?random=1',
          medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
          large: 'https://example.com/images/large.jpg',
          isDefault: true,
          width: 800,
          height: 600,
        },
        groupCreatedRegion: {
          id: 123,
          name: '서울특별시 강남구',
          province: '서울특별시',
          city: '강남구',
          town: '',
        },
      },
    },
    {
      id: 123,
      title: '모임 밋업 제목',
      meetDate: '2023-05-20',
      meetTime: '2023-05-20T13:00:00Z',
      meetupTimeAtDisplayText: '5월 20일 오후 1시',
      location: '모임 장소',
      poi: {
        id: 123,
        type: 'POI',
        name: '모임 장소',
        address: '서울특별시 강남구 역삼동 123-456',
        jibunAddress: '서울특별시 강남구 역삼동 123-456',
        region: {
          id: 123,
          name: '서울특별시 강남구',
          province: '서울특별시',
          city: '강남구',
          town: '',
        },
        coordinates: {
          latitude: 37.12345,
          longitude: 127.12345,
        },
        reviewPostCount: 10,
        categoryName: '카페',
        tagName: '분위기 좋은',
      },
      image: {
        id: 'abc1238',
        url: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220418/c7ea63b3-7493-4291-8be7-958e80153ae4t.png',
        small: 'https://picsum.photos/seed/picsum/500/600?random=1',
        medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
        large: 'https://example.com/images/large.jpg',
        isDefault: true,
        width: 800,
        height: 600,
      },
      participantsProfileImages: [
        'https://picsum.photos/seed/picsum/500/600?random=1',
        'https://picsum.photos/seed/picsum/500/600?random=2',
        'https://picsum.photos/seed/picsum/500/600?random=3',
      ],
      currentParticipantsNumber: 3,
      maximumParticipantsNumber: 10,
      progressStatus: {
        status: 'opened',
        description: '모집 중',
        color: '#123456',
      },
      exposureRange: 'ALL',
      createdAt: '2023-05-06T14:00:00Z',
      updatedAt: '2023-05-06T15:00:00Z',
      groupInfo: {
        id: 456,
        name: '모임 이름',
        category: {
          id: 1,
          name: '스포츠',
        },
        profileImage: {
          id: 'abc1239',
          url: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220418/c7ea63b3-7493-4291-8be7-958e80153ae4t.png',
          small: 'https://picsum.photos/seed/picsum/500/600?random=1',
          medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
          large: 'https://example.com/images/large.jpg',
          isDefault: true,
          width: 800,
          height: 600,
        },
        groupCreatedRegion: {
          id: 123,
          name: '서울특별시 강남구',
          province: '서울특별시',
          city: '강남구',
          town: '',
        },
      },
    },
  ],
};

const memberSection: MemberSection = {
  memberCount: 123,
  members: [
    {
      id: 123,
      role: 'member',
      state: 'member',
      temperature: 36.5,
      userRegion: {
        id: 123,
        name: '서울특별시 강남구',
        province: '서울특별시',
        city: '강남구',
        town: '',
      },
      nickname: '사용자 닉네임',
      profileImage: 'https://picsum.photos/seed/picsum/500/600?random=1',
      description: '사용자 소개',
      isAccountDeleted: false,
      memberKey: 'abcdefg123456',
      createdAt: '2023-05-06T14:00:00Z',
      updatedAt: '2023-05-06T15:00:00Z',
      verified: false,
      userRegionCheckInCount: 0, // not use
    },
  ],
};

const noticeSection: NoticeSection = {
  post: {
    ...postArticleResponse.post,
    readCount: 1020,
    commentCount: 94,
  },
};

const postSection: PostSection = {
  postCount: 123,
  allPosts: [{ ...postArticleResponse.post }],
  categoryPosts: [
    {
      boardCategory: {
        id: 123,
        category: '카테고리 이름',
        type: 'default',
      },
      posts: [
        {
          ...postArticleResponse.post,
        },
        {
          ...postArticleResponse.post,
          id: 1000,
          content: '글을 길게써보아요. 가오갤 대존잼 이게 마블 영화지~',
        },
      ],
    },
    {
      boardCategory: {
        id: 1234,
        category: '공지사항',
        type: 'custom',
      },
      posts: [
        {
          ...postArticleResponse.post,
          content: '공지사항 내용',
          postType: {
            type: 'post',
          },
        },
      ],
    },
  ],
};

const photoSection: PhotoSection = {
  photoCount: 341,
  photos: [
    {
      id: 'abc12310',
      postId: 123,
      hasMultipleImages: false,
      image: {
        id: 'abc1213',
        url: 'https://picsum.photos/seed/picsum/500/600?random=1',
        small: 'https://picsum.photos/seed/picsum/500/600?random=1',
        medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
        large: 'https://example.com/images/large.jpg',
        isDefault: true,
        width: 800,
        height: 600,
      },
      isPinned: true,
    },
    {
      id: 'abc1223',
      postId: 123,
      hasMultipleImages: false,
      image: {
        id: 'abc1233',
        url: 'https://picsum.photos/seed/picsum/500/600?random=2',
        small: 'https://picsum.photos/seed/picsum/500/600?random=2',
        medium: 'https://picsum.photos/seed/picsum/500/600?random=2',
        large: 'https://example.com/images/large.jpg',
        isDefault: true,
        width: 800,
        height: 600,
      },
      isPinned: false,
    },
    {
      id: 'abc1243',
      postId: 123,
      hasMultipleImages: true,
      image: {
        id: 'abc1253',
        url: 'https://picsum.photos/seed/picsum/500/600?random=3',
        small: 'https://picsum.photos/seed/picsum/500/600?random=3',
        medium: 'https://picsum.photos/seed/picsum/500/600?random=3',
        large: 'https://example.com/images/large.jpg',
        isDefault: true,
        width: 800,
        height: 600,
      },
      isPinned: false,
    },
    {
      id: 'abc1263',
      postId: 123,
      hasMultipleImages: false,
      image: {
        id: 'abc1273',
        url: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220418/c7ea63b3-7493-4291-8be7-958e80153ae4t.png',
        small: 'https://picsum.photos/seed/picsum/500/600?random=2',
        medium: 'https://picsum.photos/seed/picsum/500/600?random=1',
        large: 'https://example.com/images/large.jpg',
        isDefault: true,
        width: 800,
        height: 600,
      },
      isPinned: false,
    },
  ],
};

const chatSection: ChatSection = {
  chatCount: 123,
  talkRooms: [
    {
      roomId: 3427,
      groupId: 2216,
      roomType: 'default',
      admissionType: 'public',
      name: 'ㅎㅇㅎㅇ',
      description: '',
      profileImage: undefined,
      someOfMemberProfileImages: [
        'https://d1rb42tpk2uaws.cloudfront.net/origin/profile/202304/b4f03c4a4f37e64fd00df0c2c0a69f0e0f845c0a2bebafe44c272dda593ae3af.webp?q=82&s=80x80&t=crop',
      ],
      memberCount: 1,
      createdAt: '2023-04-06T06:05:32.810+00:00',
      updatedAt: '2023-04-06T06:05:32.810+00:00',
      isJoined: false,
      myRoomInfo: undefined,
      lastTalkInfo: {
        name: '',
        message: {
          id: 0,
          groupId: 2216,
          content: 'ㅌㅌㅌ',
        },
        isNew: true,
        createdAt: '2023-04-19T07:29:37.902+00:00',
      },
      channelInfo: {
        channelId: '0-10000727-CMNT-3427',
        targetUri: 'karrot.alpha://channels/0-10000727-CMNT-3427',
      },
      talkNotificationState: false,
    },
    {
      roomId: 3406,
      groupId: 2216,
      roomType: 'default',
      admissionType: 'public',
      name: '위임test',
      description: 'test',
      profileImage: undefined,
      someOfMemberProfileImages: [
        'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png',
        'https://d1rb42tpk2uaws.cloudfront.net/origin/profile/202304/d7c8eff1f3f327d9379fbc1191df41c5c9b2179e50fa68d84f86477dda664896.jpg?q=82&s=80x80&t=crop',
      ],
      memberCount: 2,
      createdAt: '2023-04-05T03:00:41.207+00:00',
      updatedAt: '2023-04-05T03:22:59.469+00:00',
      isJoined: false,
      myRoomInfo: undefined,
      lastTalkInfo: undefined,
      channelInfo: {
        channelId: '0-10000727-CMNT-3406',
        targetUri: 'karrot.alpha://channels/0-10000727-CMNT-3406',
      },
      talkNotificationState: false,
    },
    {
      roomId: 3380,
      groupId: 2216,
      roomType: 'default',
      admissionType: 'public',
      name: '나가기방12321',
      description: undefined,
      profileImage: {
        id: 'kr-7141#1516089875980079104',
        url: 'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220418/c7ea63b3-7493-4291-8be7-958e80153ae4t.png',
        small:
          'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220418/c7ea63b3-7493-4291-8be7-958e80153ae4t.png',
        medium:
          'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220418/c7ea63b3-7493-4291-8be7-958e80153ae4t.png',
        large:
          'https://d1rb42tpk2uaws.cloudfront.net/community/community/20220418/c7ea63b3-7493-4291-8be7-958e80153ae4t.png',
        width: 192,
        height: 192,
      },
      someOfMemberProfileImages: [
        'https://d1rb42tpk2uaws.cloudfront.net/origin/profile/202207/e6d4fcf30661603c3f7fff82224089fbc25304d75685c12b584488438da67642.webp?q=82&s=640x640&t=crop',
      ],
      memberCount: 1,
      createdAt: '2023-04-04T16:25:33.137+00:00',
      updatedAt: '2023-04-04T16:25:33.137+00:00',
      isJoined: false,
      myRoomInfo: undefined,
      lastTalkInfo: undefined,
      channelInfo: {
        channelId: '0-9988-CMNT-3380',
        targetUri: 'karrot.alpha://channels/0-9988-CMNT-3380',
      },
      talkNotificationState: false,
    },
    {
      roomId: 3289,
      groupId: 2216,
      roomType: 'default',
      admissionType: 'public',
      name: '방장이관로테이션테스트',
      description: '',
      profileImage: undefined,
      someOfMemberProfileImages: [
        'https://d1rb42tpk2uaws.cloudfront.net/origin/profile/202207/e6d4fcf30661603c3f7fff82224089fbc25304d75685c12b584488438da67642.webp?q=82&s=640x640&t=crop',
      ],
      memberCount: 1,
      createdAt: '2023-04-03T02:38:15.464+00:00',
      updatedAt: '2023-04-03T02:38:15.464+00:00',
      isJoined: false,
      myRoomInfo: undefined,
      lastTalkInfo: undefined,
      channelInfo: {
        channelId: '0-9988-CMNT-3289',
        targetUri: 'karrot.alpha://channels/0-9988-CMNT-3289',
      },
      talkNotificationState: false,
    },
    {
      roomId: 3206,
      groupId: 2216,
      roomType: 'default',
      admissionType: 'public',
      name: '방2 공개',
      description: '',
      profileImage: undefined,
      someOfMemberProfileImages: [
        'https://d1rb42tpk2uaws.cloudfront.net/origin/avatar_image/202209/43b86b87d518fc4f3e7843e41c292a97e589b5f8d0e189cd17d81a17a49d1ff7.png',
        'https://d1rb42tpk2uaws.cloudfront.net/origin/profile/202304/b4f03c4a4f37e64fd00df0c2c0a69f0e0f845c0a2bebafe44c272dda593ae3af.webp?q=82&s=80x80&t=crop',
      ],
      memberCount: 2,
      createdAt: '2023-03-30T09:58:40.372+00:00',
      updatedAt: '2023-03-30T09:58:40.372+00:00',
      isJoined: false,
      myRoomInfo: undefined,
      lastTalkInfo: undefined,
      channelInfo: {
        channelId: '0-10000727-CMNT-3206',
        targetUri: 'karrot.alpha://channels/0-10000727-CMNT-3206',
      },
      talkNotificationState: true,
    },
  ],
};

const groupHomeResponse = {
  groupSection: {
    ...groupSection,
  },
  meetupSection: {
    ...meetupSection,
  },
  memberSection: {
    ...memberSection,
  },
  noticeSection: {
    ...noticeSection,
  },
  postSection: {
    ...postSection,
  },
  photoSection: {
    ...photoSection,
  },
  chatSection: {
    ...chatSection,
  },
} as SectionsResponse;

export const groupHomeHandlers = [apiV1GroupsIdHomeGetMockServe(200, groupHomeResponse)];
