import { USER_ME_URL } from '@/api/base/user';
import { getGroupPostEmotionListPath } from '@/api/hooks/useGetGroupPostEmotionList';
import { getPostDetailPath } from '@/api/hooks/useGetPostDetail';
import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId: string;
  postId: string;
};

/*
 *  모임 게시글 상세에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchPostDetail = ({ groupId, postId }: Params) => {
  queryClient.refetchQueries({
    queryKey: [getGroupPostEmotionListPath(Number(groupId), Number(postId))],
  });
  queryClient.refetchQueries({
    queryKey: [getPostDetailPath(groupId, postId)],
  });

  queryClient.refetchQueries({
    queryKey: [USER_ME_URL],
  });
};
