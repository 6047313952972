import { Dialog, useDialog, useSnackbarAdapter } from '@community-group/components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetGroupMe, useReadGroupMeNoSuspense } from '@/api/hooks/useGetGroupMe';
/*
 * title: 특정 모임을 동네생활에서 보여줄지 말지 핸들링하는 Hooks
 * description: 동네생활에서 진입했는지 여부, 현재 유저의 의사, 핸들링 API 함수를 제공하는 Hooks
 * URL: https://www.notion.so/daangn/Launch-b817e7bf59794b2fb4fbd8cc5e01d7ca?pvs=4
 */
import { trackEvent } from '@/utils/analytics';
import { shortening } from '@/utils/text';

import { useFetchIsNotViewGroupFeedback } from './useFetchIsNotViewGroupFeedback';

type Props = {
  groupId: string;
  groupName: string;
};

export const useHandleIsNotViewGroup = ({ groupId, groupName }: Props) => {
  const [referRoot, setReferRoot] = useState('');

  useEffect(() => {
    if (import.meta.env.SSR) return;

    // useBottomSheet로 열린 ActionSheet 내부에서 useQueryParams를 사용할 수 없어서 URLSearchParams로 대체
    // 관련 이슈 : https://daangn.slack.com/archives/C0324MBCA9Z/p1707933784797219
    const url = window.location.search;
    const params = new URLSearchParams(url);
    const refer_root = params.get('refer_root') ?? '';

    setReferRoot(refer_root);
  }, []);

  const { data: myInfo, isLoading, refetch } = useReadGroupMeNoSuspense(groupId);

  const snackbarAdapter = useSnackbarAdapter();
  const { open: openDialog, close: closeDialog } = useDialog();

  const { isNotViewGroup, setIsNotViewGroup } = useFetchIsNotViewGroupFeedback({
    groupId,
    isNotViewGroup: !!myInfo?.currentUser.isNotViewGroup,
    onSuccess: () => {
      refetch();
    },
  });

  const shownHandleIsNotViewGroupButton = useMemo(() => {
    if (myInfo?.currentUser.role !== 'none') return false;

    return isNotViewGroup || referRoot === 'community_tab';
  }, [referRoot, isNotViewGroup, isLoading]);

  const handleShowViewGroup = async () => {
    await setIsNotViewGroup(false, {
      onSuccess: () => {
        snackbarAdapter.create({
          message: `앞으로 '${shortening(groupName, 14)}'의 게시글이 동네생활에서 보여요.`,
          type: 'default',
          timeout: 3000,
          onClick: () => {
            snackbarAdapter.dismiss();
          },
        });

        trackEvent({
          event: 'click_revert_not_interested_post',
          params: {
            groupId,
            groupName,
            feedbackType: 'postDetail',
          },
        });
      },
    });
  };

  const handleIsNotViewGroup = useCallback(async () => {
    if (isNotViewGroup) {
      await handleShowViewGroup();
      return;
    }

    await openDialog({
      element: (
        <Dialog
          title="이 모임 글 보지 않기"
          description={`'${shortening(groupName, 14)}'의 모든 게시글을 보지 않으시겠어요?`}
          secondaryActionLabel="취소"
          onSecondaryAction={async () => {
            await closeDialog();
          }}
          primaryActionLabel="안보기"
          onPrimaryAction={async () => {
            setIsNotViewGroup(true, {
              onSuccess: () => {
                snackbarAdapter.create({
                  message: `'앞으로 ${shortening(
                    groupName,
                    14
                  )}'의 게시글이 동네생활에서 보이지 않아요.`,
                  type: 'default',
                  actionLabel: '되돌리기',
                  timeout: 3000,
                  onAction: () => {
                    handleShowViewGroup();
                  },
                  shouldCloseOnAction: true,
                  onClick: () => {
                    snackbarAdapter.dismiss();
                  },
                });
                trackEvent({
                  event: 'click_not_interested_post',
                  params: {
                    groupId,
                    groupName,
                    feedbackType: 'postDetail',
                  },
                });
              },
            });
            await closeDialog();
          }}
        />
      ),
    });
  }, [isNotViewGroup, setIsNotViewGroup]);

  return {
    shownHandleIsNotViewGroupButton,
    isNotViewGroup,
    toggleIsNotViewGroup: handleIsNotViewGroup,
  };
};
