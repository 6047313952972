import { groupClient } from '@community-group/api';
import { ChallengeParticipateResponse } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: number;
  challengeId: number;
};
type Props = UseMutationOptions<AxiosResponse<ChallengeParticipateResponse>, Error, Params>;

export const usePostJoinChallengeParticipants = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postJoinChallenge =
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdParticipantsPost(
      {
        axios: fetchInstance,
      }
    );

  const fetchPostJoinChallenge = ({ groupId, challengeId }: Params) => {
    return postJoinChallenge(groupId, challengeId);
  };

  return useThrottleMutation(fetchPostJoinChallenge, {
    onError,
    onSuccess: onSuccess,
  });
};
