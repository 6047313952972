import { IconPaperplaneFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { useMemo } from 'react';

import { Spinner } from '../../base';
import { useStickyInputFormContext } from '../provider/StickyInputFormProvider';
import * as s from './SubmitButton.css';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  strokeColor?: string;
  size?: number;
}

export const StickyInputSubmitButton = ({ ...props }: Props) => {
  const {
    watch,
    formState: { isSubmitting },
  } = useStickyInputFormContext();

  const sendable = useMemo(() => {
    const content = watch('content');
    const images = watch('images');

    return content || (images.length > 0 && content);
  }, [watch('images'), watch('content')]);

  const isLoading = useMemo(() => {
    if (isSubmitting) {
      return true;
    }

    const isUploading = watch('originImages').some(({ id }) => id.includes('temp'));
    if (isUploading) {
      return true;
    }

    return false;
  }, [isSubmitting, watch('originImages')]);

  return (
    <button
      disabled={isLoading || !sendable}
      type="submit"
      {...props}
      className={clsx([props.className, s.button])}
    >
      {isLoading ? (
        <Spinner size="small" variant="gray" />
      ) : (
        <IconPaperplaneFill
          color={sendable ? vars.$semantic.color.primary : vars.$scale.color.gray400}
          size={24}
        />
      )}
    </button>
  );
};
