import { axiosClient } from '@community-group/api';
import { AxiosRequestConfig } from 'axios';

import { wrapSSRErrorHandler } from '@/api/instance/axios';

import { useKarrotCustomHeader } from './useKarrotCustomHeader';

type fetchInstanceProps = AxiosRequestConfig;

/**
 * @deprecated 이 함수는 디프리케이티드 되었습니다. 대신 useRead를 사용하세요. (아파트에서 어떻게 쓰는지 참고)
 */
export const useFetchInstance = (fetchInstanceConfig?: fetchInstanceProps) => {
  const karrotCustomHeader = useKarrotCustomHeader();

  return wrapSSRErrorHandler(
    axiosClient.initFetchInstance(
      {
        ...fetchInstanceConfig,
        baseURL: globalThis.appConfig.apiEndPoints.community,
      },
      karrotCustomHeader
    )
  );
};
