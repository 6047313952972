import {
  ActionList,
  ActionListItem,
  ActionSheet,
  Typography,
  useBottomSheet,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useSubscribedPoiStream } from '@/components/group/PostForm/hooks/useSubscribedPoiStream';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { usePlaceSearchClient } from '@/hooks/usePlaceSearchClient';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import SettingMenuItem from './SettingMenuItem';

interface Props {
  title: string;
}

export const MeetupPoisMenuItem = ({ title }: Props) => {
  const { open: openBottomSheet, closeAsync: closeButtonSheet } = useBottomSheet();

  const { openSearchPoiPage } = usePlaceSearchClient();
  const { groupId } = usePathParams();
  const { group } = useReadGroupDetail(groupId);
  const { bridge } = useBridge();
  const hasMeetupPois =
    (group?.meetupPois.length ?? 0) > 0 && group?.meetupPois[0].coordinates.latitude !== 0;

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: putGroup } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });
    },
  });

  useSubscribedPoiStream({
    callback: (poi) => {
      putGroup(
        {
          id: groupId,
          groupModifyForm: {
            meetupPoiItems: [
              {
                id: poi.poiId,
                type: poi.type,
              },
            ],
          },
        },
        {
          onSuccess: () => {
            trackEvent({
              event: 'update_group_meetup_pois',
              params: {
                section: 'SettingMeetupPoisMenuItem',
              },
            });
            bridge.openToast({
              toast: {
                body: '자주 모이는 장소를 등록했어요.',
              },
            });
          },
        }
      );
    },
  });

  const handleDeletePoiButtonClick = () => {
    putGroup(
      {
        id: groupId,
        groupModifyForm: {
          meetupPoiItems: [],
        },
      },
      {
        onSuccess: () => {
          bridge.openToast({
            toast: {
              body: '자주 모이는 장소를 삭제했어요.',
            },
          });
        },
      }
    );
  };

  const handleUpdatePoiButtonClick = () => {
    trackEvent({
      event: 'click_search_poi_page',
      params: {
        type: 'meetupPois',
        section: 'SettingMeetupPoisMenuItem',
      },
    });

    openSearchPoiPage({});
  };

  const handleMenuItemClick = () => {
    if (hasMeetupPois) {
      return openBottomSheet({
        element: (
          <ActionSheet bottomButton={{ label: '닫기' }}>
            <ActionList>
              <ActionListItem
                onClick={async () => {
                  await closeButtonSheet();
                  handleUpdatePoiButtonClick();
                }}
              >
                수정
              </ActionListItem>
              <ActionListItem
                color={vars.$semantic.color.danger}
                onClick={async () => {
                  await closeButtonSheet();
                  handleDeletePoiButtonClick();
                }}
              >
                삭제
              </ActionListItem>
            </ActionList>
          </ActionSheet>
        ),
      });
    }

    handleUpdatePoiButtonClick();
  };

  return (
    <SettingMenuItem
      title={title}
      onClick={handleMenuItemClick}
      renderRight={
        <Typography typography="label2Regular" color="primary" ellipsisAfterLines={1}>
          {group?.meetupPois[0]?.name ?? '없음'}
        </Typography>
      }
    />
  );
};
