import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = { groupId: string; meetupId: string };
type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const usePutGroupMeetupClose = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupMeetupClose = groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdClosePut({
    axios: fetchInstance,
  });

  const putGroupMeetupCloseWrapperFp = ({ groupId, meetupId }: Params) => {
    return putGroupMeetupClose(parseInt(groupId), parseInt(meetupId));
  };

  return useThrottleMutation(putGroupMeetupCloseWrapperFp, {
    onMutate,
    onSettled,
    onError,
    onSuccess,
  });
};
