import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFetchInstance } from '../../instance/useFetchInstance';

type Props = {
  eventType: string;
};

export const getOpenEventPath = groupClient.api.GroupOpenEventApi.getapiV1GroupsOpenEventGetPath;

// 현재 진행중인 이벤트 목록을 가져오는 hooks
export const useGetOpenEvent = (props?: Props) => {
  const fetchInstance = useFetchInstance();
  const getOpenEvent = groupClient.api.GroupOpenEventApi.apiV1GroupsOpenEventGet({
    axios: fetchInstance,
  });

  const { data, refetch, isLoading, isFetching } = useSuspenseQuery({
    queryKey: [getOpenEventPath()],
    queryFn: () => getOpenEvent(),
  });

  const findData = useMemo(() => {
    if (!props?.eventType) return undefined;
    return data?.data.events.find((event) => event.eventType === props?.eventType);
  }, [data?.data, props?.eventType]);

  return { data: data?.data, findData: findData, refetch, isLoading, isFetching };
};
