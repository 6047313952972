import { ChallengeBannerPresentation } from '@community-group/api/lib/group/models';
import { withAsyncBoundary } from '@community-group/components';
import { AnimatePresence, motion } from 'framer-motion';

import MultiAttendedChallenge from './MultiAttendedChallenge';
import SingleAttendedChallenge from './SingleAttendedChallenge';
import * as s from './style.css';

type Props = {
  challengeBanner: ChallengeBannerPresentation;
};

const ChallengeBanner = ({ challengeBanner }: Props) => {
  const ChallengeBanner =
    challengeBanner.challenges.length === 1 ? (
      <SingleAttendedChallenge challenge={challengeBanner.challenges[0]} />
    ) : (
      <MultiAttendedChallenge
        challenge={challengeBanner.challenges}
        totalChallengesCount={
          challengeBanner?.totalChallengesCount ?? challengeBanner.challenges.length
        }
      />
    );

  return (
    <AnimatePresence>
      <motion.div className={s.Container}>{ChallengeBanner}</motion.div>
    </AnimatePresence>
  );
};

export default withAsyncBoundary(ChallengeBanner, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
