import { Dialog, useDialog } from '@community-group/components';

import { useDeleteGroup } from '@/api/hooks/useDeleteGroup';
import useGetDeletableGroup from '@/api/hooks/useGetDeletableGroup';
import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import useBackToActivity from '@/hooks/useBackToActivity';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { isDevelopmentAppStage } from '@/utils/check';
import { replaceWebviewHome } from '@/utils/link';

import * as s from './SettingGroupDeleteMenuItem.css';
import SettingMenuItem from './SettingMenuItem';

// 모임 삭제
const SettingGroupDeleteMenuItem = () => {
  const { push } = useFlow();
  const { groupId } = usePathParams();
  const { data: deletableData } = useGetDeletableGroup({ groupId });

  const { group } = useReadGroupDetail(groupId);

  const handleBack = useBackToActivity();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { open: openDialog, close: closeDialog } = useDialog();

  const { mutate: deleteGroupMutate } = useDeleteGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      replaceWebviewHome();
      handleBack('HomePage');
    },
  });

  // 삭제시 승인이 필요한 모임인 경우 모임삭제 신청 페이지로 이동
  const openApplyDeleteGroupSurveyLink = () => {
    window.location.href = isDevelopmentAppStage()
      ? 'karrot.alpha://web/ad/user_surveys/61'
      : 'karrot://web/ad/user_surveys/16871';
  };

  // 50명 아래의 특정 기준을 넘지 못한 모임을 삭제하려는 경우 핸들링
  const handleDeletableGroup = () => {
    if ((group?.memberCount ?? 0) > 1) {
      openDialog({
        element: (
          <Dialog
            title="모임 삭제 안내"
            description="더 이상 모임을 운영하고 싶지 않다면 모임장을 위임해보세요. 그래도 모임을 삭제하고 싶다면 멤버를 모두 내보낸 후에 다시 시도해주세요."
            primaryActionLabel="모임장 위임"
            onPrimaryAction={async () => {
              await closeDialog();
              push('GroupSettingMemberDelegateSuperHostPage', { groupId: String(groupId) });
            }}
            secondaryActionLabel="취소"
            onSecondaryAction={async () => {
              await closeDialog();
              trackEvent({
                event: 'click_cancel_delete_group',
                params: {
                  deletableState: deletableData?.deletableState,
                },
              });
            }}
          />
        ),
      });
      return;
    }
    openDialog({
      element: (
        <Dialog
          title="모임을 삭제할까요?"
          description="모든 데이터가 삭제되고 다시 볼 수 없어요."
          primaryActionLabel="삭제"
          onPrimaryAction={async () => {
            await closeDialog();
            deleteGroupMutate(String(groupId), {
              onSuccess: () => {
                trackEvent({
                  event: 'click_delete_group',
                  params: {
                    deletableState: deletableData?.deletableState,
                    groupId: group?.id,
                    groupCategoryName: group?.category.name,
                  },
                });
              },
            });
          }}
          secondaryActionLabel="취소"
          onSecondaryAction={async () => {
            await closeDialog();
            trackEvent({
              event: 'click_cancel_delete_group',
              params: {
                deletableState: deletableData?.deletableState,
              },
            });
          }}
        />
      ),
    });
  };

  // 50명이 넘고 특정 기준을 넘은 모임을 삭제하려는 경우 핸들링
  const handleDeleteNeedApprovalGroup = () => {
    openDialog({
      element: (
        <Dialog
          title="모임 삭제 안내"
          description=""
          optionalElement={
            <div className={s.NeedApprovalDesc}>
              <div>
                멤버가 50명 이상인 활성 모임은 바로 삭제할 수 없어요. 모임 운영을 더이상 하고 싶지
                않다면 모임장을 위임해주세요.
              </div>
              <div
                className={s.MustDelete}
                onClick={async () => {
                  await closeDialog();
                  openApplyDeleteGroupSurveyLink();
                }}
              >
                그래도 모임을 삭제하고 싶으신가요?
              </div>
            </div>
          }
          primaryActionLabel="모임장 위임"
          onPrimaryAction={async () => {
            await closeDialog();
            trackEvent({
              event: 'click_delete_group',
              params: {
                deletableState: deletableData?.deletableState,
                groupId: group?.id,
                groupCategoryName: group?.category.name,
              },
            });
            push('GroupSettingMemberDelegateSuperHostPage', { groupId: String(groupId) });
          }}
          secondaryActionLabel="취소"
          onSecondaryAction={async () => {
            await closeDialog();
            trackEvent({
              event: 'click_cancel_delete_group',
              params: {
                deletableState: deletableData?.deletableState,
              },
            });
          }}
        />
      ),
    });
  };

  const handleDeleteGroup = () => {
    if (deletableData?.deletableState === 'DELETABLE') {
      handleDeletableGroup();
      return;
    }

    if (deletableData?.deletableState === 'NEED_APPROVAL') {
      handleDeleteNeedApprovalGroup();
    }
  };
  return <SettingMenuItem warning title="모임 삭제" onClick={handleDeleteGroup} />;
};

export default SettingGroupDeleteMenuItem;
