import {
  ChallengeBannerPresentationChallenges,
  GroupDetailPresentation,
} from '@community-group/api/lib/group/models';
import { Typography, VerticalSpacing } from '@community-group/components';
import { IconCheckmarkLine, IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import * as s from './style.css';

type Props = {
  isSuccess: boolean;
  group?: GroupDetailPresentation;
} & Pick<
  ChallengeBannerPresentationChallenges,
  'id' | 'name' | 'intervalCertifiedProgress' | 'status'
>;

// 모임 상세 - 챌린지 배너 내부 챌린지 아이템
const ChallengeBannerItem = ({
  group,
  isSuccess,
  id,
  name,
  intervalCertifiedProgress,
  status,
}: Props) => {
  const { push } = useFlow();
  const { groupId } = usePathParams();
  const handleClickItem = () => {
    trackEvent({
      event: 'click_challenge_banner_item',
      params: {
        groupId,
        challengeId: id,
        challengeName: name,
        challengeStatus: status,
        categoryId: group?.category.id,
        groupName: group?.name,
      },
    });
    push('GroupChallengeDetailPage', {
      groupId,
      challengeId: id.toString(),
    });
  };

  return (
    <div className={s.Container({ isSuccess })} onClick={handleClickItem}>
      <Typography
        typography="subtitle2Regular"
        color={isSuccess ? 'green700' : 'gray900'}
        ellipsisAfterLines={1}
        style={{
          flex: 1,
        }}
      >
        {name}
      </Typography>
      <VerticalSpacing size={12} />
      <div className={s.StatusContainer}>
        <Typography
          typography="caption1Regular"
          color={isSuccess ? 'success' : 'gray600'}
        >{`${intervalCertifiedProgress.current} / ${intervalCertifiedProgress.target}`}</Typography>
        <VerticalSpacing size={8} />
        {isSuccess ? (
          <IconCheckmarkLine size={16} color={vars.$semantic.color.success} />
        ) : (
          <IconChevronRightLine size={16} color={vars.$scale.color.gray600} />
        )}
      </div>
    </div>
  );
};

export default ChallengeBannerItem;
