import { GroupPhotoContestEntryPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import {
  IconDot3HorizontalChatbubbleLeftLine,
  IconEyeLine,
  IconThumbUpFill,
  IconThumbUpLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler } from 'react';

import {
  deleteLikePhotoContestEntries,
  patchLikePhotoContestEntries,
} from '@/api/hooks/useGetPhotoContestEntries';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { convertNumberToKoreanUnit } from '@/utils/number';

import * as s from './PhotoContestEntryItem.css';

type Props = {
  onVote?: MouseEventHandler;
  isContestResult?: boolean;
} & GroupPhotoContestEntryPresentation;

const PhotoContestEntryItem = ({
  entryId,
  onVote,
  imageUrl,
  description,
  viewCount,
  voteCount,
  commentCount,
  hasUserVoted,
  groupId,
  postId,
  isContestResult,
}: Props) => {
  const { push } = useFlow();

  const handleItemClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    trackEvent({
      event: 'click_group_photo_event_page_photo_detail',
      params: {
        viewCount,
        voteCount,
        commentCount,
        isContestResult,
      },
    });

    push('GroupPostDetailPage', {
      groupId: groupId.toString(),
      postId: postId.toString(),
      photoContestEntryId: entryId.toString(),
    });
  };

  const handleVoteClick = (e: React.MouseEvent) => {
    if (onVote) {
      onVote(e);
    }

    setTimeout(() => {
      if (hasUserVoted) {
        deleteLikePhotoContestEntries(entryId);
      } else {
        patchLikePhotoContestEntries(entryId);
      }
    }, 50);
  };

  return (
    <li className={s.EntryItem} onClick={handleItemClick}>
      <div className={s.EntryImageContainer}>
        <img className={s.EntryImage} src={imageUrl} />
      </div>
      <Spacing size={12} />
      <div className={s.ContentsWrapper}>
        <div className={s.Contents}>
          <Typography typography="subtitle2Regular" color="gray900" ellipsisAfterLines={1}>
            {description}
          </Typography>
          <Spacing size={4} />
          <div className={s.CountTextList}>
            <div className={s.CountTextItem}>
              <IconEyeLine size={14} />
              <VerticalSpacing size={2} />
              <Typography typography="caption2Regular" color="gray700">
                {convertNumberToKoreanUnit(viewCount)}
              </Typography>
            </div>
            {commentCount !== 0 && (
              <>
                <VerticalSpacing size={4} />
                <div className={s.CountTextItem}>
                  <IconDot3HorizontalChatbubbleLeftLine size={14} />
                  <VerticalSpacing size={2} />
                  <Typography typography="caption2Regular" color="gray700">
                    {convertNumberToKoreanUnit(commentCount)}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={s.Vote} onClick={handleVoteClick}>
          <div style={{ padding: '0 0.125rem' }}>
            {hasUserVoted ? (
              <IconThumbUpFill size={20} color={vars.$semantic.color.primary} />
            ) : (
              <IconThumbUpLine size={20} />
            )}
          </div>
          <Typography
            style={{ width: 'max-content' }}
            typography="label5Regular"
            color={hasUserVoted ? 'primary' : 'gray700'}
          >
            {convertNumberToKoreanUnit(voteCount)}
          </Typography>
        </div>
      </div>
    </li>
  );
};

export default PhotoContestEntryItem;
