import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export type ViewRange = 'my' | 'adjacent' | 'range2' | 'range3';

export const getRecommendSimilarGroupPath = (id: number) =>
  groupClient.api.RecommendationApi.getapiV1GroupsIdRecommendSimilarGetPath(id);

export const useGetRecommendSimilarGroup = ({
  groupId,
  excludeGroupId,
}: {
  groupId: number;
  excludeGroupId?: number;
}) => {
  const fetchInstance = useFetchInstance();
  const getRecommendSimilarGroup =
    groupClient.api.RecommendationApi.apiV1GroupsIdRecommendSimilarGet({ axios: fetchInstance });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getRecommendSimilarGroupPath(groupId)],
    queryFn: () => getRecommendSimilarGroup(groupId, excludeGroupId),

    refetchOnWindowFocus: false,
  });

  return {
    data,
    refetch,
  };
};
