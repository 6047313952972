import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Form = { groupId: number; form: model.ParentingGroupKidCreateRequest };

type Props = UseMutationOptions<AxiosResponse, Error, Form>;

export const usePostParentingGroupKids = (props: Props) => {
  const fetchInstance = useFetchInstance();
  const postKids = groupClient.api.ParentingGroupApi.apiV1GroupsGroupIdParentingKidsPost({
    axios: fetchInstance,
  });

  return useThrottleMutation(({ groupId, form }: Form) => {
    return postKids(groupId, form);
  }, props);
};
