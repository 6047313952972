import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { PaginationQueryParams } from '@community-group/components/shared';
import { InfiniteData, useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupPhotoListPath = (groupId: string) => `${GROUP_URL}/${groupId}/photos`;

export type GetGroupPhotoListProps = {
  groupId: string;
  limit?: number;
  onlyPublic?: boolean;
  onlyPinnable?: boolean;
} & PaginationQueryParams;

export const useGetGroupPhotoList = ({
  groupId,
  limit = 15,
  onlyPublic,
  onlyPinnable,
  initialCursor = undefined,
}: GetGroupPhotoListProps) => {
  const fetchInstance = useFetchInstance();
  const getGroupPhotoList = groupClient.api.ImageApi.apiV1GroupsGroupIdPhotosGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getGroupPhotoListPath(groupId), onlyPublic, limit, onlyPinnable],
      queryFn: async ({ pageParam = initialCursor }) => {
        const { data } = await getGroupPhotoList(
          parseInt(groupId),
          pageParam !== null ? pageParam : undefined,
          limit,
          onlyPublic,
          onlyPinnable
        );

        return data;
      },
      initialPageParam: initialCursor,

      getNextPageParam: (lastPage) =>
        lastPage.hasNext && lastPage.endCursor !== null ? lastPage.endCursor : undefined,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data: data as InfiniteData<model.GroupPhotoListResponse> | undefined,
    refetch,
  };
};
