import { Category } from '@community-group/api/lib/group/models';
import { useBottomSheet } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconArrowClockwiseCircularFill } from '@daangn/react-monochrome-icon';
import { Dispatch, SetStateAction } from 'react';
import { $Values } from 'utility-types';

import { ORDER_SEARCH, ORDER_SEARCH_KR_TYPO } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import { trackEvent } from '@/utils/analytics';

import SortingFilterBottomSheet from '../../bottomSheets/SortingFilterBottomSheet';
import { AgeRangeType, RegionRangeType } from '../../model';
import { getAgeRangeText, getRegionRangeValue } from '../../utils';
import AgeFilterBottomSheet from './AgeFilterBottomSheet';
import CategoryFilterBottomSheet from './CategoryFilterBottomSheet';
import FilterChipButton from './FilterChipButton';
import * as s from './index.css';
import RegionFilterBottomSheet from './RegionFilterBottomSheet';

export interface SearchResultGroupFilterProps {
  selectedRegionRange: RegionRangeType;
  setSelectedRegionRange: Dispatch<SetStateAction<RegionRangeType>>;
  selectedAgeRange: AgeRangeType;
  setSelectedAgeRange: Dispatch<SetStateAction<AgeRangeType>>;
  selectedCategoryList: Category[];
  setSelectedCategoryList: Dispatch<SetStateAction<Category[]>>;
  selectedSorting: $Values<typeof ORDER_SEARCH>;
  setSelectedSorting: Dispatch<SetStateAction<$Values<typeof ORDER_SEARCH>>>;
}

const SearchResultGroupFilter = ({
  selectedRegionRange,
  setSelectedRegionRange,
  selectedAgeRange,
  setSelectedAgeRange,
  selectedCategoryList,
  setSelectedCategoryList,
  selectedSorting,
  setSelectedSorting,
}: SearchResultGroupFilterProps) => {
  const { open: openBottomSheet } = useBottomSheet();

  const { categories } = useGetCategories();

  const hasSelectedRegionRange = Boolean(getRegionRangeValue(selectedRegionRange));
  const hasSelectedAgeRange = Boolean(selectedAgeRange);
  const hasSelectedCategory = selectedCategoryList.length > 0;

  const hasSelectedFilter = hasSelectedRegionRange || hasSelectedAgeRange || hasSelectedCategory;

  const categoryButtonText = (categories) => {
    if (categories.length === 0) {
      return undefined;
    }

    if (categories.length === 1) {
      return categories[0].name;
    }

    return `${categories[0].name} 외 ${categories.length - 1}개`;
  };

  const handleRetry = () => {
    setSelectedRegionRange(undefined);
    setSelectedAgeRange(undefined);
    setSelectedCategoryList([]);
  };

  const handleRegionFilterClick = () => {
    trackEvent({
      event: 'click_search_filter',
      params: {
        type: 'region_range',
        regionRange: selectedRegionRange,
      },
    });

    openBottomSheet({
      element: (
        <RegionFilterBottomSheet
          selectedRegionRange={selectedRegionRange}
          setSelectedRegionRange={setSelectedRegionRange}
        />
      ),
    });
  };

  const handleAgeFilterClick = () => {
    trackEvent({
      event: 'click_search_filter',
      params: {
        type: 'age_range',
        ageRange: selectedAgeRange,
      },
    });

    openBottomSheet({
      element: (
        <AgeFilterBottomSheet
          selectedAgeRange={selectedAgeRange}
          setSelectedAgeRange={setSelectedAgeRange}
        />
      ),
    });
  };

  const handleCategoryClick = () => {
    trackEvent({
      event: 'click_search_filter',
      params: {
        type: 'category',
        categoryList: selectedCategoryList.map((category) => category.name).join(', '),
      },
    });

    openBottomSheet({
      element: (
        <CategoryFilterBottomSheet
          categoryList={categories}
          selectedCategoryList={selectedCategoryList}
          setSelectedCategoryList={setSelectedCategoryList}
        />
      ),
    });
  };

  const handleSortingClick = () => {
    trackEvent({
      event: 'click_search_filter',
      params: {
        type: 'sorting',
        sorting: selectedSorting,
      },
    });

    openBottomSheet({
      element: (
        <SortingFilterBottomSheet
          selectedSorting={selectedSorting}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setSelectedSorting={setSelectedSorting}
        />
      ),
    });
  };

  return (
    <div className={s.Wrapper}>
      <div className={s.Container}>
        {hasSelectedFilter && (
          <button className={s.ResetButton} onClick={handleRetry}>
            <IconArrowClockwiseCircularFill size={14} />
          </button>
        )}
        <FilterChipButton
          label="전국"
          selectedValue={getRegionRangeValue(selectedRegionRange)}
          onClick={handleRegionFilterClick}
        />
        <VerticalSpacing size={8} />
        <FilterChipButton
          label="모집 연령대"
          selectedValue={getAgeRangeText(selectedAgeRange)}
          onClick={handleAgeFilterClick}
        />
        <VerticalSpacing size={8} />
        <FilterChipButton
          label="카테고리"
          selectedValue={categoryButtonText(selectedCategoryList)}
          onClick={handleCategoryClick}
        />
        <VerticalSpacing size={8} />
        <FilterChipButton
          label={ORDER_SEARCH_KR_TYPO[selectedSorting]}
          onClick={handleSortingClick}
        />
      </div>
    </div>
  );
};

export default SearchResultGroupFilter;
