import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { BoxButton, Typography, useSnackbarAdapter } from '@community-group/components';
import { differenceInDays } from 'date-fns';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePostJoinChallengeParticipants } from '@/api/hooks/usePostJoinChallengeParticipants';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';

import * as s from './style.css';
type Props = {
  challengeDetail: ChallengeDetailPresentation;
};

const ChallengeAccessoryBar = ({ challengeDetail }: Props) => {
  const { groupId, challengeId } = usePathParams();
  const snackbar = useSnackbarAdapter();
  const isJoined = challengeDetail.isJoined;
  const { push } = useFlow();
  const { group } = useReadGroupDetail(groupId);
  const { handleGroupOnly } = useHandleGroupOnly({ groupId });

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateJoinChallengeParticipants } = usePostJoinChallengeParticipants({
    onSuccess: () => {
      trackEvent({
        event: 'click_success_attend_challenge',
        params: {
          groupId,
          challengeId,
          challengeName: challengeDetail.name,
          categoryId: group?.category.id,
          groupName: group?.name,
          hasExampleImage: challengeDetail.images?.length ?? 0 > 0,
        },
      });
      snackbar.create({
        message: `'${challengeDetail.name}'챌린지에 참여했어요.`,
        timeout: 3000,
        type: 'default',
        onClick: () => {
          snackbar.dismiss();
        },
      });

      refetchGroupChallenge({ groupId, challengeId });
    },
    onError: handleErrorWithToast,
  });

  const handleWriteCertificationPost = () => {
    trackEvent({
      event: 'click_challenge_certify_button',
      params: {
        challengeId,
        challengeName: challengeDetail.name,
        challengeDuration: challengeDetail.certifyIntervalDays,
        challengeFrequency: challengeDetail.certifyIntervalTargetCount,
        groupId,
        hasExampleImage: challengeDetail.images?.length ?? 0 > 0,
        groupName: group?.name,
        categoryId: group?.category.id,
      },
    });
    push('GroupPostNewPage', {
      groupId,
      challengeId,
      postType: 'challengeCertify',
    });
  };

  if (challengeDetail.status === 'closed') return null;

  if (!isJoined) {
    const handleJoinChallenge = () => {
      handleGroupOnly({
        blockGroupOnlyTitle: '모임 가입 안내',
        blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getJoinChallenge,
        onSettled: () => {
          mutateJoinChallengeParticipants({
            groupId: Number(groupId),
            challengeId: Number(challengeId),
          });
        },
        onSuccess() {
          mutateJoinChallengeParticipants({
            groupId: Number(groupId),
            challengeId: Number(challengeId),
          });
        },
      });
    };

    return (
      <div className={s.Container}>
        <BoxButton onClick={handleJoinChallenge} width={'100%'} size="xlarge">
          <Typography typography="label1Bold" color="onPrimary">
            참여하기
          </Typography>
        </BoxButton>
      </div>
    );
  }

  if (challengeDetail.status === 'prepared') {
    const remainDays = differenceInDays(
      new Date(new Date(challengeDetail.startedAt).setHours(0, 0, 0, 0)),
      new Date(new Date().setHours(0, 0, 0, 0))
    );
    return (
      <div className={s.Container}>
        <BoxButton width={'100%'} size="xlarge" isDisabled>
          <Typography typography="label1Bold" color="gray600">
            {remainDays}일 후 인증 가능
          </Typography>
        </BoxButton>
      </div>
    );
  }

  return (
    <div className={s.Container}>
      <BoxButton onClick={handleWriteCertificationPost} width={'100%'} size="xlarge">
        <Typography typography="label1Bold" color="onPrimary">
          인증글 남기기
        </Typography>
      </BoxButton>
    </div>
  );
};

export default ChallengeAccessoryBar;
