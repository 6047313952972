import { PostTypePresentation } from '@community-group/api/lib/group/models';

import { getDateDiffFromNow } from '@/utils/date';

type InfoTextPostType = {
  createdAt: string;
  isPublished: boolean;
  isGroupOnly: boolean;
  isNoticed?: boolean;
  boardCategory?: string;
  postType?: PostTypePresentation;
};

// 목록 페이지에서 사용하는 포스트 정보 텍스트
export const getFeedPostInfoText = (post: InfoTextPostType) => {
  const createdAtText = getDateDiffFromNow(new Date(Date.parse(post.createdAt))).text + ' 전';
  const isNoticedText = post.isNoticed ? ' ∙ 공지' : undefined;
  const boardName = post?.boardCategory ?? '';
  const slicedBoardName = boardName?.length > 10 ? `${boardName.slice(0, 10)}...` : boardName;

  const isChallenge = post.postType?.type === 'challenge';
  const isMeetup = post.postType?.type === 'post' && post.postType.meetupId;

  const postTypeText = isChallenge ? ` ∙ 챌린지` : isMeetup ? ` ∙ 모임일정` : '';

  const renderBoardNameText = slicedBoardName ? ` ∙ ${slicedBoardName}` : '';

  if (!isNoticedText) {
    return `${createdAtText}${renderBoardNameText}${postTypeText}`;
  }

  return `${createdAtText}${isNoticedText}`;
};

// 포스트 상세에서 사용하는 포스트 정보 텍스트
export const getPostDetailInfoText = (post: InfoTextPostType) => {
  const createdAtText = getDateDiffFromNow(new Date(Date.parse(post.createdAt))).text + ' 전';
  const isNoticedText = post.isNoticed ? ' ∙ 공지사항' : '';
  const isPublished = post.isPublished ? ' ∙ 동네생활에 공유됨' : undefined;
  const isGroupOnly = post.isGroupOnly ? ' ∙ 모임에만 공개' : undefined;

  if (isPublished) {
    return `${createdAtText}${isNoticedText}${isPublished}`;
  }
  if (isGroupOnly) {
    return `${createdAtText}${isNoticedText}${isGroupOnly}`;
  }

  return `${createdAtText}${isNoticedText}`;
};
