import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupFeedSummaryMeetup = (groupId: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedSummaryMeetupGetPath(groupId);

export const useGetGroupFeedSummaryMeetup = (groupId: number) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedSummaryMeetupGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getGroupFeedSummaryMeetup(groupId)],
    queryFn: () => get(groupId),
  });
  const response = data?.data;

  return { data: response, refetch };
};
