import { AsyncBoundary, HelpBubbleTrigger } from '@community-group/components';
import {
  IconChevronLeftLine,
  IconMagnifyingglassLine,
  IconPersonCircleLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import { AnimatePresence, motion } from 'framer-motion';

import { useGetMe } from '@/api/hooks/useGetMe';
import IconWrapper from '@/components/common/Icons';
import { useBridge } from '@/contexts/Bridge';
import useCheckGroupProfileCreated from '@/hooks/useCheckGroupProfileCreated';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { trackEvent } from '@/utils/analytics';

import GroupHomeSkeleton from '../../pages/Skeleton';

interface Props {
  children: React.ReactNode;
}

export const HomeAppScreen = ({ children }: Props) => {
  const { push } = useFlow();
  const { bridge } = useBridge();
  const { isActive } = useActivity();

  const [showedProfileInduceSnsTagTooltip, setShowedProfileInduceSnsTagTooltip] = useStorage(
    'showedProfileInduceSnsTagTooltip',
    false
  );

  const handleBackButton = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    bridge.closeRouter({});
  };

  const { state: profileState } = useCheckGroupProfileCreated({});

  return (
    <AppScreen
      fallbackLoading={<GroupHomeSkeleton />}
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftLine size={24} />,
          onClick: (event) => {
            handleBackButton(event);
          },
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine size={24} />,
          onClick: (event) => {
            handleBackButton(event);
          },
        },
        title: (
          <AsyncBoundary>
            <Title />
          </AsyncBoundary>
        ),
        renderRight: () => (
          <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <IconWrapper
                onClick={() => {
                  trackEvent({ event: 'click_search_icon' });
                  push('SearchHomePage', {});
                }}
                style={{
                  marginRight: '.25rem',
                }}
              >
                <IconMagnifyingglassLine size={24} color={vars.$scale.color.gray900} />
              </IconWrapper>
            </motion.div>
            {isActive && (
              <HelpBubbleTrigger
                title={`프로필에 소셜 계정을 추가해보세요`}
                isOpen={!showedProfileInduceSnsTagTooltip}
                UNSAFE_style={{
                  whiteSpace: 'pre-wrap',
                }}
                positioning={{
                  placement: 'bottom-start',
                }}
                marginLeft={-14}
                closeOnInteractOutside={false}
                onOpenChange={() => {
                  setShowedProfileInduceSnsTagTooltip(true);
                }}
              >
                <IconWrapper
                  style={{
                    marginRight: '0.25rem',
                  }}
                >
                  <IconPersonCircleLine
                    size={24}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowedProfileInduceSnsTagTooltip(true);
                      trackEvent({ event: 'click_integrated_group_profile_icon', sample: true });
                      if (profileState.needToProfileSet) {
                        push('GroupProfileCreatePage', {});
                        return;
                      }
                      push('GroupProfileDetailPage', {});
                    }}
                  />
                </IconWrapper>
              </HelpBubbleTrigger>
            )}
          </>
        ),
      }}
    >
      {children}
    </AppScreen>
  );
};

const Title = () => {
  const { data: myInfo } = useGetMe();
  const regionCityName = myInfo?.regionCheckIns?.regionName ?? '';

  return (
    <AnimatePresence>
      <motion.span
        initial={{ opacity: 0, whiteSpace: 'nowrap' }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          type: 'spring',
          bounce: 0,
          duration: 0.2,
          delay: 0.1,
        }}
      >
        {regionCityName && `${regionCityName} 모임`}
      </motion.span>
    </AnimatePresence>
  );
};
