import {
  isHigherManager as checkIsHigherManager,
  withAsyncBoundary,
} from '@community-group/components';
import { useMemo } from 'react';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import useGroupDetailGroupBotBannerClosed from '../hooks/useGroupDetailGroupBotBannerClosed';
import useGroupDetailGroupChallengeBannerClosed from '../hooks/useGroupDetailGroupChallengeBannerClosed';
import useGroupDetailHeroImageBannerClosed from '../hooks/useGroupDetailHeroImageBannerClosed';
import GroupDetailGroupBotBanner from './GroupDetailGroupBotBanner';
import GroupDetailGroupChallengeBanner from './GroupDetailGroupChallengeBanner';
import GroupDetailHeroSliderBanner from './GroupDetailHeroSliderBanner';
import { GroupPhotoContestBanner } from './GroupPhotoContestBanner';

type Props = { groupId: number };

function GroupDetailBanner({ groupId }: Props) {
  const { data: groupMe } = useGetGroupMe(groupId.toString());
  const currentUserRole = useMemo(() => groupMe?.currentUser?.role ?? 'none', [groupMe]);
  const isSuperHost = useMemo(() => currentUserRole === 'superHost', [currentUserRole]);
  const isHigherManager = useMemo(() => checkIsHigherManager(currentUserRole), [currentUserRole]);

  const shownPhotoContest = useFeatureFlag('shownPhotoContest');

  const [groupBotBannerClosed] = useGroupDetailGroupBotBannerClosed(groupId.toString());
  const groupChallengeEnabled = useFeatureFlag('useGroupChallenge');
  const [groupChallengeBannerClosed] = useGroupDetailGroupChallengeBannerClosed(groupId.toString());

  const [heroImageBannerClosed] = useGroupDetailHeroImageBannerClosed(groupId.toString());

  // TODO: 히어로 슬라이더 레드팀 분기
  // 히어로 슬라이더 관련 사진 첨부 or 게시글 작성 안내 배너
  if (isHigherManager && !heroImageBannerClosed) {
    return <GroupDetailHeroSliderBanner groupId={groupId} />;
  }

  // 모임 챌린지 새기능 배너
  if (groupChallengeEnabled && isSuperHost && !groupChallengeBannerClosed) {
    return <GroupDetailGroupChallengeBanner groupId={groupId} />;
  }

  // TODO: 모임장봇 제거 필요
  // 모임장 봇 채팅 배너
  // if (isSuperHost && !groupBotBannerClosed) {
  //   return <GroupDetailGroupBotBanner groupId={groupId} />;
  // }

  // V2 사진전 배너 && 히어로 배너 노출 가능한 경우
  if (shownPhotoContest && groupMe?.currentUser?.role !== 'none') {
    return <GroupPhotoContestBanner />;
  }

  return null;
}

export default withAsyncBoundary(GroupDetailBanner, {
  pendingFallback: null,
  rejectedFallback: <></>,
});
