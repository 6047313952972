import { ChipToggleButton } from '@community-group/components';
import { IconFacebook, IconInstagram, IconTwitter } from '@community-group/components';
import { IconPlusLine } from '@daangn/react-monochrome-icon';

export type SnsTagItemProps = {
  name?: string;
  value?: string;
  selected?: boolean;
  iconType?: 'facebook' | 'instagram' | 'x';
  tagType?: 'new' | 'addSocialId';
  onClick?: () => void;
};

export const SnsTagItem = ({
  name,
  iconType,
  selected,
  tagType = 'addSocialId',
  onClick,
}: SnsTagItemProps) => {
  if (tagType === 'new') {
    return (
      <ChipToggleButton onClick={onClick} prefix={<IconPlusLine size={16} />}>
        소셜 계정 추가
      </ChipToggleButton>
    );
  }

  const getIcon = () => {
    if (iconType === 'facebook') {
      return <IconFacebook fill={selected ? 'white' : 'dark'} />;
    }
    if (iconType === 'instagram') {
      return <IconInstagram fill={selected ? 'white' : 'dark'} />;
    }
    return <IconTwitter fill={selected ? 'white' : 'dark'} />;
  };

  return (
    <ChipToggleButton isSelected={selected} onClick={onClick} prefix={getIcon()}>
      {name}
    </ChipToggleButton>
  );

  return <></>;
};
