import { JoinableRegionRange } from '@community-group/api/lib/group/models';
import {
  getBoundsByCoordinates,
  MapInstance,
  Slider,
  Spacing,
  Typography,
  useBottomSheet,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import {
  initialGroupRegionRanges,
  useGetGroupRegionRanges,
} from '@/api/hooks/useGetGroupRegionRanges';
import { DaangnMapProps, Map } from '@/components/common/Map';
import { useUserConfig } from '@/contexts/UserConfig';
import { trackEvent } from '@/utils/analytics';

import { RegionRangeType } from '../../model';
import { getRegionRangeName } from '../../utils';
import { addPolygonLayer, getCoordinates, getGeoJson } from '../../utils/map';
import FilterBottomSheetButtonGroup from './FilterBottomSheetButtonGroup';
import * as s from './RegionFilterBottomSheet.css';

type Props = {
  selectedRegionRange: RegionRangeType;
  setSelectedRegionRange: Dispatch<SetStateAction<RegionRangeType>>;
};

const RegionFilterBottomSheet = ({ selectedRegionRange, setSelectedRegionRange }: Props) => {
  const [regionRangeKey, setRegionRangeKey] = useState(selectedRegionRange);

  const handleReset = () => {
    setRegionRangeKey(undefined);
  };

  const { closeAsync: closeBottomSheet } = useBottomSheet();
  const handleSubmit = () => {
    trackEvent({
      event: 'click_search_apply_region_filter',
      params: {
        regionRange: regionRangeKey,
      },
    });

    closeBottomSheet();
    setSelectedRegionRange(regionRangeKey);
  };

  const regionRangeList: RegionRangeType[] = ['my', 'range2', 'range3', 'unlimited', undefined];
  const markers = useMemo(() => {
    return [
      {
        value: 0,
        label: '가까운 동네',
        align: 'start' as const,
      },
      {
        value: 1,
        label: '',
        align: 'center' as const,
      },
      {
        value: 2,
        label: '',
        align: 'center' as const,
      },
      {
        value: 3,
        label: '전국',
        align: 'end' as const,
      },
    ];
  }, []);

  return (
    <div className={s.BottomSheetContainer}>
      <Typography typography="title2Bold" color="gray900">
        모임 지역
      </Typography>
      <Spacing size={16} />
      <RegionFilterMap selectedRegionRangeKey={regionRangeKey} />
      <Spacing size={14} />
      <Slider
        step={1}
        minValue={0}
        maxValue={3}
        markers={markers}
        onChange={(value) => setRegionRangeKey(regionRangeList[value])}
        value={regionRangeList.findIndex((range) => range === regionRangeKey)}
      />
      <Spacing size={4} />
      <FilterBottomSheetButtonGroup onReset={handleReset} onApply={handleSubmit} />
    </div>
  );
};

type MapProps = {
  selectedRegionRangeKey: RegionRangeType;
};

const RegionFilterMap = withAsyncBoundary(
  ({ selectedRegionRangeKey = 'unlimited' }: MapProps) => {
    const { userConfig } = useUserConfig();
    const currentRegionId = userConfig.regionId ?? 0;
    const { data: regionData } = useGetGroupRegionRanges(currentRegionId);
    const { joinableRegionRanges } = regionData ?? initialGroupRegionRanges();

    if (joinableRegionRanges.length === 0) return <div className={s.MapWrapper} />;

    return (
      <RegionFilterMapCore
        selectedRegionRangeKey={selectedRegionRangeKey}
        joinableRegionRanges={joinableRegionRanges}
      />
    );
  },
  {
    pendingFallback: <ViewLoader />,
    rejectedFallback: <ViewError />,
  }
);

type MapCoreProps = {
  selectedRegionRangeKey: RegionRangeType;
  joinableRegionRanges: JoinableRegionRange[];
};

const RegionFilterMapCore = ({ selectedRegionRangeKey, joinableRegionRanges }: MapCoreProps) => {
  const regionRangeName = useMemo(
    () => getRegionRangeName(selectedRegionRangeKey),
    [selectedRegionRangeKey]
  );

  const mapInstanceRef = useRef<MapInstance>();
  const [mapState, setMapState] = useState<DaangnMapProps['initialMapState']>(() => {
    const coordinates = getCoordinates(joinableRegionRanges, selectedRegionRangeKey);
    return {
      bounds: getBoundsByCoordinates(coordinates, {
        container: { clientWidth: 335, clientHeight: 196 },
      }),
    };
  });

  const applyMapState = (coordinates) => {
    setMapState({
      bounds: getBoundsByCoordinates(coordinates, {
        container: { clientWidth: 335, clientHeight: 196 },
      }),
    });
  };

  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const coordinates = getCoordinates(joinableRegionRanges, selectedRegionRangeKey);
    const geoJson = getGeoJson(regionRangeName, coordinates);

    applyMapState(coordinates);
    addPolygonLayer(mapInstanceRef.current, geoJson);
  }, [joinableRegionRanges, regionRangeName, selectedRegionRangeKey]);

  return (
    <div className={s.MapWrapper}>
      <Map
        className={s.Map}
        initialMapState={mapState}
        onLoad={({ target }) => {
          mapInstanceRef.current = target;

          target.UNSAFE_maplibreglMap.setMaxBounds([
            [110.37314631042187, 32.5075706009476], // Southwest coordinates
            [143.06988184875956, 39.2083044744926], // Northeast coordinates
          ]);

          const coordinates = getCoordinates(joinableRegionRanges, selectedRegionRangeKey);
          const geoJson = getGeoJson(regionRangeName, coordinates);
          addPolygonLayer(target, geoJson);
        }}
        module={false}
        interactive={false}
        maxZoom={0}
        minZoom={0}
        minPitch={0}
        maxPitch={0}
        dragRotate={false}
        pitchWithRotate={false}
      />
      <Typography className={s.RegionRangeLabel} typography="caption2Regular" color="gray00">
        {regionRangeName}
      </Typography>
    </div>
  );
};

export default RegionFilterBottomSheet;
