import {
  Spacing,
  Thumbnail,
  Typography,
  VerticalSpacing,
  withAsyncBoundary,
} from '@community-group/components';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import IconApprovedGroup from '@/assets/images/icon_approved_group.svg';

import * as s from './ParentingGroupPreview.css';
type Props = {
  groupId: string;
};

const ParentingGroupPreview = ({ groupId }: Props) => {
  const { group } = useReadGroupDetail(groupId);

  return (
    <div className={s.Wrapper}>
      <div className={s.ItemBox}>
        <Thumbnail src={group?.profileImage.small} size={48} />
        <VerticalSpacing size={16} />
        <div className={s.TextColumnBox}>
          <Typography typography="subtitle2Bold" color="gray900" ellipsisAfterLines={1}>
            {group?.name}
          </Typography>
          <Spacing size={4} />
          <div className={s.TextBox}>
            <img src={IconApprovedGroup} alt="대표 모임 뱃지" width={14} height={14} />
            <VerticalSpacing size={4} />
            <Typography typography="caption1Regular" color="carrot950">
              대표 육아 모임
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAsyncBoundary(ParentingGroupPreview, {
  pendingFallback: (
    <div className={s.Wrapper}>
      <div className={s.ItemBox} />
    </div>
  ),
});
