import { PostDetailMeetupInfoPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconCalendarFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEvent, useMemo } from 'react';

import * as s from './MeetupBanner.css';

type Props = {
  meetup: PostDetailMeetupInfoPresentation;
  isReview?: boolean;
  onClick?: (e: MouseEvent) => void;
};

export const MeetupBanner = ({ meetup, isReview, onClick }: Props) => {
  const title = isReview ? meetup.title : meetup.meetupTimeAtDisplayText;
  const descriptionLeft = isReview ? meetup.meetupTimeAtDisplayText : '일정';
  const descriptionRight = `${meetup.currentParticipantsNumber}/${meetup.maximumParticipantsNumber}명 참여`;

  return (
    <div className={s.Container} onClick={onClick} aria-hidden="true">
      <div className={s.ImageWrapper}>
        <IconCalendarFill width={24} height={24} color={vars.$semantic.color.primary} />
      </div>
      <div className={s.ContentsWrapper}>
        <div className={s.Contents}>
          <Typography typography="bodyM2Regular" color="gray900" ellipsisAfterLines={1}>
            {title}
          </Typography>

          <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
            {`${descriptionLeft} ∙ ${descriptionRight}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
