import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

interface Props {
  groupId?: string;
}

export const getDeletableGroupPath = (groupId: number) =>
  groupClient.api.SettingsApi.getapiV1GroupsGroupIdSettingsDeletableGetPath(groupId);

const useGetDeletableGroup = ({ groupId }: Props) => {
  const fetchInstance = useFetchInstance();
  const getGetDeletableGroup = groupClient.api.SettingsApi.apiV1GroupsGroupIdSettingsDeletableGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getDeletableGroupPath(Number(groupId))],
    queryFn: async () => {
      // TODO: skipToken 사용 시 오류 발생
      if (!groupId) return null;

      return await getGetDeletableGroup(Number(groupId));
    },
  });

  return {
    data: data?.data,
    refetch,
  };
};

export default useGetDeletableGroup;
