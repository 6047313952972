import { Typography } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';

import { useFlow } from '@/stackflow';

import * as s from './style.css';

export const GroupPhotoContestBanner = () => {
  const { push } = useFlow();

  const handleClick = () => {
    push('PhotoContestPage', {});
  };

  return (
    <div className={s.Banner} onClick={handleClick}>
      <Typography typography="bodyM2Regular" color="gray800" ellipsisAfterLines={2}>
        [이벤트] 전~국모임자랑📸 러닝 인증샷을 구경해보세요!
      </Typography>
      <IconChevronRightFill size={16} />
    </div>
  );
};
