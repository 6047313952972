import { Typography, ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { useMemo } from 'react';

import { useGetRecommendSimilarGroup } from '@/api/hooks/useGetRecommendSimilarGroup';
import GroupExplorerListSection from '@/components/common/GroupExplorerListSection';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { getUserExperimentalABGroup } from '@/utils/analytics/experiment/userExperimentalGroup';

import * as s from './RecommendSimilarGroupSection.css';

type Props = {
  categoryId?: number;
};

const RecommendSimilarGroupSection = ({ categoryId }: Props) => {
  const { groupId = '0' } = usePathParams();
  const { prevViewedGroupId } = useQueryParams();
  const { data } = useGetRecommendSimilarGroup({
    groupId: Number(groupId),
    excludeGroupId: prevViewedGroupId ? Number(prevViewedGroupId) : undefined,
  });

  const { push } = useFlow();

  const { userConfig } = useUserConfig();

  const treatmentUser = getUserExperimentalABGroup(userConfig?.userId ?? '');

  const handleMoveToHome = () => {
    trackEvent({
      event: 'click_recommend_similar_group_more_button',
      params: {
        groupId: groupId,
      },
      sample: true,
    });

    push('HomePage', {
      scrollTo: 'EXPLORER_GROUP_TAB',
      groupCategory: categoryId?.toString(),
    });
  };

  const hasSimilarGroup = useMemo(
    () => data?.data?.groups && data.data.groups.length > 2,
    [data?.data.groups]
  );

  if (!hasSimilarGroup) return <></>;

  return (
    <GroupExplorerListSection
      groups={data?.data.groups ?? []}
      title="이런 모임도 추천해요"
      footerButtonOnClick={handleMoveToHome}
      footerButtonText={
        <div className={s.ButtonWrapper}>
          <Typography typography="label3Bold">더보기</Typography>
          <IconChevronRightLine size={14} />
        </div>
      }
      isExperiment={treatmentUser === 'B'}
    />
  );
};

export default withAsyncBoundary(RecommendSimilarGroupSection, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <ViewError />,
});
