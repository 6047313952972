import { IconPerson2Line } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import { MeetupListCardItemInfo } from './components/MeetupListCardItemInfo';
import * as s from './index.css';

export const DEFAULT_PROFILE_IMG =
  'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png';

export type MeetupProgressStatus = {
  status: 'opened' | 'fully_recruited' | 'closed' | 'delected' | 'canceled';
  description: string;
};

export const MeetupListCard = ({
  progressStatus,
  headerLabel = [],
  requirementsDisplayText,
  meetupAtDisplayText,
  hostInfo,
  currentParticipantsCount,
  maximumParticipantsCount,
  title,
  ...props
}: MeetupListCardProps) => {
  return (
    <div {...props} className={clsx([props.className, s.container])}>
      <div className={s.wrapper}>
        <div className={s.header}>
          <span
            className={s.badge({
              status: progressStatus.status,
            })}
          >
            {progressStatus.description}
          </span>
          {headerLabel?.length > 0 &&
            headerLabel.map((label) => {
              return (
                <>
                  <p>∙</p>
                  <p>{label}</p>
                </>
              );
            })}
        </div>
        <div className={s.body}>
          {typeof title === 'string' ? <h4 className={s.title}>{title}</h4> : title}
          <div className={s.spacing} style={{ height: '8px' }} />
          <MeetupListCardItemInfo
            infoList={[
              {
                type: 'user_group',
                text: requirementsDisplayText ?? '',
              },
              {
                type: 'calendar',
                text: meetupAtDisplayText ?? '',
              },
            ]}
          />
        </div>
        <div className={s.footer}>
          <div className={s.memberList}>
            <img
              className={s.profileImage({
                shape: hostInfo.imageShape,
              })}
              src={hostInfo.image ?? DEFAULT_PROFILE_IMG}
            />
            <p className={s.profileText}>{hostInfo.displayText ?? 'HOST'}</p>
          </div>
          <div className={s.memberCount}>
            <IconPerson2Line color={vars.$scale.color.gray600} size={16} />
            <span className={s.memberCountText}>
              {currentParticipantsCount}/{maximumParticipantsCount}명
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export type MeetupListCardProps = {
  headerLabel: string[];
  title: string;
  progressStatus: MeetupProgressStatus;
  requirementsDisplayText: string;
  meetupAtDisplayText: string;
  currentParticipantsCount: number;
  maximumParticipantsCount: number;
  hostInfo: {
    imageShape: 'square' | 'circle';
    image: string;
    displayText: string;
  };
} & HTMLAttributes<HTMLDivElement>;
