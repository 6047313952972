import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { BoxButton, withAsyncBoundary } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { PropsWithChildren } from 'react';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { isHigherManager, isMember } from '@/utils/role';

import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '../../JoinGroupState/hooks/useHandleGroupOnly';

type Props = {
  group: GroupDetailPresentation;
  summaryType: 'notices' | 'albums' | 'members' | 'meetups' | 'feeds';
  currentUserRole: GroupMemberRoleEnum;
} & PropsWithChildren;

const GroupDetailSummaryMoreButton = ({
  group,
  summaryType,
  currentUserRole,
  children = '전체보기',
}: Props) => {
  const groupId = group.id.toString();

  const { push } = useFlow();
  const { handleGroupOnly } = useHandleGroupOnly({ groupId });

  const handleClick = () => {
    trackEvent({
      event: `click_see_more_${summaryType}`,
      params: {
        groupId,
        groupName: group.name,
        isChatRequired: !group.chatRoomSetting.isShowChatRoomSetting,
        isChatActivated: !group.chatRoomSetting.isDeactivate,
      },
      sample: true,
    });

    switch (summaryType) {
      case 'notices':
        push('GroupDetailNoticeMorePage', { groupId });
        break;
      case 'albums':
        push('GroupDetailAlbumPage', { groupId });
        break;
      case 'members':
        handleGroupOnly({
          isBlock: true,
          onSettled: () => {
            const pageName = isHigherManager(currentUserRole)
              ? 'GroupMemberListForHostPage'
              : 'GroupMemberListPage';
            push(pageName, { groupId });
          },
          blockGroupOnlyTitle: '모임 가입 안내',
          blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT['getReadAllMember'],
        });
        break;
      case 'meetups':
        push('GroupDetailMeetupListPage', { groupId });
        break;
      case 'feeds': {
        const isGroupMember = isMember(currentUserRole);
        if (isGroupMember) {
          push('GroupDetailPage', { groupId, boardCategoryKey: '0' });
          return;
        }

        push('GroupDetailFeedListPage', { groupId });
        break;
      }
    }
  };

  return (
    <BoxButton
      variant="secondary"
      size="medium"
      width="100%"
      onClick={handleClick}
      suffix={<IconChevronRightFill size={14} color={vars.$scale.color.gray900} />}
    >
      {children}
    </BoxButton>
  );
};

export default withAsyncBoundary(GroupDetailSummaryMoreButton, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
