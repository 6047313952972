import { Image } from '@community-group/api/lib/group/models';
import { CoveredLazyLoadImage, resizeImage, Spacing } from '@community-group/components';
import React, { useMemo } from 'react';

import { useFlow } from '@/stackflow';

import * as s from './style.css';

type Props = {
  images: Image[];
};

const ChallengeImages = ({ images }: Props) => {
  const { push } = useFlow();

  const handleClickImage = (index: number) => {
    push('ImageViewerPage', {
      content: 'images',
      initialIndex: index.toString(),
      imageUrls: JSON.stringify(images.map((image) => image.url)),
    });
  };

  const Images = useMemo(() => {
    return images.map((image, index) => {
      const isPortrait = image.width <= image.height;

      const ratio = isPortrait ? `(4 / 3)` : `(${image.height} / ${image.width})`;
      const width = 'calc(100vw - 32px)';
      const height = `calc((100vw - 32px) * ${ratio})`;

      return (
        <div
          key={image.id}
          className={s.Image}
          onClick={() => {
            handleClickImage(index);
          }}
        >
          <CoveredLazyLoadImage
            width={width}
            height={height}
            src={resizeImage(image.url, {
              quality: 'medium',
              type: 'inside',
              size: '1075x1075',
            })}
            insideStroke
            alt="?"
            style={{ borderRadius: '0.625rem' }}
          />
        </div>
      );
    });
  }, [images]);

  return (
    <>
      <Spacing size={16} />
      <div className={s.Wrapper}>{Images}</div>
    </>
  );
};

export default ChallengeImages;
