import { IconPictureLine } from '@daangn/react-monochrome-icon';
import { HelpBubbleAnchor } from '@daangn/sprout-components-help-bubble';
import { vars } from '@seed-design/design-token';
import React from 'react';

import { usePostFormPhotoHandler } from '../../hooks/usePostFormPhotoHandler';
import { GroupPostFormProps } from '../GroupPostForm';
import * as s from './index.css';

type Props = {
  formHandler: GroupPostFormProps['formHandler'];
  shownAddPhotoGuideHelpBubble: boolean;
  setShownAddPhotoGuideHelpBubble: React.Dispatch<React.SetStateAction<boolean>>;
};

function PhotoButton({
  formHandler,
  shownAddPhotoGuideHelpBubble,
  setShownAddPhotoGuideHelpBubble,
}: Props) {
  const { openPhotoSelector: handlePhotoClick } = usePostFormPhotoHandler({
    formHandler,
  });

  return (
    <button className={s.FeatureButton}>
      <IconPictureLine
        width={24}
        height={24}
        color={vars.$scale.color.gray600}
        onClick={handlePhotoClick}
      />
      {shownAddPhotoGuideHelpBubble && (
        <HelpBubbleAnchor
          title={`사진을 첨부해서 작성하면\n모임 상단에 사진을 노출할 수 있어요.`}
          isOpen={shownAddPhotoGuideHelpBubble}
          UNSAFE_style={{
            whiteSpace: 'pre-wrap',
            zIndex: 100,
          }}
          positioning={{
            placement: 'top-start',
          }}
          marginLeft={-14}
          closeOnInteractOutside={true}
          onOpenChange={() => {
            setShownAddPhotoGuideHelpBubble(false);
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '0',
              width: '1px',
            }}
          ></div>
        </HelpBubbleAnchor>
      )}
    </button>
  );
}

export default PhotoButton;
