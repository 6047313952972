import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: number;
  imageId: string;
};
type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePutSetHeroImage = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putHeroImage = groupClient.api.ImageApi.apiV1GroupsGroupIdPhotosPinImageIdPut({
    axios: fetchInstance,
  });

  const fetchPutHeroImage = ({ groupId, imageId }: Params) => {
    return putHeroImage(groupId, imageId);
  };

  return useThrottleMutation(fetchPutHeroImage, {
    onError,
    onSuccess,
  });
};
