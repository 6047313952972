import { Dialog, useDialog, useEffectOnce } from '@community-group/components';

import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

export const MeetupReviewWrittenHandling = () => {
  const handleBack = useBack();

  const { groupId, meetupId } = usePathParams();
  const { data } = useReadGroupMeetupDetail({
    groupId,
    meetupId,
  });

  const { open: openDialog, close: closeDialog } = useDialog();

  const handleWrittenReviewCheck = async () => {
    refetchGroupDetail({ groupId });
    await closeDialog();

    handleBack();
  };

  useEffectOnce(() => {
    if (data?.currentUserInfo.reviewWritten) {
      openDialog({
        element: (
          <Dialog
            description="이미 후기 작성을 완료했어요."
            primaryActionLabel="확인"
            onPrimaryAction={handleWrittenReviewCheck}
          />
        ),
      });
    }
  });

  return <></>;
};
