import { Typography } from '@community-group/components';

import { TypeActivityNames } from '@/stackflow';
import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';

type RUNNING_GROWTH_FLOW_TYPE = {
  step: number;
  title: React.ReactNode | string;
  description: string;
  pageName: TypeActivityNames;
  params?: {
    calloutType: ActivityQueryParams['calloutType'];
  };
  missionOpenDate: string;
};

export const RUNNING_GROWTH_FLOW: RUNNING_GROWTH_FLOW_TYPE[] = [
  {
    step: 1,
    title: '모임 소개하기',
    description: '모임을 만들고 소개를 작성해보세요.',
    pageName: 'GroupSettingEditGroupDescription',
    params: {
      calloutType: 'running-growth-mission1-set-group-description',
    },
    missionOpenDate: '2024-10-28T00:00:00+09:00', //한국 시간
  },
  {
    step: 2,
    title: '모임 규칙 정하기',
    description: '규칙을 설명하는 글을 작성해보세요.',
    pageName: 'GroupPostNewPage',
    params: {
      calloutType: 'running-growth-mission2-set-notice',
    },
    missionOpenDate: '2024-10-30T00:00:00+09:00', //한국 시간
  },
  {
    step: 3,
    title: '러닝코스 정하기',
    description: '함께 달릴 코스를 글로 작성해보세요.',
    pageName: 'GroupPostNewPage',
    params: {
      calloutType: 'running-growth-mission3-set-running-course',
    },
    missionOpenDate: '2024-11-03T00:00:00+09:00', //한국 시간
  },
  {
    step: 4,
    title: '첫 일정 후기 남기기',
    description: '일정을 진행하고 후기를 업로드해보세요.',
    pageName: 'GroupMeetupNewPage',
    params: {
      calloutType: 'running-growth-mission4-set-first-meetup',
    },
    missionOpenDate: '2024-11-09T00:00:00+09:00', //한국 시간
  },
  {
    step: 5,
    title: '일정 후기 3개 더 남기기',
    description: '일정을 3개 더 진행하고 후기를 업로드해보세요.',
    pageName: 'GroupMeetupNewPage',
    params: {
      calloutType: 'running-growth-mission5-set-3-meetup',
    },
    missionOpenDate: '2024-11-18T00:00:00+09:00', //한국 시간
  },
];
