import { groupClient } from '@community-group/api';
import type { EventLogLocationRequest } from '@community-group/api/lib/group/models';
import { useActivity } from '@stackflow/react';
import { useCallback } from 'react';

import { useBridge } from '@/contexts/Bridge';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

export interface LocationEventLogRequest extends Partial<EventLogLocationRequest> {
  eventName:
    | 'create_group_detail'
    | 'join_group_detail'
    | 'create_group_meetup'
    | 'join_group_meetup'
    | 'create_group_post'
    | 'create_group_post_comment'
    | 'enter_group_detail'
    | 'enter_group_meetup_detail'
    | 'enter_group_home';
}

export const usePutEventLogLocation = () => {
  const { name } = useActivity();
  const fetchInstance = useFetchInstance();
  const putEventLogLocation = groupClient.api.EventLogApi.apiV1EventLogLocationPut({
    axios: fetchInstance,
  });
  const { bridge } = useBridge();

  const { mutate, ...rest } = useThrottleMutation(putEventLogLocation, {});

  const handleMutate = useCallback(
    async (data: LocationEventLogRequest) => {
      try {
        const { geolocation } = (await bridge.getCurrentPosition?.({})) ?? {};
        if (!geolocation) return null;
        const { geolocationAccessResponse, currentPosition } = geolocation;

        if (!geolocationAccessResponse?.granted) return;
        if (!currentPosition?.position) return;

        await mutate({
          currentPosition: currentPosition.position,
          pageName: name,
          ...data,
        });
      } catch {
        console.log('error');
      }
    },
    [name, mutate]
  );

  return {
    ...rest,
    mutate: handleMutate,
  };
};
