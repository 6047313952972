import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ReactNode } from 'react';
import { $Values } from 'utility-types';

import { useDeviceConfig } from '@/contexts/DeviceConfig';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';

import * as s from './Header.css';
interface Props {
  title?: string;
  right?: ReactNode | string;
  isLoading?: boolean;
  onRightClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  type: $Values<typeof MODAL_KEY>;
  onCancel?: () => void;
}

const ModalHeader = ({ title, right, onRightClick, type, onCancel }: Props) => {
  const { setOpenWebviewModal } = useStore();
  const { deviceConfig } = useDeviceConfig();
  const isAndroid = deviceConfig.theme === 'android';

  return (
    <div className={s.headerContainer}>
      <div
        style={{ display: 'flex', position: 'relative', height: isAndroid ? '3.5rem' : '2.75rem' }}
      >
        <div className={s.headerInner}>
          <div className={s.left}>
            <button
              onClick={() => {
                onCancel && onCancel();
                setOpenWebviewModal(type, false);
              }}
            >
              <IconXmarkLine width={24} height={24} />
            </button>
          </div>
          <div className={s.center}>
            <div className={s.title({ isAndroid })}>{title}</div>
          </div>
          {right && (
            <div className={s.right} onClick={onRightClick}>
              <div
                style={{
                  color: vars.$scale.color.carrot500,
                  fontSize: '1rem',
                  lineHeight: '1.375rem',
                  fontWeight: isAndroid ? 400 : 700,
                }}
              >
                {right}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
