import { AgeRangeType, RegionRangeType, SearchRegionRangeType } from '../model';

export const convertRegionRange = (regionRange: SearchRegionRangeType): RegionRangeType => {
  switch (regionRange) {
    case 'MY':
      return 'my';
    case 'RANGE_2':
      return 'range2';
    case 'RANGE_3':
      return 'range3';
    case 'UNLIMITED':
      return 'unlimited';
    default:
      return undefined;
  }
};

export const getRegionRangeName = (regionRange: RegionRangeType) => {
  switch (regionRange) {
    case 'adjacent':
    case 'my':
      return '가까운 동네';
    case 'range2':
      return '조금 먼 동네';
    case 'range3':
      return '먼 동네';
    case 'unlimited':
    default:
      return '전국';
  }
};

export const getRegionRangeValue = (regionRange: RegionRangeType) => {
  const regionRangeName = getRegionRangeName(regionRange);
  return regionRangeName === '전국' ? undefined : regionRangeName;
};

export const getAgeRangeText = (
  ageRange: AgeRangeType
): `${Exclude<AgeRangeType, undefined>}대` | undefined => {
  switch (ageRange) {
    case '20':
      return '20대';
    case '30':
      return '30대';
    case '40':
      return '40대';
    case '50':
      return '50대';
    case '60':
      return '60대';
    default:
      return undefined;
  }
};
