import { axiosClient } from '@community-group/api';

import { useDeviceConfig } from '@/contexts/DeviceConfig';
import { useUserConfig } from '@/contexts/UserConfig';

import { getKarrotHeader } from './getKarrotHeader';

type Props = {
  baseURL?: string;
};

export const useFetchInstance = ({
  baseURL = globalThis.appConfig.apiEndPoints.community,
}: Props) => {
  // TODO: AppFoundation 에서 가져오도록 변경
  const { deviceConfig } = useDeviceConfig();
  const { userConfig } = useUserConfig();

  const app = {
    // userAgent: deviceConfig.userAgent,
    xUserAgent: deviceConfig.xUserAgent,
  };
  const user = {
    id: userConfig.userId ?? 0,
    authToken: userConfig.userAuthToken ?? '',
  };
  const region = {
    id: userConfig.regionId ?? 0,
  };

  const fetchInstance = axiosClient.initFetchInstance(
    { baseURL },
    getKarrotHeader({ app, user, region })
  );

  return fetchInstance;
};
