import { AsyncBoundary, BoxButton, ViewError, ViewLoader } from '@community-group/components';
import {
  IconPlusLine,
  IconQuestionmarkCircleFill,
  IconQuestionmarkCircleLine,
} from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import IconWrapper from '@/components/common/Icons';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';

import ChallengeList from '../components/ChallengeList';
import ChallengeSurveyCallout from '../components/ChallengeSurveyCallout';
import EmptyChallengeList from '../components/EmptyChallengeList';
import useChallengeListData from '../hooks/useChallengeListData';
import * as s from './styles.css';

const ChallengeListPage: ActivityComponentType = () => {
  const { groupId } = usePathParams();

  const { push } = useFlow();

  const { group } = useReadGroupDetail(groupId);
  useEnterTrackEvent({
    event: 'enter_challenge_list_page',
    params: {
      groupId,
      groupName: group?.name,
      categoryId: group?.category.id,
    },
  });

  const handleCreateChallenge = () => {
    trackEvent({
      event: 'click_challenge_list_create_button',
      params: {
        groupId,
        groupName: group?.name,
        categoryId: group?.category.id,
      },
    });

    push('GroupChallengeNewTemplatePage', {
      groupId: groupId.toString(),
      from: 'shortcut',
    });
  };

  const handleOpenSurvey = () => {
    const surveyLink = 'https://www.daangn.com/wv/faqs/16948';
    openLink(surveyLink, false);
  };

  return (
    <AppScreen
      appBar={{
        title: '모임 챌린지',
        renderRight: () => {
          return (
            <IconWrapper>
              <IconQuestionmarkCircleLine size={24} onClick={handleOpenSurvey} />
            </IconWrapper>
          );
        },
      }}
      onPull={() => {
        refetchGroupChallenge({ groupId });
      }}
      disabledClientOnly
    >
      <div className={s.Container}>
        <BoxButton
          variant="secondary"
          prefix={<IconPlusLine size={16} />}
          onClick={handleCreateChallenge}
        >
          챌린지 만들기
        </BoxButton>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <ChallengeListCore groupId={Number(groupId)} />
        </AsyncBoundary>
      </div>
    </AppScreen>
  );
};

const ChallengeListCore = ({ groupId }: { groupId: number }) => {
  const { opened, prepared, closed, hasChallengeData } = useChallengeListData({
    groupId: Number(groupId),
  });

  return (
    <>
      {!hasChallengeData && <EmptyChallengeList />}
      {hasChallengeData && (
        <>
          <ChallengeList groupId={Number(groupId)} title="진행 중인 챌린지" {...opened} />
          <ChallengeSurveyCallout />
          <ChallengeList groupId={Number(groupId)} title="진행 예정 챌린지" {...prepared} />
          <ChallengeList groupId={Number(groupId)} title="종료된 챌린지" {...closed} />
        </>
      )}
    </>
  );
};

export default ChallengeListPage;
