import {
  ActionableCallout,
  Divider,
  Switch,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconQuestionmarkCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import FormGuideCallout from '@/components/common/FormGuideCallout';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import * as s from './GroupSettingMemberGradePage.css';

type Params = Pick<PageParams, 'groupId'>;

const GroupSettingMemberGradePage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { push } = useFlow();

  const [memberGradeActivated, setMemberGradeActivated] = useState(false);

  return (
    <AppScreen
      appBar={{
        title: '멤버 등급 관리',
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
        renderRight: () => (
          <IconQuestionmarkCircleLine size={24} color={vars.$scale.color.gray900} />
        ),
      }}
    >
      <SettingList.Wrapper>
        <SettingList.Item
          title="멤버 등급 사용"
          renderRight={
            <Switch
              onChange={(isSelected) => setMemberGradeActivated(isSelected)}
              isSelected={memberGradeActivated}
            />
          }
        />
        <SettingList.Item
          title="멤버 권한 관리"
          onClick={() => {
            push('GroupSettingPermissionPage', {
              groupId,
            });
          }}
        />
      </SettingList.Wrapper>
      <Divider />
      <div className={s.CalloutWrapper}>
        <FormGuideCallout calloutType="member-grade-setting" />
      </div>
      <div className={s.CalloutWrapper}>
        <ActionableCallout variant="normal" width="100%">
          <Typography typography="bodyM2Regular" color="gray900">
            <b>설문</b> 멤버 등급에 대한 의견을 들려주세요.
          </Typography>
        </ActionableCallout>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingMemberGradePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
