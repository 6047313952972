import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetPhotoContestEntries } from '@/api/hooks/useGetPhotoContestEntries';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

type Props = {
  groupId: string;
};

// 사진전 featureFlag가 켜져있고, 사진전이 진행중이며, 사진전 버전이 3이고, 운동 카테고리이고 경우에만 노출
function useShowPhotoContestInOngoingExerciseGroup({ groupId }: Props) {
  const contestVersion = useFeatureFlag('photoContestVersion');
  const shownPhotoContest = useFeatureFlag('shownPhotoContest');

  const { group } = useReadGroupDetail(groupId);
  const { data } = useGetPhotoContestEntries({});

  const isPhotoContestV3 = contestVersion === 3;
  const isOnGoing = data?.pages[0]?.data?.isCurrentContestOngoing;
  const isExerciseGroup = group?.category.id === 1;

  if (!shownPhotoContest) return false;
  if (!isOnGoing) return false;

  if (isPhotoContestV3) {
    // 사진전 3회차인 경우
    if (isExerciseGroup) return true;
    return false;
  }

  return true;
}

export default useShowPhotoContestInOngoingExerciseGroup;
