import { isNotMember, Typography } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { useMemo } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { useJoinGroupState } from '@/components/group/JoinGroupState/hooks/useJoinGroupState';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useJoinGroupMeetup } from '@/hooks/useJoinGroupMeetup';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import { GroupMeetupDetailAccessoryBarWrapper } from './shared/GroupMeetupDetailAccessoryBarWrapper';

export const NotGroupMemberJoinButton = () => {
  const { groupId, meetupId } = usePathParams();
  const { data } = useReadGroupMeetupDetail({ groupId, meetupId });
  const { group } = useReadGroupDetail(groupId);
  const currentUser = useCurrentGroupMe();
  const { groupInfo } = useJoinGroupState({ groupId });
  const { push } = useFlow();

  const { handleClick: handleJoinGroupClick } = useJoinGroupMeetup({
    groupId,
    meetupId,
    regionId: currentUser.regionInfo.userRegion.id,
    regionName: currentUser.regionInfo.userRegion.name,
    onSuccess: () => {
      if (groupInfo.isSettingSubNickname) {
        push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
          groupId,
        });
      }

      const eventParams = {
        meetupId,
        groupId: data?.groupInfo.id,
        meetupName: data?.title,
        groupName: data?.groupInfo.name,
        groupCategory: group?.category.name,
        isGroupMember: data?.currentUserInfo.groupJoined,
        isGroupMeetupMember: data?.currentUserInfo.meetupJoined,
        isLikedGroupMeetup: data?.currentUserInfo.like,
      };
      trackEvent({ event: 'click_group_meetup_join_button', params: eventParams });
    },
  });

  const isApplied = isNotMember(currentUser.role) && currentUser.state === 'applied';

  const buttonStatus = useMemo(() => {
    const status = data.progressStatus.status;

    if (status === 'fullyRecruited')
      return {
        disabled: true,
        label: '모집 마감',
      };

    if (isApplied) {
      return {
        label: '참여 취소',
        disabled: false,
      };
    }

    return {
      label: '참여하기',
      disabled: false,
    };
  }, [currentUser.role, currentUser.state, data.progressStatus.status]);

  const handleClick = () => {
    handleJoinGroupClick();
  };

  const renderButton = useMemo(() => {
    if (isApplied) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography typography="label2Bold" color="gray900">
            승인 대기 중
          </Typography>
          <BoxButton
            isDisabled={buttonStatus.disabled}
            onClick={handleClick}
            size="xlarge"
            variant="secondary"
            width={'50%'}
          >
            {buttonStatus.label}
          </BoxButton>
        </div>
      );
    }

    return (
      <BoxButton
        width={'100%'}
        isDisabled={buttonStatus.disabled}
        onClick={handleClick}
        size="xlarge"
      >
        {buttonStatus.label}
      </BoxButton>
    );
  }, [buttonStatus.disabled, buttonStatus.label, isApplied]);

  if (data.progressStatus.status === 'closed') return <></>;

  return (
    <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
      {renderButton}
    </GroupMeetupDetailAccessoryBarWrapper>
  );
};
