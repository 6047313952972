import {
  AsyncBoundary,
  BoxButton,
  TextButton,
  Typography,
  ViewLoader,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useUserConfig } from '@/contexts/UserConfig';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import { getLevel3DBadgeImage } from '../../GroupLevel/Components/CurrentLevelAndMission/CurrentLevelBadge';
import * as s from './NoticeGroupLevelBottomSheet.css';

export type NoticeGroupLevelBottomSheetParams = Pick<PageParams, 'groupId'>;

const NoticeGroupLevelBottomSheet: ActivityComponentType<
  NoticeGroupLevelBottomSheetParams
> = () => {
  const { pop } = useFlow();
  const { groupId } = usePathParams();
  const { data: levelData } = useGetGroupLevel({ groupId: Number(groupId) });
  const { group } = useReadGroupDetail(groupId);

  const {
    userConfig: { userId },
  } = useUserConfig();

  useEnterTrackEvent({
    event: 'enter_notice_group_level_bottom_sheet',
    params: {
      currentLevel: levelData?.currentLevel,
      currentProgressPercentage: levelData?.forLevelUp.percentage,
      groupId,
      userId: (userId ?? 0).toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
    },
  });

  const [
    closeSuperHostNoticeGroupLevelBottomSheetList,
    setCloseSuperHostNoticeGroupLevelBottomSheetList,
  ] = useStorage('closeSuperHostNoticeGroupLevelBottomSheetList', []);

  const updateStorageData = () => {
    setCloseSuperHostNoticeGroupLevelBottomSheetList([
      ...(closeSuperHostNoticeGroupLevelBottomSheetList ?? []),
      groupId.toString(),
    ]);
  };

  const handleCloseBottomSheet = () => {
    updateStorageData();
    pop();
  };

  return (
    <BottomSheet onOutsideClick={updateStorageData}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <NoticePublishGroupBottomSheetWrapper onClose={handleCloseBottomSheet} />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const NoticePublishGroupBottomSheetWrapper = ({
  onClose: handleCloseBottomSheet,
}: {
  onClose: () => void;
}) => {
  const { groupId } = usePathParams();
  const { group } = useReadGroupDetail(groupId);
  const { push } = useFlow();
  const {
    userConfig: { userId },
  } = useUserConfig();

  const handleMoveToCheckGroupLevel = () => {
    trackEvent({
      event: 'click_go_to_group_level',
      params: {
        groupId,
        userId: (userId ?? 0).toString(),
        from: 'NoticeGroupLevelBottomSheet',
        categoryId: group?.category.id,
        categoryName: group?.category.name,
      },
    });
    handleCloseBottomSheet();
    setTimeout(() => {
      push('GroupLevelDetailPage', {
        groupId,
      });
    }, 300);
  };

  const MAX_GROUP_LEVEL_IMG = getLevel3DBadgeImage(-1, 'front');

  return (
    <div className={s.Wrapper}>
      <Typography textAlign="center" as="h3" color="gray900" typography="title2Bold">
        우리 모임은 몇 레벨일까요?
      </Typography>
      <Spacing size={6} />
      <Typography textAlign="center" color="gray700" typography="subtitle1Regular" as="p">
        레벨별 혜택을 확인하고
        <br />더 높은 레벨에 도전해보세요!
      </Typography>
      <Spacing size={16} />
      <img style={{ width: '11.875rem', height: '10.1875rem' }} src={MAX_GROUP_LEVEL_IMG} />
      <Spacing size={12} />
      <BoxButton size="xlarge" width="100%" onClick={handleMoveToCheckGroupLevel}>
        모임 레벨 확인하기
      </BoxButton>
      <Spacing size={14} />
      <TextButton
        variant="secondary"
        width="100%"
        size="small"
        bold={true}
        onClick={handleCloseBottomSheet}
      >
        다음에 할게요
      </TextButton>
    </div>
  );
};

export default NoticeGroupLevelBottomSheet;
