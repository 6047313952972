import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getChallengeNotificationSettingPath = (id: number, challengeId: number) =>
  groupClient.api.GroupChallengeMemberApi.getapiV1GroupsGroupIdChallengesChallengeIdNotificationSettingsMeGetPath(
    id,
    challengeId
  );

export const useGetChallengeNotificationSetting = (id: number, challengeId: number) => {
  const fetchInstance = useFetchInstance();
  const get =
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdNotificationSettingsMeGet(
      {
        axios: fetchInstance,
      }
    );

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getChallengeNotificationSettingPath(id, challengeId)],
    queryFn: () => {
      return get(id, challengeId);
    },
  });
  const response = data?.data;

  return { data: response, refetch };
};
