import { categoriesHandlers } from '../msw/categories.msw';
import { feedHandlers } from '../msw/feed.msw';
import { groupHomeHandlers } from '../msw/groupHome.msw';
import { leaderBoardHandlers } from '../msw/leaderboard.msw';
import { postDetailHandlers } from '../msw/postDetail.msw';

export const workerHandler = [
  ...categoriesHandlers,
  ...groupHomeHandlers,
  ...feedHandlers,
  ...postDetailHandlers,
  ...leaderBoardHandlers,
];
