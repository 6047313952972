import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { queryClient } from '@/shared/api/instance';

import { getMyGroupListPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';
import { getGroupMePath } from './useGetGroupMe';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type Props = UseMutationOptions<
  AxiosResponse<model.GroupMemberJoinResponse>,
  Error,
  { id: number; joinForm?: model.GroupJoinForm }
> & {
  groupId: string;
};

export const usePostJoinGroup = ({ onError, onSuccess, groupId, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();

  const postGroupJoin = groupClient.api.GroupApi.apiV1GroupsIdJoinPost({ axios: fetchInstance });

  const postGroupJoinWrapperFp = ({
    id,
    joinForm,
  }: {
    id: number;
    joinForm: model.GroupJoinForm;
  }) => {
    return postGroupJoin(id, joinForm);
  };

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  const { mutate, ...rest } = useThrottleMutation(postGroupJoinWrapperFp, {
    onError,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
      queryClient.invalidateQueries({ queryKey: [getGroupMePath(groupId)] });

      onSuccess?.(data, variables, context);

      mutateEventLogLocation({
        eventName: 'join_group_detail',
        groupId: variables.id,
      });
    },
    onMutate,
    onSettled,
  });
  return { mutate, ...rest };
};
