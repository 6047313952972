import { ParentingGroupKidForm } from '@community-group/api/lib/group/models';
import { TextButton } from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { MouseEvent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { usePutParentingGroupKidProfile } from '@/api/hooks/usePutParentingGroupKidProfile';
import { useConnectedFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { formatDateStringToKebabCase } from '@/utils/date';
import { groupMemberProfileFieldSchema } from '@/utils/validate/formSchema/subNickname';

import ParentingGroupKidProfileForm from '../components/ParentingGroupKidProfileForm';

type Params = Pick<PageParams, 'groupId' | 'kidId' | 'title' | 'kidBirthday' | 'kidGender'>;

const ParentingGroupKidProfileEditPage: ActivityComponentType<Params> = () => {
  const { groupId, kidId } = usePathParams();
  const { title, kidBirthday, kidGender } = useQueryParams();

  const methods = useForm<ParentingGroupKidForm>({
    defaultValues: {
      birthday: kidBirthday,
      gender: kidGender,
    },
  });
  const { watch, handleSubmit } = methods;

  const { pop } = useConnectedFlow();
  const { mutateAsync: putKidProfile, isPending } = usePutParentingGroupKidProfile({
    onSuccess: () => pop().send('submit'),
  });

  const handleBack = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    pop().send('back');
  };
  const handleComplete = handleSubmit(async (form) => {
    const formattedForm = {
      ...form,
      birthday: formatDateStringToKebabCase(form.birthday.trim()),
    };

    await putKidProfile({
      groupId: Number(groupId),
      kidId,
      form: formattedForm,
    });
  });

  const formValue = watch();
  const isDisableButton = useMemo(() => {
    const kidResult = groupMemberProfileFieldSchema.kidProfile.safeParse(formValue);
    return !kidResult.success;
  }, [formValue]);

  return (
    <AppScreen
      appBar={{
        title: title,
        backButton: {
          renderIcon: () => <IconChevronLeftLine size={24} />,
          onClick: handleBack,
        },
        closeButton: {
          renderIcon: () => <IconChevronLeftLine size={24} />,
          onClick: handleBack,
        },
        renderRight: () => (
          <TextButton
            variant="secondary"
            UNSAFE_style={{ padding: '0.5rem' }}
            isDisabled={isDisableButton || isPending}
            onClick={handleComplete}
          >
            완료
          </TextButton>
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <FormProvider {...methods}>
        <ParentingGroupKidProfileForm />
      </FormProvider>
    </AppScreen>
  );
};

export default ParentingGroupKidProfileEditPage;
