import { ActionableCallout, Spacing, Typography } from '@community-group/components';

import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

const GroupLevelLeaderBoardRank = () => {
  const { groupId } = usePathParams();

  const { data: leaderBoardData } = useGetGroupLeaderBoard({ weekAgo: 0 });
  const hasOver5RankGroups = (leaderBoardData?.rankList?.length ?? 0) > 5;

  const { push } = useFlow();

  const onClickLeaderBoard = () => {
    trackEvent({
      event: 'click_current_rank_banner_level_page',
      params: {
        groupId,
      },
    });
    push('GroupLeaderBoard', {});
  };

  if (!hasOver5RankGroups) return null;

  return (
    <>
      <ActionableCallout onClick={onClickLeaderBoard}>
        <Typography typography="bodyM2Regular" color="gray900">
          이번 주 <b>실시간 모임 랭킹</b>을 확인해보세요!
        </Typography>
      </ActionableCallout>
      <Spacing size={20} />
    </>
  );
};

export default GroupLevelLeaderBoardRank;
