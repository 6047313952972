import { Dialog, useDialog, useEffectOnce } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType, useActivity } from '@stackflow/react';
import { isEmpty, isNil } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';

import IconWrapper from '@/components/common/Icons';
import { useBridge } from '@/contexts/Bridge';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import PollForm, { PollFormValues } from '../PollForm';
import * as s from './groupPollVotedMembersPage.css';

const MINIMUM_POLL_OPTION_COUNT = 2;
const MINIMUM_POLL_TITLE_LENGTH = 2;
const MAXIMUM_POLL_TITLE_LENGTH = 24;
const DEFAULT_POLL_OPTIONS = ['', '', ''];

export type PollFormActivityParams = {
  pollOptionValueJSONString: string;
};

export type PollFormActivityParamsJSON = {
  title: string;
  pollOptionTitles: string[];
  isMultiple: boolean;
};

const GroupPollFormPage: ActivityComponentType<PollFormActivityParams> = () => {
  const { groupId } = usePathParams();
  const { pollOptionValueJSONString, from } = useQueryParams();

  const params = pollOptionValueJSONString
    ? (JSON.parse(pollOptionValueJSONString) as PollFormActivityParamsJSON)
    : {
        title: '',
        pollOptionTitles: [],
        isMultiple: false,
      };

  const pollOptionTitles = params.pollOptionTitles.filter(
    (pollOption) => !isNil(pollOption)
  ) as string[];

  const formMethods = useForm<PollFormValues>({
    defaultValues: {
      pollOptionTitles: pollOptionTitles.length === 0 ? DEFAULT_POLL_OPTIONS : pollOptionTitles,
      isMultiple: params.isMultiple,
      title: params.title,
    },
  });

  const { pop } = useConnectedActions();
  const { replace } = useFlow();
  const { isRoot } = useActivity();
  const { open: openDialog } = useDialog();

  const changedPollOptions = formMethods.watch('pollOptionTitles');
  const pollTitle = formMethods.watch('title');
  const isDisable = changedPollOptions.every((pollOption) => isEmpty(pollOption)) || !pollTitle;

  const { bridge } = useBridge();
  useEffectOnce(() => {
    if (from === 'individual_chat') {
      openDialog({
        element: (
          <Dialog
            description="개별 채팅방에서는 투표를 사용하실 수 없어요."
            primaryActionLabel="닫기"
            onPrimaryAction={async () => {
              bridge.closeRouter({});
            }}
          />
        ),
      });
    }
  });

  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => (
            <IconWrapper>
              <IconXmarkLine onClick={() => pop()} />
            </IconWrapper>
          ),
        },
        title: '투표 만들기',
        renderRight: () => (
          <button
            className={s.SubmitButton({
              isDisabled: isDisable,
            })}
            type="submit"
            disabled={isDisable}
            form="poll-form"
          >
            완료
          </button>
        ),
      }}
    >
      <FormProvider {...formMethods}>
        <div style={{ padding: '0 1rem 1rem 1rem' }}>
          <PollForm
            onSubmit={(formValues) => {
              const validPollOptionTitles = formValues.pollOptionTitles.filter(
                (pollOptionTitle) => !isEmpty(pollOptionTitle)
              );

              if (
                formValues.title.length < MINIMUM_POLL_TITLE_LENGTH ||
                formValues.title.length > MAXIMUM_POLL_TITLE_LENGTH
              ) {
                console.log('invalid title length');
                bridge.openToast({
                  toast: {
                    body: '투표 제목은 2자에서 24자 사이로 입력해주세요.',
                  },
                });
                return;
              }

              if (validPollOptionTitles.length < MINIMUM_POLL_OPTION_COUNT) {
                console.log('das title length');

                bridge.openToast({
                  toast: {
                    body: `투표 항목을 ${MINIMUM_POLL_OPTION_COUNT}개 이상 입력해주세요`,
                  },
                });
                return;
              }

              if (isRoot) {
                replace('GroupPostNewPage', {
                  groupId,
                  pollOptionValueJSONString: JSON.stringify({
                    pollOptionTitles: validPollOptionTitles,
                    isMultiple: formValues.isMultiple,
                    title: formValues.title,
                  } as PollFormActivityParamsJSON),
                  from,
                });
                return;
              }

              pop().send({
                pollOptionTitles: validPollOptionTitles,
                isMultiple: formValues.isMultiple,
                title: formValues.title,
              } as PollFormActivityParamsJSON);
            }}
          />
        </div>
      </FormProvider>
    </AppScreen>
  );
};

export default GroupPollFormPage;
