import { useMemo } from 'react';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetParentingGroupKids } from '@/api/hooks/useGetParentingGroupKids';
import { isMember } from '@/utils/role';

import { useGetParentingGroupStorage } from './useGetParentingGroupStorage';

const useParentingGroupKidProfile = (groupId: string) => {
  const { data } = useGetGroupMe(groupId);
  const { isKidProfileEnabled } = useGetParentingGroupStorage(groupId);

  const shouldUseParentingGroupKidProfile = useMemo(
    () => isMember(data?.currentUser.role) && isKidProfileEnabled,
    [data?.currentUser.role, isKidProfileEnabled]
  );

  const { data: parentingGroup } = useGetParentingGroupKids(
    groupId,
    !shouldUseParentingGroupKidProfile // disabled
  );

  return useMemo(() => {
    const isKidsEmpty = (parentingGroup?.kids ?? []).length === 0;

    return shouldUseParentingGroupKidProfile && isKidsEmpty;
  }, [shouldUseParentingGroupKidProfile, parentingGroup?.kids]);
};

export default useParentingGroupKidProfile;
