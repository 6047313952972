import {
  ChallengeStatusEnum,
  GroupMeetupProgressStatusPresentationStatus,
} from '@community-group/api/lib/group/models';
import { vars } from '@seed-design/design-token';

export const getMeetupStatusTitleColor = (
  status: GroupMeetupProgressStatusPresentationStatus | ChallengeStatusEnum
) => {
  switch (status) {
    case 'opened':
      return vars.$semantic.color.primary;
    case 'fullyRecruited':
      return vars.$scale.color.yellow400;
    case 'closed':
    case 'deleted':
    case 'canceled':
    case 'blocked':
    default:
      return vars.$scale.color.gray600;
  }
};
