import { useBottomSheet } from '@community-group/components';

import { usePostJoinRoom } from '@/api/hooks/apartment/usePostJoinRoom';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { openLink } from '@/utils/link';
import { refetchGroupRooms } from '@/utils/refetch/rooms';

const useEnterPasswordRoomWithQR = (groupId: string, roomId?: string | number) => {
  const { closeAsync } = useBottomSheet();

  const { push, replace } = useFlow();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { bridge } = useBridge();
  const { mutate: mutateJoinRoom, isPending } = usePostJoinRoom({
    onError: async (error) => {
      await handleErrorWithToast(error);
      setTimeout(async () => {
        replace(
          'GroupDetailPage',
          {
            groupId: groupId,
          },
          { animate: false }
        );
      }, 100);
    },
    onSuccess: async ({ data }) => {
      refetchGroupRooms({ groupId: groupId ?? '' });
      bridge.openToast({
        toast: {
          body: '채팅방에 입장했어요.',
        },
      });
      await closeAsync();

      setTimeout(() => {
        openLink(data.talkRoom.channelInfo?.targetUri);
      }, 200);
    },
  });

  return {
    openForm: async () => {
      push('BottomSheet/GroupChatRoomPrivateBottomSheet', {
        groupId,
        roomId: roomId?.toString() ?? '',
      });
    },
    joinDirectly: () => {
      if (isPending) {
        return;
      }

      mutateJoinRoom({
        id: Number(groupId),
        roomId: Number(roomId),
        talkRoomJoinForm: {},
      });
    },
  };
};

export default useEnterPasswordRoomWithQR;
