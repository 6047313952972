import { GroupPhotoPresentation } from '@community-group/api/lib/group/models';
import { Typography, videoDurationText } from '@community-group/components';
import { IconPushpinFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { memo, MouseEventHandler, PropsWithChildren, useCallback, useMemo } from 'react';

import { isVideo } from '@/components/common/base/slider/ImageSlider/utils/medias';

import * as s from './AlbumSummarySection.css';

type Props = PropsWithChildren<{
  totalCount: number;
  photos: GroupPhotoPresentation[];
  onPhotoClick: (postId: number, imageIndex: number) => MouseEventHandler;
}>;

const AlbumSummarySection = ({ totalCount, photos, onPhotoClick, children }: Props) => {
  const hasMore = useMemo(() => totalCount > 9, [totalCount]);

  const renderDecoratePhoto = useCallback((data: GroupPhotoPresentation) => {
    const { isPinned, media } = data;

    const isVideoMedia = isVideo(media);

    if (isVideoMedia) {
      const durationText = videoDurationText(media.duration);
      return (
        <div className={s.VideoDurationWrapper}>
          <Typography typography="label6Regular" color="staticWhite">
            {durationText}
          </Typography>
        </div>
      );
    }

    if (isPinned) {
      return (
        <>
          <div className={s.PinnedGradation} />
          <div className={s.PinnedIcon}>
            <IconPushpinFill size={24} color={vars.$static.color.staticWhite} />
          </div>
        </>
      );
    }

    return null;
  }, []);

  return (
    <section className={s.Container}>
      <Typography typography="title3Bold">앨범</Typography>
      <ul className={s.PhotoList}>
        {photos.slice(0, 9).map((photo, index) => (
          <li
            className={s.PhotoListItem}
            key={photo.id}
            onClick={onPhotoClick(photo.post.id, index)}
          >
            <div className={s.Photo} style={{ backgroundImage: `url(${photo.image.small})` }}>
              {renderDecoratePhoto(photo)}
              <img className="real-image" src={photo.image.small} />
            </div>
          </li>
        ))}
      </ul>
      {hasMore && children}
    </section>
  );
};

export default memo(AlbumSummarySection);
