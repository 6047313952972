import { ChallengeSummaryPresentation } from '@community-group/api/lib/group/models';
import { ColorValue, Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { IconPerson2Fill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { getCertificationIntervalText } from '@/components/group/Challenge/utils/certification';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { getChallengeDateInfo } from '../../../utils/date';
import * as s from './style.css';

type Props = {
  groupId: number;
  challenge: ChallengeSummaryPresentation;
};

type DateInfo = {
  text: string;
  color: ColorValue;
};

const ChallengeListItem = ({ groupId, challenge }: Props) => {
  const { push } = useFlow();

  const { group } = useReadGroupDetail(groupId.toString());
  const handleClickItem = () => {
    trackEvent({
      event: 'click_challenge_list_item',
      params: {
        challengeId: challenge.id,
        challengeName: challenge.name,
        groupId,
        groupName: group?.name,
        categoryId: group?.category.id,
      },
    });
    push('GroupChallengeDetailPage', {
      groupId: groupId.toString(),
      challengeId: challenge.id.toString(),
    });
  };

  const dateInfo: DateInfo = useMemo(() => {
    const { text, color } = getChallengeDateInfo({
      startedAt: challenge.startedAt,
      endedAt: challenge.endedAt,
      status: challenge.status,
      totalDays: challenge.totalDays,
    });

    return { text, color };
  }, [challenge.endedAt, challenge.startedAt, challenge.status, challenge.totalDays]);

  const totalDaysText = useMemo(() => {
    return `${challenge.totalDays / challenge.certifyIntervalDays}주 동안`;
  }, [challenge.totalDays, challenge.certifyIntervalDays]);

  const intervalText = useMemo(() => {
    return getCertificationIntervalText(challenge.certifyIntervalTargetCount, '일');
  }, [challenge.certifyIntervalTargetCount]);

  return (
    <div className={s.Container} onClick={handleClickItem}>
      <div className={s.InfoContainer}>
        <Typography typography="subtitle1Bold" ellipsisAfterLines={1}>
          {challenge.name}
        </Typography>
        <Spacing size={4} />
        <div className={s.StatusContainer}>
          <Typography typography="caption1Regular" color={dateInfo.color}>
            {dateInfo.text}
          </Typography>
          <Typography typography="caption1Regular" color="gray600" style={{ padding: '0 .25rem' }}>
            ⸱
          </Typography>
          <Typography typography="caption1Regular" color="gray600">
            {totalDaysText}
          </Typography>
          <Typography typography="caption1Regular" color="gray600" style={{ padding: '0 .25rem' }}>
            ⸱
          </Typography>
          <Typography typography="caption1Regular" color="gray600">
            {intervalText}
          </Typography>
        </div>
        <Spacing size={8} />
        <div className={s.ParticipantsContainer}>
          <IconPerson2Fill size={16} color={vars.$scale.color.gray500} />
          <VerticalSpacing size={4} />
          <Typography typography="subtitle2Regular" color="gray700">
            {challenge.participantsCount}명 참여
          </Typography>
        </div>
      </div>
      {challenge.thumbnail && (
        <div className={s.ThumbnailContainer}>
          <VerticalSpacing size={12} />
          <img className={s.ThumbnailImage} src={challenge.thumbnail?.medium} />
        </div>
      )}
    </div>
  );
};

export default ChallengeListItem;
