import { HelpBubbleAnchor, ImageFieldItem, VerticalSpacing } from '@community-group/components';
import { IconCameraFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { GroupChallengeEditFormProps } from '../../pages';
import * as s from './style.css';
import { useHandleImageClick } from './useHandleImageClick';

type Props = {
  formHandler: UseFormReturn<GroupChallengeEditFormProps>;
};

export const GroupChallengeFormImagesField = ({ formHandler }: Props) => {
  const { handleImageClick, retryUploadImage } = useHandleImageClick({ formHandler });

  const images = useMemo(() => {
    return formHandler.watch('originImages') ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHandler.watch('originImages')]);

  const handleImageRemove = (id: string) => {
    formHandler.setValue(
      'originImages',
      formHandler.getValues('originImages').filter((image) => image.id !== id)
    );
  };

  return (
    <div className={s.Wrapper}>
      <HelpBubbleAnchor
        title={`인증샷 예시 사진을 올려주세요.`}
        isOpen={images.length === 0}
        portalled={false}
        positioning={{
          placement: 'right',
        }}
        UNSAFE_style={{
          pointerEvents: 'none',
          whiteSpace: 'pre-wrap',
        }}
      >
        <button className={s.ImageAddButton} onClick={handleImageClick}>
          <IconCameraFill size={24} color={vars.$scale.color.gray600} />
          <span style={{ color: vars.$scale.color.gray600, fontSize: '0.8125rem' }}>
            <strong
              style={{
                fontWeight: 400,
                color: images.length > 0 ? vars.$scale.color.carrot500 : vars.$scale.color.gray600,
              }}
            >
              {images.length}
            </strong>
            /10
          </span>
        </button>
      </HelpBubbleAnchor>
      {images.map((image) => (
        <ImageFieldItem
          key={image.url}
          image={image.url}
          onRemoveClick={handleImageRemove}
          onRetryClick={retryUploadImage}
          imageId={image.id}
        />
      ))}
      <VerticalSpacing size={8} />
    </div>
  );
};
