import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { withAsyncBoundary } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { useStorage } from '@/hooks/useStorage';

import useMainBannerFolded from '../../hooks/useMainBannerFolded';
import useMainBanners from './useMainBanners';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupDetailMainBanner = ({ group, currentUser }: Props) => {
  const { isTop } = useActivity();
  const { banners } = useMainBanners({ group, currentUser });
  const { handleChangeState } = useMainBannerFolded();
  const [foldedGroupDetailMainBanner] = useStorage('foldedGroupDetailMainBanner', true);

  const swiperWrapperRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperRef>(null);

  const initialRandomSlide = useMemo(() => {
    return Math.floor(Math.random() * banners.length);
  }, [banners]);

  const updateSwiper = () => {
    let count = 0;
    let interval: any = undefined;

    const updateAutoHeight = () => {
      // autoHeight 동작이 제대로 되지 않는 문제가 있어서 추가. 방어코드로 30번 이상 동작하면 중단.
      if (count > 30) {
        if (interval) clearInterval(interval);
        return;
      }
      count++;
      swiperRef.current?.swiper?.updateAutoHeight?.();
    };
    interval = setInterval(updateAutoHeight, 10);

    const timer = setTimeout(() => {
      clearInterval(interval);
    }, 300);

    return () => clearTimeout(timer);
  };

  // 배너의 열고 닫기 시 height가 변동되는데, autoHeight는 슬라이드를 넘기지 않은 상태에서는 Height를 업데이트 해주지 않아서 따로 업데이트 해줌
  useEffect(() => {
    swiperRef.current?.swiper?.updateAutoHeight?.();

    // 페이지 접근 후 500ms 뒤에 배너 열기
    // ssr 시점 swiper height가 제대로 반영되지 않는 문제가 있어서 500ms 뒤에 여는 로직 추가
    const openTimer = setTimeout(() => {
      handleChangeState(!!foldedGroupDetailMainBanner);
      updateSwiper();
    }, 500);

    swiperWrapperRef.current?.addEventListener('click', updateSwiper);
    return () => {
      clearTimeout(openTimer);
      swiperWrapperRef.current?.removeEventListener('click', updateSwiper);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foldedGroupDetailMainBanner, isTop]);

  return banners.length > 0 ? (
    <div ref={swiperWrapperRef}>
      <Swiper
        ref={swiperRef}
        modules={[Pagination]}
        pagination={banners.length > 1 ? true : false}
        initialSlide={initialRandomSlide}
        autoHeight
        loop
        style={
          {
            '--swiper-pagination-color': vars.$semantic.color.primary,
            '--swiper-pagination-bullet-size': '6px',
            '--swiper-pagination-bullet-horizontal-gap': '4px',
            '--swiper-pagination-bullet-inactive-color': vars.$scale.color.grayAlpha500,
            '--swiper-pagination-bottom': '.375rem',
            '--swiper-pagination-height': '100%',
          } as { [key: string]: string | number }
        }
      >
        {banners.map((banner: { id: string; element: React.ReactNode }, index: number) => {
          return (
            <SwiperSlide key={index}>
              <motion.div
                style={{
                  paddingBottom: banners.length > 1 ? '1.625rem' : '.25rem',
                }}
              >
                {banner.element}
              </motion.div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : null;
};

export default withAsyncBoundary(GroupDetailMainBanner, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
