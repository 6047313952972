import { BoxButton, VerticalSpacing } from '@community-group/components';

import * as s from './FilterBottomSheetButtonGroup.css';

type Props = {
  onApply: () => void;
  onReset: () => void;
};

const FilterBottomSheetButtonGroup = ({ onApply, onReset }: Props) => {
  return (
    <div className={s.ButtonContainer}>
      <div className={s.ResetButton}>
        <BoxButton width="100%" variant="secondary" size="xlarge" onClick={onReset}>
          초기화
        </BoxButton>
      </div>
      <VerticalSpacing size={8} />
      <div className={s.SubmitButton}>
        <BoxButton width="100%" size="xlarge" onClick={onApply}>
          적용하기
        </BoxButton>
      </div>
    </div>
  );
};

export default FilterBottomSheetButtonGroup;
