import { Typography } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React from 'react';

import { openLink } from '@/utils/link';

import * as s from './ChallengeSurveyCallout.css';

const ChallengeSurveyCallout = () => {
  const openChallengeSurvey = () => {
    const surveyLink = 'https://karrot-form-webview.kr.karrotwebview.com/survey/3462';
    openLink(surveyLink, false);
  };

  return (
    <div className={s.SurveyContainer}>
      <div className={s.OutlinedFeedbackBanner} onClick={openChallengeSurvey}>
        <Typography typography="bodyL2Regular" color="gray700">
          💌 챌린지에 대한 의견을 들려주세요.
        </Typography>
        <p className={s.LinkButton}>
          <Typography typography="bodyL2Bold" color="gray600">
            설문
          </Typography>
          <IconChevronRightFill width={14} height={14} color={vars.$scale.color.gray600} />
        </p>
      </div>
    </div>
  );
};

export default ChallengeSurveyCallout;
