import { BoxButton, Spacing, Typography } from '@community-group/components';

import { useFlow } from '@/stackflow';

import * as s from './GroupCreateButtonSection.css';

export const GroupCreateButtonSection = () => {
  const { push } = useFlow();

  const handleClickCreateGroup = () => {
    push('GroupNewSetGroupCategoryRecommendPage', {
      from: 'GroupDetailNotMemberPage',
    });
  };

  return (
    <div className={s.GroupCreateButtonSectionStyle}>
      <div className={s.GroupCreateButtonSectionContentStyle}>
        <Typography typography="subtitle1Bold">마음에 드는 모임이 없나요?</Typography>
        <Spacing size={2} />
        <Typography typography="subtitle2Regular" color="gray700">
          모임을 직접 만들어 보세요.
        </Typography>
        <Spacing size={24} />
        <BoxButton
          onClick={handleClickCreateGroup}
          minWidth="140px"
          size="small"
          variant="secondary"
        >
          모임 만들기
        </BoxButton>
      </div>
    </div>
  );
};
