import { AsyncBoundary } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import useLeaderBoard from '@/components/group/GroupLeaderBoard/hooks/useLeaderBoard';

import { BannerSection } from './BannerSection';
import ExplorerGroupListSection from './ExplorerGroupListSection';
import { ExplorerGroupListTitle } from './ExplorerGroupListSection/ExplorerGroupListTitle';
import LeaderBoardSection from './LeaderBoardSection';
import RecommendCurationGroupSection from './RecommendCurationGroupSection';
import RecommendNewGroupSection from './RecommendNewGroupSection';

const ExplorerGroupTab = () => {
  const { isOpenedLeaderBoard } = useLeaderBoard();

  return (
    <div style={{ minHeight: '100vh', background: vars.$semantic.color.paperDefault }}>
      <BannerSection />
      {isOpenedLeaderBoard && (
        <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
          <LeaderBoardSection />
        </AsyncBoundary>
      )}
      <RecommendCurationGroupSection />
      <RecommendNewGroupSection />
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <ExplorerGroupListSection appendTop={<ExplorerGroupListTitle />} />
      </AsyncBoundary>
    </div>
  );
};

export default ExplorerGroupTab;
