import { Switch } from '@community-group/components';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import { useBridge } from '@/contexts/Bridge';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import SettingMenuItem from './SettingMenuItem';
import SettingSectionWrapper from './Wrapper';

const GroupApplicationSettingSection = () => {
  const { groupId } = usePathParams();
  const { group } = useReadGroupDetail(groupId);

  const currentUser = useCurrentGroupMe();

  const { push } = useFlow();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { bridge } = useBridge();
  const { mutate, isPending } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });

      bridge.openToast({
        toast: {
          body: '수정되었어요.',
        },
      });
    },
  });

  const haveQuestion = !!group?.applicationConfig?.haveQuestion;

  const handleSetHaveQuestion = () => {
    if (isPending) return;

    if (!haveQuestion === true) {
      return push('BottomSheet/GroupSettingApplicationQuestionBottomSheet', {
        groupId,
        initialQuestionRequired: 'false',
        initialQuestionValue: '',
      });
    }

    mutate({
      id: groupId,
      groupModifyForm: {
        applicationConfig: {
          questions: [],
          haveQuestion: false,
        },
      },
    });
  };

  const shownApproval = group?.joinType === 'approval';
  const isUnlimitMaximumMember = !group?.maximumMemberCount || group?.maximumMemberCount === -1;

  return (
    <>
      <SettingSectionWrapper title="가입 관리">
        <SettingMenuItem
          title="가입 방식"
          onClick={() => {
            push('BottomSheet/GroupSettingApplicationApprovalBottomSheet', {
              groupId,
              initialJoinType: group?.joinType,
            });
          }}
          renderRight={group?.joinType === 'free' ? <p> 바로 가입</p> : <p>승인 후 가입</p>}
        />
        {shownApproval && (
          <>
            <SettingMenuItem
              title="가입 질문"
              description="가입 시 받을 질문을 입력할 수 있어요."
              renderRight={<Switch isSelected={haveQuestion} onChange={handleSetHaveQuestion} />}
            />
          </>
        )}
        {shownApproval && haveQuestion && (
          <SettingMenuItem
            title="가입 질문 수정"
            onClick={() => {
              push('BottomSheet/GroupSettingApplicationQuestionBottomSheet', {
                groupId,
                initialQuestionRequired:
                  (group?.applicationConfig?.questions[0].required.toString() ?? 'false') as
                    | 'true'
                    | 'false',
                initialQuestionValue: group?.applicationConfig?.questions[0].question ?? '',
              });
            }}
          />
        )}
        <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
          <SettingMenuItem
            title="모집 동네 범위 설정"
            onClick={() => {
              push('GroupSettingJoinableRegionRangePage', {
                groupId,
              });
            }}
          />
        </CheckAccessibleRole>
        <SettingMenuItem
          title="최대 가입 인원"
          onClick={() => {
            push('BottomSheet/GroupSettingEditMaximumMemberBottomSheet', {
              groupId,
              currentMemberCount: (group?.memberCount ?? 1).toString(),
              initialMaximumMemberCount: (group?.maximumMemberCount ?? -1).toString(),
            });
          }}
          renderRight={
            isUnlimitMaximumMember ? <p>제한 없음</p> : <p>{group?.maximumMemberCount}명</p>
          }
        />
      </SettingSectionWrapper>
    </>
  );
};

export default GroupApplicationSettingSection;
