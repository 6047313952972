import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetEventLogMyInfo = () => {
  const fetchInstance = useFetchInstance();
  const getEventLogMyInfo = groupClient.api.EventLogApi.apiV1EventLogMyInfoGet({
    axios: fetchInstance,
  });

  return useQuery({
    queryKey: ['eventLogMyInfo'],
    queryFn: () => getEventLogMyInfo(),
  });
};
