import { groupClient } from '@community-group/api';
import { InfiniteData, useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { queryClient } from '@/shared/api/instance';

import { useFetchInstance } from './instance/useFetchInstance';

export const getPhotoContestEntriesPath = () =>
  groupClient.api.GroupPhotoContestApi.getapiV1GroupsPhotoContestsCurrentEntriesGetPath();

type Params = {
  order?: 'popularity' | 'latest';
  localOnly?: boolean;
  initialCursor?: string | undefined;
};

export const useGetPhotoContestEntries = ({ order, localOnly, initialCursor }: Params) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.GroupPhotoContestApi.apiV1GroupsPhotoContestsCurrentEntriesGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data } = useSuspenseInfiniteQuery({
    queryKey: [getPhotoContestEntriesPath(), order, localOnly],
    queryFn: ({ pageParam = initialCursor }) => {
      const cursor = pageParam ? pageParam : undefined;
      const limit = 30;

      return get(cursor, limit, order, localOnly);
    },
    initialPageParam: initialCursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
  };
};

const updatePhotoContestEntries = (hasUserVoted: boolean) => (entryId: string) => {
  const queryKey = getPhotoContestEntriesPath();

  const updateEntry = (entry: groupClient.model.GroupPhotoContestEntryPresentation) => {
    if (entry.entryId === entryId) {
      const getCurrentVoteCount = () => {
        const isAlreadyVoted = entry.hasUserVoted === hasUserVoted;
        if (isAlreadyVoted) {
          return entry.voteCount;
        }

        return hasUserVoted ? entry.voteCount + 1 : entry.voteCount - 1;
      };

      return {
        ...entry,
        voteCount: getCurrentVoteCount(),
        hasUserVoted,
      };
    }

    return entry;
  };

  queryClient.setQueryData<
    InfiniteData<AxiosResponse<groupClient.model.GroupPhotoContestEntriesResponse>>
  >([queryKey], (prev) => {
    if (!prev) return;

    const next = prev.pages.map((page) => {
      return {
        ...page,
        data: {
          ...page.data,
          list: page.data?.list?.map(updateEntry),
        },
      };
    });

    return {
      pages: next,
      pageParams: prev.pageParams,
    };
  });
};

export const deleteLikePhotoContestEntries = updatePhotoContestEntries(false);
export const patchLikePhotoContestEntries = updatePhotoContestEntries(true);
