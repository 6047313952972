import useGetIsExistHeroSlider from '@/components/group/Detail/hooks/useGetIsExistHeroSlider';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useGetParentingGroupStorage } from '@/hooks/useGetParentingGroupStorage';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import GotoGroupHomeBanner from './GotoGroupHomeBanner';
import ParentingGroupRealtimeBanner from './ParentingGroupRealtimeBanner';

type Props = { groupId: string };

function GroupDetailNotMemberBanner({ groupId }: Props) {
  const { refer_root = '' } = useQueryParams();
  const isExistHeroSliderPhotos = useGetIsExistHeroSlider({ groupId });

  const parentingGroupBanner = useFeatureFlag('parentingGroupNotMemberBannerEnabled');
  const parentingGroupStorage = useGetParentingGroupStorage(groupId);

  // 동네생활 피드에서 진입하는 경우
  if (refer_root === 'community_tab') {
    return <GotoGroupHomeBanner isExistHeroSliderPhotos={isExistHeroSliderPhotos} />;
  }

  if (parentingGroupBanner && parentingGroupStorage.isShowNotMemberParentingGroupBannerEnabled) {
    return <ParentingGroupRealtimeBanner groupId={groupId} />;
  }

  return null;
}

export default GroupDetailNotMemberBanner;
