import axios from 'axios';
export interface ErrorResponse {
  timestamp: string;
  status: number;
  error: string;
  path: string;
  code: number;
  type: string;
  message: string;
}

export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    return error.response ?? { data: null };
  }

  throw error;
};
