import { GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import React, { useMemo } from 'react';

import { useGetChallengeDetail } from '@/api/hooks/useGetChallengeDetail';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './AppBarTitle.css';

export type AppBarTitleProps = {
  group?: GroupDetailPresentation;
};

type DisplayText = {
  title: string;
  subTitle?: string;
};

const AppBarTitle = ({ group }: AppBarTitleProps) => {
  const { postType, challengeId } = useQueryParams();
  const groupName = group?.name ?? '';
  const defaultTitle = '글쓰기';

  const isChallengeCertifyPost = postType === 'challengeCertify';

  // TODO: suspense와 enable을 함께 사용하는 법 찾아보기
  const { data: challengeDetail } = useGetChallengeDetail({
    groupId: group?.id.toString() ?? '',
    challengeId: challengeId ?? '',
  });

  const displayText: DisplayText = useMemo(() => {
    if (isChallengeCertifyPost) {
      return {
        title: '인증글 쓰기',
        subTitle: challengeDetail?.name ?? '',
      };
    }
    return {
      title: defaultTitle,
      subTitle: groupName,
    };
  }, [isChallengeCertifyPost, defaultTitle, groupName, challengeDetail]);

  return (
    <div className={s.Container}>
      <Typography typography="title3Bold" ellipsisAfterLines={1}>
        {displayText.title}
      </Typography>
      <Typography typography="caption2Regular" color="gray600" ellipsisAfterLines={1}>
        {displayText.subTitle}
      </Typography>
    </div>
  );
};

export default AppBarTitle;
