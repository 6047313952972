import { VerticalSpacing } from '@community-group/components';
import { IconFlameLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './index.css';

type Props = {
  groupId: string;
  closeBottomSheet: () => Promise<void>;
  replacePage: (page: string, params: Record<string, string>) => void;
};

const NEW_BADGE_IMG =
  'https://assetstorage.krrt.io/1025317940251365954/21405f71-d6fa-4270-9b7d-3fb985a7de3c/width=48,height=48.png';

function StartBottomSheetChallengeButton({ groupId, closeBottomSheet, replacePage }: Props) {
  const onClickChallengeButton = async () => {
    await closeBottomSheet();

    setTimeout(() => {
      replacePage('GroupChallengeNewTemplatePage', { groupId, from: 'postWrite' });
    }, 50);
  };

  return (
    <button className={s.ButtonItem} onClick={onClickChallengeButton}>
      <IconFlameLine size={20} color={vars.$scale.color.gray900} />
      <VerticalSpacing size={12} />
      <p>챌린지</p>
      <VerticalSpacing size={4} />
      <img src={NEW_BADGE_IMG} alt="new-service" width={16} height={16} />
    </button>
  );
}

export default StartBottomSheetChallengeButton;
