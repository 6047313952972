import { ContentType } from '@community-group/api/lib/group/models';
import { Spacing, useSnackbarAdapter } from '@community-group/components';
import { IconSquare2StackedFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { IconShareRegular } from '@/components/common/Icons/shareRegular';
import { StackflowTheme } from '@/components/common/TransparentNavScreen/Navbar';
import { useFlow } from '@/stackflow';
import { useTheme } from '@/stackflow/hooks/useTheme';
import { trackEvent } from '@/utils/analytics';
import {
  copyToClipboard,
  getGroupMeetupPermalink,
  getGroupPostPermalink,
  shareGroupMeetupPermalink,
  shareGroupPostPermalink,
} from '@/utils/link';

import ActionButtonItem from './ActionButtonItem';
import * as s from './ShareContentsActionButtonGroup.css';

type Props = {
  contentType: ContentType;
  groupId: string;
  permalinkId: string;
};

const ShareContentsActionButtonGroup = ({ contentType, groupId, permalinkId }: Props) => {
  const { pop } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();

  const { getTheme } = useTheme();
  const deviceType = useMemo(() => getTheme(), []) as StackflowTheme;

  const handleCopyLinkClipboard = () => {
    pop();
    if (contentType === 'groupPost' || contentType === 'groupMeetupReview') {
      copyToClipboard(getGroupPostPermalink(permalinkId));
    } else if (contentType === 'groupMeetup') {
      copyToClipboard(getGroupMeetupPermalink(permalinkId));
    }

    trackEvent({
      event: 'click_share_external',
      params: {
        groupId,
        postType: contentType,
        type: 'copy_clipboard',
      },
    });

    snackbarAdapter.create({
      message: '링크가 복사되었어요.',
      type: 'default',
      timeout: 1500,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
  };

  const handleShareExternal = () => {
    pop();

    trackEvent({
      event: 'click_share_external',
      params: {
        groupId,
        postType: contentType,
        type: 'share_external',
      },
    });

    if (contentType === 'groupMeetup') {
      shareGroupMeetupPermalink(permalinkId);
      return;
    }
    shareGroupPostPermalink(permalinkId);
  };

  return (
    <div className={s.ActionButtonListWrapper}>
      <ActionButtonItem
        buttonText="링크 복사"
        icon={<IconSquare2StackedFill size={20} color={vars.$scale.color.gray700} />}
        onClick={handleCopyLinkClipboard}
      />
      {deviceType === 'cupertino' ? <Spacing size={11} /> : <Spacing size={12} />}
      <ActionButtonItem
        buttonText="외부 공유"
        icon={<IconShareRegular size={20} color={vars.$scale.color.gray700} />}
        onClick={handleShareExternal}
      />
    </div>
  );
};

export default ShareContentsActionButtonGroup;
