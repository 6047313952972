import {
  IconBarchartSquareFill,
  IconCalendarFill,
  IconMegaphoneFill,
  IconPenHorizlineFill,
  IconPerson2Fill,
  IconPictureFill,
} from '@daangn/react-monochrome-icon';

import { MissionId } from './GroupLevelType';

export type MissionInfoType = Record<
  MissionId,
  {
    icon: React.ElementType;
    title: string;
    onlyHost: boolean;
  }
>;

// 미션 정보
export const MISSION_INFO: MissionInfoType = {
  activityPoint: {
    icon: IconBarchartSquareFill,
    title: '활동점수 모으기',
    onlyHost: false,
  },
  hasGroupDescription: {
    icon: IconPenHorizlineFill,
    title: '모임 소개 작성하기',
    onlyHost: true,
  },
  hasGroupThumbnail: {
    icon: IconPictureFill,
    title: '모임 대표사진 등록하기',
    onlyHost: true,
  },
  writeFirstPost: {
    icon: IconMegaphoneFill,
    title: '첫 글 작성하기',
    onlyHost: false,
  },
  writePost: {
    icon: IconPenHorizlineFill,
    title: '게시글 남기기',
    onlyHost: false,
  },
  writeMeetup: {
    icon: IconCalendarFill,
    title: '일정 만들기',
    onlyHost: false,
  },
  raiseMember: {
    icon: IconPerson2Fill,
    title: '멤버 모으기',
    onlyHost: false,
  },
  rollbackActivityPoint: {
    icon: IconBarchartSquareFill,
    title: '추가 활동 점수 모으기',
    onlyHost: false,
  },
};
