import { Typography, VerticalSpacing } from '@community-group/components';
import { IconDot3HorizontalChatbubbleLeftFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useEffect, useState } from 'react';

import * as s from './ParentingGroupRealtimeBanner.css';

type Props = {
  groupId: string;
};

type RealtimeMessageType = {
  [groupId: string]: string[];
};

const ParentingGroupRealtimeBanner = ({ groupId }: Props) => {
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    const isProduction = globalThis.appConfig.appEnv === 'production';
    import('./parentingGroupRealtimeMessage.json').then((json) => {
      const realtimeMessage: RealtimeMessageType = json.default;
      const key = isProduction ? groupId : 'alpha';
      setData(realtimeMessage[key] ?? []);
    });
  }, [groupId]);

  if (data.length === 0) return null;

  return (
    <div className={s.Banner}>
      <div className={s.PrefixText}>
        <IconDot3HorizontalChatbubbleLeftFill
          width={16}
          height={16}
          color={vars.$scale.color.blue700}
        />
        <VerticalSpacing size={6} />
        <Typography typography="bodyM2Bold" color="blue700">
          실시간
        </Typography>
      </div>
      <div className={s.MessageListWrapper}>
        <ul className={s.MessageList}>
          {[...data, data[0]].map((message, index) => (
            <li key={index} className={s.MessageListItem}>
              <Typography typography="label3Regular" color="blue700" ellipsisAfterLines={1}>
                {message}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ParentingGroupRealtimeBanner;
