import { IconCheckmarkFill, IconXmarkFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { Spinner } from '../../base';
import {
  initialStickyInputFormData,
  useStickyInputFormContext,
} from '../provider/StickyInputFormProvider';
import { useStickInputStore } from '../store';
import * as s from './EditButton.css';

export const StickyInputEditButton = () => {
  const {
    reset,
    watch,
    formState: { isSubmitting },
  } = useStickyInputFormContext();
  const { setFocused } = useStickInputStore();

  const shownSubmitButton = useMemo(() => {
    const content = watch('content');
    const images = watch('images');

    return content || images.length > 0;
  }, [watch('images'), watch('content')]);

  const isLoading = useMemo(() => {
    if (isSubmitting) {
      return true;
    }

    const isUploading = watch('originImages').some(({ id }) => id.includes('temp'));
    if (isUploading) {
      return true;
    }

    return false;
  }, [isSubmitting, watch('originImages')]);

  if (!shownSubmitButton) return <></>;

  return (
    <>
      <button
        className={s.button}
        disabled={isLoading}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          (document.activeElement as HTMLTextAreaElement)?.blur();
          setFocused(false);
          reset(initialStickyInputFormData);
        }}
        type="reset"
        style={{
          background: vars.$semantic.color.danger,
        }}
      >
        <IconXmarkFill size={18} color={vars.$static.color.staticWhite} />
      </button>
      <button
        className={s.button}
        disabled={isLoading}
        type="submit"
        style={{
          background: vars.$semantic.color.success,
        }}
      >
        {isLoading ? (
          <Spinner width={16} height={16} variant="white" />
        ) : (
          <IconCheckmarkFill size={16} color={vars.$static.color.staticWhite} />
        )}
      </button>
    </>
  );
};
