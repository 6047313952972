import {
  AsyncBoundary,
  Switch,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType, useActiveEffect } from '@stackflow/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import SubNicknamePreview from '@/components/common/GroupMemberSubNicknamePreview/SubNicknamePreview';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';

import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import useCreateForm from '../hooks/useCreateForm';
import useCreateGroupFunnel from '../hooks/useCreateGroupFunnel';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';
import * as s from './index.css';

export type GroupNewSetSubNicknameParams = Pick<
  PageParams,
  | 'name'
  | 'categoryId'
  | 'joinType'
  | 'question'
  | 'requiredQuestion'
  | 'haveQuestion'
  | 'createType'
  | 'from'
  | 'description'
  | 'joinableRegionRange'
  | 'needVerification'
  | 'canViewVerifiedUserInfo'
  | 'joinGrowth'
  | 'joinExerciseGrowth'
  | 'createGroupFlowType'
>;

const GroupNewSetSubNicknamePage: ActivityComponentType<GroupNewSetSubNicknameParams> = () => {
  const { push, pop } = useFlow();
  const { push: asnycPush } = useConnectedActions();
  const {
    from,
    createType,
    createGroupFlowType,
    ...postNewGroupPayload
  }: GroupNewSetSubNicknameParams = useQueryParams();

  const { handleSubmit, watch, control, setValue } = useCreateForm({
    defaultValues: {
      subNicknameQuestion: undefined,
    },
  });

  const hasSubNicknameFieldValue = watch('hasSubNickname');
  const questionFieldValue = watch('subNicknameQuestion');

  useActiveEffect(() => {
    if (hasSubNicknameFieldValue && !questionFieldValue) {
      setValue('hasSubNickname', false);
    }
  });

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'subNickname',
      needVerification: postNewGroupPayload.needVerification,
      canViewVerifiedUserInfo: postNewGroupPayload.canViewVerifiedUserInfo,
      createGroupFlowType: createGroupFlowType ?? 'normal',
    },
  });
  useSetFixedLayoutSize(false);
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false });

  const { nextPageName } = useCreateGroupFunnel();

  const onSubmit = handleSubmit(async () => {
    push(nextPageName, {
      ...postNewGroupPayload,
      from,
      createType,
      hasSubNickname: hasSubNicknameFieldValue,
      subNicknameQuestion: questionFieldValue,
      createGroupFlowType,
    });
  });

  const isButtonDisabled = useMemo(() => {
    if (!hasSubNicknameFieldValue) return false;
    if (hasSubNicknameFieldValue === true) {
      if (!questionFieldValue) return true;
      if (!questionFieldValue.trim()) return true;
    }
    return false;
  }, [questionFieldValue, hasSubNicknameFieldValue]);

  const handleOpenBottomSheet = async () => {
    const data = await asnycPush('BottomSheet/GroupSetNewSubnicknameQuestion', {
      subNicknameQuestion: questionFieldValue ?? '',
    });
    setValue('subNicknameQuestion', (data as { name: string }).name);
  };

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetSubNicknamePage')}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup
            onBack={pop}
            onSubmit={onSubmit}
            buttonDisabled={isButtonDisabled}
          />
        </>
      )}
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <LayoutWrapper padding="0 1rem">
          <p className={s.title}>별명을 사용할까요?</p>
          <Typography typography="bodyL1Regular" color="gray900">
            별명은 이 모임에서만 닉네임 옆에 함께 표시돼요.
          </Typography>
          <Spacing size={24} />
          <SubNicknamePreview subNicknameText="별명" />
          <Spacing size={16} />
          <div className={s.switchBox}>
            <Typography typography="bodyL1Regular" color="gray900">
              별명 사용
            </Typography>
            <Controller
              control={control}
              name="hasSubNickname"
              render={({ field: { onChange, name, value } }) => (
                <Switch
                  onChange={() => {
                    onChange(!value);
                    if (!value) {
                      handleOpenBottomSheet();
                    }
                  }}
                  name={name}
                  isSelected={value}
                />
              )}
            />
          </div>
          <Spacing size={8} />
          <AnimatePresence>
            {questionFieldValue && (
              <motion.div
                className={s.motionFlex}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                }}
                transition={{
                  duration: 0.1,
                }}
                exit={{ opacity: 0 }}
              >
                <div>
                  <Typography typography="bodyM1Regular" color="gray900">
                    별명 입력 요청 문구
                  </Typography>
                  <Spacing size={4} />
                  <Typography typography="label3Regular" color="gray600">
                    {questionFieldValue}
                  </Typography>
                </div>
                <IconChevronRightLine
                  size={20}
                  onClick={() => {
                    handleOpenBottomSheet();
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <Spacing size={32} />
        </LayoutWrapper>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetSubNicknamePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
