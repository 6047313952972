import { groupClient } from '@community-group/api';
import { GroupRestrictForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<void>,
  Error,
  {
    id: number;
    restrictForm: GroupRestrictForm;
  }
>;

export const usePostRestrictGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const restrictGroup = groupClient.api.GroupRestrictApi.apiV1GroupsIdRestrictPost({
    axios: fetchInstance,
  });

  const restrictGroupWrapperFp = ({
    id,
    restrictForm,
  }: {
    id: number;
    restrictForm: GroupRestrictForm;
  }) => {
    return restrictGroup(id, restrictForm);
  };

  return useThrottleMutation(restrictGroupWrapperFp, {
    onError,
    onSuccess,
  });
};
