import { Typography, VerticalSpacing } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './style.css';

type Props = {
  text: string;
  onClick: () => void;
};

const FooterButton = ({ text, onClick }: Props) => {
  return (
    <div onClick={onClick} className={s.Container}>
      <Typography typography="label3Regular" color="gray600">
        {text}
      </Typography>
      <VerticalSpacing size={1} />
      <IconChevronRightLine size={14} color={vars.$scale.color.gray600} />
    </div>
  );
};

export default FooterButton;
