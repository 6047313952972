import { GroupLevel } from './GroupLevelType';

// 라이트 모드
const GROUP_LEVEL_ICONS_LIGHT = {
  _2d: {
    large: {
      [GroupLevel[0]]:
        'https://assetstorage.krrt.io/1025317940251365954/9700cfbd-78dc-4f9a-9561-9e6837288e3c/width=300,height=300.png',
      [GroupLevel[1]]:
        'https://assetstorage.krrt.io/1025317940251365954/70bb76b2-a75e-4e52-9365-2cfc01e7ce89/width=144,height=144.png',
      [GroupLevel[2]]:
        'https://assetstorage.krrt.io/1025317940251365954/f48e4844-2a6a-4570-a14e-f1f97132fd20/width=144,height=144.png',
      [GroupLevel[3]]:
        'https://assetstorage.krrt.io/1025317940251365954/9f6ac421-2f67-468e-96fd-aade1893fdfd/width=144,height=144.png',
      [GroupLevel[4]]:
        'https://assetstorage.krrt.io/1025317940251365954/8c63471a-8233-4495-846c-91676bc27e59/width=144,height=144.png',
      [GroupLevel[5]]:
        'https://assetstorage.krrt.io/1025317940251365954/f64cd1a5-16eb-4df8-a713-8804c975ea00/width=144,height=144.png',
    },
    large_lock: {
      [GroupLevel[0]]:
        'https://assetstorage.krrt.io/1414875091366735739/f71e9e15-aa02-4f13-89df-d6ebb8a5b9f9/width=144,height=144.png',
      [GroupLevel[1]]:
        'https://assetstorage.krrt.io/1414875091366735739/f71e9e15-aa02-4f13-89df-d6ebb8a5b9f9/width=144,height=144.png',
      [GroupLevel[2]]:
        'https://assetstorage.krrt.io/1414875091366735739/b78e337e-08f6-422c-b951-650d117ae17a/width=144,height=144.png',
      [GroupLevel[3]]:
        'https://assetstorage.krrt.io/1414875091366735739/9bf3accf-6b4f-450b-8e85-a364b547b5d1/width=144,height=144.png',
      [GroupLevel[4]]:
        'https://assetstorage.krrt.io/1414875091366735739/9117caa4-c95b-4bce-a92f-47b96be122ef/width=144,height=144.png',
      [GroupLevel[5]]:
        'https://assetstorage.krrt.io/1414875091366735739/5e87b6cd-b34d-4ed4-b618-be189033dba5/width=144,height=144.png',
    },
    small: {
      [GroupLevel[0]]:
        'https://assetstorage.krrt.io/1025317940251365954/9700cfbd-78dc-4f9a-9561-9e6837288e3c/width=300,height=300.png',
      [GroupLevel[1]]:
        'https://assetstorage.krrt.io/1025317940251365954/09eb3ef4-4e20-4110-a73c-704c82324d66/width=84,height=84.png',
      [GroupLevel[2]]:
        'https://assetstorage.krrt.io/1025317940251365954/407de515-e9e4-4dcf-bc68-bf22fe357644/width=84,height=84.png',
      [GroupLevel[3]]:
        'https://assetstorage.krrt.io/1025317940251365954/32356cee-e4f3-4f0a-9fb2-a3d7e07d4465/width=84,height=84.png',
      [GroupLevel[4]]:
        'https://assetstorage.krrt.io/1025317940251365954/f928103b-6b63-4334-96bb-6e7d8123b8d2/width=84,height=84.png',
      [GroupLevel[5]]:
        'https://assetstorage.krrt.io/1025317940251365954/d5b478a0-4317-4578-aab9-7d450bf3f3da/width=84,height=84.png',
    },
  },
};

// 다크모드 아이콘
const GROUP_LEVEL_ICONS_DARK = {
  _2d: {
    large: {
      [GroupLevel[0]]:
        'https://assetstorage.krrt.io/1025317940251365954/9700cfbd-78dc-4f9a-9561-9e6837288e3c/width=300,height=300.png',
      [GroupLevel[1]]:
        'https://assetstorage.krrt.io/1025317940251365954/70bb76b2-a75e-4e52-9365-2cfc01e7ce89/width=144,height=144.png',
      [GroupLevel[2]]:
        'https://assetstorage.krrt.io/1025317940251365954/f48e4844-2a6a-4570-a14e-f1f97132fd20/width=144,height=144.png',
      [GroupLevel[3]]:
        'https://assetstorage.krrt.io/1025317940251365954/9f6ac421-2f67-468e-96fd-aade1893fdfd/width=144,height=144.png',
      [GroupLevel[4]]:
        'https://assetstorage.krrt.io/1025317940251365954/8c63471a-8233-4495-846c-91676bc27e59/width=144,height=144.png',
      [GroupLevel[5]]:
        'https://assetstorage.krrt.io/1025317940251365954/f64cd1a5-16eb-4df8-a713-8804c975ea00/width=144,height=144.png',
    },
    large_lock: {
      [GroupLevel[0]]:
        'https://assetstorage.krrt.io/1414875091366735739/f71e9e15-aa02-4f13-89df-d6ebb8a5b9f9/width=144,height=144.png',
      [GroupLevel[1]]:
        'https://assetstorage.krrt.io/1414875091366735739/f71e9e15-aa02-4f13-89df-d6ebb8a5b9f9/width=144,height=144.png',
      [GroupLevel[2]]:
        'https://assetstorage.krrt.io/1414875091366735739/b78e337e-08f6-422c-b951-650d117ae17a/width=144,height=144.png',
      [GroupLevel[3]]:
        'https://assetstorage.krrt.io/1414875091366735739/9bf3accf-6b4f-450b-8e85-a364b547b5d1/width=144,height=144.png',
      [GroupLevel[4]]:
        'https://assetstorage.krrt.io/1414875091366735739/9117caa4-c95b-4bce-a92f-47b96be122ef/width=144,height=144.png',
      [GroupLevel[5]]:
        'https://assetstorage.krrt.io/1414875091366735739/5e87b6cd-b34d-4ed4-b618-be189033dba5/width=144,height=144.png',
    },
    small: {
      [GroupLevel[0]]:
        'https://assetstorage.krrt.io/1025317940251365954/9700cfbd-78dc-4f9a-9561-9e6837288e3c/width=300,height=300.png',
      [GroupLevel[1]]:
        'https://assetstorage.krrt.io/1025317940251365954/df862863-392b-4b54-8df6-2d01611754d4/width=84,height=84.png',
      [GroupLevel[2]]:
        'https://assetstorage.krrt.io/1025317940251365954/43546194-de8e-4a98-804f-65da03620943/width=84,height=84.png',
      [GroupLevel[3]]:
        'https://assetstorage.krrt.io/1025317940251365954/a30313f2-1d82-407d-a73a-d81910c96be0/width=84,height=84.png',
      [GroupLevel[4]]:
        'https://assetstorage.krrt.io/1025317940251365954/478ecd8a-e7b1-475a-a7e5-6ef6fd693502/width=84,height=84.png',
      [GroupLevel[5]]:
        'https://assetstorage.krrt.io/1025317940251365954/124a3ccd-295c-4302-9e31-da9af6c87e8a/width=84,height=84.png',
    },
  },
};

const detectTheme = (): 'light' | 'dark' => {
  if (typeof window === 'undefined') return 'light';
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

const isDarkTheme = () => {
  return detectTheme() === 'dark';
};

export const GROUP_LEVEL_ICONS: {
  _2d: {
    large: Record<GroupLevel, string>;
    large_lock: Record<GroupLevel, string>;
    small: Record<GroupLevel, string>;
  };
} = isDarkTheme() ? GROUP_LEVEL_ICONS_DARK : GROUP_LEVEL_ICONS_LIGHT;
