import {
  GroupLevelPresentation,
  GroupLevelUpMissions,
} from '@community-group/api/lib/group/models';
import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { IconHandWaveFill } from '@daangn/react-monochrome-icon';
import {
  IconCalendarFill,
  IconDot3HorizontalChatbubbleLeftLine,
  IconMegaphoneFill,
  IconPenHorizlineFill,
  IconPictureFill,
} from '@daangn/react-monochrome-icon';
import {
  IconChevronDownFill,
  IconChevronRightFill,
  IconChevronUpFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { MISSION_INFO } from '../../utils/missions';
import * as s from './LevelUpMissionItem.css';

const ACTIVE_POINTS_GUIDE = [
  {
    title: '모임 방문하기',
    points: '0.025점',
    description: '실제 반영까지 시간이 걸릴 수 있어요.',
    icon: IconHandWaveFill,
  },
  {
    title: '일정 만들기',
    description: '3명 이상 모인 일정만 점수를 얻을 수 있어요.',
    points: '50점',
    icon: IconCalendarFill,
  },
  {
    title: '일정 후기 남기기',
    description: '3명 이상 모인 일정만 점수를 얻을 수 있어요.',
    points: '30점',
    icon: IconCalendarFill,
  },
  {
    title: '모임 소개 작성하기',
    points: '10점',
    icon: IconPenHorizlineFill,
  },
  {
    title: '모임 대표 사진 등록하기',
    points: '10점',
    icon: IconPictureFill,
  },
  {
    title: '첫 글 작성하기',
    points: '10점',
    icon: IconMegaphoneFill,
  },
  {
    title: '게시글 남기기',
    points: '5점',
    icon: IconPenHorizlineFill,
  },
  {
    title: '댓글 남기기',
    points: '1점',
    icon: IconDot3HorizontalChatbubbleLeftLine,
  },
];

const DoneMission = () => {
  return (
    <div className={s.DoneWrapper}>
      <Typography typography="caption1Bold" color="gray600">
        완료
      </Typography>
    </div>
  );
};

type MissionItemProps = {
  missions: GroupLevelUpMissions;
  missionId: keyof GroupLevelUpMissions;
  handleMissionClick: (missionId: keyof GroupLevelUpMissions, onlyHost: boolean) => void;
  index: number;
};
const DetailLevelUpMissionItem = ({
  missions,
  missionId,
  handleMissionClick,
  index,
}: MissionItemProps) => {
  const { openActivityPoint } = useQueryParams();
  const [fold, setFold] = useState<boolean>(openActivityPoint === 'true' ? false : true);

  const missionList = Object.keys(missions);
  const mission = missions[missionId];
  const missionInfo = MISSION_INFO[missionId];
  if (!mission || !missionInfo) return null; // 해당 미션이 없으면 렌더링하지 않음

  const doneMission = mission.currentCount >= mission.achieveCount;

  const isActivityPointMission =
    missionId === 'activityPoint' || missionId === 'rollbackActivityPoint';

  const renderMoreButton = () => {
    if (doneMission) return <DoneMission />;

    if (isActivityPointMission)
      return fold ? (
        <IconChevronDownFill color={vars.$scale.color.gray600} size={20} />
      ) : (
        <IconChevronUpFill color={vars.$scale.color.gray600} size={20} />
      );

    return <IconChevronRightFill color={vars.$scale.color.gray600} size={20} />;
  };

  const handleClickItem = () => {
    if (isActivityPointMission) {
      setFold(!fold);
    } else {
      handleMissionClick(missionId, missionInfo.onlyHost);
    }
  };

  return (
    <div key={missionId} onClick={handleClickItem}>
      <div className={s.MissionItemWrapper}>
        <div className={s.MissionInfoWrapper}>
          <div className={s.IconWrapper}>
            <missionInfo.icon color={vars.$scale.color.gray700} width={18} height={18} />
          </div>
          <VerticalSpacing size={12} />
          <div className={s.MissionTextWrapper}>
            <Typography typography="subtitle1Regular" color="gray900">
              {missionInfo.title}
            </Typography>
            <Spacing size={4} />
            <Typography typography="caption1Regular" color="gray600">
              {Number(mission.currentCount).toLocaleString()}/
              {Number(mission.achieveCount).toLocaleString()}
            </Typography>
          </div>
        </div>
        <div>{renderMoreButton()}</div>
      </div>
      <Spacing size={20} />
      <AnimatePresence initial={false}>
        {!fold && isActivityPointMission && <ActivityPointsSection />}
      </AnimatePresence>
    </div>
  );
};

export default DetailLevelUpMissionItem;

const ActivityPointsSection = () => {
  return (
    <>
      <motion.div
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { opacity: 0, height: 0 },
        }}
        transition={{
          type: 'just',
        }}
        className={s.ActivityPointsSectionWrapper}
      >
        <div className={s.ActivityPointsSectionInner}>
          {ACTIVE_POINTS_GUIDE.map((guide, index) => {
            const Icon = guide.icon;
            return (
              <div key={index} className={s.ItemWrapper}>
                <div className={s.ItemInnerWrapper}>
                  <Icon color={vars.$scale.color.gray700} size={16} />
                  <VerticalSpacing size={12} />
                  <div className={s.ItemTitleWrapper}>
                    <Typography typography="subtitle2Regular" color="gray900">
                      {guide.title}
                    </Typography>
                    {guide?.description && (
                      <>
                        <Spacing size={2} />
                        <Typography typography="caption1Regular" color="gray600">
                          {guide.description}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
                <VerticalSpacing size={12} />

                <Typography typography="subtitle2Regular" color="gray600">
                  {guide.points}
                </Typography>
              </div>
            );
          })}
        </div>
        <Spacing size={20} />
      </motion.div>
    </>
  );
};
