import { Typography } from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { MouseEventHandler, ReactNode } from 'react';

import * as s from './GroupDetailMainBannerCore.css';

type Props = {
  title: string;
  description: string;
  categoryIcon: ReactNode;
  onClick: MouseEventHandler;
};

const GroupDetailMainBannerCore = ({ title, description, categoryIcon, onClick }: Props) => {
  return (
    <div className={s.Wrapper} onClick={onClick}>
      <div className={s.Body}>
        {categoryIcon}
        <VerticalSpacing size={16} />
        <div className={s.BodyContent}>
          <Typography typography="caption1Regular" color="gray900">
            {description}
          </Typography>
          <Spacing size={2} />
          <Typography typography="subtitle2Bold" color="gray900">
            {title}
          </Typography>
        </div>
        <VerticalSpacing size={16} />
        <IconChevronRightFill size={16} color="gray600" />
      </div>
    </div>
  );
};

export default GroupDetailMainBannerCore;
