import { groupClient } from '@community-group/api';

import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId: number;
};

/*
 *  전국 러닝 그로스 데이터 리페치하는 함수
 */
export const refetchGroupRunningGrowth = ({ groupId }: Params) => {
  // onSuccess 시점에 refetch를 하면, 수정된 runningGrowth mission 데이터가 바로 반영되지 않는 이슈가 있어서 setTimeout으로 처리
  setTimeout(() => {
    queryClient.refetchQueries({
      queryKey: [
        groupClient.api.GroupRunningGrowthApi.getapiV1GroupsGroupIdRunningGrowthGetPath(groupId),
      ],
    });
  }, 1000);
};
