import { GroupCurrentUser } from '@community-group/api/lib/group/models';
import {
  Dialog,
  GroupAvatar,
  HorizontalSpacing,
  isHigherManager,
  isNotMember,
  Typography,
  useDialog,
  VerticalSpacing,
} from '@community-group/components';
import { IconCrownFill } from '@daangn/react-monochrome-icon';
import { ReactNode } from 'react';

import { GroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import { BLOCK_GROUP_ONLY_TEXT } from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import ActivityBox from '@/components/group/profile/GroupProfile/Detail/components/ActivityBox';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { getUserProfileUrlInduceExperiment } from '@/utils/analytics/experiment/experiments/userProfileInduceExperiment';

import { useHandleGroupOnlyForProfile } from '../hooks/useHandleGroupOnlyForProfile';
import * as s from './Information.css';

type Props = {
  profile?: Pick<
    GroupMainProfile,
    | 'id'
    | 'profileImage'
    | 'nickname'
    | 'subNickname'
    | 'temperature'
    | 'description'
    | 'role'
    | 'joinedAt'
    | 'userRegion'
    | 'userRegionCheckInCount'
    | 'verifiedUserInfoText'
    | 'state'
    | 'activities'
    | 'snsTags'
  > & {
    verified?: boolean;
  };
  isModal?: boolean;
  moreInformationRender?: ReactNode;
  currentUser?: Pick<GroupCurrentUser, 'id' | 'role' | 'state'> & { verified?: boolean };
  onClickProfileImage?: () => void;
};

const Information = ({
  profile,
  isModal,
  moreInformationRender,
  currentUser,
  onClickProfileImage,
}: Props) => {
  const { push } = useFlow();

  const { groupId } = usePathParams();
  const profileImage = getUserProfileUrlInduceExperiment({
    groupId: groupId ? parseInt(groupId) : 0,
    userId: currentUser?.id ?? 0,
    defaultUrl: profile?.profileImage ?? '',
  });
  const { open: openDialog, close: closeDialog } = useDialog();
  const { handleGroupOnly } = useHandleGroupOnlyForProfile({
    groupId,
  });

  const handleImageClick = () => {
    if (onClickProfileImage) {
      onClickProfileImage();
      return;
    }
    const isBlock = currentUser && isNotMember(currentUser?.role);
    trackEvent({
      event: 'click_user_profile_image',
      params: { groupId: groupId, isJoined: !isBlock, userState: currentUser?.state },
      sample: true,
    });
    if (currentUser?.state === 'applied') {
      openDialog({
        element: (
          <Dialog
            title="모임 가입 안내"
            description="가입 승인 후 프로필을 볼 수 있어요. 승인 후 다시 확인해주세요."
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
            }}
          />
        ),
        onOutsideClick: closeDialog,
      });
      return;
    }

    handleGroupOnly({
      isBlock,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getMemberProfileImage,
      onSettled: () => {
        push('ImageViewerPage', {
          content: 'profile',
          groupId,
          userId: profile?.id.toString() ?? '0',
          initialIndex: '0',
        });
      },
      onSuccess: () => {
        push('ImageViewerPage', {
          content: 'profile',
          groupId,
          userId: profile?.id.toString() ?? '0',
          initialIndex: '0',
        });
      },
    });
  };

  return (
    <div>
      <div className={s.top}>
        <div className={s.leftBox} onClick={handleImageClick}>
          <GroupAvatar size="large" src={profileImage} role={profile?.role}></GroupAvatar>
        </div>
        <div className={s.rightBox}>
          <div className={s.topContentWrapper}>
            <div
              className={s.titleWrapper}
              style={{ marginBottom: moreInformationRender ? '0.125rem' : '0' }}
            >
              <Typography className={s.name} typography="title3Bold" color="gray900">
                {profile?.nickname}
              </Typography>
              {isHigherManager(profile?.role) && (
                <>
                  <VerticalSpacing size={4} />
                  <IconCrownFill
                    size={16}
                    color={profile?.role === 'superHost' ? '#f7be68' : '#30c795'}
                  />
                  <VerticalSpacing size={2} />
                  <Typography typography="subtitle2Regular" color="gray600">
                    {profile?.role === 'superHost' ? '모임장' : '운영진'}
                  </Typography>
                </>
              )}
            </div>
            <HorizontalSpacing size={2} />
            <Typography color="gray900" typography="subtitle2Regular">
              {profile?.subNickname}
            </Typography>
          </div>
          {moreInformationRender}
        </div>
      </div>
      <HorizontalSpacing size={16} />
      {profile?.activities && (
        <>
          <div className={s.activityWrapper}>
            <ActivityBox title="모임 방문" count={profile?.activities.groupVisitCount} />
            <div className={s.divider} />
            <ActivityBox title="게시글" count={profile?.activities.entirePostCount} />
            <div className={s.divider} />
            <ActivityBox title="댓글" count={profile?.activities.entireCommentCount} />
            <div className={s.divider} />
            <ActivityBox title="일정 참여" count={profile?.activities.entireMeetupCount} />
          </div>
          <HorizontalSpacing size={16} />
        </>
      )}
      {profile?.description && (
        <>
          <Typography
            color="gray900"
            typography="bodyM1Regular"
            whiteSpace="pre-wrap"
            style={{
              lineHeight: '1.625rem',
              maxHeight: isModal ? '150px' : 'auto',
            }}
          >
            {profile?.description.trim()}
          </Typography>
          <HorizontalSpacing size={profile?.snsTags && profile.snsTags.length > 0 ? 8 : 16} />
        </>
      )}
    </div>
  );
};

export default Information;
