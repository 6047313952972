import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useMemo } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useHandleRestrictedUser } from '@/hooks/useHandleRestrictedUser';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { openAptV2Detail } from '@/utils/link';
import { isMember as checkIsMember } from '@/utils/role';

import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GroupDetailNotMemberPage from '../../DetailNotMember/pages/GroupDetailNotMemberPage';
import GroupDetailAppBarMenu from '../components/GroupDetailAppBarMenu';
import GroupDetailAppScreen from '../components/GroupDetailAppScreen';
import GroupDetailBanner from '../components/GroupDetailBanner';
import { GroupDetailContext } from '../components/GroupDetailContext';
import GroupDetailForMember from '../components/GroupDetailForMember';
import GroupDetailHeader from '../components/GroupDetailHeader';
import GroupDetailHooks from '../components/GroupDetailHooks';
import GroupDetailSidebar from '../components/GroupDetailSidebar';

type GroupDetailPageParams = Pick<
  PageParams,
  | 'groupId'
  | 'activeTab'
  | 'isNew'
  | 'from'
  | 'requestMeetupReviewId'
  | 'boardCategoryKey'
  | 'applicationStatus'
  | 'openSidebar'
  | 'isNewGroup'
>;

const GroupDetailPage: ActivityComponentType<GroupDetailPageParams> = (params) => {
  const { groupId } = usePathParams();
  const { data: groupMe } = useGetGroupMe(groupId);
  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId });
  const { group } = useReadGroupDetail(groupId);
  const currentUserRole = useMemo(() => groupMe?.currentUser?.role ?? 'none', [groupMe]);
  const hideHeroImage = useMemo(() => Boolean(group?.backgroundImage.isDefault), [group]);
  const isMember = useMemo(() => checkIsMember(currentUserRole), [currentUserRole]);

  // TODO: https://daangn.slack.com/archives/C0336FFHA8L/p1731911233257399?thread_ts=1731895483.923689&cid=C0336FFHA8L
  useEffect(() => {
    if (group?.domainType === 'apt') {
      openAptV2Detail(group.id.toString());
    }
  }, []);

  useHandleRestrictedUser({
    serviceType: 'group',
    groupId,
  });

  if (!group) return <ViewLoader />;

  if (group?.domainType === 'apt') return null;

  return (
    <GroupDetailAppScreen
      title={group.name}
      isExistHeroSlider={isExistHeroSlider}
      transparent={!hideHeroImage}
      appendRight={({ iconColor }) => (
        <GroupDetailAppBarMenu
          group={group}
          currentUserRole={currentUserRole}
          iconColor={iconColor}
        />
      )}
    >
      <GroupDetailContext>
        {!isMember ? <GroupDetailNotMemberPage {...params} /> : <GroupDetailMemberPage />}
      </GroupDetailContext>
    </GroupDetailAppScreen>
  );
};

const GroupDetailMemberPage = () => {
  const { groupId } = usePathParams();
  const { data: groupMe } = useGetGroupMe(groupId);
  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId });
  const { group } = useReadGroupDetail(groupId);
  const currentUserRole = useMemo(() => groupMe?.currentUser?.role ?? 'none', [groupMe]);
  const hideHeroImage = useMemo(() => Boolean(group?.backgroundImage.isDefault), [group]);
  const { data } = useGetGroupLevel({ groupId: Number(groupId) });

  const { push } = useFlow();
  const handleHeaderTitleTextClick = () => {
    push('GroupDetailSummaryPage', { groupId });
  };

  if (!group) {
    return <ViewLoader />;
  }

  return (
    <>
      <GroupDetailHeader
        isExistHeroSlider={isExistHeroSlider}
        groupId={group.id}
        title={group.name}
        postCount={group.postCount}
        memberCount={group.memberCount}
        profileImageUrl={group.profileImage.medium}
        lastActivatedAt={group.lastActivatedAt}
        onClickTitleText={handleHeaderTitleTextClick}
        role={currentUserRole}
        heroImageUrl={hideHeroImage ? undefined : group.backgroundImage.medium}
        groupLevel={data?.currentLevel ?? 0}
      >
        <GroupDetailBanner groupId={group.id} />
      </GroupDetailHeader>
      {groupMe?.currentUser && (
        <GroupDetailForMember group={group} currentUser={groupMe.currentUser} />
      )}
      {groupMe?.currentUser && (
        <GroupDetailSidebar group={group} currentUser={groupMe.currentUser} />
      )}
      {groupMe?.currentUser && <GroupDetailHooks group={group} currentUser={groupMe.currentUser} />}
    </>
  );
};

export default withAsyncBoundary(GroupDetailPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
