import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupSidebarPath = (groupId: number) =>
  groupClient.api.GroupSidebarApi.getapiV1GroupsIdSidebarGetPath(groupId);

export const useGetGroupSideBar = (groupId: number) => {
  const fetchInstance = useFetchInstance();
  const getGroupHome = groupClient.api.GroupSidebarApi.apiV1GroupsIdSidebarGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getGroupSidebarPath(groupId)],
    queryFn: () => getGroupHome(groupId),
  });

  const response = data?.data;

  return { data: response, refetch };
};
