import {
  HelpBubbleAnchor as SproutHelpBubbleAnchor,
  SeedHelpBubbleProps,
} from '@daangn/sprout-components-help-bubble';
import React from 'react';

type Props = {
  children: React.ReactNode;
} & SeedHelpBubbleProps;

export const HelpBubbleAnchor = ({ children, ...props }: Props) => {
  return <SproutHelpBubbleAnchor {...props}>{children}</SproutHelpBubbleAnchor>;
};
