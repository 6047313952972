import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type OptionType = UseQueryOptions<
  AxiosResponse<model.GroupLeaderBoardPresentation, unknown> | undefined,
  unknown,
  AxiosResponse<model.GroupLeaderBoardPresentation, unknown> | undefined,
  string[]
>;

export const getGroupLeaderBoardPath = (weekAgo: number) =>
  groupClient.api.GroupLeaderboardApi.getapiV1GroupsLeaderboardGetPath(weekAgo);

type Params = {
  weekAgo: number;
};

export const useGetGroupLeaderBoard = ({ weekAgo }: Params, options?: OptionType) => {
  const fetchInstance = useFetchInstance();
  const getGroupLeaderBoard = groupClient.api.GroupLeaderboardApi.apiV1GroupsLeaderboardGet({
    axios: fetchInstance,
  });

  const { data, isLoading, isError, refetch } = useSuspenseQuery({
    queryKey: [getGroupLeaderBoardPath(weekAgo), weekAgo.toString()],
    queryFn: () => getGroupLeaderBoard(weekAgo),

    ...options,
  });

  const response = data?.data;

  return { data: response, isLoading, isError, refetch };
};
