import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse,
  Error,
  {
    id: number;
    putActiveNotificationModifyForm?: model.GroupNotificationSettingV2Form;
  }
>;

export const usePatchActiveNotification = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putModify = groupClient.api.NotificationApi.apiV2GroupsIdNotificationPatch({
    axios: fetchInstance,
  });

  const putModifyWrapperFp = ({
    id,
    putActiveNotificationModifyForm = { daangnGroupNotificationState: 'all_on' },
  }: {
    id: number;
    putActiveNotificationModifyForm: model.GroupNotificationSettingV2Form;
  }) => {
    return putModify(id, putActiveNotificationModifyForm);
  };

  return useThrottleMutation(putModifyWrapperFp, {
    onError,
    onSuccess,
  });
};
