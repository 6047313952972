import { groupClient } from '@community-group/api';
import { GroupAptMemberTalkTargetUriResponse } from '@community-group/api/lib/apt/models';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<GroupAptMemberTalkTargetUriResponse>,
  Error,
  { id: string; memberId: string }
>;

export const useGetGroup1on1PayTalkTargetUri = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const getGroup1on1TalkTargetUri =
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdPayTalkTargetUriGet({
      axios: fetchInstance,
    });

  const getGroup1on1TalkTargetUriFp = ({ id, memberId }: { id: string; memberId: string }) => {
    return getGroup1on1TalkTargetUri(Number(id), Number(memberId));
  };

  return useMutation({
    mutationFn: getGroup1on1TalkTargetUriFp,
    onError,
    onSuccess,
  });
};
