import { vars } from '@seed-design/design-token';

import * as s from './index.css';

type Props = {
  size?: number;
  background?: string;
  padding?: number;
  direction?: 'horizontal' | 'vertical';
};

export const Divider = ({
  size = 1,
  background = vars.$semantic.color.divider1,
  padding = 0,
  direction = 'horizontal',
}: Props) => {
  if (direction === 'vertical') {
    return (
      <div
        className={s.verticalWrapper}
        style={{
          width: `${size}px`,
          padding: `${padding}px 0`,
        }}
      >
        <div className={s.line} style={{ width: `${size}px`, height: '100%', background }} />
      </div>
    );
  }
  return (
    <div
      className={s.wrapper}
      style={{
        height: `${size}px`,
        padding: `0 ${padding}px`,
      }}
    >
      <div
        className={s.line}
        style={{
          height: `${size}px`,
          background,
        }}
      />
    </div>
  );
};
