import { AsyncBoundary, Divider, Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupFeedSummaryMeetup } from '@/api/hooks/useGetGroupFeedSummaryMeetup';
import { useGetGroupFeedSummaryMember } from '@/api/hooks/useGetGroupFeedSummaryMember';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetGroupSuperHostDetail } from '@/api/hooks/useGetGroupSuperHostDetail';
import { useGetMeetupReviewsForNotMember } from '@/api/hooks/useGetMeetupReviewsForNotMember';
import TransparentNavTitleShowObserver from '@/components/common/TransparentNavScreen/TransparentNavTitleShowObserver';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import FixedBottomSection from '../../Detail/components/FixedBottomSection';
import RecommendSimilarGroupSection from '../../Detail/components/Home/components/RecommendSimilarGroupSection';
import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GroupDetailHooks from '../../DetailV2/components/GroupDetailHooks';
import GroupDetailHeroSlider from '../../ImageViewerPage/components/GroupDetailHeroSlider';
import DetailNotMemberFeedSummary from '../components/DetailNotMemberFeedSummary';
import { GroupCreateButtonSection } from '../components/GroupCreateButtonSection';
import GroupDetailNotMemberBanner from '../components/GroupDetailNotMemberBanner';
import GroupInfoSection from '../components/GroupInfoSection';
import HostInfoSection from '../components/HostInfoSection';
import MeetupReviewSection from '../components/MeetupReviewsSection';
import MeetupSection from '../components/MeetupSection';
import MemberSection from '../components/MemberSection';
import { validHostInfoData } from '../utils/validData';
import NotMemberSkeleton from './NotMemberSkeleton';

export type GroupDetailPageParams = Pick<
  PageParams,
  'groupId' | 'activeTab' | 'isNew' | 'from' | 'prevViewedGroupId'
> &
  Pick<ActivityQueryParams, 'requestMeetupReviewId' | 'boardCategoryKey' | 'applicationStatus'>;

// Detail 미가입자뷰
const GroupDetailNotMemberPage: ActivityComponentType<GroupDetailPageParams> = () => {
  const { groupId } = usePathParams();
  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId });

  return (
    <AsyncBoundary pendingFallback={<NotMemberSkeleton isExistHeroSlider={isExistHeroSlider} />}>
      <GroupDetailNotMemberInner />
    </AsyncBoundary>
  );
};

const GroupDetailNotMemberInner = () => {
  const { groupId } = usePathParams();
  const { group } = useReadGroupDetail(groupId);
  const { data: groupMe } = useGetGroupMe(groupId);
  const { data: feedMembers } = useGetGroupFeedSummaryMember({
    groupId: Number(groupId),
  });
  const currentUserRole = useMemo(() => groupMe?.currentUser?.role ?? 'none', [groupMe]);

  const { data: superHostInfo } = useGetGroupSuperHostDetail(groupId);
  const { data: feedMeetups } = useGetGroupFeedSummaryMeetup(Number(groupId));
  const { data: meetupReviews } = useGetMeetupReviewsForNotMember(groupId);

  const filteredMembers =
    feedMembers?.members?.filter((member) => member.id !== group?.superHost.id) ?? [];

  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId });

  if (!group) return <NotMemberSkeleton />;

  const sections = [
    {
      order: 1,
      name: 'heroSlider',
      condition: isExistHeroSlider,
      component: (
        <GroupDetailHeroSlider groupId={groupId} initialIndex={0} role={currentUserRole} />
      ),
    },
    {
      order: 2,
      name: 'groupDetailBanner',
      condition: group,
      component: <GroupDetailNotMemberBanner groupId={groupId} />,
    },
    {
      order: 3,
      name: 'groupInfoSection',
      condition: group,
      component: (
        <>
          <TransparentNavTitleShowObserver />
          <GroupInfoSection group={group} />
        </>
      ),
    },
    {
      order: 4,
      name: 'meetupReviewSection',
      dividerHeight: 1,
      condition: (meetupReviews?.reviews ?? []).length > 2,
      component: <MeetupReviewSection groupId={groupId} />,
    },
    {
      order: 5,
      name: 'hostInfoSection',
      dividerHeight: 1,
      condition: validHostInfoData(superHostInfo),
      component: superHostInfo && <HostInfoSection groupId={groupId} hostInfo={superHostInfo} />,
    },
    {
      order: 6,
      name: 'memberSection',
      dividerHeight: 1,
      condition: filteredMembers?.length > 0,
      component: (
        <MemberSection
          groupId={groupId}
          members={filteredMembers}
          group={group}
          groupMe={groupMe}
        />
      ),
    },
    {
      order: 7,
      name: 'meetupSection',
      dividerHeight: 1,
      condition: feedMeetups && feedMeetups.meetups.length > 0,
      component: (
        <MeetupSection
          feedMeetup={feedMeetups}
          groupId={groupId}
          group={group}
          shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
        />
      ),
    },
    {
      order: 8,
      name: 'detailNotMemberFeedSummary',
      dividerHeight: 1,
      condition: groupMe?.currentUser,
      component: <DetailNotMemberFeedSummary group={group} currentUser={groupMe?.currentUser} />,
    },
    {
      order: 9,
      name: 'recommendSimilarGroupSection',
      condition: true,
      dividerHeight: 8,
      dividerPadding: 0,
      component: <RecommendSimilarGroupSection categoryId={group.category.id} />,
    },
    {
      order: 10,
      dividerHeight: 8,
      dividerPadding: 0,
      name: 'groupCreateButtonSection',
      condition: true,
      component: <GroupCreateButtonSection />,
    },
  ];

  return (
    <>
      {sections
        .filter((section) => Boolean(section.condition))
        .map((section) => (
          <>
            {section?.dividerHeight && (
              <Divider size={section.dividerHeight} padding={section?.dividerPadding ?? 16} />
            )}
            {section.component}
          </>
        ))}
      <Spacing size={120} />
      <FixedBottomSection enableNeedVerificationNotice={false} />
      {groupMe?.currentUser && <GroupDetailHooks group={group} currentUser={groupMe.currentUser} />}
    </>
  );
};

export default GroupDetailNotMemberPage;
