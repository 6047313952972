import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { useMemo } from 'react';

import { useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { getInfinitiveImagesFlatList } from '../utils/imageUtil';
import { SLIDER_IMAGE_COUNT } from './GroupDetailHeroSlider';
import * as s from './GroupSettingEditInfoHeroSlider.css';
import ImageViewerSlider from './ImageViewerSlider';

export type AlbumImageViewerProps = {
  groupId: string;
  initialIndex: number;
  role: GroupMemberRoleEnum;
};

// 앨범 이미지 뷰어 : 게시물 더보기 버튼, 상단 고정 버튼
const GroupSettingEditInfoHeroSlider = ({ groupId, initialIndex, role }: AlbumImageViewerProps) => {
  const { push } = useFlow();
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetGroupPhotoList({
    groupId,
  });

  const photoList = useMemo(() => getInfinitiveImagesFlatList(data), [data]).slice(
    0,
    SLIDER_IMAGE_COUNT
  );
  const flattenPhotoList = photoList.map((el) => {
    return {
      id: el.image.id,
      imageUrl: el.image.medium,
      postId: el.post.id,
      isPinned: el.isPinned,
    };
  });

  const handlePinPhoto = async () => {
    trackEvent({
      event: 'click_pin_the_image_setting_page',
      params: {
        groupId,
      },
    });
    push('GroupSettingHeroImageSelectorPage', { groupId });
  };

  return (
    <div
      className={clsx(s.Wrapper({ notMember: role === 'none' }), 'group-setting-edit-info-slider')}
    >
      <ImageViewerSlider
        imageData={{
          imageList: flattenPhotoList,
          initialIndex,
          hasNextPage,
          fetchNextPage,
          isFetchingNextPage,
        }}
        objectFit="cover"
      />
      <div className={s.TopGradation} />
      <div className={s.BottomGradation} />
      <div className={s.MoveToPostAlbumWrapper} onClick={handlePinPhoto}>
        <Typography typography="caption2Regular" color="staticWhite">
          배경사진 설정
        </Typography>
        <IconChevronRightLine size={14} color={vars.$static.color.staticWhite} />
      </div>
    </div>
  );
};

export default GroupSettingEditInfoHeroSlider;
