import { Spacing, Typography } from '@community-group/components';
import { PaginationList } from '@community-group/components/shared';

import { useGetChallengeParticipantsRank } from '@/api/hooks/useGetChallengeParticipantsRank';
import { useUserConfig } from '@/contexts/UserConfig';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import ParticipantsItem from './ParticipantsItem';
import * as s from './style.css';

const ParticipantsRankSection = () => {
  const { groupId, challengeId } = usePathParams();
  const { userConfig } = useUserConfig();
  const { fetchNextPage, hasNextPage, isFetchingNextPage, data } = useGetChallengeParticipantsRank({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  return (
    <div className={s.Container}>
      <div className={s.TitleWrapper}>
        <Typography typography="title3Bold">전체 순위</Typography>
      </div>
      <Spacing size={8} />
      <PaginationList
        items={data?.pages ?? []}
        render={(item) =>
          item && (
            <ParticipantsItem
              key={item.id}
              groupId={groupId}
              isMe={item.id === userConfig.userId}
              {...item}
            />
          )
        }
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default ParticipantsRankSection;
